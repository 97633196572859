import firebase from 'firebase/compat/app';
import {getAnalytics, setUserId} from 'firebase/analytics';
import 'firebase/compat/auth';
import {config} from './firebase-credentials';
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  onIdTokenChanged,
  signInWithPopup,
  User,
} from 'firebase/auth';
import {getStorage} from 'firebase/storage';
import {getPerformance} from 'firebase/performance';

import {setAccessToken} from '../user/user-utils';

const app = firebase.initializeApp(config);
const analytics = getAnalytics(app);

export const setAnalyticsUserId = (userId: string) => {
  setUserId(analytics, userId);
};

export const auth: any = getAuth(app);
export const storage = getStorage(app);

// Enabled Firebase performance monitoring (https://firebase.google.com/docs/perf-mon/get-started-web)
export const performance = getPerformance(app);

const providerGoogle = new GoogleAuthProvider();
const providerFacebook = new FacebookAuthProvider();
const providerApple = new OAuthProvider('apple.com');

providerGoogle.setCustomParameters({prompt: 'select_account'});
providerFacebook.setCustomParameters({prompt: 'select_account'});
providerApple.setCustomParameters({prompt: 'select_account'});

export const signInWithGoogle = () => signInWithPopup(auth, providerGoogle);
export const signInWithFacebook = () => signInWithPopup(auth, providerFacebook);
export const signInWithApple = () => signInWithPopup(auth, providerApple);

export default firebase;

onAuthStateChanged(auth, setCurrentAccessToken);
onIdTokenChanged(auth, setCurrentAccessToken);

async function setCurrentAccessToken(user: User | null) {
  const newToken = await user?.getIdToken();
  if (newToken) {
    setAccessToken(newToken);
  }
}

import {parseDateTime} from '../../utils/utils';
import styles from './SchedulingCard.module.scss';
import {Session} from './SchedulingForm';
import {useGetSport} from '../../common/useGetSport';

type ScheduleCardProps = {
  session: Session;
  selected?: boolean;
  small?: boolean;
};

export const ScheduleCard = (props: ScheduleCardProps) => {
  const {session} = props;
  const {sessionTypeName} = useGetSport();

  const parsedStart = parseDateTime(session.startDate);
  const parsedEnd = parseDateTime(session.endDate);
  const coach = `Coach ${session?.staff?.firstName} ${session?.staff?.lastName}`;

  return (
    <>
      <div className={`${styles.container} ${props.selected ? styles.selected : ''}`}>
        <div className={`${styles.topSection} ${styles.small} `}>
          <span className={styles.dateSmall}>
            {parsedStart.monthStr} {parsedStart.day}
          </span>
          <span className={styles.date}>{sessionTypeName}</span>
        </div>
        <div className={styles.main}>
          <span className={styles.time}>
            {parsedStart.time} - {parsedEnd.time} <span className={styles.zone}>{parsedEnd.zone}</span>
          </span>
          <span className={styles.coach}>{coach}</span>
        </div>
      </div>
    </>
  );
};

import {PropsWithChildren, useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useProfiles} from '../../api/User/useProfiles';
import {isCoach} from 'user/user-utils';
import {useGetCoachInfo} from 'api/Coach/useGetCoachInfo';
import {Team} from 'user/user.interface';
import Spinner from 'components/Loader/Spinner';

export default function CoachAuthContainer({children}: PropsWithChildren) {
  const {currentProfile} = useProfiles();
  const navigate = useNavigate();
  const {data: coachInfo, isLoading} = useGetCoachInfo(currentProfile?._id);
  const {teamId} = useParams();

  const isCoachInTeam = useMemo(
    () => coachInfo?.teams?.some((team: Team) => team?._id === teamId),
    [coachInfo, teamId]
  );

  if (!isCoach(currentProfile)) {
    return <div>Insufficient permissions</div>;
  }

  if (isLoading) {
    return (
      <div className="w-full h-screen flex flex-1 items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (teamId && !isCoachInTeam) {
    navigate('/coach');
    return null;
  }

  return <>{children}</>;
}

import {memo} from 'react';
import styles from './ConnectsComponents.module.scss';

type ErrorDisplayProps = {
  text: string;
};

export const ErrorDisplay = memo(({text}: ErrorDisplayProps) => {
  return (
    <div className={styles.errorDisplay}>
      <p>{text}</p>
    </div>
  );
});
ErrorDisplay.displayName = 'ErrorDisplay';

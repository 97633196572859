import {memo, ReactNode} from 'react';
import styles from './FKOModal.module.scss';
import {ReactComponent as CloseIcon} from '../../../assets/img/icons/close-icon-grey.svg';
import ModalWrapper from 'components/CustomModal/ModalWrapper';

type FKOModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  children: ReactNode;
  heading?: string;
  subHeading?: string;
  showFullScreen?: boolean;
  fixedMaxWidth?: boolean;
};

export const FKOModal = memo(
  ({
    isOpen,
    children,
    closeModal,
    heading,
    subHeading,
    showFullScreen = true,
    fixedMaxWidth = false,
  }: FKOModalProps) => {
    return (
      <ModalWrapper
        open={isOpen}
        onClose={closeModal}
        showFullScreen={showFullScreen}
        addClass={fixedMaxWidth ? 'modal-fixed-max-width' : ''}
      >
        <div className={styles.container}>
          <div className={styles.heading}>
            <button className={styles.closeButton} onClick={closeModal}>
              <div className={styles.icon}>
                <CloseIcon />
              </div>
            </button>
            {heading && <h1>{heading}</h1>}
            {subHeading && <h2>{subHeading}</h2>}
          </div>
          {children}
        </div>
      </ModalWrapper>
    );
  }
);
FKOModal.displayName = 'FKOModal';

import {createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useAppDispatch} from 'redux/reduxUtils';
import {useLocation} from 'react-router-dom';
import {resetFkoToInitial} from 'redux/slices/fkoFormData';

type SportType = 'DBAT' | 'TOCA' | 'PLAYER_ASSESSMENT' | 'CLASSES' | 'FREE_TRIAL_CLASS';

type SportContextType = {sport: SportType | null; setSport: ({sport}: {sport: SportType}) => void};

const SportContext = createContext<SportContextType>({sport: null, setSport: ({sport}) => (sport ? {} : {})});

export const useSportContext = () => useContext(SportContext);

export const SportProvider = ({children}: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const initalSport = location.pathname.includes('player-assessment', 1)
    ? 'PLAYER_ASSESSMENT'
    : location.pathname.includes('dbat', 1)
    ? 'DBAT'
    : location.pathname.includes('ftc', 1)
    ? 'FREE_TRIAL_CLASS'
    : location.pathname.includes('classes', 1) // || location.pathname.includes('programs', 1)
    ? 'CLASSES'
    : 'TOCA';

  const [sportState, setSportState] = useState<SportType | null>(initalSport);

  useEffect(() => {
    if (sportState) {
      document.body.dataset.theme = sportState;
    }
  }, [sportState]);

  const setSport = useCallback(
    ({sport}: {sport: SportType | null}) => {
      dispatch(resetFkoToInitial());
      setSportState(sport);
    },
    [dispatch]
  );

  const sportContextValue = useMemo(() => ({sport: sportState, setSport}), [setSport, sportState]);

  return <SportContext.Provider value={sportContextValue}>{children}</SportContext.Provider>;
};

import {useQuery} from '@tanstack/react-query';
import {getMboPrograms, GetMboProgramsParams} from 'api/api';

type useGetProgramsParams = GetMboProgramsParams & {
  select?: (data: Program[]) => any;
};

export const useGetPrograms = ({siteId, select}: useGetProgramsParams) => {
  return useQuery<Program[]>({
    queryKey: ['programs-list', siteId],
    queryFn: () => getMboPrograms({siteId}),
    enabled: !!siteId,
    select,
    refetchOnWindowFocus: false,
  });
};

export const usePrefetchPrograms = ({siteId, select}: useGetProgramsParams) => {
  void useQuery<Program[]>({
    queryKey: ['programs-list', siteId],
    queryFn: () => getMboPrograms({siteId}),
    enabled: !!siteId,
    select,
    refetchOnWindowFocus: false,
    notifyOnChangeProps: [],
  });
};

export type Program = {
  // !! ensure this stays in sync with 'TocaProgram' on the back-end
  programId: string;
  name: string;
  scheduleType?: string;
  cancelOffset: number;
  contentFormats: string[];
  scheduleOffset: number;
  scheduleOffsetEnd: number;
  pricingRelationships: {
    paysFor: number[];
    paidBy: unknown[];
  };
  siteId: string;
  description?: string;
  highlightTitle?: string;
  highlights?: string[];
  mainImage?: {
    url: string;
    width?: number;
    height?: number;
  };
  slug?: string;
  tags?: string[];
  enrollments: Enrollment[];
  classSchedules?: ClassSchedule[];
};

export type Enrollment = {
  ages?: string[]; // TODO CLASSES TEMP FIX (not on front end)
  // !! ensure this stays in sync with 'TocaEnrollment' on the back-end
  enrollmentId: string; // id:number
  capacity: {
    available: number;
    max: number;
  };
  classes: EnrollmentClass[];
  classDescription: {
    isActive?: boolean;
    description?: string;
    classDescriptionId: string | null;
    imageURL?: string;
    lastUpdated?: string;
    level?: {
      id: string | null;
      name: string;
      description: string;
    };
    name?: string;
    notes?: string;
    prereq?: string;
    sessionType: {
      id: string | null;
      type?: string;
      defaultTimeLength?: number;
      staffTimeLength?: number;
      name?: string;
      numDeducted?: number;
      onlineDescription?: string;
      isAvailableForAddOn?: boolean;
    };
    category?: string;
    categoryId?: string;
    subcategory?: string;
    subcategoryId?: string;
  };
  costPerSession: number | null;
  description?: string;
  location?: TocaEventLocation;
  name?: string;
  programId: string | null;
  programName?: string;
  schedule: {
    days: {
      sunday: boolean;
      monday: boolean;
      tuesday: boolean;
      wednesday: boolean;
      thursday: boolean;
      friday: boolean;
      saturday: boolean;
    };
    daysArray: string[];
    allowOpenEnrollment: boolean;
    allowDateForwardEnrollment: boolean;
    startTime: string; // TODO make this better
    endTime: string;
    startDate: string;
    endDate: string;
  };
  serviceId: string | null;
  siteId: string;
  skillLevel?: string;
  staff?: {
    id: string | null;
    firstName: string;
    lastName: string;
    displayName?: string;
    isMale?: boolean;
    staffSettings?: {
      useStaffNicknames: boolean;
      showStaffLastNamesOnSchedules: boolean;
    };
  };
  tags?: {
    classType?: string;
    unsorted?: string[];
  };
  // images?: any[]; // ?
  // skillLevel?: string;
  // coachName: string;
};

export type EnrollmentClass = {
  // !! ensure this stays in sync with 'TocaEnrollmentClass' on the back-end
  siteId: string;
  isoWeekday?: number;
  name: string | null; // "Soccer Class - Age 8-9 - Fall 2023"
  enrollmentId: string | null;
  capacity: {
    available: number;
    max: number;
  };
  startTimeString?: string;
  startDateString?: string;
  endTimeString?: string;
  bookingStatus?: string;
  bookingWindow?: {
    StartDateTime: string;
    EndDateTime: string;
    DailyStartTime: string;
    DailyEndTime: string;
  };
  classDescription?: {
    isActive?: boolean;
    description: string;
    classDescriptionId: string | null;
    imageURL?: unknown;
    lastUpdated?: string;
    level?: {
      id: string;
      name: string;
      description: string;
    };
    name?: string;
    notes?: string;
    prereq?: string;
    programId: string | null;
    programName?: string;
    sessionTypeId: string | null;
    category?: string;
    categoryId?: string;
    subcategory?: string;
    subcategoryId?: string;
  };
  classId: string;
  classScheduleId: string | null;
  clients?: unknown[];
  endDateTime: string;
  hideCancel?: boolean;
  isActive?: boolean;
  isAvailable?: boolean;
  isCanceled?: boolean;
  isEnrolled?: boolean;
  isSubstitute?: boolean;
  isWaitlistAvailable?: boolean;
  lastModifiedDateTime?: string;
  maxCapacity: number | null;
  resource?: {
    id: string | null;
    name?: string;
  };
  semesterId: string | null;
  staff?: {
    id: string | null;
    firstName: string;
    lastName: string;
    displayName?: string;
    isMale?: boolean;
    staffSettings?: {
      useStaffNicknames: boolean;
      showStaffLastNamesOnSchedules: boolean;
    };
  };
  startDateTime: string;
  totalBooked?: number;
  totalBookedWaitlist?: number;
  totalSignedIn?: number;
  virtualStreamLink?: unknown;
  visits?: unknown[];
  waitListSize?: number;
  webBooked?: number;
  webCapacity?: number;
};

export type TocaEventLocation = {
  // !! ensure this stays in sync with 'TransformedMboLocation' on the back-end
  additionalImageURLs?: unknown[];
  address?: string | null;
  address2?: string | null;
  amenities?: unknown | null;
  averageRating?: number;
  businessDescription?: unknown | null;
  city?: string | null;
  description?: unknown | null;
  hasClasses?: boolean;
  locationId?: string; // number -> string
  latitude?: number;
  longitude?: number;
  name?: string | null;
  phone?: string | null;
  phoneExtension?: unknown | null;
  postalCode?: string | null;
  siteId?: unknown | null;
  stateProvCode?: string | null;
  tax1?: number;
  tax2?: number;
  tax3?: number;
  tax4?: number;
  tax5?: number;
  totalNumberOfDeals?: number;
  totalNumberOfRatings?: number;
};

export type ClassSchedule = {
  // this must in sync with 'TocaClassSchedule' on the back-end
  ages: string[];
  capacity: {
    available: number;
    max: number;
  };
  classes: EnrollmentClass[];
  classScheduleId: string;
  classDescription: {
    isActive?: boolean;
    description?: string;
    classDescriptionId: string | null;
    imageURL?: string;
    lastUpdated?: string;
    level?: {
      id: string | null;
      name: string;
      description: string;
    };
    name?: string;
    notes?: string;
    prereq?: string;
    sessionType: {
      id: string | null;
      type?: string;
      defaultTimeLength?: number;
      staffTimeLength?: number;
      name?: string;
      numDeducted?: number;
      onlineDescription?: string;
      isAvailableForAddOn?: boolean;
    };
    category?: string;
    categoryId?: string;
    subcategory?: string;
    subcategoryId?: string;
  };
  costPerSession: number | null;
  description?: string;
  heading?: string;
  highlightTitle?: string;
  highlights?: string[];
  isAvailable?: boolean;
  location?: TocaEventLocation;
  name?: string;
  programId: string | null;
  programName?: string;
  schedule: {
    days: {
      sunday: boolean;
      monday: boolean;
      tuesday: boolean;
      wednesday: boolean;
      thursday: boolean;
      friday: boolean;
      saturday: boolean;
    };
    allowOpenEnrollment: boolean;
    allowDateForwardEnrollment: boolean;
    startTime?: string;
    endTime?: string;
    startDate: string;
    endDate: string;
    daysArray: string[];
  };
  semesterId: unknown;
  serviceId: string | null;
  siteId: string;
  skillLevel?: string;
  slug?: string;
  staff?: {
    id: string | null;
    firstName: string;
    lastName: string;
    displayName?: string;
    isMale?: boolean;
    staffSettings?: {
      useStaffNicknames: boolean;
      showStaffLastNamesOnSchedules: boolean;
    };
  };
  tags?: {
    classType?: string;
    unsorted?: string[];
  };
};

import {useState} from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {FormInput} from 'components/Forms/FormInput/FormInput';
import {Button} from 'components/Connects/Button';
import {PHONE_REGEX} from 'pages/RegistrationLanding/RegistrationLandingForm';
import {ClassSchedule, Enrollment} from 'api/Classes/useGetPrograms';
import {logAnalyticsEvent} from '../../common/analytics-events';
import {createOrUpdateHubSpotContact} from '../../api/api';
import {getLocationBySiteId} from '../../constants/locations';
import {useAppSelector} from 'redux/reduxUtils';

type EmptyDayFormProps = {
  enrollment?: Enrollment | ClassSchedule | null;
};

export type FormType = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};

export const ProgramEmptyDayForm = ({enrollment}: EmptyDayFormProps) => {
  const {userData} = useAppSelector((state) => state.fkoFormDataReducer);
  const [contactRequestResult, setContactRequestResult] = useState<null | 'sent' | 'error'>(null);

  const selectedDate = enrollment?.schedule.startDate;
  const siteId = enrollment?.siteId;

  const sendContactRequest = ({firstName, lastName, email, phoneNumber}: FormType) => {
    setContactRequestResult('sent');

    logAnalyticsEvent(`free_class_appointment_call_requested`, {
      siteId: enrollment?.siteId,
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phoneNumber,
      requested_date: selectedDate,
    });
    createOrUpdateHubSpotContact({
      firstname: firstName,
      lastname: lastName,
      email: email,
      phone: phoneNumber,
      locations__drop_down_: siteId ? getLocationBySiteId(siteId)?.hubspotName : undefined,
      fko_request_date: selectedDate,
      fko_method: 'player_portal_free_toca_class_registration',
    })
      .then(() => {
        setContactRequestResult('sent');
      })
      .catch((err) => {
        setContactRequestResult('error');
        console.error('Failed to create/update HubSpot contact', err);
      });
  };

  const formFieldConfig: any = {
    email: yup
      .string()
      .email('Email is invalid')
      .matches(/^[^+]+$/, 'Email must not contain "+" signs')
      .required('Email is required'),
    firstName: yup
      .string()
      .trim('Name cannot include leading or trailing spaces')
      .required('Please enter your first name'),
    lastName: yup
      .string()
      .trim('Name cannot include leading or trailing spaces')
      .required('Please enter your last name'),
    phoneNumber: yup.string().required('Phone number is required').matches(PHONE_REGEX, 'Must be a valid phone number'),
  };

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<FormType>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(yup.object().shape(formFieldConfig)),
    defaultValues: userData
      ? {
          ...userData, // TODO this probably works
        }
      : undefined,
  });

  if (!enrollment) {
    return null;
  }

  return (
    <div className="flex flex-col items-center gap-4">
      <h3 className="m-4 text-xl text-primary capitalize font-semibold text-center">
        Get notified of any availabilites!
      </h3>

      <div className="flex flex-col gap-2 max-w-[480px]">
        {contactRequestResult === 'error' ? (
          <p className="text-sm">
            We&apos;re sorry but it looks like some of our systems are having issues at the moment. Please{' '}
            <a
              href="https://www.tocafootball.com/contact-us" // TODO CLASSES confirm
              className="underline text-secondary"
              target="_blank"
              rel="noreferrer"
            >
              click here
            </a>{' '}
            to contact us directly.
          </p>
        ) : (
          <form onSubmit={handleSubmit(sendContactRequest)} className="flex flex-col items-center">
            <p className="text-sm mb-4">
              This class is popular! Enter your contact info below to have our teammates work their magic. We&apos;ll
              call you back at the number you provide below.
            </p>

            <FormInput
              {...register('firstName')}
              id="firstName"
              hasError={!!errors.firstName?.message}
              placeholder="First Name"
              spellCheck="false"
              type="text"
            />
            <p className="text-xs text-alert mb-1">{errors.firstName?.message ?? '\u00A0'}</p>

            <FormInput
              {...register('lastName')}
              id="lastName"
              hasError={!!errors.lastName?.message}
              placeholder="Last Name"
              spellCheck="false"
              type="text"
            />
            <p className="text-xs text-alert mb-1">{errors.lastName?.message ?? '\u00A0'}</p>

            <FormInput
              {...register('email')}
              id="email"
              hasError={!!errors.email?.message}
              placeholder="Email"
              spellCheck="false"
              type="email"
            />
            <p className="text-xs text-alert mb-1">{errors.email?.message ?? '\u00A0'}</p>

            <FormInput
              {...register('phoneNumber')}
              id="phoneNumber"
              hasError={!!errors.phoneNumber?.message}
              placeholder="Phone Number"
              spellCheck="false"
              type="tel"
            />
            <p className="text-xs text-alert mb-1">{errors.phoneNumber?.message ?? '\u00A0'}</p>

            <div className="shrink">
              {contactRequestResult === 'sent' ? (
                <p className="p-3 mb-[-4px] text-lg font-semibold uppercase text-fko-primary">Sent!</p>
              ) : (
                <Button text="Contact me" type="submit" />
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

import {memo} from 'react';
import moment from 'moment';
import {ClassSchedule, Enrollment} from 'api/Classes/useGetPrograms';
import {MapDisplay} from 'components/Programs/MapDisplay';
import {ReactComponent as LocationPinIcon} from '../../assets/img/icons/redesign-icons/location-pin.svg';
import {ReactComponent as ClockFaceIcon} from '../../assets/img/icons/redesign-icons/clock-face.svg';
import {ReactComponent as CalendarIcon} from '../../assets/img/icons/redesign-icons/calendar-minimal.svg';
import {ReactComponent as SoccerballIcon} from '../../assets/img/icons/redesign-icons/soccerball-basic.svg';
import {ReactComponent as WhistleIcon} from '../../assets/img/icons/redesign-icons/whistle.svg';
import {format24hrTimeString, formatWeekdaysArrayForDisplay, getClassDisplayName} from 'utils/programs-utils';

type EnrollmentCheckoutCardProps = {
  enrollment: Enrollment | ClassSchedule;
  overrideStartDate?: string;
  overrideSessionCount?: number;
  isSingleSelection?: boolean;
  showMap?: boolean;
};

export const EnrollmentCheckoutCard = memo(
  ({enrollment, overrideStartDate, overrideSessionCount, isSingleSelection = false}: EnrollmentCheckoutCardProps) => {
    // const sessionTime = `${moment(enrollment.schedule.startTime).format('h:mma')} - ${moment(
    //   enrollment.schedule.endTime
    // ).format('h:mma')}`;
    const sessionTime = `${format24hrTimeString({
      time: enrollment.schedule.startTime,
      includeTimePeriod: false,
    })} - ${format24hrTimeString({time: enrollment.schedule.endTime})}`;

    const mapAddress = enrollment?.location ? `${enrollment.location.address} ${enrollment.location.address2}` : null;
    const nextClass = enrollment.classes?.[0];
    const nextClassDate = moment(overrideStartDate ?? nextClass?.startDateTime).format('dddd, MMM Do');
    const selectedClassDate = moment(overrideStartDate).format('dddd, MMM Do');
    const ageRange = enrollment.ages?.length ? `${enrollment.ages[0]} - ${enrollment.ages.slice(-1)}` : '';
    const classDisplayName = getClassDisplayName(enrollment?.name);
    const coachDisplayName = enrollment.staff
      ? `${enrollment.staff.firstName}\u00A0${enrollment.staff.lastName}`
      : `Being scheduled`;

    return (
      <>
        <div className="bg-white overflow-hidden border border-grey rounded-lg grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-8 shadow-flat-lg max-w-[640px]">
          <div className="flex flex-col px-6 py-3 gap-2 md:py-4 md:gap-3">
            <p className="font-poppins font-semibold text-xl leading-[24px] text-primary">{classDisplayName}</p>
            <p className="font-poppins font-semibold text-lg leading-[24px] text-secondary">
              {enrollment.costPerSession && !isSingleSelection && `$${enrollment.costPerSession?.toFixed(2)}`}
            </p>
            <div className="flex flex-col gap-1">
              <div className="flex items-center gap-2">
                <CalendarIcon className="shrink-0 h-4 w-4 min-h-4 fill-grey-xdark" />
                {isSingleSelection ? (
                  <p className="font-poppins font-medium text-sm text-primary">
                    {overrideStartDate ? selectedClassDate : 'Select a Class Below'}
                  </p>
                ) : (
                  <p className="font-poppins font-medium text-sm text-primary">Starting {nextClassDate}</p>
                )}
              </div>

              {!isSingleSelection && (
                <div className="flex items-center gap-2">
                  <CalendarIcon className="shrink-0 h-4 w-4 min-h-4 fill-grey-xdark" />
                  <p className="font-poppins font-medium text-sm text-grey-xdark">
                    {formatWeekdaysArrayForDisplay(enrollment.schedule.daysArray)}
                  </p>
                </div>
              )}

              <div className="flex items-center gap-2">
                <ClockFaceIcon className="shrink-0 h-4 w-4 min-h-4 fill-grey-xdark" />
                <p className="font-poppins font-medium text-sm text-grey-xdark">{sessionTime}</p>
              </div>

              <div className="flex items-center gap-2">
                <SoccerballIcon className="shrink-0 h-4 w-4 min-h-4 fill-grey-xdark" />
                <p className="font-poppins font-medium text-sm text-grey-xdark">Ages:&nbsp;{ageRange}</p>
              </div>

              {!isSingleSelection && (
                <div className="flex items-center gap-2">
                  <SoccerballIcon className="shrink-0 h-4 w-4 min-h-4 fill-grey-xdark" />
                  <p className="font-poppins font-medium text-sm text-grey-xdark">
                    Sessions:&nbsp;{overrideSessionCount ?? enrollment.classes.length}
                  </p>
                </div>
              )}

              <div className="flex items-center gap-2">
                <WhistleIcon className="shrink-0 h-4 w-4 min-h-4 fill-grey-xdark" />
                <p className="font-poppins font-medium text-sm text-grey-xdark">Coach:&nbsp;{coachDisplayName}</p>
              </div>

              {enrollment.location && (
                <div className="flex items-center gap-2">
                  <LocationPinIcon className="shrink-0 h-4 w-4 min-h-4 fill-grey-xdark" />
                  <p className="font-poppins font-medium text-sm text-grey-xdark">
                    {enrollment.location.address}
                    <br />
                    {enrollment.location.address2}
                  </p>
                </div>
              )}
            </div>
          </div>

          {!!mapAddress && (
            <div className="flex grow border-grey border-t sm:border-l sm:border-t-0 min-h-[240px] sm:min-h-[120px]">
              <MapDisplay address={mapAddress} />
            </div>
          )}
        </div>
      </>
    );
  }
);
EnrollmentCheckoutCard.displayName = 'EnrollmentCheckoutCard';

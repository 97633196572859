import {memo} from 'react';
import styles from './ModalBookingSession.module.scss';
import {BookingSession} from 'redux/slices/booking';
import {parseDateTime} from 'utils/utils';

export const ModalBookingSession = memo(({session}: {session: BookingSession}) => {
  const parsedStart = parseDateTime(session?.startDate);
  const parsedEnd = parseDateTime(session?.endDate);
  const coachName = `${session?.staff?.firstName} ${session?.staff?.lastName}`;

  return (
    <div className={styles.container}>
      <h3 className={styles.name}>{session.sessionType.name}</h3>

      <div className={styles.info}>
        <p>
          <span>Date: </span>
          {parsedStart.dayStrShort},&nbsp;
          {parsedStart.monthStr} {parsedStart.day},&nbsp;
          {parsedStart.year}
        </p>
        <p>
          <span>Time: </span>
          {parsedStart.time} - {parsedEnd.time} {parsedEnd.zone}
        </p>
        {coachName !== ' ' && (
          <p className={styles.coach}>
            <span>Coach: </span>
            {coachName}
          </p>
        )}
      </div>
    </div>
  );
});
ModalBookingSession.displayName = 'ModalBookingSession';

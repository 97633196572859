import {useState} from 'react';
import styles from './SignInSignUp.module.scss';
import {useAppDispatch} from 'redux/reduxUtils';
import {useQueryClient} from '@tanstack/react-query';
import {Link, useNavigate} from 'react-router-dom';
import {auth as fireBaseAuth, signInWithApple, signInWithFacebook, signInWithGoogle} from '../../firebase/config';
import {useSocialAuth} from '../../firebase/socialAuth.hooks';
import {signInWithEmailAndPassword} from 'firebase/auth';
import {getPostLoginRedirectUri, setAccessToken, setCurrentAccountId} from '../../user/user-utils';
import {useSetGlobalLoading} from '../../hooks/useGlobalLoading';
import {Helmet} from 'react-helmet';
import {logLoginEvent} from 'common/analytics-events';
//
import {EmailSignInForm} from 'pages/SignInSignUp/EmailSignInForm';
import {BookingFormButton} from 'components/Buttons/Form/FormButton';
import {FormDividerRevised} from 'components/Forms/FormDivider/FormDividerRevised';
import {ReactComponent as MyTocaLogo} from '../../assets/img/icons/redesign-icons/mytoca-logo.svg';
import {ReactComponent as GoogleIcon} from '../../assets/img/icons/redesign-icons/logo-google.svg';
import {ReactComponent as FacebookIcon} from '../../assets/img/icons/redesign-icons/logo-facebook.svg';
import {ReactComponent as AppleIcon} from '../../assets/img/icons/redesign-icons/logo-apple.svg';

export const SignInSignUp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const socialAuth = useSocialAuth();
  const queryClient = useQueryClient();
  const {setGlobalLoading} = useSetGlobalLoading();
  const [errMes, setErrMes] = useState<string>('');

  const onEmailSubmit = async (data: any) => {
    setGlobalLoading(true);

    let userCredentials: any; // TODO typescript
    try {
      userCredentials = await signInWithEmailAndPassword(fireBaseAuth, data.email, data.password);
      logLoginEvent('emailAndPassword', {
        userUid: userCredentials?.user?.uid,
        operationType: userCredentials?.operationType,
      });
    } catch (err) {
      setGlobalLoading(false);
      setErrMes('Invalid username or password provided');
      return;
    }

    const authUser = userCredentials.user;

    setAccessToken(authUser.accessToken);
    setCurrentAccountId(authUser.uid);

    dispatch({type: 'GET_USER'});
    setGlobalLoading(false);
    navigate(authUser.emailVerified ? getPostLoginRedirectUri() : '/verify-email');
  };

  const onSocialSignIn = async (socialProvider: any) => {
    setGlobalLoading(true);
    const {success} = await socialAuth(socialProvider);
    queryClient.invalidateQueries({queryKey: ['user'], refetchType: 'all'});
    setGlobalLoading(false);
    if (success) {
      navigate(getPostLoginRedirectUri());
    }
  };

  return (
    <>
      <Helmet>
        <title>MyTOCA | Sign In/Sign Up</title>
      </Helmet>

      <div className={styles.container}>
        <div className={styles.logo}>
          <Link to={'/auth'}>
            <MyTocaLogo />
          </Link>
        </div>
        <h1 className={styles.heading}>Welcome</h1>
        <h2 className={styles.subheading}>Please Log in with your MyTOCA credentials</h2>

        <div className={styles.socialAuth}>
          <button onClick={() => onSocialSignIn(signInWithGoogle)} className={styles.socialButton}>
            <GoogleIcon />
          </button>
          <button onClick={() => onSocialSignIn(signInWithFacebook)} className={styles.socialButton}>
            <FacebookIcon />
          </button>
          <button onClick={() => onSocialSignIn(signInWithApple)} className={styles.socialButton}>
            <AppleIcon />
          </button>
        </div>

        <FormDividerRevised text="or" />

        <div className={styles.flexContainer}>
          <EmailSignInForm errorMessageProps={errMes} onSubmitHandler={onEmailSubmit} />
          <div className={styles.forgotPasswordLinkContainer}>
            <Link to="/forget-password">Forgot your password?</Link>
          </div>
          <FormDividerRevised text="or" />
          <Link to={'/signup'}>
            <div className={styles.buttonWrapper}>
              <BookingFormButton>
                <p className={styles.buttonText}>Create An Account</p>
              </BookingFormButton>
            </div>
          </Link>
        </div>

        <div className={styles.helpLinkContainer}>
          <Link to="/helpdesk">Need help?</Link>
        </div>
      </div>
    </>
  );
};

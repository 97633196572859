import {useCallback, useEffect, useRef} from 'react';
import {TransformedLocationInfo} from 'pages/CenterDiscovery/Utils/dataTransformer';
import {LocationItem} from '../LocationItem/LocationItem';
import {HorizontalScroll} from 'components/HorizontalScroll/HorizontalScroll';

interface LocationsListProps {
  locations: TransformedLocationInfo[];
  onSelectLocation: (lat: number, lon: number, index: number) => void;
  showHorizontalView?: boolean;
  activeLocationIndex: number;
}
/**
 * Renders a list of toca location item cards.
 */
export const LocationsList = ({
  locations,
  onSelectLocation,
  showHorizontalView = false,
  activeLocationIndex,
}: LocationsListProps) => {
  const verticalListContainerRef = useRef<HTMLDivElement>(null);
  const activeItemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Stop scrolling the vertical list if the horizontal view is active
    if (showHorizontalView) {
      return;
    }
    if (!verticalListContainerRef.current || !activeItemRef.current) {
      return;
    }

    const container = verticalListContainerRef.current;
    const activeItem = activeItemRef.current;

    const containerTop = container.scrollTop;
    const containerBottom = containerTop + container.clientHeight;
    const itemTop = activeItem.offsetTop;

    // Check if active item is in view
    const isItemInView = itemTop >= containerTop && itemTop <= containerBottom;

    if (!isItemInView) {
      // Scroll the container to bring the active item into view
      container.scrollTo({
        top: itemTop - container.offsetTop,
        behavior: 'smooth',
      });
    }
  }, [activeLocationIndex, showHorizontalView]);

  const onScrollMapSelectionHandler = useCallback(
    (index: number) => {
      const selectedLocation = locations[index];
      const {lat, lon} = selectedLocation.coordinates;
      onSelectLocation(lat, lon, index);
    },
    [locations, onSelectLocation]
  );

  if (showHorizontalView) {
    return (
      <HorizontalScroll
        onScrollSelectionHandler={onScrollMapSelectionHandler}
        activeLocationListIndex={activeLocationIndex}
      >
        {locations.map((location, index) => (
          <LocationItem
            key={location.siteId}
            isNearest={index === 0}
            index={index}
            showHorizontalView={showHorizontalView}
            isActive={index === activeLocationIndex}
            {...location}
            onSelectLocation={onSelectLocation}
          />
        ))}
      </HorizontalScroll>
    );
  }
  return (
    <div ref={verticalListContainerRef} className="overflow-y-scroll h-full pb-2">
      {locations.map((location, index) => (
        <div key={location.siteId} ref={index === activeLocationIndex ? activeItemRef : undefined}>
          <LocationItem
            isNearest={index === 0}
            isActive={index === activeLocationIndex}
            index={index}
            {...location}
            onSelectLocation={onSelectLocation}
          />
        </div>
      ))}
    </div>
  );
};

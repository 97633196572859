import {Dispatch, SetStateAction, memo, useCallback} from 'react';
import styles from './PathwaySelection.module.scss';
import {PathwayLevel, PlayerDetails} from '../../api/User/useGetPlayerDetails';
import {ReactComponent as ArrowDown} from '../../assets/img/icons/redesign-icons/arrow-down.svg';
import {ReactComponent as FilledCheckmark} from '../../assets/img/icons/redesign-icons/checked-circle-filled.svg';
import {NonUndefined} from 'react-hook-form';

export type PathwaySelectionProps = {
  pathways: PlayerDetails['pathways'];
  activeLevel: PathwayLevel;
  setActiveLevel: Dispatch<SetStateAction<PathwayLevel | undefined>>;
};

export const PathwaySelection = memo(({pathways, activeLevel, setActiveLevel}: PathwaySelectionProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.buttonGrid}>
        <PathwayCard
          title="beginner"
          isActive={activeLevel === 'beginner'}
          hasData={!!pathways?.beginner?.sessions.length}
          setActivePathway={setActiveLevel}
        />
        <PathwayCard
          title="intermediate"
          isActive={activeLevel === 'intermediate'}
          hasData={!!pathways?.intermediate?.sessions.length}
          setActivePathway={setActiveLevel}
        />
        <PathwayCard
          title="advanced"
          isActive={activeLevel === 'advanced'}
          hasData={!!pathways?.advanced?.sessions.length}
          setActivePathway={setActiveLevel}
        />
      </div>
    </div>
  );
});
PathwaySelection.displayName = 'PathwaySelection';

export const PathwayCard = ({
  hasData,
  isActive,
  isComplete,
  title,
  setActivePathway,
}: {
  hasData: boolean;
  isActive: boolean;
  isComplete?: boolean;
  title: NonUndefined<PathwayLevel>;
  setActivePathway: (p: PathwayLevel) => void;
}) => {
  const titleCharacter = title.charAt(0).toUpperCase();
  const buttonClasses = `${styles.cardContainer} ${styles[title]} 
    ${isActive ? styles.isActive : ''}
    ${isComplete ? styles.isComplete : ''}
    ${hasData ? styles.hasData : ''}`;

  const setPathway = useCallback(() => {
    if (hasData) {
      setActivePathway(title);
    }
  }, [title, hasData, setActivePathway]);

  return (
    <button className={buttonClasses} onClick={setPathway}>
      <span>{titleCharacter}</span>
      <p>{title}</p>
      <FilledCheckmark className={styles.completedIcon} />
      <ArrowDown className={styles.activeIcon} />
    </button>
  );
};

import styles from './TrainerSearch.module.scss';
import {memo, useCallback, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {getTrainerUser} from '../../../api/api';
import {useTrainerContext} from '../../../context/UserContextProvider';
import {useTrainerSearch} from '../../../hooks/useTrainerSearch';
import {PlayerShort} from '../Trainer';
import {TrainerSearchPlayerCard} from './_Components/PlayerCard/TrainerSearchPlayerCard';
import Spinner from '../../../components/Loader/Spinner';
import {logAnalyticsEvent} from '../../../common/analytics-events';
import {getTrainerEmulationUrlPath} from '../../../components/TrainerEmulationContainer/TrainerEmulationContainer';
import {Profile} from '../../../user/player-info.interface';
import {StandardInput} from 'components/Input/StandardInput/StandardInput';

export const TrainerSearch = memo(({players}: {players: PlayerShort[]}) => {
  const navigate = useNavigate();
  const trainerContext = useTrainerContext();
  const {updateQuery, indexResults} = useTrainerSearch({players});
  const [loading, setLoading] = useState(false);

  const emulatePlayer = useCallback(
    async (profileId: string) => {
      if (trainerContext) {
        setLoading(true);
        const {data: user} = await getTrainerUser({profileId});
        const profile = user?.profiles.find((p: Profile) => p._id === profileId);
        if (user && profile) {
          trainerContext.emulateUser(user, profileId, false);
          logAnalyticsEvent('trainer-emulation-started', {
            profileId,
            mboClientId: profile.mboDetails?.clientId,
            mboSiteId: profile.mboDetails?.siteId,
            directLink: false,
          });
          navigate(getTrainerEmulationUrlPath('/home', profile.mboDetails?.siteId));
        } else {
          console.error('Missing user or profile to start emulatation');
        }
        setLoading(false);
      }
    },
    [navigate, trainerContext]
  );

  const createCards = useCallback(
    ({players, searchResults}: {players: PlayerShort[]; searchResults?: number[]}) =>
      players
        .map((player, index) => {
          return searchResults?.includes(index) ? (
            <TrainerSearchPlayerCard key={`tspc_${index}`} player={player} onClick={() => emulatePlayer(player._id)} />
          ) : undefined;
        })
        .filter((el) => el !== undefined) as JSX.Element[],
    [emulatePlayer]
  );

  const playerCards = useMemo(() => {
    return createCards({
      players: players,
      searchResults: indexResults,
    });
  }, [createCards, indexResults, players]);

  const onSearchChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => updateQuery(e.currentTarget.value),
    [updateQuery]
  );

  return (
    <div className={styles.container}>
      <div className={styles.inputWrapper}>
        <StandardInput variant="new" onChange={onSearchChange} placeholder="Search players by name or email" />
      </div>

      {playerCards.length ? <ul>{playerCards.map((card) => card)}</ul> : null}

      {loading && (
        <div className={styles.loader}>
          <Spinner />
        </div>
      )}
    </div>
  );
});
TrainerSearch.displayName = 'TrainerSearch';

import {useMemo} from 'react';
import {useTrainerContext} from '../context/UserContextProvider';

export const useRestrictions = () => {
  const trainer = useTrainerContext();

  const restrictions = useMemo(
    () => ({
      bookings: {readOnly: !!trainer},
    }),
    [trainer]
  );

  return useMemo(
    () => ({
      restrictions,
    }),
    [restrictions]
  );
};

import styles from './LeaderboardDetails.module.scss';
import {ScrollableList} from '../../../components/ScrollableList/ScrollableList';
import {useCallback, useMemo, useRef, useState} from 'react';
import {LeaderboardInfoPane} from './LeaderboardInfoPane/LeaderboardInfoPane';
import {ListHeading} from './ListHeading/ListHeading';
import {ListCard} from './ListCard/ListCard';
import {LeaderboardPageContainer} from '../LeaderboardPageContainer/LeaderboardPageContainer';
import {Link, useNavigate} from 'react-router-dom';
import {useLeaderboardSearch} from '../../../hooks/useLeaderboardSearch';
import {LeaderboardSearchBar} from './LeaderboardSearchBar/LeaderboardSearchBar';
import {useAuthStatus} from '../../../firebase/useAuthStatus.hook';
import {useProfiles} from '../../../api/User/useProfiles';
import {PageHeading} from 'components/PageHeading/PageHeading';
import {PageSubHeading} from 'components/PageSubHeading/PageSubHeading';
import {LeaderboardRankings} from 'api/Leaderboards/leaderboard.types';
import {isTvMode} from 'utils/utils';

export const LeaderboardDetails = ({leaderboard}: {leaderboard: LeaderboardRankings}) => {
  const navigate = useNavigate();
  const {loggedIn, checkingStatus} = useAuthStatus();
  const publicView = !loggedIn;
  const showBackButton = !isTvMode() && loggedIn;
  const {currentProfile} = useProfiles();
  // TODO don't hardcode, and probably allow logos to be uploaded during leaderboard creation
  const showMLSLogo = leaderboard.leaderboard.locations?.[0].mboSiteId === '-2147480770';

  const [searchIndex, setSearchIndex] = useState<number>(0); // used to cycle through search results
  const userRank = useRef<number>(); // calculated when creating Cards

  const {updateQuery, indexResults} = useLeaderboardSearch({rankings: leaderboard.rankings});

  // creates ListCard component array
  const createCards = useCallback(
    ({rankings, searchResults}: {rankings?: LeaderboardRankings['rankings']; searchResults?: number[]}) =>
      rankings?.map((ranking, index) => {
        const player =
          Boolean(ranking.profileId && ranking.profileId === currentProfile?._id) ||
          Boolean(ranking.clientId && ranking.clientId === currentProfile?.mboDetails?.clientId);
        if (player) {
          userRank.current = index;
        }
        const search = !!searchResults?.includes(index);
        return <ListCard key={ranking._id} highlight={{player, search}} player={ranking} />;
      }),
    [currentProfile?._id, currentProfile?.mboDetails?.clientId]
  );

  // creates/recreate cards based on rankings and search results
  const items = useMemo(() => {
    setSearchIndex(0);
    return createCards({
      rankings: leaderboard.rankings,
      searchResults: indexResults,
    });
  }, [createCards, leaderboard, indexResults]);

  // set fixed parameters for rankings list
  // const itemHeight = useMemo(() => (window.innerWidth < 768 ? 52 : window.innerWidth < 1800 ? 64 : 88), []);
  const itemHeight = useMemo(() => (window.innerWidth < 768 ? 52 : 64), []);
  const startingItem = useMemo(() => (userRank.current ? userRank.current - 3 : 0), []);

  const scrollToItem = useMemo(() => {
    return indexResults.length ? Math.max(indexResults[searchIndex] - 2, 0) : undefined;
  }, [indexResults, searchIndex]);
  const onSearchChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => updateQuery(e.currentTarget.value),
    [updateQuery]
  );

  return (
    <LeaderboardPageContainer>
      <>
        {!checkingStatus && (
          <>
            {publicView && (
              <div className={styles.fkoBanner}>
                <Link to="/fko">
                  <span>
                    Join MyTOCA and get a <b>FREE</b> baseline session!
                  </span>
                </Link>
              </div>
            )}
            <div className="flex justify-center xs:justify-between min-h-[80px]">
              {showMLSLogo ? (
                <>
                  <div className="hidden xs:flex">
                    <PageHeading
                      text="Leaderboards"
                      backButton={showBackButton}
                      onClickOverride={() => navigate(publicView ? '/' : '/leaderboards')}
                    />
                  </div>
                  <div className="grid place-content-center">
                    <img
                      src="/images/mls-next-toca-partnership-logo.png"
                      alt="MLS NEXT"
                      className="h-[64px] max-h-[64px] md:h-[96px] md:max-h-[96px]"
                    />
                  </div>
                </>
              ) : (
                <PageHeading
                  text="Leaderboards"
                  backButton={showBackButton}
                  onClickOverride={() => navigate(publicView ? '/' : '/leaderboards')}
                />
              )}
            </div>
            <div className={styles.container}>
              <div className={styles.infoFlex}>
                <div className={styles.leaderboardInfo}>
                  <LeaderboardInfoPane leaderboard={leaderboard} />
                </div>
                <div>
                  <PageSubHeading text="search" />
                  <LeaderboardSearchBar
                    onChange={onSearchChange}
                    indexResults={indexResults}
                    index={searchIndex}
                    setIndex={setSearchIndex}
                  />
                </div>
              </div>
              {items && (
                <div className={styles.listWrapper}>
                  <PageSubHeading text="leaderboard results" />
                  <ScrollableList
                    items={items}
                    itemHeight={itemHeight}
                    heading={<ListHeading />}
                    scrollToItem={scrollToItem}
                    startingItem={startingItem}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </>
    </LeaderboardPageContainer>
  );
};

import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {StandardInput} from 'components/Input/StandardInput/StandardInput';
import styles from './EmailSignInForm.module.scss';
import {BookingFormButton} from 'components/Buttons/Form/FormButton';
import {ErrorMessage} from 'components/Forms/ErrorMessage/ErrorMessage';

type FormTypes = {
  email: string;
  password: string;
};

type Props = {
  errorMessageProps: any;
  onSubmitHandler: any;
};

export const EmailSignInForm = ({errorMessageProps, onSubmitHandler}: Props) => {
  const validationSchema = yup.object().shape({
    email: yup.string().required('Email is required').email('Email is invalid'),
    password: yup
      .string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters')
      .max(40, 'Password must not exceed 40 characters'),
  });

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<FormTypes>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} className={styles.container}>
      <StandardInput
        spellCheck="false"
        type="text"
        {...register('email')}
        id="email"
        placeholder="Email"
        autoComplete="email"
        hasError={!!errors.email?.message}
      />
      <ErrorMessage text={errors.email?.message ? errors.email.message : '\u00A0'} />

      <StandardInput
        spellCheck="false"
        {...register('password')}
        type="password"
        id="password"
        placeholder="Password"
        hasError={!!errors.password?.message}
      />
      <ErrorMessage text={errors.password?.message ? errors.password.message : '\u00A0'} />

      <ErrorMessage text={errorMessageProps ? errorMessageProps : '\u00A0'} />
      <div className={styles.buttonWrapper}>
        <BookingFormButton type="submit" hasError={errorMessageProps} isPrimary>
          <p className={styles.buttonText}>Log In</p>
        </BookingFormButton>
      </div>
    </form>
  );
};

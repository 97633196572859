import {memo} from 'react';
import styles from './PageHeading.module.scss';
import {useNavigate} from 'react-router-dom';
import {ReactComponent as ChevronLeft} from '../../assets/img/icons/redesign-icons/chevron-left.svg';

type PageHeadingProps = {
  backButton?: boolean;
  backDestination?: string;
  onClickOverride?: () => void;
  text: string;
};

export const PageHeading = memo(
  ({backButton = true, backDestination = '/home', onClickOverride, text}: PageHeadingProps) => {
    const navigate = useNavigate();

    return (
      <div className={styles.container}>
        {backButton ? (
          <button
            onClick={onClickOverride ? onClickOverride : () => navigate(backDestination ?? -1)}
            className={styles.backButton}
          >
            <ChevronLeft />
          </button>
        ) : null}
        <h2 className={styles.headingText}>{text}</h2>
      </div>
    );
  }
);
PageHeading.displayName = 'PageHeading';

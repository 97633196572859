import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {isEqual} from 'lodash';
import {TocaLocation} from '../../constants/locations';
import {Session} from '../../pages/RegistrationScheduling/SchedulingForm';
import {getCurrentProfileId} from '../../user/user-utils';
// import {parseDateTime} from '../../utils/utils';
import {AppointmentCalendar, FkoPlayer} from './fkoFormData';

export type BookingSession = Session & {
  bookingStatus?: {
    confirmed?: boolean;
    error?: unknown;
    reselected?: boolean;
    unavailableCoach?: boolean;
    unavailableTime?: boolean;
  };
};

type PlayerBookingPreferences = {
  preferredSession?: BookingSession;
  desiredDates?: string[];
};

type BookingCreation = {
  initializedSiteId: string | null;
  profileId: string;
  usingPreferences?: boolean;
  playerPreferences: PlayerBookingPreferences;
  sessions: BookingSession[];
  tempSession: BookingSession | null;
  bookingCompleted?: boolean;
};

type BookingState = {
  appointmentCalendar: AppointmentCalendar;
  bookingCreation: BookingCreation;
  playerLocations: TocaLocation[];
  players: FkoPlayer[];
  removedSessions: BookingSession[];
};

// type SetAvailableAppointments = {
//   data: {
//     appointmentCount: number;
//     appointments: BookingSession[];
//     locations: {[id: string]: any};
//     sessionTypes: {[id: string]: any};
//     staffDetails: {[id: string]: any};
//   };
//   datesInRange?: string[];
// };

export const sortSessionsArrayByDate = (a: BookingSession, b: BookingSession) =>
  new Date(a.startDate).getTime() - new Date(b.startDate).getTime();

const initialState = {
  appointmentCalendar: {},
  bookingCreation: {
    initializedSiteId: null,
    profileId: getCurrentProfileId(),
    usingPreferences: false,
    playerPreferences: {},
    sessions: [],
    bookedSessions: [],
    tempSession: null,
    bookingCompleted: false,
  },
  playerLocations: [],
  players: [],
  removedSessions: [],
} as BookingState;

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    initializeBookingCreation: (state, action: PayloadAction<Partial<BookingCreation>>) => {
      state.bookingCreation = {
        ...state.bookingCreation,
        ...action.payload,
      };
    },
    setPlayerPreferences: (state, action: PayloadAction<PlayerBookingPreferences>) => {
      state.bookingCreation.playerPreferences = {
        ...state.bookingCreation.playerPreferences,
        ...action.payload,
      };
    },
    resetBookingPreferences: (state) => {
      state.bookingCreation.playerPreferences.preferredSession = undefined;
    },
    setDesiredDates: (state, action: PayloadAction<string[]>) => {
      state.bookingCreation.playerPreferences.desiredDates = action.payload;
    },
    clearDesiredDates: (state) => {
      state.bookingCreation.playerPreferences.desiredDates = undefined;
    },
    setAvailableAppointmentsFetching: (state, action: PayloadAction<{fetching: boolean; dateKeys: string[]}>) => {
      for (const dateKey of action.payload.dateKeys) {
        if (state.appointmentCalendar[dateKey]) {
          state.appointmentCalendar[dateKey].fetching = action.payload.fetching;
        }
      }
    },
    // setAvailableAppointments: (state, action: PayloadAction<SetAvailableAppointments>) => {
    //   const {data, datesInRange} = action.payload;

    //   if (data.appointments) {
    //     state.appointmentCalendar = {
    //       ...state.appointmentCalendar,
    //       ...data.appointments.reduce((map: AppointmentCalendar, appointment: Session) => {
    //         const dateInfo = parseDateTime(appointment.startDate);
    //         map[dateInfo.dateStr] = map[dateInfo.dateStr] || {
    //           ...dateInfo,
    //           appointments: [],
    //           fetching: false,
    //           hasFetched: true,
    //         };
    //         appointment.dateStr = dateInfo.dateStr; // add dateStr on appointment for easy map lookup later on
    //         map[dateInfo.dateStr].appointments.push(appointment);
    //         return map;
    //       }, {}),
    //     };
    //   }

    //   // Make sure all dates in range get the updated fetch status, even if no data was returned for a day
    //   if (datesInRange?.length) {
    //     for (const dateStr of datesInRange) {
    //       Object.assign(state.appointmentCalendar[dateStr], {
    //         fetching: false,
    //         hasFetched: true,
    //       });
    //     }
    //   }
    // },
    setBookingSessions: (state, action: PayloadAction<BookingSession[]>) => {
      state.bookingCreation.sessions = action.payload;
    },
    resetBookingSessions: (state) => {
      state.bookingCreation.sessions = [];
      state.bookingCreation.tempSession = null;
    },
    selectAppointment: (state, action: PayloadAction<{session: BookingSession}>) => {
      const {session} = action.payload;
      // const dateStr = session.dateStr ?? '';

      if (!state.bookingCreation.sessions.find((s) => isEqual(s, session))) {
        state.bookingCreation.sessions.push(session);
        state.bookingCreation.sessions.sort(
          (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
        );
      }

      // if (!state.bookingCreation.sessions.find((s) => isEqual(s.session, session))) {
      //   state.bookingCreation.sessions.push({session, dateStr});
      // }

      //TODO using fko appts currently breaks this
      // state.appointmentCalendar[dateStr].appointments = state.appointmentCalendar[dateStr].appointments.filter(
      //   (session) => session.id !== session.id
      // );
    },
    removeSelectedAppointment: (state, action: PayloadAction<{session: Session}>) => {
      const {session} = action.payload;
      // const dateStr = session.dateStr ?? '';

      state.bookingCreation.sessions = state.bookingCreation.sessions
        .filter((s) => !isEqual(s, session))
        .sort(sortSessionsArrayByDate);

      // state.appointmentCalendar[dateStr].appointments.push(session);
      // state.appointmentCalendar[dateStr].appointments.sort(
      //   sortSessionsArrayByDate
      // );
    },
    reselectBookingSession: (state, action: PayloadAction<BookingSession>) => {
      state.bookingCreation.tempSession = action.payload;
      state.bookingCreation.sessions = state.bookingCreation.sessions
        .filter((s) => s.startDate !== action.payload.startDate || s.id !== action.payload.id)
        .sort(sortSessionsArrayByDate);
    },
    revertBookingReselect: (state) => {
      if (state.bookingCreation.tempSession) {
        state.bookingCreation.sessions.push(state.bookingCreation.tempSession);
        state.bookingCreation.sessions.sort(sortSessionsArrayByDate);
        state.bookingCreation.tempSession = null;
      }
    },
    setBookingCompleted: (state, action: PayloadAction<boolean>) => {
      state.bookingCreation.bookingCompleted = action.payload;
    },
    resetInitializedSiteId: (state) => {
      state.bookingCreation.initializedSiteId = null;
    },
  },
});

const actions = bookingSlice.actions;
export const {
  initializeBookingCreation,
  setPlayerPreferences,
  resetBookingPreferences,
  setDesiredDates,
  clearDesiredDates,
  setAvailableAppointmentsFetching,
  // setAvailableAppointments,
  setBookingSessions,
  resetBookingSessions,
  selectAppointment,
  removeSelectedAppointment,
  reselectBookingSession,
  revertBookingReselect,
  setBookingCompleted,
  resetInitializedSiteId,
} = actions;

export default bookingSlice.reducer;

import {Outlet} from 'react-router-dom';
import {TeamOnboardingContainer} from 'components/PageContainer/TeamOnboardingContainer';

export default function TeamOnboardingLayout() {
  return (
    <TeamOnboardingContainer>
      <Outlet />
    </TeamOnboardingContainer>
  );
}

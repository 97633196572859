import {memo, useCallback} from 'react';
import styles from './ProfileModal.module.scss';
import {useProfiles} from '../../api/User/useProfiles';
import {Profile} from '../../user/player-info.interface';
import {useLogout} from '../../hooks/useLogout';
import {useNavigate} from 'react-router-dom';
import {useTrainerContext} from '../../context/UserContextProvider';
import {useGetMboClientStatus} from '../../hooks/useGetMboClientStatus';
import {Modal} from 'components/Modal/Modal';
import {StandardButton} from '../Buttons/Standard/StandardButton';

export const ProfileModal = memo(({isOpen, setClosed}: {isOpen: boolean; setClosed: () => void}) => {
  const navigate = useNavigate();
  const trainerContext = useTrainerContext();
  const {logout} = useLogout();
  const {currentProfile, otherProfiles} = useProfiles();
  const {isInactive} = useGetMboClientStatus();

  const logoutClick = useCallback(() => {
    setClosed();
    logout();
  }, [logout, setClosed]);

  const profileClick = useCallback(() => {
    setClosed();
    navigate('/profile');
  }, [navigate, setClosed]);

  const endEmulation = useCallback(() => {
    setClosed();
    if (trainerContext) {
      trainerContext.endEmulateUser();
      navigate('/trainer');
    }
  }, [navigate, setClosed, trainerContext]);

  return (
    <Modal isOpen={isOpen} onClose={setClosed} title="Profiles">
      <div className={styles.container}>
        <div className={styles.profileWrapper}>
          <div className={styles.profile}>
            <div className={styles.flexRow}>
              <p className={styles.name}>
                {currentProfile?.firstName} {currentProfile?.lastName}
              </p>
              {isInactive(currentProfile?.mboDetails?.clientId) ? (
                <p className={styles.inactiveLabel}>inactive</p>
              ) : null}
            </div>
            {currentProfile.email && <p>{currentProfile.email}</p>}
            <p>{currentProfile?.location?.name}</p>
          </div>
          <StandardButton variant="light" text="My Profile" onClick={profileClick} />
        </div>

        {otherProfiles.length > 0 ? (
          <>
            <h4 className={styles.subheading}>Switch to a different profile below</h4>
            <ul>
              {otherProfiles.map((profile, i) => (
                <ProfileCard profile={profile} key={`profilecard${i}`} setClosed={setClosed} />
              ))}
            </ul>
          </>
        ) : null}

        {trainerContext && trainerContext.trainer.accountId !== currentProfile.accountId ? (
          <div className={styles.buttonContainer}>
            <StandardButton variant="primary" text="End User Emulation" onClick={endEmulation} />
          </div>
        ) : (
          <div className={styles.buttonContainer}>
            <StandardButton variant="light" text="Log Out" onClick={logoutClick} />
          </div>
        )}
      </div>
    </Modal>
  );
});
ProfileModal.displayName = 'ProfileModal';

const ProfileCard = memo(({profile, setClosed}: {profile: Profile; setClosed: () => void}) => {
  const {selectProfile} = useProfiles();
  const {isInactive} = useGetMboClientStatus();

  const onClick = useCallback(() => {
    selectProfile(profile._id);
    setClosed();
  }, [profile._id, selectProfile, setClosed]);

  return (
    <li className={styles.cardContainer} onClick={onClick}>
      <div className={styles.profileWrapper}>
        <div className={styles.profile}>
          <div className={styles.flexRow}>
            <p className={styles.name}>
              {profile.firstName} {profile.lastName}
            </p>
            {isInactive(profile.mboDetails?.clientId) ? <p className={styles.inactiveLabel}>inactive</p> : null}
          </div>
          <p>{profile.location?.name}</p>
        </div>
      </div>
    </li>
  );
});
ProfileCard.displayName = 'ProfileCard';

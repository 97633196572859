import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type DirectorState = {
  activeAssessmentId: string;
  activeTeamId: string;
};

const initialState = {activeAssessmentId: ''} as DirectorState;

const directorSlice = createSlice({
  name: 'director',
  initialState,
  reducers: {
    setActiveAssessmentId(state, action: PayloadAction<string>) {
      state.activeAssessmentId = action.payload;
    },
    setActiveTeamId(state, action: PayloadAction<string>) {
      state.activeTeamId = action.payload;
    },
  },
});

export const {setActiveAssessmentId, setActiveTeamId} = directorSlice.actions;

export default directorSlice.reducer;

import {Outlet} from 'react-router-dom';
import ScrollToTop from '../utils/ScrollToTop';
import Toast from 'components/Toast/Toast';
import {SportProvider} from 'context/SportContext';

export default function RootLayout() {
  return (
    <div className="App toca-member-portal">
      <Toast />
      <ScrollToTop />
      <SportProvider>
        <Outlet />
      </SportProvider>
    </div>
  );
}

import {memo, ReactNode, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './LeaderboardPageContainer.module.scss';
import {ReactComponent as ChevronBack} from '../../../assets/img/icons/new-chevron-left-white.svg';
import {ReactComponent as WorldCupIcon} from '../../../assets/img/icons/wc-logo.svg';
import {useFullWidth} from '../../../hooks/useFullWidth';
import {isTvMode} from '../../../utils/utils';

type LeaderboardPageContainerProps = {
  backButton?: boolean;
  backDestination?: string;
  children: ReactNode;
  icon?: boolean;
  heading?: string;
  secondaryHeading?: string;
};

export const LeaderboardPageContainer = memo(
  ({
    children,
    backButton = false,
    backDestination = '/home',
    icon = false,
    heading,
    secondaryHeading,
  }: LeaderboardPageContainerProps) => {
    const navigate = useNavigate();
    useFullWidth(isTvMode());

    const navigateBack = useCallback(() => navigate(backDestination), [backDestination, navigate]);

    return (
      <div className={styles.container}>
        {heading ? (
          <div className={styles.heading}>
            {backButton ? <BackButton onClick={navigateBack} /> : <div className={styles.blank} />}
            <div className={styles.headingText}>
              {secondaryHeading && <p>{secondaryHeading}</p>}
              <h2>{heading}</h2>
            </div>
            {icon ? <WorldCupIcon className={styles.icon} /> : <div className={styles.blank} />}
          </div>
        ) : null}
        {children}
      </div>
    );
  }
);
LeaderboardPageContainer.displayName = 'LeaderboardPageContainer';

// const ShareButton = memo(({onClick}: {onClick: () => void}) => {
//   return (
//     <button onClick={onClick} className={styles.share}>
//       <ShareIcon />
//     </button>
//   );
// });
// ShareButton.displayName = 'ShareButton';

const BackButton = memo(({onClick}: {onClick: () => void}) => {
  return (
    <button onClick={onClick} className={styles.back}>
      <ChevronBack />
    </button>
  );
});
BackButton.displayName = 'BackButton';

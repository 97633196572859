import {FormProvider, UseFormRegister} from 'react-hook-form';
import Spinner from 'components/Loader/Spinner';
import {Button} from 'components/Connects/Button';
import {ReactComponent as EditIcon} from '../../../assets/img/icons/edit-icon-minimal.svg';
import {teamColors} from 'constants/teamColors';
import {TeamColorSelectionModal} from 'components/TeamColorSelection/TeamColorSelection';
import {StandardInput} from 'components/Input/StandardInput/StandardInput';
import {ErrorMessage} from 'components/Forms/ErrorMessage/ErrorMessage';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import {Team} from 'user/user.interface';

type FormTypes = {
  teamName: string;
};

type EditTeamViewProps = {
  isLoading: boolean;
  team: Team;
  selectedColor: string;
  setSelectedColor: (color: string) => void;
  showTeamColorSelectionModal: boolean;
  setShowTeamColorSelectionModal: (isOpen: boolean) => void;
  editTeamName: boolean;
  setEditTeamName: (edit: boolean) => void;
  handleSubmit: (callback: (data: FormTypes) => void) => (e: React.BaseSyntheticEvent) => Promise<void>;
  onSubmitHandler: (data: FormTypes) => void;
  onClickCancelHandler: () => void;
  register: UseFormRegister<FormTypes>;
  errors: any;
  updatedImgSrc: File | string;
  setUpdatedImgSrc: (img: File | string) => void;
};

export const EditTeamView = ({
  isLoading,
  team,
  selectedColor,
  setSelectedColor,
  showTeamColorSelectionModal,
  setShowTeamColorSelectionModal,
  editTeamName,
  setEditTeamName,
  handleSubmit,
  onSubmitHandler,
  onClickCancelHandler,
  register,
  updatedImgSrc,
  setUpdatedImgSrc,
  errors,
}: EditTeamViewProps) => {
  const teamActiveLogoUrl = (team?.logoUrl as string) || '';

  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmitHandler)}
      className="flex-1 flex flex-col w-auto items-center mt-4 lg:mt-20 mb-8"
    >
      <div className="flex justify-center items-center flex-col">
        <ImageUpload defaultImageUrl={teamActiveLogoUrl} onImageSelect={setUpdatedImgSrc} />
        {!team.logoUrl && !updatedImgSrc && <small className="err-message">Team logo is required</small>}
      </div>

      <div className="mt-7 w-56">
        <div className="text-sm flex items-center justify-between">
          Team&apos;s Name
          {!editTeamName && (
            <EditIcon className="fill-grey-xdark h-4 w-4 cursor-pointer mb-1" onClick={() => setEditTeamName(true)} />
          )}
        </div>
        {editTeamName ? (
          <>
            <StandardInput
              spellCheck="false"
              type="text"
              id="teamName"
              placeholder="Team Name"
              {...register('teamName')}
            />
            {errors.teamName?.message && <ErrorMessage text={errors.teamName?.message} />}
          </>
        ) : (
          <div className="text-lg text-primary font-semibold border-b border-grey mt-4">{team.teamName}</div>
        )}
      </div>
      <div className="mt-7 w-56">
        <div className="text-sm flex items-center justify-between border-b border-grey pb-1">
          <div className="flex items-center">
            Color <div className="w-4 h-4 rounded-sm ml-5" style={{backgroundColor: selectedColor}} />
          </div>
          <EditIcon
            className="fill-grey-xdark h-4 w-4 cursor-pointer mb-1"
            onClick={() => setShowTeamColorSelectionModal(true)}
          />
        </div>
        <TeamColorSelectionModal
          isOpen={showTeamColorSelectionModal}
          closeModal={() => setShowTeamColorSelectionModal(false)}
          activeColor={team.teamColor}
          onColorSelectionHandler={setSelectedColor}
        />
      </div>

      <div className="flex mt-6 lg:mt-24 w-80 items-center">
        <Button type="submit" text="Update" color="secondary" variant="cta" />
        <Button
          className="ml-4"
          text="Cancel"
          color="primary"
          variant="standard"
          onClick={onClickCancelHandler}
          type="button"
        />
      </div>
    </form>
  );
};

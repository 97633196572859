import {useState} from 'react';
import {useForm} from 'react-hook-form';
import defaultTeamLogo from '../../../assets/img/team-logo-placeholder.svg';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import {TeamColorSelectionModal} from 'components/TeamColorSelection/TeamColorSelection';
import {FormInput} from 'components/Forms/FormInput/FormInput';
import {Button} from 'components/Connects/Button';

export interface TeamFormData {
  teamName: string;
  teamColor: string;
  logoUrl?: File | string;
}

interface NativeInputFormData {
  teamName: string;
}

interface TeamInfoProps {
  onCancelHandler: () => void;
  onClickViewTeamMembersHandler?: () => void;
  onSubmitHandler: (data: TeamFormData) => void;
  loading: boolean;
  error: string;
}

/**
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onCancelHandler - The function to call once user click on cancel.
 * @returns {JSX.Element} The JSX element representing the form to create team.
 */
export const TeamForm = ({onCancelHandler, onSubmitHandler, loading, error}: TeamInfoProps) => {
  //team logo related states
  const [imgSrc, setImgSrc] = useState<File | string>('');

  //team color related states
  const [teamColor, setTeamColor] = useState<string>('');
  const [showTeamColorSelectionModal, setShowTeamColorSelectionModal] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: {errors, isSubmitted},
  } = useForm<NativeInputFormData>();

  const submitFormHandler = (data: NativeInputFormData) => {
    if (!imgSrc || !teamColor) {
      return;
    }
    const teamData: TeamFormData = {
      teamName: data.teamName.trim(),
      teamColor,
      logoUrl: imgSrc,
    };
    onSubmitHandler(teamData);
  };

  const onClickCancelHandler = () => {
    reset();
    onCancelHandler();
  };

  return (
    <form className="flex flex-col justify-center items-center" onSubmit={handleSubmit(submitFormHandler)}>
      <div className="flex justify-center items-center flex-col mb-4">
        <ImageUpload defaultImageUrl={defaultTeamLogo} onImageSelect={setImgSrc} />
        {isSubmitted && !imgSrc && <p className="text-xs text-alert mt-1 mb-1">Team logo is required</p>}
      </div>

      <div className="w-full">
        <FormInput
          {...register('teamName', {required: 'Team Name is required'})}
          id="teamName"
          hasError={!!errors.teamName?.message}
          placeholder="Team Name"
          type="text"
        />
        <p className="text-xs text-alert m-1 text-left">{errors.teamName?.message ?? '\u00A0'}</p>
      </div>

      <div className="w-full mb-4">
        <button
          type="button"
          className="w-full h-12 bg-grey-xlight rounded-md"
          onClick={() => setShowTeamColorSelectionModal(true)}
        >
          <div className="flex items-center justify-between ml-4 mr-4">
            <span className="text-base text-grey-xdark font-light">
              {teamColor ? 'Team Color' : 'Select Team Color'}
            </span>{' '}
            <span style={{backgroundColor: teamColor || 'transparent'}} className="w-6 h-6"></span>
          </div>
        </button>

        <TeamColorSelectionModal
          activeColor={teamColor}
          isOpen={showTeamColorSelectionModal}
          closeModal={() => setShowTeamColorSelectionModal(false)}
          onColorSelectionHandler={setTeamColor}
        />
        {isSubmitted && !teamColor && <p className="text-xs text-alert m-1">Team color is required</p>}
      </div>

      {error && <p className="text-xs text-alert mb-1">{error}</p>}

      <div className="flex items-center justify-center mt-4">
        <Button text="Save" color="secondary" variant="cta" disabled={loading} />
        <Button className="ml-4" text="Cancel" color="primary" variant="standard" onClick={onClickCancelHandler} />
      </div>
    </form>
  );
};

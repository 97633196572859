export interface BenchmarkEntry {
  targetSOP: number;
  targetAccuracy: number;
  targetMph: number;
  targetStreak: number;
}

export interface ProficiencyLevel {
  [key: string]: BenchmarkEntry;
}
export interface NewBaselineBenchmarkValues {
  beginner: ProficiencyLevel;
  intermediate: ProficiencyLevel;
  advanced: ProficiencyLevel;
}
type BaselineLevel = 'beginner' | 'intermediate' | 'advanced';

export function isBaselineLevel(level: string): level is BaselineLevel {
  return ['beginner', 'intermediate', 'advanced'].includes(level);
}

export const baselineBenchmarkValues: NewBaselineBenchmarkValues = {
  beginner: {
    'GC1-5 to FL/FR (2) x 10 Passing': {
      targetSOP: 2.81,
      targetAccuracy: 0.37,
      targetMph: 11.79,
      targetStreak: 1.83,
    },
    'GC1-5 to FL/FR (1) x 10 Passing': {
      targetSOP: 3.03,
      targetAccuracy: 0.47,
      targetMph: 11.73,
      targetStreak: 2.83,
    },
    'GC1-5 to BL/BR (1) x 10 Passing': {
      targetSOP: 2.87,
      targetAccuracy: 0.35,
      targetMph: 12.32,
      targetStreak: 1.5,
    },
    'GC1-5 to ALL 4 (3) x 10 Passing': {
      targetSOP: 3.42,
      targetAccuracy: 0.42,
      targetMph: 12.12,
      targetStreak: 2.98,
    },
    'GLR1-5 to FL/FR (1) x 10 Passing': {
      targetSOP: 2.56,
      targetAccuracy: 0.38,
      targetMph: 14.97,
      targetStreak: 2.1,
    },
    'GLR1-5 to BL/BR (1) x 10 Passing': {
      targetSOP: 2.4,
      targetAccuracy: 0.4,
      targetMph: 12.46,
      targetStreak: 2.08,
    },
    'GLR1-5 to ALL 4 (3) x 10 Passing': {
      targetSOP: 3.17,
      targetAccuracy: 0.36,
      targetMph: 13.18,
      targetStreak: 2.12,
    },
    'GC1-6 to ALL 4 (3) x 10 Finishing Multi Touch': {
      targetSOP: 3.66,
      targetAccuracy: 0.47,
      targetMph: 16.78,
      targetStreak: 3.14,
    },
    'GC1-5 to ALL 4 (3) x 10 Finishing (NDF)': {
      targetSOP: 2.92,
      targetAccuracy: 0.29,
      targetMph: 12.0,
      targetStreak: 1.47,
    },
    'GC1-5 to ALL 4 (3) x 10 Finishing (DF)': {
      targetSOP: 2.46,
      targetAccuracy: 0.29,
      targetMph: 18.47,
      targetStreak: 1.75,
    },
  },
  intermediate: {
    'GC2-5 to ALL 4 (4) x 12 Passing': {
      targetSOP: 2.44,
      targetAccuracy: 0.49,
      targetMph: 16.78,
      targetStreak: 3.32,
    },
    'GC2-5 to ALL 4 (2) x 12 Passing': {
      targetSOP: 2.85,
      targetAccuracy: 0.26,
      targetMph: 14.82,
      targetStreak: 1.75,
    },
    'GLR2-5 to ALL 4 (2) x 12 Passing': {
      targetSOP: 2.67,
      targetAccuracy: 0.25,
      targetMph: 11.24,
      targetStreak: 1.8,
    },
    'G3PAN2-5 to ALL 4 (2) x 12 Passing': {
      targetSOP: 2.92,
      targetAccuracy: 0.33,
      targetMph: 12.62,
      targetStreak: 2.25,
    },
    'AC2-5 to ALL 4 (2) x 12 Passing': {
      targetSOP: 3.13,
      targetAccuracy: 0.23,
      targetMph: 13.57,
      targetStreak: 1.18,
    },
    'LC2-5 to ALL 4 (2) x 12 Passing': {
      targetSOP: 3.57,
      targetAccuracy: 0.17,
      targetMph: 13.94,
      targetStreak: 1.2,
    },
    'GALC2-5 to ALL 4 (2) x 12 Passing': {
      targetSOP: 3.24,
      targetAccuracy: 0.3,
      targetMph: 12.06,
      targetStreak: 1.89,
    },
    'GLR2-5 to ALL 4 (3) x 12 Finishing': {
      targetSOP: 2.96,
      targetAccuracy: 0.31,
      targetMph: 17.33,
      targetStreak: 2.45,
    },
    'AC1-5 to ALL 4 (3) x 12 Finishing (DF)': {
      targetSOP: 3.07,
      targetAccuracy: 0.27,
      targetMph: 12.24,
      targetStreak: 1.9,
    },
    'LC3-5 to ALL 4 (3) x 12 Finishing': {
      targetSOP: 3.43,
      targetAccuracy: 0.2,
      targetMph: 17.21,
      targetStreak: 1.42,
    },
  },
  advanced: {
    'GC2-4 to ALL 4 (4) x 12 Passing': {
      targetSOP: 2.69,
      targetAccuracy: 0.49,
      targetMph: 15.0,
      targetStreak: 3.21,
    },
    'GC2-4 to ALL 4 (1) x 12 Passing': {
      targetSOP: 2.99,
      targetAccuracy: 0.38,
      targetMph: 18.35,
      targetStreak: 3.33,
    },
    'G3PAN2-4 to ALL 4 (1) x 12 Passing': {
      targetSOP: 2.74,
      targetAccuracy: 0.35,
      targetMph: 13.33,
      targetStreak: 3.86,
    },
    'AC2-5 to ALL 4 (1) x 12 Passing': {
      targetSOP: 3.89,
      targetAccuracy: 0.34,
      targetMph: 17.62,
      targetStreak: 2.67,
    },
    'GALC2-5 to ALL 4 (2) x 12 Passing': {
      targetSOP: 3.38,
      targetAccuracy: 0.21,
      targetMph: 19.35,
      targetStreak: 2.0,
    },
    'GLR3-5 to ALL 4 (3) x 12 Finishing': {
      targetSOP: 3.29,
      targetAccuracy: 0.25,
      targetMph: 24.36,
      targetStreak: 1.5,
    },
    'AC1-4 to ALL 4 (3) x 12 Finishing (DF)': {
      targetSOP: 2.46,
      targetAccuracy: 0.31,
      targetMph: 26.74,
      targetStreak: 2.5,
    },
    'LC4-5 to ALL 4 (3) x 12 Finishing': {
      targetSOP: 3.66,
      targetAccuracy: 0.33,
      targetMph: 21.1,
      targetStreak: 2.33,
    },
    'GLR2-4 to ALL 4 (1) x 12 Passing': {
      targetSOP: 2.15,
      targetAccuracy: 0.26,
      targetMph: 17.92,
      targetStreak: 2.25,
    },
    'LC2-5 to ALL 4 (1) x 12 Passing': {
      targetSOP: 2.92,
      targetAccuracy: 0.21,
      targetMph: 12.0,
      targetStreak: 2.0,
    },
  },
};

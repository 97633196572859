import {ReactNode, useMemo} from 'react';
import styles from './StandardButtonFKO.module.scss';

export type ButtonVariant = 'primary' | 'light' | 'dark' | 'ghost' | 'warning' | 'danger';

export interface StandardButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: ReactNode;
  iconToLeft?: boolean;
  size?: 'standard' | 'large';
  text?: string;
  variant?: ButtonVariant;
}

export const StandardButtonFKO = (props: StandardButtonProps) => {
  const {variant = 'light', size = 'standard', icon, iconToLeft, text, ...rest} = props;

  const buttonClasses = useMemo(
    () => `${styles[variant]} ${styles[size]} ${styles.container} ${iconToLeft ? styles.iconToLeft : ''}`,
    [iconToLeft, size, variant]
  );

  if (!text && !icon) {
    return null;
  }

  return (
    <button className={buttonClasses} {...rest}>
      {text && <div className={styles.textContainer}>{text}</div>}
      {icon && <div className={styles.icon}>{icon}</div>}
    </button>
  );
};

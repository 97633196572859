import {useQuery} from '@tanstack/react-query';
import {Profile} from 'user/player-info.interface';
import {PlayerSessionSummary} from 'api/Trainer/useGetPlayerLastSessionSummary';
import {getSelectedPlayersAssessmentPerformanceData} from 'api/api';

export type AssessmentPlayerPerformanceData = {
  playerInfo: Profile;
  performanceDetails: PlayerSessionSummary;
};

export type GetSelectedPlayerAssessmentDataParams = {
  playerIds: string;
  assessmentId: string;
  role: string;
  sortBy: string;
};

//playerIds is a string of id's joined by commas
export const useGetSelectedPlayersAssessmentData = ({
  playerIds,
  assessmentId,
  role,
  sortBy,
}: GetSelectedPlayerAssessmentDataParams) => {
  return useQuery<AssessmentPlayerPerformanceData[]>({
    queryKey: ['selected-players-assessment-data', playerIds, assessmentId, sortBy, role],
    queryFn: () => getSelectedPlayersAssessmentPerformanceData(playerIds, assessmentId, role, sortBy),
    enabled: !!playerIds && !!assessmentId,
  });
};

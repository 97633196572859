import {useEffect, useMemo, useRef, useState} from 'react';
import {debounce} from 'lodash';
import Fuse from 'fuse.js';
import {LeaderboardRankings} from 'api/Leaderboards/leaderboard.types';

export const useLeaderboardSearch = ({rankings}: {rankings: LeaderboardRankings['rankings']}) => {
  const [query, setQuery] = useState('');

  const updateQuery = useRef(debounce(setQuery, 250)).current;

  useEffect(() => {
    return () => {
      updateQuery.cancel();
    };
  }, [updateQuery]);

  const fuse = useMemo(
    () =>
      new Fuse(rankings, {
        findAllMatches: true,
        ignoreLocation: true,
        keys: ['athleteName'],
        minMatchCharLength: 2,
        shouldSort: false,
        threshold: 0,
      }),
    [rankings]
  );

  const indexResults = useMemo(() => fuse.search(query).map((res) => res.refIndex), [fuse, query]);

  return {query, updateQuery, indexResults};
};

import {useMutation} from '@tanstack/react-query';
import {updateTeam as updateTeamApi, updateTeamLogo} from 'api/api';
import {useCallback} from 'react';
import {Team} from 'user/user.interface';

export const useUpdateTeam = (onSuccessHandler: () => void, onErrorHandler: () => void, role: string) => {
  // Mutation for updating team details
  const mutateUpdateTeam = useMutation<void, unknown, Partial<Team>>({
    mutationFn: (updatedTeam) => updateTeamApi(updatedTeam, role).then((res: any) => res.data),
  });

  // Mutation for updating team logo
  const mutateUpdateTeamLogo = useMutation<void, unknown, FormData>({
    mutationFn: (formData) => updateTeamLogo(formData, role).then((res: any) => res.data),
  });

  // Function to update team details and then the team logo
  const updateTeamAndLogo = useCallback(
    async ({teamDetails, teamId, logo}: {teamDetails: Partial<Team>; teamId: string; logo: File | string}) => {
      try {
        // Step 1: Update Team Details
        await mutateUpdateTeam.mutateAsync(teamDetails);

        // Step 2: Update Team Logo if available
        if (teamId && logo instanceof File) {
          const formData = new FormData();
          formData.append('file', logo as File);
          formData.append('teamId', teamId as string);
          await mutateUpdateTeamLogo.mutateAsync(formData);
        }

        // Show success toast if both operations succeed
        onSuccessHandler();
      } catch (error) {
        onErrorHandler();
      }
    },
    [mutateUpdateTeam, mutateUpdateTeamLogo, onSuccessHandler, onErrorHandler]
  );

  return {
    updateTeamAndLogo,
    loading: mutateUpdateTeam.isLoading || mutateUpdateTeamLogo.isLoading,
  };
};

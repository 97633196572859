import {memo} from 'react';
import {StandardButton} from '../../../../../components/Buttons/Standard/StandardButton';
import {PlayerShort} from '../../../Trainer';
import styles from './TrainerSearchPlayerCard.module.scss';

type SearchCardProps = {
  player: PlayerShort;
  onClick: () => void;
};

export const TrainerSearchPlayerCard = memo(({player, onClick}: SearchCardProps) => {
  return (
    <li className={styles.container}>
      <div className={styles.playerInfo}>
        <p className={styles.name}>
          {player.firstName} {player.lastName}
        </p>
        <p className={styles.email}>{player.email}</p>
      </div>
      <StandardButton text="Emulate" onClick={onClick} />
    </li>
  );
});
TrainerSearchPlayerCard.displayName = 'TrainerSearchPlayerCard';

import {FutureVisit, Visit} from 'api/Booking/useGetMboVisits';
import {BOOKING_CANCELLATION_THRESHOLD_HOURS} from 'constants/bookingConsts';
import {SandboxLocation, getLocationBySiteId} from 'constants/locations';
import {getAdjustedISODate} from './utils';

export const parseVisits = ({visits, limit}: {visits?: Visit[]; limit?: number}) => {
  const now = Date.now();
  const isCancellable = now + BOOKING_CANCELLATION_THRESHOLD_HOURS * 60 * 60 * 1000;
  const futureVisits: FutureVisit[] = [];
  const pastVisits = [];

  if (visits?.length) {
    for (const visit of visits) {
      // TODO is it even possible for visits to have different locations??
      const location = getLocationBySiteId(String(visit.SiteId));
      // we're fetching visits with a siteId in the request...
      const locationIsoDateStr = getAdjustedISODate(visit.StartDateTime, location || SandboxLocation);
      const startTime = new Date(locationIsoDateStr).getTime();
      if (now < startTime) {
        futureVisits.push({...visit, cancellable: isCancellable < startTime, location});
      } else {
        pastVisits.push(visit);
      }
    }
  }

  pastVisits.reverse(); // Most recent visits first
  if (limit && limit < 0) {
    limit = undefined;
  }
  // Most recent [X] visits
  const recentVisits = limit && pastVisits.length > limit ? pastVisits.slice(0, limit + 1) : pastVisits;
  const nearFutureVisits = limit && futureVisits.length > limit ? futureVisits.slice(0, limit + 1) : futureVisits;

  return {
    pastVisits,
    futureVisits,
    recentVisits,
    nearFutureVisits,
  };
};

import {memo, ReactNode} from 'react';
import {ReactComponent as CloseIcon} from '../../assets/img/icons/close-icon-grey.svg';
import styles from './DrawerModal.module.scss';
import {ModalContainer} from '../Modal/ModalContainer';
import {StandardButtonFKO} from 'components/Buttons/Standard/StandardButtonFKO';

type DrawerModalProps = {
  asModal?: boolean;
  children: ReactNode;
  // closeOnClick?: React.MouseEventHandler<HTMLButtonElement>;
  closeOnClick?: () => void;
  open?: boolean;
  visible?: boolean;
};

// TODO fix animations
export const DrawerModal = memo(
  ({asModal = false, children, closeOnClick, open = true, visible = true}: DrawerModalProps) => {
    const DrawerModalContent = () => (
      <div className={styles.container}>
        <div className={`${styles.mobileWrapper} ${open ? styles.open : ''}`}>
          {closeOnClick && visible && (
            <StandardButtonFKO onClick={closeOnClick} icon={<CloseIcon />} className={styles.closeButton} />
          )}
          {visible && <div className={styles.content}>{children}</div>}
        </div>
      </div>
    );

    return asModal ? (
      <ModalContainer isOpen={open} onClose={() => closeOnClick?.()}>
        <DrawerModalContent />
      </ModalContainer>
    ) : (
      <DrawerModalContent />
    );
  }
);
DrawerModal.displayName = 'DrawerModal';

import './Admin.style.scss';
import {Link} from 'react-router-dom';

const Admin = () => {
  return (
    <div className="bottom-container admin">
      <h1>Admin</h1>
      <div>
        <Link to="/admin/analyze-user" className="submit-btn">
          Analyze user
        </Link>
      </div>
      <div className="button-container">
        <Link to="/logout" className="submit-btn">
          Sign out
        </Link>
      </div>
    </div>
  );
};

export default Admin;

import {useCallback, useEffect, useState} from 'react';
import styles from './SchedulingForm.module.scss';
import {Swiper, SwiperSlide} from 'swiper/react';
import type {Swiper as SwiperType} from 'swiper';
import {Navigation} from 'swiper';
import {FormButton} from '../../components/Forms/FormButton/FormButton';
import {ScheduleCard} from './SchedulingCard';
import LoaderInline from '../LoaderInline/LoaderInline';
import {AppointmentCalendar, FkoPlayer} from '../../redux/slices/fkoFormData';
import {useAppDispatch, useAppSelector} from '../../redux/reduxUtils';
import {FetchAppointmentsHack} from 'pages/RegistrationLanding/FetchAppointmentsHack';
import {ReactComponent as ArrowLeftIcon} from '../../assets/img/icons/redesign-icons/arrow-filled-circle-left.svg';
import {ReactComponent as ArrowRightIcon} from '../../assets/img/icons/redesign-icons/arrow-filled-circle-right.svg';
import {ReactComponent as CalendarIcon} from '../../assets/img/icons/redesign-icons/calendar-minimal.svg';
import {FkoDatePicker} from 'components/DatePicker/DatePicker';
import moment from 'moment';
import {constructDateFromHyphenated} from 'utils/utils';
import {AvailabilityLocationInfo} from 'api/FKO/useGetAvailableSessions';
import {SchedulingEmptyDayForm} from './SchedulingEmptyDayForm';
import {DEFAULT_FKO_DAILY_END_TIME, DEFAULT_FKO_DAILY_START_TIME, getLocationBySiteId} from '../../constants/locations';
import {DateTime} from 'luxon';
import {useGetSport} from '../../common/useGetSport';

export type Session = {
  id: string;
  startDate: string;
  endDate: string;
  sessionType: {id: number; name: string};
  staff: {id: number; firstName: string; lastName: string};
  location: AvailabilityLocationInfo;
  siteId?: string;
  dateStr?: string;
};

export const SchedulingForm = ({player, onSubmit}: {player: FkoPlayer; onSubmit: (session: Session) => void}) => {
  const dispatch = useAppDispatch();
  const {appointmentCalendar, siteId, initialParams} = useAppSelector((state) => state.fkoFormDataReducer);
  const {isDbat, isPlayerAssessment} = useGetSport();

  const firstSessionKey = Object.keys(appointmentCalendar)[0];
  const lastSessionKey = Object.keys(appointmentCalendar).reverse()[0];
  const [selectedSession, setSelectedSession] = useState<Session | undefined>();
  const [selectedDate, setSelectedDate] = useState<string>(firstSessionKey);
  const [fetching, setFetching] = useState<boolean>(
    Boolean(firstSessionKey && appointmentCalendar[firstSessionKey]?.fetching)
  );
  const [fetched, setFetched] = useState<boolean>(
    Boolean(firstSessionKey && appointmentCalendar[firstSessionKey]?.hasFetched)
  );
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [scrollToDate, setScrollToDate] = useState<string>();

  const disabled = !selectedSession;
  let visibleAppointments = selectedDate ? appointmentCalendar[selectedDate].appointments : [];

  const location = getLocationBySiteId(siteId);
  const dailyStartFromUrl = initialParams?.dailyStartHour
    ? {
        hour: Number(initialParams.dailyStartHour),
        minute: initialParams?.dailyStartMinute ? Number(initialParams.dailyStartMinute) : 0,
      }
    : null;
  const dailyEndFromUrl = initialParams?.dailyEndHour
    ? {
        hour: Number(initialParams.dailyEndHour),
        minute: initialParams?.dailyEndMinute ? Number(initialParams.dailyEndMinute) : 0,
      }
    : null;

  const fkoDailyStartTime =
    dailyStartFromUrl ?? location?.fkoApplyDailyTimeRestrictions
      ? location?.fkoDailyStartTime ?? DEFAULT_FKO_DAILY_START_TIME
      : null;
  const fkoDailyEndTime =
    dailyEndFromUrl ?? location?.fkoApplyDailyTimeRestrictions
      ? location?.fkoDailyEndTime ?? DEFAULT_FKO_DAILY_END_TIME
      : null;

  // Filter visible appointments based on daily time restrictions (given via URL params or configured)
  if (fkoDailyStartTime || fkoDailyEndTime) {
    const earliestAllowedTime = fkoDailyStartTime
      ? DateTime.fromISO(selectedDate).toUTC().set(fkoDailyStartTime)
      : null;
    const latestAllowedTime = fkoDailyEndTime ? DateTime.fromISO(selectedDate).toUTC().set(fkoDailyEndTime) : null;

    visibleAppointments = visibleAppointments.filter((appt) => {
      const apptStart = DateTime.fromISO(appt.startDate, {setZone: true});
      const apptEnd = DateTime.fromISO(appt.endDate, {setZone: true});
      const validStartTime = !earliestAllowedTime || apptStart >= earliestAllowedTime;
      const validEndTime = !latestAllowedTime || apptEnd <= latestAllowedTime;
      return validStartTime && validEndTime;
    });
  }

  const onDateSelect = useCallback(
    (dateKey: string) => {
      if (!appointmentCalendar?.[dateKey]) {
        return;
      }

      const fetching = Boolean(appointmentCalendar[dateKey].fetching);
      const fetched = Boolean(appointmentCalendar[dateKey].hasFetched);
      setFetching(fetching);
      setFetched(fetched);
      setSelectedDate(dateKey);
      setSelectedSession(undefined);
    },
    [appointmentCalendar]
  );

  const calendarSelect = useCallback(
    (date?: Date) => {
      setIsCalendarOpen(false);
      if (date) {
        const dateStr = moment(date.toISOString()).format('YYYY[-]MM[-]DD');
        onDateSelect(dateStr);
        setScrollToDate(dateStr);
      }
    },
    [onDateSelect]
  );

  // TODO RQ was envisioning replacing all the fetching logic with individual day-queries.
  // that might not be the correct direction, but maybe re-evaluate all these state calls
  useEffect(() => {
    if (!appointmentCalendar?.[selectedDate]) {
      if (selectedDate !== firstSessionKey) {
        setSelectedDate(firstSessionKey);
      }
      return;
    }

    const fetching = Boolean(appointmentCalendar[selectedDate].fetching);
    const fetched = Boolean(appointmentCalendar[selectedDate].hasFetched);
    setFetching(fetching);
    setFetched(fetched);

    // TODO currently selected session should be evalutated to still exist after RQ refetches.
    // need to consider if this is the correct space for that as it would add a frequently changing dependancy
  }, [selectedDate, appointmentCalendar, dispatch, siteId, firstSessionKey]);

  let headingText = 'Book Now - Free Baseline Sessions Fill Quickly';

  if (isDbat) {
    headingText = 'Book Now - Promotional Lessons fill quickly';
  } else if (isPlayerAssessment) {
    headingText = 'Book Now - Player Assessments fill quickly';
  }

  return (
    <div className={styles.container}>
      <div className={styles.formHeading}>
        <h1>{headingText}</h1>
        <h2>
          Pick a time for
          <br />
          <span>
            {player.firstName} {player.lastName}
          </span>
        </h2>
      </div>

      <div className={`relative flex flex-col transition-all`}>
        <div className="absolute top-[-60px] right-0 h-[36px] w-[36px] grid place-content-center pb-[2px] rounded-full bg-fko-primary/5 hover:bg-fko-primary/10 transition-all">
          <button onClick={() => setIsCalendarOpen((prev) => !prev)}>
            <CalendarIcon className="h-[24px] w-[24px] fill-fko-primary active:fill-fko-highlight opacity-90 hover:opacity-100 transition-all" />
          </button>
        </div>
        <div
          className={`${
            isCalendarOpen ? 'h-auto max-h-[500px] opacity-100 mb-[24px]' : 'max-h-[0px] opacity-0'
          } flex justify-center items-start overflow-hidden transition-all`}
        >
          <div className="p-2 border border-fko-primary/20 rounded-lg">
            <FkoDatePicker
              selected={constructDateFromHyphenated(selectedDate)}
              onSelect={(date) => calendarSelect(date)}
              startDate={firstSessionKey}
              endDate={lastSessionKey}
            />
          </div>
        </div>
      </div>

      <FkoDateCarousel
        appointmentCalendar={appointmentCalendar}
        setSelectedDate={setSelectedDate}
        selectedDate={selectedDate}
        onDateSelect={onDateSelect}
        scrollToDate={scrollToDate}
      />

      {fetching && (
        <div className={styles.loader}>
          <LoaderInline text="Loading session times..." />
        </div>
      )}

      <div>
        {Boolean(visibleAppointments?.length) && (
          <>
            {visibleAppointments.map((appointment: Session, index: number) => (
              <div key={index} onClick={() => setSelectedSession(appointment)}>
                <ScheduleCard session={appointment} selected={selectedSession?.id === appointment.id} />
              </div>
            ))}

            <div className={styles.buttonWrapper}>
              <div className={styles.buttonContainer}>
                {disabled && <p className={styles.scrollText}>Scroll down for more times</p>}
                <FormButton
                  type="submit"
                  disabled={disabled}
                  hasError={disabled}
                  isPrimary
                  // 'disabled' = !selectedSession
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  onClick={() => onSubmit(selectedSession!)}
                >
                  {disabled ? 'Select A Time To Continue' : 'Reserve This Time'}
                </FormButton>
              </div>
            </div>
          </>
        )}

        {fetched && !visibleAppointments?.length && (
          <div className="flex flex-col items-center gap-4 mx-auto mt-12 p-3 xs:p-6 max-w-[420px] border border-grey-light rounded-lg shadow-dispersed">
            <SchedulingEmptyDayForm selectedDate={selectedDate} />
          </div>
        )}
      </div>

      {/* This is a fall-back to the one in PlayersRoot, and ideally never gets used.
          It only fetches single days instead of the full booking window. */}
      {siteId && (
        <FetchAppointmentsHack
          refetch={!fetched && !visibleAppointments.length}
          siteId={siteId}
          startDate={`${selectedDate}T00:00:00Z`}
          endDate={`${selectedDate}T23:59:59Z`}
        />
      )}
    </div>
  );
};

const FkoDateCarousel = ({
  appointmentCalendar,
  onDateSelect,
  selectedDate,
  setSelectedDate,
  scrollToDate,
}: {
  appointmentCalendar: AppointmentCalendar;
  onDateSelect: (dateStr: string) => void;
  selectedDate: string;
  setSelectedDate: (key: string) => void;
  scrollToDate?: string;
}) => {
  const [swiper, setSwiper] = useState<SwiperType>();

  const onSlideSelection = (key: string) => {
    setSelectedDate(key);
    onDateSelect(key);
  };

  useEffect(() => {
    if (swiper && scrollToDate) {
      const index = Object.keys(appointmentCalendar).findIndex((date) => date === scrollToDate);
      swiper?.slideTo(index - 1);
    }
  }, [appointmentCalendar, scrollToDate, swiper]);

  return (
    <div className={styles.carouselWrapper}>
      <div className="relative">
        <ArrowLeftIcon
          id="button-prev"
          className="h-[32px] w-[32px] bg-white rounded-full fill-fko-primary active:fill-fko-highlight opacity-90 hover:opacity-100 absolute left-px top-[50%] translate-y-[-50%] z-10 transition-all"
        />
        <ArrowRightIcon
          id="button-next"
          className="h-[32px] w-[32px] bg-white rounded-full fill-fko-primary active:fill-fko-highlight opacity-90 hover:opacity-100 absolute right-px top-[50%] translate-y-[-50%] z-10 transition-all"
        />
        <Swiper
          modules={[Navigation]}
          id="date-carousel"
          onSwiper={setSwiper}
          slidesPerView="auto"
          navigation={{
            nextEl: '#button-next',
            prevEl: '#button-prev',
            disabledClass: '!opacity-0',
          }}
          freeMode={true}
          spaceBetween={16}
          slidesOffsetAfter={64}
          slidesOffsetBefore={24}
        >
          {Object.keys(appointmentCalendar).map((dateKey: string, index: number) => (
            <SwiperSlide key={index} onClick={() => onSlideSelection(dateKey)} className={styles.carouselSlide}>
              <div
                className={`${styles.carouselItem} ${selectedDate === dateKey ? styles.selected : ''} ${
                  !appointmentCalendar[dateKey].appointments.length && appointmentCalendar[dateKey].hasFetched
                    ? styles.empty
                    : ''
                } `}
              >
                <div className={styles.dateDay}>{appointmentCalendar[dateKey].dayStrShort}</div>
                <div className={styles.dateMonth}>{appointmentCalendar[dateKey].day}</div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

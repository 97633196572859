import {memo, ReactNode} from 'react';
import {Link} from 'react-router-dom';
import styles from './FKOFormContainer.module.scss';
import {useFullWidth} from 'hooks/useFullWidth';
import {useGetSport} from 'common/useGetSport';

export const FKOFormContainer = memo(
  ({
    children,
    restartLink = true,
    withFooter = true,
  }: {
    children: ReactNode;
    restartLink?: boolean;
    withFooter?: boolean;
  }) => {
    const {basePath} = useGetSport();
    useFullWidth(true);

    return (
      <div className={styles.container}>
        {children}
        {withFooter ? (
          <div className={styles.footerContainer}>
            <Link className={styles.link} to={`${basePath}/help`}>
              Need help?
            </Link>
            {restartLink && (
              <Link className={styles.link} to={`${basePath}/restart`}>
                Start over
              </Link>
            )}
          </div>
        ) : null}
      </div>
    );
  }
);

FKOFormContainer.displayName = 'FKOFormContainer';

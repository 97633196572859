interface SegmentedProgressCircleProps {
  r: number;
  cx?: number;
  cy?: number;
  strokeWidth?: number;
  value?: number;
  maxValue?: number;
}

const SegmentedProgressCircle = ({
  r,
  cx = 50,
  cy = 50,
  strokeWidth = 4,
  value = 50,
  maxValue = 100,
}: SegmentedProgressCircleProps) => {
  const progress = (value / maxValue) * 100;

  const circumference = 2 * 3.14 * r;
  const offset = circumference * ((100 - progress) / 100);

  const size = Math.ceil((r * 2 + strokeWidth * 4) / 8) * 8;

  return (
    <div>
      <div className="relative inline-block">
        <div className="flex flex-col justify-center w-full h-full absolute z-1 text-center">
          <div className="text-2xl text-white">{value}</div>
        </div>
        <div className="transform -rotate-90">
          <svg width={size} height={size}>
            <circle
              fill="transparent"
              r={r}
              cy={cy}
              cx={cx}
              stroke="white"
              strokeWidth={strokeWidth}
              strokeDasharray="2,2"
              strokeLinecap="butt"
            ></circle>
            {progress && (
              <circle
                fill="transparent"
                r={r}
                cy={cy}
                cx={cx}
                stroke="white"
                strokeWidth={2 * strokeWidth}
                strokeLinecap="round"
                strokeDasharray={circumference + 'px'}
                strokeDashoffset={offset + 'px'}
              ></circle>
            )}
          </svg>
        </div>
      </div>
    </div>
  );
};

export default SegmentedProgressCircle;

import logo from '../../../assets/img/icons/redesign-icons/dbat-logo.png';

export const DbatLogo = ({withBackground = true}: {withBackground?: boolean}) => {
  return (
    // 'toca-logo' toggles display:none when embedded
    <div
      className={`toca-logo flex justify-center ${
        withBackground ? 'h-[192px] bg-fko-primary w-full mb-2' : 'h-[128px]'
      }`}
    >
      <img src={logo} className="max-w-[192px] object-contain" />
    </div>
  );
};

import {Outlet} from 'react-router-dom';
import AuthContainer from '../components/AuthContainer/AuthContainer';
import {Helmet} from 'react-helmet';
import CoachAuthContainer from 'components/CoachAuthContainer/CoachAuthContainer';
import {roleType} from 'constants/constants';
import DirectorAuthContainer from 'components/DirectorAuthContainer/DirectorAuthContainer';

export default function CoachDirectorLayout({role}: {role: string}) {
  const RoleBasedAuthContainer = role === roleType.teamCoach ? CoachAuthContainer : DirectorAuthContainer;
  return (
    <>
      <Helmet>
        <title>MyTOCA | {role} View</title>
      </Helmet>
      <AuthContainer>
        <RoleBasedAuthContainer>
          <Outlet />
        </RoleBasedAuthContainer>
      </AuthContainer>
    </>
  );
}

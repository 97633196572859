import {useCallback} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import emailLogo from 'assets/img/icons/redesign-icons/email.svg';
import phoneLogo from 'assets/img/icons/redesign-icons/phone.svg';
import addressLogo from 'assets/img/icons/redesign-icons/address.svg';
import {Button} from 'components/Connects/Button';

interface LocationItemProps {
  siteId: string;
  isNearest: boolean;
  index: number;
  distanceInMiles: number;
  name: string;
  address: string;
  state: string;
  email: string;
  centerUrl: string;
  phoneNumber: string;
  onSelectLocation: (lat: number, lon: number, index: number) => void;
  isActive: boolean;
  coordinates: {
    lat: number;
    lon: number;
  };
  fkoSupported: boolean;
  showHorizontalView?: boolean;
}

/**
 * Represents a location item card component which shows detailed info about a toca location.
 */
export const LocationItem = ({
  siteId,
  isNearest,
  name,
  address,
  state,
  email,
  phoneNumber,
  onSelectLocation,
  index,
  coordinates,
  isActive,
  showHorizontalView,
  centerUrl,
  fkoSupported,
}: LocationItemProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Handles the selection of a toca location on map.
   */
  const handleSelectLocation = useCallback(() => {
    onSelectLocation(coordinates.lat, coordinates.lon, index);
  }, [onSelectLocation, coordinates, index]);

  /**
   * Handles the click event for more details.
   */
  const handleClickOnMoreDetails = useCallback(() => {
    window?.parent?.postMessage({ toca_map_navigate: `https://my.tocafootball.com/fko?locationId=${siteId}`, redirect: true }, '*');
    
    // toggle on if used by specified route: all-centers
    if(location.pathname === '/all-centers') {
      navigate(`/fko?locationId=${siteId}`);
    }
  }, [siteId, navigate, location]);

  /**
   * Handles the click event for center info.
   */
  const goToCenterHomePage = useCallback(
    (event: React.MouseEvent<HTMLParagraphElement>) => {
      event.stopPropagation();
      window?.parent?.postMessage({ toca_map_navigate: centerUrl, redirect: true }, '*');
          
    // toggle on if used by specified route: all-centers
      if(location.pathname === '/all-centers') {
        window.location.href = centerUrl;
      }
    },
    [centerUrl, location]
  );

  return (
    <div
      className={`border-solid m-4 mt-0 rounded-lg min-w-[304px] bg-white lg:bg-transparent ${
        isActive ? 'border-primary border-4' : 'border-grey border'
      } ${showHorizontalView ? 'min-h-[286px]' : 'min-h-[255px]'}`}
      onClick={handleSelectLocation}
    >
      {isNearest && (
        <div className="border-primary border-2 bg-primary text-white pl-6 pt-3 pb-3 rounded-tl-[16px] rounded-tr-[16px] leading-5 text-sm font-medium">
          Nearest Center to you
        </div>
      )}
      <div className="pt-3 pl-4 pb-4 pr-5 w-full mt-3">
        <p
          className="text-lg text-primary font-medium leading-6 cursor-pointer inline"
          onClick={goToCenterHomePage}
        >{`${index + 1}. ${name}${state && `, ${state}`}`}</p>

        <div className="mt-6 mb-4">
          {phoneNumber && (
            <div className="flex text-sm">
              <img className="mr-2" src={phoneLogo} />
              {phoneNumber}
            </div>
          )}
          {email && (
            <div className="flex mt-2 text-sm">
              <img className="mr-2" src={emailLogo} />
              {email}
            </div>
          )}
          {address && (
            <div className="flex mt-2 text-sm">
              <img className="mr-2" src={addressLogo} />
              {address}
            </div>
          )}
        </div>
        <p className="text-primary text-xs cursor-pointer underline inline" onClick={goToCenterHomePage}>
          Center Details
        </p>
        {fkoSupported && (
          <div className="mt-4">
            <Button text="Free Baseline Session" color="primary" variant="cta" onClick={handleClickOnMoreDetails} />
          </div>
        )}
      </div>
    </div>
  );
};

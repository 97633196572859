import {memo, useCallback, useMemo, useState} from 'react';
import moment from 'moment';
import {ClassSchedule, Enrollment, EnrollmentClass} from 'api/Classes/useGetPrograms';
import {Button} from 'components/Connects/Button';
import {CheckboxAlt} from 'components/Checkbox/Checkbox';
import {EnrollmentCheckoutCard} from './EnrollmentCheckoutCard';
import {FormDividerRevised} from 'components/Forms/FormDivider/FormDividerRevised';

type ProgramCheckoutSelectionProps = {
  enrollment: Enrollment | ClassSchedule;
  onSubmit: ({selectedClasses}: {selectedClasses?: string[]}) => void;
  selectedPlayerIds: string[];
};

export const ProgramCheckoutSelection = memo(
  ({enrollment, selectedPlayerIds, onSubmit}: ProgramCheckoutSelectionProps) => {
    const [isSelectingClasses, setIsSelectingClasses] = useState(false);
    const [selectedClasses, setSelectedClasses] = useState<string[]>();

    const selectClass = useCallback(
      (time: string) => {
        if (selectedClasses?.includes(time)) {
          setSelectedClasses((prev) => prev?.filter((selectedTime) => selectedTime !== time));
        } else {
          setSelectedClasses((prev) => [...(prev ?? []), time]);
        }
      },
      [selectedClasses]
    );

    const toggleClassSelection = useCallback(() => {
      if (isSelectingClasses) {
        setIsSelectingClasses(false);
        setSelectedClasses(undefined);
      } else {
        setIsSelectingClasses(true);
        setSelectedClasses(enrollment?.classes?.map((cls) => cls?.startDateTime));
      }
    }, [enrollment?.classes, isSelectingClasses]);

    const checkoutConditionsError = useMemo(
      () =>
        !selectedPlayerIds.length ||
        (isSelectingClasses && !selectedClasses?.length) ||
        (selectedClasses?.length &&
          enrollment.classes.find(
            (cls) => Number(cls?.webCapacity) - Number(cls?.webBooked) < selectedPlayerIds.length
          )) ||
        (!isSelectingClasses && enrollment.capacity.available < selectedPlayerIds.length),
      [
        enrollment.capacity.available,
        enrollment.classes,
        isSelectingClasses,
        selectedClasses?.length,
        selectedPlayerIds.length,
      ]
    );

    // TODO this is brittle
    const overrideStartDate = useMemo(
      () =>
        selectedClasses?.length
          ? enrollment.classes.find((cls) => cls.startDateTime === selectedClasses[0])?.startDateTime
          : undefined,
      [enrollment.classes, selectedClasses]
    );

    if (!enrollment) {
      console.error('enrollment missing during checkout');
      return null;
    }

    // if (enrollment?.schedule.dateForward...) {
    //   // TODO add initial class date, maybe expand down list of dates?
    // }

    return (
      <>
        <div className="flex flex-col gap-8">
          <EnrollmentCheckoutCard
            enrollment={enrollment}
            overrideSessionCount={selectedClasses?.length}
            overrideStartDate={overrideStartDate}
          />

          <div className="flex flex-col-reverse sm:flex-row xs:mx-8 sm:mx-0 justify-around gap-y-2 gap-x-4">
            {enrollment.schedule.allowOpenEnrollment ? (
              <>
                <div className="shrink">
                  <Button
                    text={isSelectingClasses ? `Select Full Enrollment` : `Select Individual Dates`}
                    onClick={toggleClassSelection}
                  />
                </div>
                <div className="block sm:hidden">
                  <FormDividerRevised text="or" />
                </div>
              </>
            ) : null}

            <div className="shrink">
              <Button
                variant="cta"
                onClick={() => onSubmit({selectedClasses})}
                text="Confirm Reservation"
                color={checkoutConditionsError ? 'grey' : 'primary'}
                disabled={!!checkoutConditionsError}
              />
            </div>
          </div>

          {/* <div className="flex">
            <CheckboxAlt
              id="selectall"
              // checked={selectedClasses?.includes(cls.StartDateTime)}
              // onChange={() => selectClass(cls.StartDateTime)}
            />
            <p className="font-poppins font-semibold text-lg text-primary">Select All</p>
          </div> */}

          {isSelectingClasses ? (
            <div className="grid gap-2 grid-cols-1 sm:grid-cols-2 sm:gap-x-8">
              {enrollment.classes.map((cls) => {
                return (
                  <div key={cls.classId} className="flex">
                    <div className="grid place-content-center mr-2">
                      <CheckboxAlt
                        id={cls.startDateTime}
                        checked={selectedClasses?.includes(cls.startDateTime)}
                        onChange={() => selectClass(cls.startDateTime)}
                      />
                    </div>
                    <_ClassCard enrollmentClass={cls} />
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </>
    );
  }
);
ProgramCheckoutSelection.displayName = 'ProgramCheckoutSelection';

type _ClassCardProps = {
  enrollmentClass: EnrollmentClass;
};
const _ClassCard = memo(({enrollmentClass}: _ClassCardProps) => {
  const dateTime = moment(enrollmentClass.startDateTime);
  const capacity = {
    max: Math.max(Number(enrollmentClass.maxCapacity), Number(enrollmentClass.webCapacity)),
    available: Number(enrollmentClass.webCapacity) - Number(enrollmentClass.webBooked),
  };

  return (
    <div className="grow px-6 py-2 overflow-hidden bg-white border border-grey rounded-lg shadow-flat">
      <p className="font-poppins font-semibold text-lg text-primary">
        <span className="text-secondary">{dateTime.format('dddd[, ]')}</span>
        {dateTime.format('MMM D YYYY')}
      </p>
      <div className="flex justify-between">
        <p className="font-poppins font-medium text-sm text-grey-xdark">{dateTime.format('h:mma')}</p>
        <p className="font-poppins font-medium text-sm text-grey-xdark">{`${capacity.available}/${capacity.max} available`}</p>
      </div>
    </div>
  );
});
_ClassCard.displayName = 'ClassCard';

import {forwardRef, ReactNode} from 'react';
import styles from './FormSelect.module.scss';

export type FormSelectOption = {
  id: any;
  value: any;
  displayValue?: any;
};

export type FormOptionGroup = {
  label: string;
  options: FormSelectOption[];
};

interface FormSelectProps extends React.HTMLProps<HTMLSelectElement> {
  hasError?: boolean;
  options?: FormSelectOption[];
  optionGroups?: FormOptionGroup[];
  placeholderValue?: string;
}

export const FormSelect = forwardRef<HTMLSelectElement, FormSelectProps>((props, ref) => {
  const {hasError, options, optionGroups, placeholderValue, ...rest} = props;

  const selectOptions: ReactNode[] = [];

  if (options) {
    selectOptions.push(
      ...options.map((option) => {
        return (
          <option key={option.id} value={option.value}>
            {option.displayValue ?? option.value}
          </option>
        );
      })
    );
  }

  if (optionGroups) {
    selectOptions.push(
      ...optionGroups.map((optionGroup, index) => {
        return (
          <optgroup key={index} label={optionGroup.label}>
            {optionGroup.options.map((option) => (
              <option key={option.id} value={option.value}>
                {option.displayValue ?? option.value}
              </option>
            ))}
          </optgroup>
        );
      })
    );
  }

  return (
    <select ref={ref} className={hasError ? `${styles.formSelect} ${styles.errorState}` : styles.formSelect} {...rest}>
      {placeholderValue && (
        // disabled-placeholder value is currently hacky and will be improved
        <option disabled value="disabled-placeholder">
          {placeholderValue}
        </option>
      )}
      {selectOptions}
    </select>
  );
});
FormSelect.displayName = 'FormSelect';

import {memo} from 'react';
import {Link} from 'react-router-dom';
import {ClassSchedule, Enrollment} from 'api/Classes/useGetPrograms';
import {
  format24hrTimeString,
  formatTimeVaguely,
  formatWeekdaysArrayForDisplay,
  getClassDisplayName,
} from 'utils/programs-utils';
import {Button} from 'components/Connects/Button';
import {ReactComponent as LocationPinIcon} from '../../assets/img/icons/redesign-icons/location-pin.svg';
import {ReactComponent as ClockFaceIcon} from '../../assets/img/icons/redesign-icons/clock-face.svg';
import {ReactComponent as CalendarIcon} from '../../assets/img/icons/redesign-icons/calendar-minimal.svg';
//
import classPlaceholder from '../../assets/img/groupClass.jpg';
import imageOverrideAges1_2 from '../../assets/img/ages1-2.jpeg';
import imageOverrideAges3_4 from '../../assets/img/ages3-4.jpeg';
import imageOverrideAges5_6 from '../../assets/img/ages5-6.jpeg';
import imageOverrideAges7_8 from '../../assets/img/ages7-8.jpeg';
import imageOverrideAges9_10 from '../../assets/img/ages9-10.jpeg';
import imageOverrideAges11_13 from '../../assets/img/ages11-13.jpeg';

// TODO CLASSES host images properly and remove hacky overrides
export const selectOverrideImage = ({enrollmentName}: {enrollmentName?: string}) => {
  return enrollmentName?.toLowerCase().includes('1-2')
    ? imageOverrideAges1_2
    : enrollmentName?.toLowerCase().includes('3-4')
    ? imageOverrideAges3_4
    : enrollmentName?.toLowerCase().includes('5-6')
    ? imageOverrideAges5_6
    : enrollmentName?.toLowerCase().includes('7-8')
    ? imageOverrideAges7_8
    : enrollmentName?.toLowerCase().includes('9-10')
    ? imageOverrideAges9_10
    : enrollmentName?.toLowerCase().includes('11-13')
    ? imageOverrideAges11_13
    : classPlaceholder;
};

type EnrollmentCardProps = {
  enrollment?: Enrollment;
  classSchedule?: ClassSchedule;
  locationName?: string;
  partialBookingUrl: string;
  openContactForm: ({enrollment}: {enrollment: Enrollment | ClassSchedule | null}) => void;
};
// TODO CLASSES reevaluate naming
export const EnrollmentCard = memo(
  ({enrollment, classSchedule, locationName, partialBookingUrl, openContactForm}: EnrollmentCardProps) => {
    const _enrollment = enrollment ?? classSchedule;
    const _enrollmentId = enrollment?.enrollmentId ?? classSchedule?.classScheduleId;
    if (!_enrollment) {
      // TODO CLASSES error handling
      return null;
    }

    const enrollmentImage = selectOverrideImage({enrollmentName: _enrollment?.name});

    const startTime = format24hrTimeString({time: _enrollment.schedule.startTime});
    const vagueTime = startTime ? formatTimeVaguely({timeString: _enrollment.schedule.startTime}) : 'TimeError';
    const availableCount = _enrollment.capacity?.available;
    // const startTime = moment(_enrollment.schedule.startTime).format('h:mma');
    const classDisplayName = getClassDisplayName(_enrollment?.name);

    return (
      <div className="bg-white overflow-hidden border border-grey rounded-lg flex flex-col sm:flex-row sm:gap-2 lg:gap-4 shadow-md">
        <Link to={`${_enrollmentId}`}>
          <div className="flex h-44 sm:w-52 sm:h-full md:w-44 lg:w-56">
            {/* TODO CLASSES fix image things */}
            <img
              alt=""
              className="h-full w-full object-cover"
              src={enrollmentImage}
              // src={_enrollment.classDescription.imageURL ?? classPlaceholder}
              // className="h-full w-full object-cover object-[25%,60%]"
            />
          </div>
        </Link>

        <div className="grow grid grid-cols-[1fr_auto] gap-2 p-4 md:gap-1 md:p-2 lg:p-4">
          <Link to={`${_enrollmentId}`} className="col-span-2 hover:underline">
            <h4 className="font-poppins font-semibold text-xl leading-[24px] text-primary">{classDisplayName}</h4>
          </Link>

          <div className="col-span-1 sm:col-span-1 flex flex-col gap-2">
            <p className="font-poppins font-semibold text-sm text-grey-xdark uppercase tracking-[2px] mb-1">
              {_enrollment.tags?.classType}
            </p>

            <div className="flex flex-col gap-1">
              <div className="grid grid-cols-[auto_1fr] gap-2 items-center mr-1">
                <LocationPinIcon className="h-4 w-4 min-h-4 fill-grey-xdark" />
                <p className="font-poppins font-medium text-sm text-grey-xdark">{locationName}</p>
              </div>
              <div className="grid grid-cols-[auto_1fr] gap-2 items-center mr-1">
                <ClockFaceIcon className="h-4 w-4 min-h-4 fill-grey-xdark" />
                <p className="font-poppins font-medium text-sm text-grey-xdark">{`${vagueTime} (${startTime})`}</p>
              </div>
              <div className="grid grid-cols-[auto_1fr] gap-2 items-center mr-1">
                <CalendarIcon className="h-4 w-4 min-h-4 fill-grey-xdark" />
                <p className="font-poppins font-medium text-sm text-grey-xdark">
                  {formatWeekdaysArrayForDisplay(_enrollment.schedule.daysArray)}
                </p>
              </div>
            </div>
          </div>

          <div className="col-span-2 md:col-span-1 grid grid-cols-2 xs:grid-cols-[auto,auto,1fr] md:grid-cols-1 gap-x-3 gap-y-2 items-center mt-2 md:mr-1">
            <Link to={`${_enrollmentId}`}>
              <Button text="Details" />
            </Link>

            {availableCount ? (
              <Link to={`${partialBookingUrl}/${_enrollmentId}/register`}>
                <Button text="Book&nbsp;Now" variant="cta" color="primary" />
              </Link>
            ) : (
              <div>
                <Button
                  text="Book&nbsp;Now"
                  variant="cta"
                  color="grey"
                  onClick={() => openContactForm({enrollment: _enrollment})}
                />
                {/* <Button text="Book&nbsp;Now" variant="cta" color="grey" disabled /> */}
              </div>
            )}

            {/* ---disabling this as the display is confusing for Classes--- */}
            {/* TODO CLASSES: re-evaluate when Enrollments are a thing again */}
            {/* <div className="col-span-2 xs:col-span-1 grid place-content-center">
              <p className="font-poppins text-sm text-grey-xdark">
                {availableCount === 1 ? `${availableCount} spot available` : `${availableCount} spots available`}
              </p>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
);
EnrollmentCard.displayName = 'EnrollmentCard';

import {logLoginEvent} from '../common/analytics-events';
import {setAccessToken, setCurrentAccountId} from '../user/user-utils';

export const useSocialAuth = () => {
  return async (socialProvider: any) => {
    try {
      const result = await socialProvider();
      if (result) {
        setCurrentAccountId(result.user.uid);
        setAccessToken(result.user.accessToken);
      }

      logLoginEvent(result.providerId || 'socialAuth', {
        userUid: result.user?.uid,
        operationType: result.operationType,
      });

      return {success: true, result: result};
    } catch (err: any) {
      if (err.message !== 'Firebase: Error (auth/popup-closed-by-user).') {
        console.log('socialProvider error:', err);
      }
      return {success: false, error: err};
    }
  };
};

import {memo} from 'react';
import logo from '../../assets/img/icons/toca-logo-black.png';
import {Link, useParams} from 'react-router-dom';
import {getLocationBySiteId} from 'constants/locations';
import {HamburgerMenu} from './HamburgerMenu';
// import {ReactComponent as ShareIcon} from '../../assets/img/icons/redesign-icons/share.svg';

export const MenuBar = memo(({showHamburger = true}: {showHamburger?: boolean}) => {
  const {siteId} = useParams();
  const locationName = getLocationBySiteId(siteId ?? '')?.name;

  return (
    <div className="sticky ml-[-20px] md:ml-[-32px] lg:ml-[-48px] top-0 left-0 w-[calc(100%+40px)] md:w-[calc(100%+64px)] lg:w-[calc(100%+96px)] z-50">
      <div className="h-[52px] xs:h-[64px] flex justify-between items-center px-4 md:px-12 bg-white border-0 border-b border-grey">
        <div className="flex py-4">
          <Link to="http://tocafootball.com/">
            <img src={logo} className="object-contain w-20 xs:w-24" />
          </Link>
        </div>

        <div className="flex justify-end items-center gap-3">
          {/* <ShareButton /> */}
          {/* <ShareIcon /> */}

          <div className="hidden md:flex items-center py-4">
            {locationName ? (
              <p className="font-light font-poppins text-sm text-black uppercase tracking-[2px]">
                Location:&nbsp;<span className="font-semibold underline">{locationName}</span>
                {/* <LocationSelect /> */}
              </p>
            ) : null}
            {/* TODO handle missing location? */}
          </div>

          {showHamburger && <HamburgerMenu />}
        </div>
      </div>

      {locationName ? (
        <div className="md:hidden">
          <div className="flex justify-center p-1 bg-primary">
            <p className="font-light font-poppins text-xs xs:text-sm text-white uppercase tracking-[2px]  ">
              Location:&nbsp;<span className="font-semibold">{locationName}</span>
            </p>
          </div>
        </div>
      ) : null}
    </div>
  );
});
MenuBar.displayName = 'MenuBar';

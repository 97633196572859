import {ReactNode} from 'react';
import styles from './MessageContainer.module.scss';
import {ReactComponent as AlertIcon} from '../../../assets/img/icons/exclamation-mark.svg';

export const MessageContainer = ({children}: {children: ReactNode}) => {
  return <div className={styles.container}>{children}</div>;
};

export const AlertContainer = ({children}: {children: ReactNode}) => {
  return (
    <div className={`${styles.container} ${styles.alert}`}>
      <div className={styles.alertIcon}>
        <AlertIcon />
      </div>
      {children}
    </div>
  );
};

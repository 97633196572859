import {useProfiles} from '../../api/User/useProfiles';
import {PropsWithChildren} from 'react';

export default function WithCurrentProfileContainer({children}: PropsWithChildren) {
  const {currentProfile} = useProfiles();

  if (!currentProfile) {
    return null;
  }

  return <>{children}</>;
}

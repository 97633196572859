import {useGetFkoAvailableSessions} from 'api/FKO/useGetFkoAvailableSessions';
import {memo, useEffect} from 'react';

type FetchAppointmentsProps = {
  siteId: string;
  startDate: string;
  endDate: string;
  refetch?: boolean;
  sessionTypeNames?: string[];
};

export const FetchAppointmentsHack = memo(
  ({siteId, startDate, endDate, refetch, sessionTypeNames}: FetchAppointmentsProps) => {
    // this fetches TOCA or DBAT fko sessions based on SportContext within 'useGetFkoAvailableSessions'
    const {refetch: forceRefetch} = useGetFkoAvailableSessions({
      siteId: refetch !== false ? siteId : undefined, // siteId doubles as 'enabled' for getAvailableSessions
      startDate,
      endDate,
      sessionTypeNames,
    });

    useEffect(() => {
      if (refetch) {
        void forceRefetch();
      }
    }, [forceRefetch, refetch]);

    return null;
  }
);
FetchAppointmentsHack.displayName = 'FetchAppointmentsHack';

import {memo} from 'react';
import {Profile} from 'user/player-info.interface';
import {ClassSchedule, Enrollment} from 'api/Classes/useGetPrograms';
import {EnrollmentCheckoutCard} from './EnrollmentCheckoutCard';
import Spinner from 'components/Loader/Spinner';
import {ReactComponent as CheckmarkIcon} from '../../assets/img/icons/redesign-icons/checked-circle-filled.svg';
import {ReactComponent as ErrorIcon} from '../../assets/img/icons/redesign-icons/cancel.svg';
import {AddClientToClassResponse} from 'api/api';
import moment from 'moment';
import {FkoPlayer} from 'redux/slices/fkoFormData';

type ProgramCheckoutConfirmationProps = {
  enrollment: Enrollment | ClassSchedule;
  playersArray: (Profile | FkoPlayer)[];
  selectedPlayerIds: string[];
  selectedSessionTime?: string;
  // response: ProgramsCheckoutResponse[]; // TODO CLASSES re-enable programs checkout
  response: AddClientToClassResponse[];
};

export const ProgramCheckoutConfirmation = memo(
  ({enrollment, response, playersArray, selectedPlayerIds, selectedSessionTime}: ProgramCheckoutConfirmationProps) => {
    if (!enrollment) {
      console.error('enrollment missing during checkout');
      return null;
    }

    // const overrideStartDateTime = response
    //   ? moment(response.find((res) => res.Visit?.StartDateTime)?.Visit?.StartDateTime).toISOString()
    //   : undefined;
    const overrideStartDateTime = selectedSessionTime ? moment(selectedSessionTime).toISOString() : undefined;

    return (
      <>
        <div className="animate-fade-in flex flex-col gap-3">
          {/* <div className="flex mx-4 xs:mx-6 max-w-[800px] flex-wrap"> */}
          <div className="flex flex-wrap gap-2 max-w-[800px] ">
            {selectedPlayerIds.map((id) => (
              <_PlayerConfirmationCard key={id} playerMboId={id} response={response} playersArray={playersArray} />
            ))}
          </div>

          <EnrollmentCheckoutCard enrollment={enrollment} overrideStartDate={overrideStartDateTime} isSingleSelection />
        </div>

        {/* <div className="flex mx-auto">
          <Button variant="cta" text="Add to Calendar" />
        </div> */}
      </>
    );
  }
);
ProgramCheckoutConfirmation.displayName = 'ProgramCheckoutConfirmation';

type _PlayerConfirmationCardProps = {
  playersArray: (Profile | FkoPlayer)[];
  playerMboId: string;
  // response: ProgramsCheckoutResponse[] | AddClientToClassResponse[]; // TODO CLASSES re-enable programs checkout functionality
  response: AddClientToClassResponse[];
};
const _PlayerConfirmationCard = memo(({playerMboId, response, playersArray}: _PlayerConfirmationCardProps) => {
  const profile = playersArray.find((profile) => profile?.mboDetails?.clientId === playerMboId);
  // const player = response?.find((res) => res.Clients?.find((client) => client.Id === profile?.mboDetails?.clientId)); // TODO "Added"?
  const player = response?.find((res) => res.Visit?.ClientId === profile?.mboDetails?.clientId);
  const playerError = !!response?.find((res) => res.error?.playerId === playerMboId);

  return (
    // <div className="flex flex-col xs:flex-row items-center gap-x-2 sm:gap-x-4 px-4 sm:px-6 py-2 bg-white overflow-hidden border border-grey rounded-tr rounded-tl mb-[-4px] ml-[-4px] shadow-flat-lg">
    <div className="flex flex-col xs:flex-row items-center gap-x-2 sm:gap-x-4 px-8 xs:px-4 sm:px-6 py-2 bg-white overflow-hidden border border-grey rounded shadow-flat-lg">
      <div
        className={`h-[48px] w-[48px] min-h-[48px] min-w-[48px] border-2 border-primary rounded-full grid place-content-center`}
      >
        {player ? (
          <CheckmarkIcon className="h-[48px] w-[48px] rounded-full fill-[green]" />
        ) : playerError ? (
          <ErrorIcon className="h-[48px] w-[48px] rounded-full fill-[red]" />
        ) : (
          <Spinner />
        )}
      </div>

      <p className={`font-poppins font-medium text-lg text-primary text-center capitalize truncate w-full`}>
        {profile?.firstName}
      </p>
    </div>
  );
});
_PlayerConfirmationCard.displayName = 'PlayerTab';

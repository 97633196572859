import ProgressBar from 'react-customizable-progressbar';
import './NestedRadialBar.style.scss';
import {ReactComponent as Fire} from '../../assets/img/icons/nested-bar-icon.svg';
import {themeColors} from 'constants/theme';

interface NestedRadialBarTypes {
  firstProg: number;
  secondProg: number;
  thirdProg: number;
}

const NestedRadialBar = ({firstProg, secondProg, thirdProg}: NestedRadialBarTypes) => {
  const calcStreakPercentage = (progress: number) => {
    const percentage = parseInt(((100 / 12) * progress).toFixed(0));
    if (percentage > 100) {
      return 100;
    }
    return percentage;
  };
  const calcSpeedPercentage = (progress: number) => {
    const percentage = parseInt(progress.toFixed(0));
    if (percentage > 100) {
      return 100;
    }
    return percentage;
  };

  // const props = {
  //   pointerRadius: 3,
  //   strokeWidth: 12,
  //   trackStrokeWidth: 4,
  //   pointerStrokeWidth: 0,
  //   pointerStrokeColor: '#7bcd5c',
  //   initialAnimationDelay: 1000,
  //   trackStrokeColor: '#474d5d',
  //   initialAnimation: true,
  //   trackTransition: '.1s ease',
  //   transition: '1s ease',
  // };
  const props = {
    pointerRadius: 3,
    strokeWidth: 12,
    trackStrokeWidth: 4,
    pointerStrokeWidth: 0,
    // pointerStrokeColor: '#7bcd5c',
    initialAnimationDelay: 250,
    trackStrokeColor: themeColors.lightgrey,
    initialAnimation: true,
    trackTransition: '.25s ease',
    transition: '0.75s ease',
  };

  return (
    <div className="nested-radial-bar">
      <ProgressBar
        className="first-circle"
        radius={66}
        progress={firstProg}
        strokeColor={themeColors.primary}
        {...props}
      >
        <div className="indicator">
          <ProgressBar
            className="second-circle"
            radius={51}
            progress={calcSpeedPercentage(secondProg)}
            strokeColor={themeColors.secondary}
            {...props}
          >
            <div className="indicator-2">
              <ProgressBar
                className="third-circle"
                radius={36}
                progress={calcStreakPercentage(thirdProg)}
                strokeColor={themeColors.tertiary}
                {...props}
              />
              <div className="indicator-3">
                <Fire fill={themeColors.lightgrey} />
              </div>
            </div>
          </ProgressBar>
        </div>
      </ProgressBar>
    </div>
  );
};

export default NestedRadialBar;

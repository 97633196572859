import {useState} from 'react';
import './AdminAnalyzeUser.style.scss';
import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {analyzeUser} from '../../api/api';
import {getLocationBySiteId} from '../../constants/locations';
import {useProfiles} from '../../api/User/useProfiles';
import {isAdmin} from '../../user/user-utils';
import {Profile} from '../../user/player-info.interface';
import {ReactComponent as ChevronLeft} from '../../assets/img/icons/redesign-icons/chevron-left.svg';

type UserAnalysisFormTypes = {
  email: string;
  firstName: string;
  lastName: string;
};

const userAnalysisFormValidationSchema = yup.object().shape({
  email: yup.string().required('Email is required').email('Email is invalid'),
  firstName: yup.string(),
  lastName: yup.string(),
});

const AdminAnalyzeUser = () => {
  const {currentProfile} = useProfiles();
  const [analysisResult, setAnalysisResult] = useState<any>();
  const [analysisError, setAnalysisError] = useState<string | null>();
  const [analysisRunning, setAnalysisRunning] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<UserAnalysisFormTypes>({
    mode: 'onChange',
    resolver: yupResolver(userAnalysisFormValidationSchema),
  });

  if (!isAdmin(currentProfile)) {
    return null;
  }

  const onSubmitHandler = async (data: any) => {
    setAnalysisRunning(true);
    setAnalysisError(null);
    setAnalysisResult(null);
    const result = await analyzeUser(data);
    if (result.error) {
      setAnalysisError(result.error);
    } else {
      setAnalysisResult(result.data);
    }
    setAnalysisRunning(false);
  };

  return (
    <div className="bottom-container admin">
      <h1>
        <a href="/admin">
          <ChevronLeft />
        </a>
        Analyze User
      </h1>

      <div className="user-analysis-data-form">
        <div className="description">
          Analyzes the setup and data of a user by checking Firebase Auth, MongoDB, and MBO data.
        </div>
        <form onSubmit={handleSubmit(onSubmitHandler)} className="form">
          <div className={`form-control ${errors.email?.message ? 'error' : ''}`}>
            <input {...register('email')} type="email" id="email" placeholder="Player Email" spellCheck="false" />
            <small className="err-message">{errors.email?.message ? errors.email.message : '\u00A0'}</small>
          </div>

          <div className={`form-control ${errors.firstName?.message ? 'error' : ''}`}>
            <input
              {...register('firstName')}
              type="text"
              id="firstName"
              placeholder="First Name (optional)"
              spellCheck="false"
            />
            <small className="err-message">{errors.firstName?.message ? errors.firstName.message : '\u00A0'}</small>
          </div>

          <div className={`form-control ${errors.lastName?.message ? 'error' : ''}`}>
            <input
              {...register('lastName')}
              type="text"
              id="lastName"
              placeholder="Last Name (optional)"
              spellCheck="false"
            />
            <small className="err-message">{errors.lastName?.message ? errors.lastName.message : '\u00A0'}</small>
          </div>

          <div className="button-container">
            <button
              type="submit"
              className={`submit-btn ${Object.keys(errors).length ? '' : 'red'}`}
              disabled={analysisRunning}
            >
              {analysisRunning ? 'Analyzing...' : 'Analyze'}
            </button>
          </div>
        </form>
      </div>

      {!analysisRunning && (analysisResult || analysisError) && (
        <AnalysisResultDisplay data={analysisResult} error={analysisError} />
      )}
    </div>
  );
};

const AnalysisResultDisplay = ({data, error}: any) => {
  if (error || !data) {
    return (
      <div className="analysis-result">
        <div className="error">{error?.message || 'No data available'}</div>
      </div>
    );
  }

  return (
    <div className="analysis-result">
      <div>
        <h2>Result</h2>
        <FirebaseAuthResultDisplay data={data} />
        <MongoDbResultDisplay data={data} />
        <MboResultDisplay data={data} />
      </div>
    </div>
  );
};

const FirebaseAuthResultDisplay = ({data}: any) => {
  const [showRawData, setShowRawData] = useState<boolean>(false);

  return (
    <div className="section">
      <h3>Firebase Auth</h3>
      {!data.firebaseAuthUserFound && <div>No Firebase Auth user found</div>}
      {data.firebaseAuthUserFound && (
        <div>
          <table>
            <tbody>
              <tr>
                <td>UID</td>
                <td>{data.firebaseAuthUserData.uid}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{data.firebaseAuthUserData.email}</td>
              </tr>
              <tr>
                <td>Phone number</td>
                <td>{data.firebaseAuthUserData.phoneNumber || 'n/a'}</td>
              </tr>
              <tr>
                <td>Email verified</td>
                <td>{data.firebaseAuthUserData.emailVerified ? 'Yes' : 'No'}</td>
              </tr>
              <tr>
                <td>Disabled</td>
                <td>{data.firebaseAuthUserData.disabled ? 'Yes' : 'No'}</td>
              </tr>
              <tr>
                <td>Created</td>
                <td>{data.firebaseAuthUserData.metadata.creationTime}</td>
              </tr>
              <tr>
                <td>Last sign in</td>
                <td>{data.firebaseAuthUserData.metadata.lastSignInTime}</td>
              </tr>
              <tr>
                <td>Auth providers</td>
                <td>{data.firebaseAuthUserData.providerData.map((pd: any) => pd.providerId).join(', ')}</td>
              </tr>
            </tbody>
          </table>
          <div>
            <button className="toggle-button" onClick={() => setShowRawData(!showRawData)}>
              {showRawData ? 'Hide' : 'Show'} raw data
            </button>
          </div>
          {showRawData && (
            <pre>
              <code>{JSON.stringify(data.firebaseAuthUserData, null, 4)}</code>
            </pre>
          )}
        </div>
      )}
    </div>
  );
};

const MongoDbResultDisplay = ({data}: any) => {
  const [showRawData, setShowRawData] = useState<boolean>(false);

  return (
    <div className="section">
      <h3>MongoDB Profiles</h3>
      {!data.mongoDbProfilesFound && <div>No MongoDB profiles found</div>}
      {data.mongoDbProfilesFound && (
        <div>
          {data.mongoDbProfiles.map((profile: Profile) => {
            return (
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td>Account ID</td>
                      <td>{profile.accountId}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{profile.email}</td>
                    </tr>
                    <tr>
                      <td>Phone number</td>
                      <td>{profile.phoneNumber || 'n/a'}</td>
                    </tr>
                    <tr>
                      <td>First name</td>
                      <td>{profile.firstName}</td>
                    </tr>
                    <tr>
                      <td>Last name</td>
                      <td>{profile.lastName}</td>
                    </tr>
                    <tr>
                      <td>Role</td>
                      <td>{profile.role?.length ? profile.role.join(', ') : '-'}</td>
                    </tr>
                    <tr>
                      <td>MBO Data</td>
                      <td>
                        {!profile.mboDetails?.clientId && <div>-</div>}
                        {profile.mboDetails?.clientId && (
                          <pre>
                            <code>{JSON.stringify(profile.mboDetails, null, 4)}</code>
                          </pre>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  <button className="toggle-button" onClick={() => setShowRawData(!showRawData)}>
                    {showRawData ? 'Hide' : 'Show'} raw data
                  </button>
                </div>
                {showRawData && (
                  <pre>
                    <code>{JSON.stringify(profile, null, 2)}</code>
                  </pre>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const MboResultDisplay = ({data}: any) => {
  return (
    <div className="section">
      <h3>MBO Clients</h3>
      {!data.mboClientsFound && <div>No MBO clients found</div>}
      {data.mboClientsFound && (
        <div>
          <p>
            MBO clients were found by <strong>{data.mboClientsFoundByEmail ? 'email' : 'name'}</strong>.
            {!data.mboClientsFoundByEmail && ' No matching MBO clients found by email.'}
          </p>
          <div>
            {Object.keys(data.mboClientsBySiteId).map((siteId, index) => (
              <div key={index}>
                <div>
                  <strong>
                    Site {siteId} / {getLocationBySiteId(siteId)?.name || ''}
                  </strong>
                </div>
                <div>
                  <pre>
                    <code>{JSON.stringify(data.mboClientsBySiteId[siteId], null, 4)}</code>
                  </pre>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminAnalyzeUser;

import {useProfiles} from '../api/User/useProfiles';
import {useCallback, useEffect, useState} from 'react';
import {getClientStatus} from '../api/api';

interface MboInfoMap {
  [clientId: string]: {active?: boolean};
}

export const useGetMboClientStatus = () => {
  const {currentProfile} = useProfiles();
  const [mboClientIdToInfoMap, setMboClientIdToInfoMap] = useState<MboInfoMap>({});

  useEffect(() => {
    (async () => {
      const res = await getClientStatus();
      const info: MboInfoMap = {};
      for (const result of res.data.results) {
        info[result.clientId] = {
          active: Boolean(result.active),
        };
      }
      setMboClientIdToInfoMap(info);
    })();
  }, [currentProfile.accountId]);

  const getMboClientStatus = useCallback(
    (mboClientId?: string) => {
      if (!mboClientId) {
        return {};
      }
      return mboClientIdToInfoMap[mboClientId] || {};
    },
    [mboClientIdToInfoMap]
  );

  const isInactive = useCallback(
    (mboClientId?: string) => {
      if (!mboClientId) {
        // active status cannot be determined, assume active
        return false;
      }
      return (mboClientIdToInfoMap[mboClientId] || {}).active === false; // do not simplify - we check for exact `false`
    },
    [mboClientIdToInfoMap]
  );

  const isActive = useCallback(
    (mboClientId?: string) => {
      if (!mboClientId) {
        // active status cannot be determined, assume active
        return true;
      }
      return (mboClientIdToInfoMap[mboClientId] || {}).active !== false; // do not simplify - we check for exact `false`
    },
    [mboClientIdToInfoMap]
  );

  return {mboClientIdToInfoMap, getMboClientStatus, isActive, isInactive};
};

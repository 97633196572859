import {ReactNode} from 'react';
import {Link} from 'react-router-dom';

interface CustomWrapperTypes {
  link?: string | null;
  className?: string;
  children: ReactNode;
}

// TODO STYLES evaluate and probably redesign
const CustomWrapper = ({link, className, children}: CustomWrapperTypes) => {
  if (link) {
    return (
      <Link to={`${link}`} className={className}>
        {children}
      </Link>
    );
  } else {
    return <div className={className}>{children}</div>;
  }
};

export default CustomWrapper;

import { forwardRef } from 'react'
import styles from './FormInput.module.scss'

interface FormInputProps extends React.HTMLProps<HTMLInputElement> {
	hasError?: boolean
}

export const FormInput = forwardRef<HTMLInputElement, FormInputProps>(
	(props, ref) => {
		const { hasError, ...rest } = props

		return (
			<input
				ref={ref}
				className={
					hasError
						? `${styles.formInput} ${styles.errorState}`
						: styles.formInput
				}
				{...rest}
			/>
		)
	}
)
FormInput.displayName = 'FormInput'

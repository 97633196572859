import {useEffect} from 'react';

export const useFullWidth = (enabled: boolean) => {
  useEffect(() => {
    if (enabled) {
      document.getElementsByClassName('page-container')?.[0]?.classList.add('uncapped-width');
      return () => document.getElementsByClassName('page-container')?.[0]?.classList.remove('uncapped-width');
    } else {
      document.getElementsByClassName('page-container')?.[0]?.classList.remove('uncapped-width');
    }
  }, [enabled]);
};

export const use1200Width = (enabled = true) => {
  useEffect(() => {
    if (enabled) {
      document.getElementsByClassName('page-container')?.[0]?.classList.add('full-width');
      return () => document.getElementsByClassName('page-container')?.[0]?.classList.remove('full-width');
    } else {
      document.getElementsByClassName('page-container')?.[0]?.classList.remove('full-width');
    }
  }, [enabled]);
};

import {useEffect, useMemo, useRef, useState} from 'react';
import {debounce} from 'lodash';
import Fuse from 'fuse.js';
import {PlayerShort} from '../pages/Trainer/Trainer';

//todo convert to generic search func and use for leaderboards as well
export const useTrainerSearch = ({players}: {players: PlayerShort[]}) => {
  const [query, setQuery] = useState('');

  const updateQuery = useRef(debounce(setQuery, 250)).current;

  useEffect(() => {
    return () => {
      updateQuery.cancel();
    };
  }, [updateQuery]);

  const fuse = useMemo(
    () =>
      new Fuse(players, {
        findAllMatches: true,
        ignoreLocation: true,
        keys: ['email', 'firstName', 'lastName'],
        minMatchCharLength: 2,
        shouldSort: false,
        threshold: 0,
      }),
    [players]
  );

  const indexResults = useMemo(() => fuse.search(query).map((res) => res.refIndex), [fuse, query]);

  return {query, updateQuery, indexResults};
};

import {useSportContext} from 'context/SportContext';
import {useEffect} from 'react';
import {Outlet} from 'react-router-dom';

export const TocaSportWrapper = () => {
  const {sport, setSport} = useSportContext();

  useEffect(() => {
    if (!sport) {
      setSport({sport: 'TOCA'});
    }
  }, [setSport, sport]);

  return sport ? <Outlet /> : null;
};

import {Helmet} from 'react-helmet';
import styles from './BaselineRetest.module.scss';
import {PageHeading} from 'components/PageHeading/PageHeading';
import {FormEventHandler, useState} from 'react';
import {SelectOptions, StandardSelect} from 'components/Select/StandardSelect/StandardSelect';
import {BaselineContainer} from 'components/Baseline/Container/BaselineContainer';
import {SessionInfoLevel} from '../SessionInfo/BaselineSessionInfo';
import {BaselineLevelInfo} from 'components/Baseline/LevelInfo/BaselineLevelInfo';
import {BaselineLevelInfoTable} from 'components/Baseline/LevelInfoTable/BaselineLevelInfoTable';
import {BaselineExerciseKey} from 'components/Baseline/ExerciseKey/BaselineExerciseKey';
import {ResponsiveContainer} from 'components/ResponsiveContainer/ResponsiveContainer';

export const baselineRetestValues: SessionInfoLevel[] = [
  {
    level: 'beginner',
    totals: {
      balls: 220,
      time: 50,
    },
    info: [
      {
        title: 'Passing',
        data: [
          '1 GC1-5 to FL/FR (2) x 10 (P)',
          '2 GC1-5 to FL/FR (2) x 10 (P)',
          '3 0C1-5 to FL/FR (1) x 10 (C)',
          '4 GC1-5 to BL/BR (2) x 10 (P)',
          '5 GC1-5 to BL/BR (2) x 10 (P)',
          '6 GC1-5 to BL/BR (1) x 10 (C)',
          '7 GC1-5 to ALL 4 (4) x 10 (P)',
          '8 GC1-5 to ALL 4 (3) x 10 (C)',
        ],
      },
      {
        title: 'Passing Progression',
        data: [
          '9 GLR1-5 to FL/FR (2) x 10 (P)',
          '10 GLR1-5 to FL/FR (2) x 10 (P)',
          '11 GLR1-5 to FL/FR (1) x 10 (C)',
          '12 GLR1-5 to BL/BR (2) x 10 (P)',
          '13 GLR1-5 to BL/BR (2) x 10 (P)',
          '14 GLR1-5 to BL/BR (1) x 10 (C)',
          '15 GLR1-5 to ALL 4 (4) x 10 (P)',
          '16 GLR1-5 to ALL 4 (3) x 10 (C)',
        ],
      },
      {
        title: 'Finishing',
        data: [
          '17 GC1-6 to ALL 4 (4) x 10 (P)',
          '18 GC1-6 to ALL 4 (3) x 10 (C)',
          '19 GC1-5 to ALL 4 (4) x 10 (DF) (P)',
          '20 GC1-5 to ALL 4 (3) x 10 (DF) (C)',
          '21 GC1-5 to ALL 4 (4) x 10 (NDF) (P)',
          '22 GC1-5 to ALL 4 (3] x 10 (NDF) (C)',
        ],
      },
    ],
  },
  {
    level: 'intermediate',
    totals: {
      balls: 288,
      time: 50,
    },
    info: [
      {
        title: 'Passing 360',
        data: [
          '1 GC2-5 to ALL 4 (4) x 18 (P)',
          '2 GC2-5 to ALL 4 (3) x 18 (P)',
          '3 GC2-5 to ALL 4 (2) x 12 (C)',
          '4 GLR2-5 to ALL 4 (4) x 18 (P)',
          '5 GLR2-5 to ALL 4 (2) x 12 (C)',
          '6 G3PAN2-5 to ALL 4 (4) x 18 (P)',
          '7 G3PAN2-5 to ALL 4 (2) x 12 (C)',
        ],
      },
      {
        title: 'Passing 360 Progression',
        data: [
          '8 AC2-5 to ALL 4 (4) x 18 (P)',
          '9 AC2-5 to ALL 4 (2) x 12 (C)',
          '10 LC2-5 to ALL 4 (4) x 18 (P)',
          '11 LC2-5 to ALL 4 (2) x 12 (C)',
          '12 GALC2-5 to ALL 4 (4) x 18 (P)',
          '13 GALC2-5 to ALL 4 (2) x 12 (C)',
        ],
      },
      {
        title: 'Finishing',
        data: [
          '14 GLR2-5 to ALL 4 (4) x 18 (P)',
          '15 GLR2-5 to ALL 4 (3) x 12 (C)',
          '16 AC1-5 to ALL 4 (4) x 18 (DF) (P)',
          '17 AC1-5 to ALL 4 (3) x 12 (DF) (C)',
          '18 LC3-5 to ALL 4 (4) x 18 (P)',
          '19 LC3-5 to ALL 4 (3) x 12 (C)',
        ],
      },
    ],
  },
  {
    level: 'advanced',
    totals: {
      balls: 324,
      time: 50,
    },
    info: [
      {
        title: 'Passing 360',
        data: [
          '1 GC2-4 to ALL 4 (4) x 18 (P)',
          '2 GC2-4 to FL/BR (1) x 18 (P)',
          '3 GC2-4 to FR/BL (1) x 18 (P)',
          '4 GC2-4 to ALL 4 (1) x 12 (C)',
          '5 GLR2-4 to ALL 4 (4) x 18 (P)',
          '6 GLR2-4 to ALL 4 (1) x 12 (C)',
          '7 G3PAN2-4 to ALL 4 (4) x 18 (P)',
          '8 G3PAN2-4 to ALL 4 (2) x 18 (P)',
          '9 G3PAN2-4 to ALL 4 (1) x 12 (C)',
        ],
      },
      {
        title: 'Passing 360 Progression',

        data: [
          '10 AC2-5 to ALL 4 (4) x 18 (P)',
          '11 AC2-5 to ALL 4 (1) x 12 (C)',
          '12 LC2-5 to ALL 4 (4) x 18 (P)',
          '13 LC2-5 to ALL 4 (1) x 12 (C)',
          '14 GALC2-5 to ALL 4 (4) x 18 (P)',
          '15 GALC2-5 to ALL 4 (2) x 12 (C)',
        ],
      },
      {
        title: 'Finishing',
        data: [
          '16 GLR3-5 to ALL 4 (4) x 18 (P)',
          '17 GLR3-5 to ALL 4 (3) x 12 (C)',
          '18 AC1-4 to ALL 4 (4) x 18 (DF) (P)',
          '19 AC1-5 to ALL 4 (3) x 12 (DF) (C)',
          '20 LC4-5 to ALL 4 (4) x 18 (P)',
          '21 LC4-5 to ALL 4 (3) x 12 (C)',
        ],
      },
    ],
  },
];

export const BaselineRetest = () => {
  const retestInfoArray = baselineRetestValues;

  const [selectedLevel, setSelectedLevel] = useState<SessionInfoLevel | undefined>(retestInfoArray?.[0]);

  const selectOptions = retestInfoArray.map((level: SessionInfoLevel) => ({
    value: level.level,
  }));

  const onSelectLevel: FormEventHandler<SelectOptions> = (e) =>
    setSelectedLevel(retestInfoArray.find((level) => level.level === e.currentTarget.value));

  return (
    <>
      <Helmet>
        <title>MyTOCA | Baseline Retest</title>
      </Helmet>

      <BaselineContainer>
        <PageHeading text="Baseline Retest" />

        <div className={styles.container}>
          <ResponsiveContainer sm md>
            <div className={styles.maxWidthWrapper}>
              <StandardSelect
                id="session-select"
                options={selectOptions}
                onChange={onSelectLevel}
                defaultValue={selectOptions[0].value}
              />

              {selectedLevel && (
                <div className={styles.singleLevelWrapper}>
                  <BaselineLevelInfo selectedLevel={selectedLevel} />
                </div>
              )}
            </div>
          </ResponsiveContainer>

          <ResponsiveContainer lg xl>
            <BaselineLevelInfoTable levels={retestInfoArray} />
            <BaselineExerciseKey />
          </ResponsiveContainer>
        </div>
      </BaselineContainer>
    </>
  );
};

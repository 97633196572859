import {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {LoadingAnimationFullHeight, LoadingAnimationOverlay} from 'components/Loader/LoadingAnimation';
import {getAthleteCurriculum} from 'constants/curriculumData';
import {PageHeading} from 'components/Connects/PageHeading';
import {ButtonTray} from 'components/Connects/ButtonTray';
import {Button} from 'components/Connects/Button';
import {LogoBanner} from 'components/Connects/LogoBanner';
import {UpcomingSessionSummary} from 'components/Connects/UpcomingSessionSummary/UpcomingSessionSummary';
import {CurriculumSession} from 'pages/Connects/types';
import {useGetPlayerDetails} from 'api/User/useGetPlayerDetails';
import {ProfileBar} from 'components/ProfileBar/ProfileBar';
import {useProfiles} from 'api/User/useProfiles';

export const NextSession = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sessionNum = searchParams.get('sessionNum');
  // const [level, sessionNum] = [searchParams.get('level'), searchParams.get('sessionNum')];
  const [nextSession, setNextSession] = useState<CurriculumSession>();

  const {currentProfileTeam} = useProfiles();

  const {data: playerDetails, isLoading, isFetching} = useGetPlayerDetails();

  useEffect(() => {
    if (playerDetails?.pathways?.currentLevel) {
      // TODO confirm the actual session numbering system
      setNextSession(getAthleteCurriculum(playerDetails?.pathways?.currentLevel, Number(sessionNum)));
    }
  }, [playerDetails?.pathways?.currentLevel, sessionNum]);

  return (
    <>
      <Helmet>
        <title>MyTOCA | Home</title>
      </Helmet>
      {isLoading ? (
        <LoadingAnimationFullHeight />
      ) : playerDetails && nextSession ? (
        <>
          <LogoBanner />
          <ProfileBar playerDetails={playerDetails} teamColor={currentProfileTeam?.teamColor || ''} />
          <div className="my-6">
            <PageHeading text="Next Week" />
          </div>

          <UpcomingSessionSummary nextSession={nextSession} />

          <div style={{height: '32px'}} />

          <div className="md:mx-auto md:max-w-[600px] md:w-full">
            <ButtonTray>
              <Button text="Previous" color="secondary" onClick={() => navigate(`/home`)} />
              <Button text="Next" color="secondary" variant="cta" onClick={() => navigate(`/upcoming`)} />
            </ButtonTray>
          </div>
        </>
      ) : (
        <div>
          <p>unable to load athlete information</p>
          {/* TODO */}
        </div>
      )}
      <LoadingAnimationOverlay isLoading={isFetching} />
    </>
  );
};

import {Helmet} from 'react-helmet';
import styles from './BaselineSessionInfo.module.scss';
import {PageHeading} from 'components/PageHeading/PageHeading';
import {FormEventHandler, useCallback, useMemo, useState} from 'react';
import {SelectOptions, StandardSelect} from 'components/Select/StandardSelect/StandardSelect';
import {BaselineContainer} from 'components/Baseline/Container/BaselineContainer';
import {BaselineLevelInfoTable} from 'components/Baseline/LevelInfoTable/BaselineLevelInfoTable';
import {BaselineLevelInfo} from 'components/Baseline/LevelInfo/BaselineLevelInfo';
import {BaselineExerciseKey} from 'components/Baseline/ExerciseKey/BaselineExerciseKey';
import {ResponsiveContainer} from 'components/ResponsiveContainer/ResponsiveContainer';

export type SessionInfoLevel = {
  level: string;
  totals: {
    balls: number;
    time: number;
  };
  info: {
    title: string;
    data: string[];
  }[];
};

export const baselineSessionInfoValues: SessionInfoLevel[] = [
  {
    level: 'beginner',
    totals: {
      balls: 180,
      time: 40,
    },
    info: [
      {
        title: 'Passing',
        data: [
          '1 GC1-5 to FL/FR (2) x 10 (P)',
          '2 0C1-5 to FL/FR (1) x 10 (C)',
          '3 GC1-5 to BL/BR (2) x 10 [P)',
          '4 GC1-5 to BL/BR (1) x 10 (C)',
          '5 GC1-5 to ALL 4 (4) x 10 (P)',
          '6 GC1-5 to ALL 4 (3) x 10 (C)',
        ],
      },
      {
        title: 'Passing Progression',
        data: [
          '7 GLR1-5 to FL/FR (2) x 10 (P)',
          '8 GLR1-5 to FL/FR (1) x 10 (C)',
          '9 GLR1-5 to BL/BR (2) x 10 (P)',
          '10 GLR1-5 to BL/BR (1) x 10 (C)',
          '11 GLR1-5 to ALL 4 (4) x 10 (P)',
          '12 GLR1-5 to ALL 4 (3) x 10 (C)',
        ],
      },
      {
        title: 'Finishing',
        data: [
          '13 GC1-6 to ALL 4 141 x 10 (P)',
          '14 GC1-6 to ALL 4 (3) x 10 (C)',
          '15 GC1-5 to ALL 4 (4) x 10 (DF) (P)',
          '16 GC1-5 to ALL 4 (3) x 10 (DF) (C)',
          '17 GC1-5 to ALL 4 (4) x 10 (NDF) (P)',
          '18 GC1-5 to ALL 4 (3] x 10 (NDF) (C)',
        ],
      },
    ],
  },
  {
    level: 'intermediate',
    totals: {
      balls: 216,
      time: 40,
    },
    info: [
      {
        title: 'Passing 360',
        data: [
          '1 GC2-5 to ALL 4 (4) x 12 (P)',
          '2 GC2-5 to ALL 4 (2) x 12 (C)',
          '3 GLR2-5 to ALL 4 (4) x 12 (P)',
          '4 GLR2-5 to ALL 4 (2) x 12 (C)',
          '5 G3PAN2-5 to ALL 4 (4) x 12 (P)',
          '6 G3PAN2-5 to ALL 4 (2) x 12 (C)',
        ],
      },
      {
        title: 'Passing 360 Progression',
        data: [
          '7 AC2-5 to ALL 4 (4) x 12 (P)',
          '8 AC2-5 to ALL 4 (2) x 12 (C)',
          '9 LC2-5 to ALL 4 (4) x 12 (P)',
          '10 LC2-5 to ALL 4 (2) x 12 (C)',
          '11 GALC2-5 to ALL 4 (4) x 12 (P)',
          '12 GALC2-5 to ALL 4 (2) x 12 (C)',
        ],
      },
      {
        title: 'Finishing',
        data: [
          '13 GLR2-5 to ALL 4 (4) x 12 (P)',
          '14 GLR2-5 to ALL 4 (3) x 12 (C)',
          '15 AC1-5 to ALL 4 (4) x 12 (DF) (P)',
          '16 AC1-5 to ALL 4 (3) x 12 (DF) (C)',
          '17 LC3-5 to ALL 4 (4) x 12 (P)',
          '18 LC3-5 to ALL 4 (3) x 12 (C)',
        ],
      },
    ],
  },
  {
    level: 'advanced',
    totals: {
      balls: 216,
      time: 37,
    },
    info: [
      {
        title: 'Passing 360',
        data: [
          '1 GC2-4 to ALL 4 (4) x 12 (P)',
          '2 GC2-4 to ALL 4 (1) x 12 (C)',
          '3 GLR2-4 to ALL 4 (4) x 12 (P)',
          '4 GLR2-4 to ALL 4 (1) x 12 (C)',
          '5 G3PAN2-4 to ALL 4 (4) x 12 (P)',
          '6 G3PAN2-4 to ALL 4 (1) x 12 (C)',
        ],
      },
      {
        title: 'Passing 360 Progression',

        data: [
          '7 AC2-5 to ALL 4 (4) x 12 (P)',
          '8 AC2-5 to ALL 4 (1) x 12 (C)',
          '9 LC2-5 to ALL 4 (4) x 12 (P)',
          '10 LC2-5 to ALL 4 (1) x 12 (C)',
          '11 GALC2-5 to ALL 4 (4) x 12 (P)',
          '12 GALC2-5 to ALL 4 (2) x 12 (C)',
        ],
      },
      {
        title: 'Finishing',
        data: [
          '13 GLR3-5 to ALL 4 (4) x 12 (P)',
          '14 GLR3-5 to ALL 4 (3) x 12 (C)',
          '15 AC1-4 to ALL 4 (4) x 12 (DF) (P)',
          '16 AC1-5 to ALL 4 (3) x 12 (DF) (C)',
          '17 LC4-5 to ALL 4 (4) x 12 (P)',
          '18 LC4-5 to ALL 4 (3) x 12 (C)',
        ],
      },
    ],
  },
  {
    level: 'pro',
    totals: {
      balls: 216,
      time: 37,
    },
    info: [
      {
        title: 'Passing 360',
        data: [
          '1 GC2-5 to ALL 4 (4) x 12 (P)',
          '2 GC2-5 to ALL 4 (2) x 12 (C)',
          '3 GLR2-5 to ALL 4 (4) x 12 (P)',
          '4 GLR2-5 to ALL 4 (2) x 12 (C)',
          '5 G3PAN2-5 to ALL 4 (4) x 12 (P)',
          '6 G3PAN2-5 to ALL 4 (2) x 12 (C)',
        ],
      },
      {
        title: 'Passing 360 Progression',
        data: [
          '7 AC2-5 to ALL 4 (3) x 12 (P)',
          '8 AC2-5 to ALL 4 (1) x 12 (C)',
          '9 LC2-5 to ALL 4 (3) x 12 (P)',
          '10 LC2-5 to ALL 4 (1) x 12 (C)',
          '11 ALC2-5 to ALL 4 (3) x 12 (P)',
          '12 ALC2-5 to ALL 4 (1) x 12 (C)',
        ],
      },
      {
        title: 'Finishing',
        data: [
          '13 GLR3-5 to FL/FR (2) x 12 (P)',
          '14 GLR3-5 to FL/FR (1) x 12 (C)',
          '15 AC2-4 to FL/FR (2) x 12 (DF) (P)',
          '16 AC2-4 to FL/FR (1) x 12 (DF) (C)',
          '17 LC4-5 to FL/FR (21 x 12 (P)',
          '18 LC4-5 to FL/FR (1) x 12 (C)',
        ],
      },
    ],
  },
];

export const BaselineSessionInfo = () => {
  const sessionInfoArray = baselineSessionInfoValues;

  const [selectedLevel, setSelectedLevel] = useState<SessionInfoLevel | undefined>(sessionInfoArray?.[0]);

  const selectOptions = useMemo(
    () =>
      sessionInfoArray.map((level: SessionInfoLevel) => ({
        value: level.level,
      })),
    [sessionInfoArray]
  );

  const onSelectLevel: FormEventHandler<SelectOptions> = useCallback(
    (e) => setSelectedLevel(sessionInfoArray.find((level) => level.level === e.currentTarget.value)),
    [sessionInfoArray]
  );

  return (
    <>
      <Helmet>
        <title>MyTOCA | Baseline Session Info</title>
      </Helmet>

      <BaselineContainer>
        <PageHeading text="Baseline Session Info" />

        <div className={styles.container}>
          <ResponsiveContainer sm md>
            <div className={styles.maxWidthWrapper}>
              <StandardSelect
                id="session-select"
                options={selectOptions}
                onChange={onSelectLevel}
                defaultValue={selectOptions[0].value}
              />

              {selectedLevel && (
                <div className={styles.singleLevelWrapper}>
                  <BaselineLevelInfo selectedLevel={selectedLevel} />
                </div>
              )}
            </div>
          </ResponsiveContainer>

          <ResponsiveContainer lg xl>
            <BaselineLevelInfoTable levels={sessionInfoArray} />
            <BaselineExerciseKey />
          </ResponsiveContainer>
        </div>
      </BaselineContainer>
    </>
  );
};

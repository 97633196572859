import {ReactNode, memo} from 'react';
import styles from './ResponsiveContainer.module.scss';

type Props = {
  children?: ReactNode;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
};

export const ResponsiveContainer = memo(({children, sm, md, lg, xl}: Props) => {
  return (
    <div
      className={`${styles.container} ${sm ? styles.sm : ''} ${md ? styles.md : ''} ${lg ? styles.lg : ''} ${
        xl ? styles.xl : ''
      }`}
    >
      {children}
    </div>
  );
});

ResponsiveContainer.displayName = 'ResponsiveContainer';

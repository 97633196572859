import {PropsWithChildren, memo} from 'react';
import styles from './AboveNavbarContainer.module.scss';

export const AboveNavbarContainer = memo(({children}: PropsWithChildren) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>{children}</div>
    </div>
  );
});
AboveNavbarContainer.displayName = 'AboveNavbarContainer';

import {useCallback} from 'react';
import {useAppDispatch, useAppSelector} from 'redux/reduxUtils';
import {setActiveAssessmentId as setActiveAssessmentIdAction} from 'redux/slices/coach';

export const useGlobalActiveAssessmentState = () => {
  const dispatch = useAppDispatch();
  const globalActiveAssessmentId = useAppSelector((state) => state.coachReducer.activeAssessmentId);

  const setGlobalActiveAssessmentId = useCallback(
    (activeAssessmentId: string) => {
      dispatch(setActiveAssessmentIdAction(activeAssessmentId));
    },
    [dispatch]
  );

  return {setGlobalActiveAssessmentId, globalActiveAssessmentId};
};

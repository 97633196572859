import {getAnalytics, logEvent, setUserProperties} from 'firebase/analytics';
import uuid from 'react-uuid';

const analytics = getAnalytics();

/*
 * List of build-in events:
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events
 */

export function logAnalyticsEvent(eventName: string, eventParams?: any) {
  logEvent(analytics, eventName, eventParams);
}

export function logLoginEvent(method?: string, otherParams?: {[key: string]: any}) {
  logEvent(analytics, 'login', {
    method: method,
    ...otherParams,
  });
}

export function logSignUpEvent(method?: string, otherParams?: {[key: string]: any}) {
  logEvent(analytics, 'sign_up', {
    method: method,
    ...otherParams,
  });
}

export function logShareEvent(eventParams: {method?: string; content_type?: string; item_id?: string}) {
  logEvent(analytics, 'share', eventParams);
}

export function trackUserViaCustomClientId() {
  try {
    let clientTrackingId = localStorage.getItem('clientTrackingId');
    if (!clientTrackingId) {
      clientTrackingId = `${Date.now()}-${uuid()}`;
      localStorage.setItem('clientTrackingId', clientTrackingId);
    }
    setUserProperties(analytics, {client_tracking_id: clientTrackingId});
  } catch (err) {
    console.error('Failed to get/set tracking client ID', err);
  }
}

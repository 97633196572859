import styles from './SessionCard.module.scss';
import {memo, ReactNode, useCallback, useEffect, useState} from 'react';
import {parseDateTime} from '../../../utils/utils';
import {BookingSession, reselectBookingSession, removeSelectedAppointment} from '../../../redux/slices/booking';
import {ReactComponent as EditIcon} from '../../../assets/img/icons/redesign-icons/options.svg';
import {ReactComponent as CloseIcon} from '../../../assets/img/icons/redesign-icons/close.svg';
import {ReactComponent as ConfirmedIcon} from '../../../assets/img/icons/redesign-icons/checked-circle.svg';
import {StandardButton} from '../../Buttons/Standard/StandardButton';
import {useAppDispatch} from '../../../redux/reduxUtils';
import {useNavigate} from 'react-router-dom';

export type SessionCardProps = {
  session?: BookingSession;
  loadingDate?: string;
  disabled?: boolean;
  displayOnly?: boolean;
  highlight?: boolean;
  tray?: ReactNode;
  trayOpen?: boolean;
};

export const SessionCard = memo(
  ({
    session,
    loadingDate,
    disabled = false,
    displayOnly = !!session?.bookingStatus?.confirmed ||
      !!session?.bookingStatus?.error ||
      !!session?.bookingStatus?.unavailableTime ||
      disabled,
    highlight = false,
    tray,
    trayOpen: trayOpenProp,
  }: SessionCardProps) => {
    const [trayIsOpen, setTrayIsOpen] = useState(trayOpenProp);
    const [editing, setEditing] = useState(trayOpenProp);

    const parsedStart = parseDateTime(session?.startDate);
    const parsedEnd = parseDateTime(session?.endDate);
    const coachName = `${session?.staff?.firstName} ${session?.staff?.lastName}`;
    const [confirmed, error, reselected, unavailableTime] = [
      session?.bookingStatus?.confirmed,
      session?.bookingStatus?.error,
      session?.bookingStatus?.reselected,
      session?.bookingStatus?.unavailableCoach,
      session?.bookingStatus?.unavailableTime,
    ];

    useEffect(() => {
      setTrayIsOpen(!!session?.bookingStatus?.unavailableTime);
      setEditing(!!session?.bookingStatus?.unavailableTime);
    }, [session]);

    const onEditClick = useCallback(() => {
      setEditing((prev) => !prev);
      setTrayIsOpen((prev) => !prev);
    }, []);

    // TODO animate eyebrow
    // TODO eyebrow becomes component?

    if (!session) {
      const tempDate = `${parseDateTime(loadingDate).monthStr} ${parseDateTime(loadingDate).day}`;
      return (
        <div className={`${styles.container} ${styles.loading}`}>
          <div className={styles.loadingAnimation}>
            <p>Confirming availability{loadingDate && ` for ${tempDate}`}...</p>
          </div>
        </div>
      );
    }

    const eyebrow = error
      ? {
          status: 'error',
          text: 'something went wrong',
        }
      : confirmed
      ? {
          status: 'confirmed',
          text: 'booked',
        }
      : unavailableTime
      ? {
          status: 'unavailableTime',
          text: 'not available',
        }
      : editing
      ? {
          status: 'editing',
          text: 'edit',
        }
      : reselected
      ? {
          status: 'editing',
          text: 'new time selected',
        }
      : undefined;

    return (
      <div
        className={`${styles.container} ${highlight ? styles.highlight : ''} ${
          disabled ? styles.disabled : ''
        }`} /** TODO highlight??? */
      >
        {eyebrow && (
          <div className={`${styles.eyebrow} ${styles[eyebrow.status]}`}>
            <p>{eyebrow.text}</p>
          </div>
        )}

        <div className={styles.content}>
          <div className={styles.topRow}>
            <h5>{session.sessionType.name}</h5>

            {!displayOnly && !confirmed && (
              <div className={styles.editIcon} onClick={onEditClick}>
                {editing ? <CloseIcon /> : <EditIcon />}
              </div>
            )}
            {confirmed && (
              <div className={styles.confirmedIcon}>
                <ConfirmedIcon />
              </div>
            )}
          </div>
          <div className={styles.info}>
            <div className={`${styles.dateAndTime} ${trayIsOpen ? styles.flexRow : ''}`}>
              <p>
                {parsedStart.dayStrShort},&nbsp;
                {parsedStart.monthStr} {parsedStart.day}
                ,&nbsp;{parsedStart.year}
              </p>
              <p>
                {parsedStart.time} - {parsedEnd.time}&nbsp;<span className={styles.zone}>{parsedEnd.zone}</span>
              </p>
            </div>
            {coachName !== ' ' ? (
              <p>
                <span>Coach: </span>
                {coachName}
              </p>
            ) : null}
          </div>

          {trayIsOpen && <>{tray ?? <ButtonTray session={session} />}</>}
        </div>
      </div>
    );
  }
);
SessionCard.displayName = 'SessionCard';

const ButtonTray = memo(({session}: {session: BookingSession}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [deleting, setDeleting] = useState(false);

  const removeOnClick = useCallback(() => {
    setDeleting(true);
  }, []);

  const confirmDelete = useCallback(() => {
    setDeleting(false);
    dispatch(removeSelectedAppointment({session}));
  }, [dispatch, session]);

  const reselectOnClick = useCallback(() => {
    dispatch(reselectBookingSession(session));
    navigate(`/bookings/sessions/${session.dateStr}`);
  }, [dispatch, navigate, session]);

  return (
    <div className={styles.buttonTrayContainer}>
      {deleting ? (
        <>
          <p className={styles.dangerText}>Are you sure?</p>
          <StandardButton text="Yes, Remove" variant="danger" onClick={confirmDelete} />
        </>
      ) : (
        <>
          <StandardButton text="Remove" variant="alert" onClick={removeOnClick} />
          <StandardButton text="Change Time" variant="primary" onClick={reselectOnClick} />
        </>
      )}
    </div>
  );
});
ButtonTray.displayName = 'ButtonTray';

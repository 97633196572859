import {memo, useCallback, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../../redux/reduxUtils';
import {useNavigate, useParams} from 'react-router-dom';
import {Session} from '../../RegistrationScheduling/SchedulingForm';
import {BookingInfo} from '../../../components/Booking/BookingInfo/BookingInfo';
import {
  resetBookingSessions,
  resetInitializedSiteId,
  revertBookingReselect,
  selectAppointment,
  setBookingCompleted,
  setDesiredDates,
  setPlayerPreferences,
} from '../../../redux/slices/booking';
import {getWeeklyDatesInclusive, parseDateTime} from '../../../utils/utils';
import {useInitializeBookings} from '../../../hooks/Booking/useInitializeBookings';
import {BookingSessionForm} from './BookingSessionForm';
import {useProfiles} from '../../../api/User/useProfiles';
import {ProfileUpdate} from '../../../api/api';
import {useUpdateProfile} from '../../../api/User/useUpdateProfile';
import {DayOfWeek, Profile} from '../../../user/player-info.interface';
import {Helmet} from 'react-helmet';
import {useGetMboClientStatus} from '../../../hooks/useGetMboClientStatus';
import {PageHeading} from 'components/PageHeading/PageHeading';
import {logAnalyticsEvent} from '../../../common/analytics-events';

export const BookingSessionList = memo(({reselect = false}: {reselect?: boolean}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {initialDate} = useParams();
  const {initializeBookings} = useInitializeBookings();
  const {currentProfile} = useProfiles();
  const {updateProfile} = useUpdateProfile();
  const {isInactive} = useGetMboClientStatus();

  const {profileId, initializedSiteId, sessions, bookingCompleted} = useAppSelector(
    (state) => state.bookingReducer.bookingCreation
  );

  useEffect(() => {
    if (!initializedSiteId || currentProfile._id !== profileId) {
      initializeBookings();
    } else if (sessions.length && !reselect) {
      navigate('/bookings/review');
    }
  }, [initializedSiteId, currentProfile, initializeBookings, profileId, sessions.length, navigate, reselect]);

  useEffect(() => {
    if (
      !currentProfile ||
      isInactive(currentProfile.mboDetails?.clientId) ||
      (initializedSiteId && currentProfile.location?.siteId !== initializedSiteId)
    ) {
      navigate('/home');
    }
  }, [navigate, currentProfile, profileId, initializedSiteId, isInactive]);

  useEffect(() => {
    if (bookingCompleted) {
      dispatch(setBookingCompleted(false));
      dispatch(resetBookingSessions());
      dispatch(resetInitializedSiteId());
    }
  }, [bookingCompleted, dispatch]);

  const sessionOnSubmit = useCallback(
    async ({session, repeat}: {session: Session; repeat: number}) => {
      logSessionSelectEvent(currentProfile, session, {repeat});
      dispatch(resetBookingSessions());
      dispatch(setDesiredDates(getWeeklyDatesInclusive({startDate: session.startDate, numWeeks: repeat})));
      dispatch(selectAppointment({session}));
      dispatch(setPlayerPreferences({preferredSession: session}));
      // Thought process: if we aren't using booking preferences, then there are no saved preferences to be
      // updated/this is probably their initial booking. Server will generate preferences from these completed
      // bookings on next visit.
      if (currentProfile.preferences?.booking) {
        const parsedStart = parseDateTime(session.startDate);
        const parsedEnd = parseDateTime(session.endDate);
        const profileUpdate: ProfileUpdate = {
          email: currentProfile.email ?? '', // TODO ACCOUNTS
          profileId: currentProfile._id,
          update: {
            preferences: {
              booking: {
                referenceStartDate: session.startDate, // 2023-01-20T00:50:00.000-07:00
                weeks: repeat,
                day: parsedStart.dayStrShort as DayOfWeek,
                sessionType: session.sessionType,
                startTime: parsedStart.bareIsoTime ?? undefined,
                endTime: parsedEnd.bareIsoTime ?? undefined,
                coach: session.staff.id,
              },
            },
          },
        };
        await updateProfile(profileUpdate);
      }
      navigate(`/bookings/review`);
    },
    [currentProfile, dispatch, navigate, updateProfile]
  );

  const reselectSessionOnSubmit = useCallback(
    ({session}: {session: Session}) => {
      logSessionSelectEvent(currentProfile, session, {reselected: true});
      dispatch(selectAppointment({session: {...session, bookingStatus: {reselected: true}}}));
      navigate(`/bookings/review`);
    },
    [currentProfile, dispatch, navigate]
  );

  const navigateBack = useCallback(() => {
    if (reselect) {
      dispatch(revertBookingReselect());
      navigate(`/bookings/review`);
    } else {
      navigate(`/`);
    }
  }, [dispatch, navigate, reselect]);

  return (
    <>
      <Helmet>
        <title>MyTOCA | Bookings</title>
      </Helmet>

      <div className="lg:max-w-[900px] lg:w-full lg:mx-auto lg:px-8 lg:border-x border-solid border-grey-light flex flex-col">
        <PageHeading onClickOverride={navigateBack} text="Book Sessions" />
        <BookingInfo />

        {initializedSiteId && profileId === currentProfile._id ? (
          <BookingSessionForm
            siteId={currentProfile.location?.siteId as string}
            onSubmit={reselect ? reselectSessionOnSubmit : sessionOnSubmit}
            initialDate={initialDate}
            reselecting={reselect}
          />
        ) : null}
      </div>
    </>
  );
});

BookingSessionList.displayName = 'BookingSessionList';

function logSessionSelectEvent(
  currentProfile: Profile,
  session: Session,
  options?: {repeat?: number; reselected?: boolean}
) {
  logAnalyticsEvent('booking_session_selected', {
    profileId: currentProfile._id,
    mboSiteId: currentProfile.mboDetails?.siteId,
    mboClientId: currentProfile.mboDetails?.clientId,
    startDate: session.startDate,
    endDate: session.endDate,
    typeId: session.sessionType?.id,
    typeName: session.sessionType?.name,
    staffId: session.staff?.id,
    locationId: session.location?.id,
    locationName: session.location?.name,
    dateStr: session.dateStr,
    repeat: options?.repeat,
    reselected: !!options?.reselected,
  });
}

import moment from 'moment';
import {Helmet} from 'react-helmet';
import {useParams} from 'react-router-dom';
import {useGetPlayerDetails} from 'api/User/useGetPlayerDetails';
import {PageHeading as PageHeadingClassic} from 'components/PageHeading/PageHeading';
import {PageHeading} from 'components/Connects/PageHeading';
import {MobileBaselineSessionTable} from 'components/Baseline/SessionTable/MobileBaselineSessionTable';
import {LoadingAnimationFullHeight} from 'components/Loader/LoadingAnimation';
import {BaselineSessionTable} from 'components/Baseline/SessionTable/BaselineSessionTable';
import {LogoBanner} from 'components/Connects/LogoBanner';
import {ProfileBar} from 'components/ProfileBar/ProfileBar';

export const BaselineSessionData = () => {
  const {sessionDate} = useParams();
  const {data: playerDetails, isLoading} = useGetPlayerDetails();

  //TODO confirm sessionData key
  const sessionData = playerDetails?.baselineSessions?.find(
    (session) => moment(session.startTime).startOf('day').toDate() === moment(sessionDate).startOf('day').toDate()
  );

  const startDate = sessionData?.startTime ? moment(sessionData.startTime).format('MMM DD, YYYY') : undefined;

  return (
    <>
      <Helmet>
        <title>MyTOCA | Baseline Session Data</title>
      </Helmet>

      {isLoading ? (
        <LoadingAnimationFullHeight />
      ) : playerDetails ? (
        <div className="grow flex flex-col">
          {/* Mobile View */}
          <div className="lg:hidden">
            <LogoBanner />
            <ProfileBar playerDetails={playerDetails} />

            {sessionData ? (
              <div className="exceed-global-padding">
                <div className="text-center">
                  <PageHeading text="Baseline Session Data" />
                </div>

                <p className="p-2 text-lg text-center font-semibold text-secondary">{startDate}</p>

                <div className="text-lg text-white font-medium uppercase">
                  <p className="p-3 text-center bg-secondary">{`${sessionData?.baselineLevel} pathway`}</p>
                  <div className="p-3 bg-primary flex items-center justify-around">
                    <div className="flex gap-2 items-center justify-center">
                      <p>Balls:</p>
                      <div className="p-2 rounded-full grid place-content-center bg-secondary">
                        <p>{sessionData.totalBallsFired}</p>
                      </div>
                    </div>
                    <div className="flex gap-2 items-center justify-center">
                      <p>Sets:</p>
                      <div className="p-2 rounded-full grid place-content-center bg-secondary">
                        <p>{sessionData.performance?.metrics.repetitions}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <MobileBaselineSessionTable sessionData={sessionData} />
              </div>
            ) : (
              <div className="grid place-content-center">
                <p className="text-sm text-grey-xdark text-center p-4">Unable to load session data</p>
              </div>
            )}
          </div>

          {/* Desktop View */}
          <div className="hidden lg:block">
            <PageHeadingClassic text="Baseline Session Data" />
            {sessionData ? (
              <BaselineSessionTable sessionData={sessionData} />
            ) : (
              <div className="grid place-content-center">
                <p className="text-sm text-grey-xdark text-center p-4">Unable to load session data</p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="grow grid place-content-center">
          <p className="text-sm text-grey-xdark text-center p-4">Unable to load player details</p>
        </div>
      )}
    </>
  );
};

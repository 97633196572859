import {memo, PropsWithChildren} from 'react';
import {Link, useMatches, useParams} from 'react-router-dom';
import {getLocationBySiteId} from '../../constants/locations';

type CrumbInfo = {
  name: string;
  path?: string;
};

type CrumbInfoWithPath = CrumbInfo & {path: string};

// TODO type
export const Breadcrumbs = memo(({data = {}}: {data?: any}) => {
  const matches = useMatches();
  const {siteId} = useParams();
  const currentLocation = siteId ? getLocationBySiteId(siteId) : undefined;

  const relevantMatches = matches.filter((match) => Boolean((match.handle as any)?.crumb));
  const crumbInfos: CrumbInfoWithPath[] = [];

  for (const match of relevantMatches) {
    const crumbInfo: CrumbInfo | CrumbInfo[] = (match.handle as any).crumb({
      ...data,
      currentLocation,
      routeData: match.data,
    });
    const crumbInfoArray = Array.isArray(crumbInfo) ? crumbInfo : [crumbInfo];
    const validCrumbInfos = crumbInfoArray
      .filter((info) => !!info?.name)
      .map((info) => {
        info.path ||= match.pathname;
        return info as CrumbInfoWithPath;
      });
    crumbInfos.push(...validCrumbInfos);
  }

  return (
    <div className="flex items-center flex-wrap">
      {crumbInfos.map((info, index) => (
        <Crumb key={info.name + index} path={info.path} isLast={index === crumbInfos.length - 1}>
          {info.name}
        </Crumb>
      ))}
    </div>
  );
});

Breadcrumbs.displayName = 'Breadcrumbs';

function Crumb({children, path, isLast}: PropsWithChildren<{path: string; isLast: boolean}>) {
  return (
    <>
      <div className="p-1">
        <Link to={path} className="font-poppins text-xs xs:text-sm font-medium text-black capitalize hover:underline">
          {children}
        </Link>
      </div>
      {!isLast && <div className="crumb-separator mx-1">&gt;</div>}
    </>
  );
}

import {useEffect, useState} from 'react';

export function useLoadScript(src: string) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (window && document) {
      const script = document.createElement('script');
      const body = document.getElementsByTagName('body')[0];
      script.src = src;
      body.appendChild(script);
      script.addEventListener('load', () => {
        setIsLoaded(true);
      });
    }
  }, [src]);

  return isLoaded;
}

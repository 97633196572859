import {forwardRef} from 'react';
import styles from './Checkbox.module.scss';
import {CheckboxAltProps} from './Checkbox';

export const CheckboxWrapper = forwardRef<HTMLInputElement, CheckboxAltProps>((props, ref) => {
  const {id, children, ...rest} = props;

  return (
    <label
      htmlFor={id}
      className={`grow flex items-center gap-4 ${props.disabled ? 'cursor-default' : 'cursor-pointer'}`}
    >
      <input ref={ref} className={styles.checkboxAltInput} id={id} type="checkbox" {...rest} />
      <div className={styles.checkboxAltDisplay} />
      {children}
    </label>
  );
});
CheckboxWrapper.displayName = 'CheckboxWrapper';

import {RefObject, useEffect, useMemo, useRef, useState} from 'react';

export const useHeightObserver = (elementRef: RefObject<HTMLElement>) => {
  const [height, setHeight] = useState<number>(0);

  const observer = useRef(
    new ResizeObserver((e) => {
      setHeight(e[0].contentRect.width);
    })
  );

  const obs = observer.current;

  useEffect(() => {
    if (elementRef.current) {
      const ref = elementRef.current;
      obs.observe(ref);

      return () => {
        obs.unobserve(ref);
      };
    }
  }, [elementRef, obs, observer]);

  return useMemo(() => ({observedHeight: height}), [height]);
};

import {useCallback, useEffect, useRef, useState} from 'react';
import styles from './FkoHelpForm.module.scss';
import {useNavigate} from 'react-router-dom';
import {ReactComponent as ArrowLeft} from '../../../assets/img/icons/new-chevron-left-black.svg';
import {useHubspotForm} from '@aaronhayes/react-use-hubspot-form';
import {auth as fireBaseAuth} from '../../../firebase/config';
import {getLocationBySiteId} from '../../../constants/locations';
import {useAppSelector} from '../../../redux/reduxUtils';
import {TrackingStrings} from '../../../redux/slices/fkoFormData';
import {useProfiles} from '../../../api/User/useProfiles';
import {useGetSport} from '../../../common/useGetSport';

const trackingParams: TrackingStrings[] = [
  'fbclid',
  'gclid',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
];

// TODBAT confirm if needs dbat analytics params
export const FkoHelpForm = () => {
  const navigate = useNavigate();
  const firebaseUser = fireBaseAuth.currentUser;
  const {currentProfile} = useProfiles();
  const {isDbat, isPlayerAssessment, isFreeTrialClasses} = useGetSport();

  const {siteId, initialParams} = useAppSelector((state) => state.fkoFormDataReducer);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const prefillHandled = useRef(false);
  const intervalId = useRef(0);

  const formOptions = {
    portalId: process.env.REACT_APP_HUBSPOT_PORTAL_ID ?? '',
    formId: process.env.REACT_APP_HUBSPOT_FKO_HELP_FORM_ID ?? '',
    target: '#fko-help-hubspot-form',
  };
  let fkoMethodInputValue =
    process.env.REACT_APP_HUBSPOT_FKO_HELP_FORM_METHOD_FIELD_VALUE ?? 'player_portal_fko_help_form';

  if (isDbat) {
    fkoMethodInputValue = process.env.REACT_APP_HUBSPOT_DBAT_FKO_HELP_FORM_METHOD_FIELD_VALUE ?? '';
    formOptions.formId = process.env.REACT_APP_HUBSPOT_DBAT_FKO_HELP_FORM_ID ?? '';
  } else if (isPlayerAssessment) {
    fkoMethodInputValue = process.env.REACT_APP_HUBSPOT_PLAYER_ASSESSMENT_HELP_FORM_METHOD_FIELD_VALUE ?? '';
    formOptions.formId = process.env.REACT_APP_HUBSPOT_PLAYER_ASSESSMENT_HELP_FORM_ID ?? '';
  } else if (isFreeTrialClasses) {
    fkoMethodInputValue = process.env.REACT_APP_HUBSPOT_FREE_TRIAL_CLASSES_HELP_FORM_METHOD_FIELD_VALUE ?? '';
    formOptions.formId = process.env.REACT_APP_HUBSPOT_FREE_TRIAL_CLASSES_HELP_FORM_ID ?? '';
  }

  // Copy form options since HubSpot modifies the passed object
  const {loaded, formCreated} = useHubspotForm({
    ...formOptions,
    onFormSubmitted: () => setFormSubmitted(true),
  });

  const prefillForm = useCallback(() => {
    const formContainer = document.getElementById('fko-help-hubspot-form');

    if (!formContainer) {
      return false;
    }

    const fkoMethodHiddenInput = formContainer.querySelector('input[name="fko_method"]');
    if (fkoMethodHiddenInput) {
      (fkoMethodHiddenInput as any).value = fkoMethodInputValue;
    }

    const fkoSourceUrlHiddenInput = formContainer.querySelector('input[name="form_submission_source_url"]');
    if (fkoSourceUrlHiddenInput) {
      (fkoSourceUrlHiddenInput as any).value = window.location.href;
    }

    for (const trackingParamName of trackingParams) {
      const value = initialParams?.[trackingParamName];
      if (value) {
        const hiddenInput = formContainer.querySelector(`input[name="${trackingParamName}"]`);
        if (hiddenInput) {
          (hiddenInput as any).value = value;
        }
      }
    }

    if (firebaseUser || currentProfile) {
      const email = firebaseUser?.email;
      const phoneNumber = firebaseUser?.phoneNumber || currentProfile?.phoneNumber;
      const firstName = currentProfile?.firstName;
      const lastName = currentProfile?.lastName;

      if (email) {
        const emailInput = formContainer.querySelector('input[name="email"]');
        if (emailInput) {
          (emailInput as any).value = email;
        }
      }
      if (phoneNumber) {
        const phoneNumberInput = formContainer.querySelector('input[name="phone"]');
        if (phoneNumberInput) {
          (phoneNumberInput as any).value = phoneNumber;
        }
      }
      if (firstName) {
        const firstNameInput = formContainer.querySelector('input[name="firstname"]');
        if (firstNameInput) {
          (firstNameInput as any).value = firstName;
        }
      }
      if (lastName) {
        const lastNameInput = formContainer.querySelector('input[name="lastname"]');
        if (lastNameInput) {
          (lastNameInput as any).value = lastName;
        }
      }
      if (siteId) {
        const location = getLocationBySiteId(siteId);
        if (location) {
          const locationSelect = formContainer.querySelector('select[name="locations__drop_down_"]');
          if (locationSelect) {
            (locationSelect as any).value = location.name;
          }
        }
      }
    }

    return true;
  }, [firebaseUser, initialParams, siteId, currentProfile, fkoMethodInputValue]);

  useEffect(() => {
    // Set face jQuery object and functions to allow HubSpot form callbacks
    // https://community.hubspot.com/t5/APIs-Integrations/Form-callback-throws-unrelated-jquery-error/m-p/232121
    (window as any).jQuery =
      (window as any).jQuery ||
      (() => ({
        change: () => true,
        trigger: () => true,
      }));

    if (!prefillHandled.current && formCreated && loaded) {
      prefillHandled.current = true;
      intervalId.current = window.setInterval(() => {
        const result = prefillForm();
        console.log('prefilled form:', result);
        if (result) {
          window.clearInterval(intervalId.current);
        }
      }, 500);
    }

    return () => {
      window.clearInterval(intervalId.current);
      delete (window as any).jQuery;
    };
  }, [firebaseUser, currentProfile, formCreated, loaded, prefillForm]);

  return (
    <div className={`${styles.helpContainer} ${isFreeTrialClasses ? styles.freeTrialClasses : ''}`}>
      {!isFreeTrialClasses ? (
        <button onClick={() => navigate(-1)}>
          <ArrowLeft />
        </button>
      ) : null}
      <h1>Help</h1>
      {!formSubmitted && (
        <div className={styles.helpInstructions}>
          {isFreeTrialClasses ? (
            <p>Please provide your details below and we will call you to book your Free Trial Class.</p>
          ) : (
            <p>Please provide your details below and we will call you to book your Free Baseline Session.</p>
          )}
        </div>
      )}
      <div id="fko-help-hubspot-form" className={styles.hubspotForm}></div>
    </div>
  );
};

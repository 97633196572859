import {memo} from 'react';
import styles from './Profile.module.scss';
import {Helmet} from 'react-helmet';
// import {useGetPlayerDetails} from '../../api/User/useGetPlayerDetails';
import {useProfiles} from '../../api/User/useProfiles';
import {getLocationBySiteId} from '../../constants/locations';
import {PageHeading} from 'components/PageHeading/PageHeading';
import {BookingFormButton} from 'components/Buttons/Form/FormButton';
import {ReactComponent as AvatarPlaceholder} from '../../assets/img/icons/redesign-icons/avatar-placeholder.svg';
import {useNavigate} from 'react-router-dom';
import {getUserDisplayName} from 'utils/utils';

export const Profile = memo(() => {
  const navigate = useNavigate();
  const {currentProfile} = useProfiles();
  // const {data: playerDetails, isLoading: isPlayerDetailsLoading} = useGetPlayerDetails();
  const location = currentProfile.mboDetails?.siteId ? getLocationBySiteId(currentProfile.mboDetails.siteId) : null;
  // const photo = useRef<any>(null);

  const profileUserName = getUserDisplayName(currentProfile.firstName, currentProfile.lastName);

  return (
    <>
      <Helmet>
        <title>MyTOCA | Profile</title>
      </Helmet>

      <PageHeading text="Profile" />

      {/* {isPlayerDetailsLoading ? (
        <LoadingAnimation />
      ) : ( */}
      <>
        <div className={styles.profile}>
          <div className={styles.avatar}>
            <AvatarPlaceholder />
          </div>
          <p className={styles.name}>{profileUserName}</p>
          <p className={styles.email}>{currentProfile.email}</p>
          <div className={styles.divider} />
          {location?.name && (
            <p className={styles.stat}>
              Location: <span>{location.name}</span>
            </p>
          )}
          {currentProfile.dob && (
            <p className={styles.stat}>
              Date of Birth: <span>{currentProfile.dob}</span>
            </p>
          )}
        </div>
      </>
      {/* )} */}

      <div className={styles.buttonContainer}>
        {/* <BookingFormButton isPrimary text="Edit Profile" /> */}
        <BookingFormButton text="Help" onClick={() => navigate('/help')} />
      </div>
    </>
  );
});
Profile.displayName = 'Profile';

import {forwardRef, ReactNode} from 'react';
import styles from './Checkbox.module.scss';

export interface CheckboxProps extends React.HTMLProps<HTMLInputElement> {
  labelText: string | ReactNode;
  id: string;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const {id, labelText, ...rest} = props;

  return (
    <label htmlFor={id} className={styles.checkboxLabel}>
      <input ref={ref} className={styles.checkboxInput} id={id} type="checkbox" {...rest} />
      <div className={styles.checkboxDisplay} />
      {labelText}
    </label>
  );
});
Checkbox.displayName = 'Checkbox';

// TODO temp alt checkbox
export interface CheckboxAltProps extends React.HTMLProps<HTMLInputElement> {
  labelText?: string | ReactNode;
  id: string;
}
export const CheckboxAlt = forwardRef<HTMLInputElement, CheckboxAltProps>((props, ref) => {
  const {id, labelText, ...rest} = props;

  return (
    <label htmlFor={id} className={styles.checkboxAltLabel}>
      <input ref={ref} className={styles.checkboxAltInput} id={id} type="checkbox" {...rest} />
      <div className={`${styles.checkboxAltDisplay} ${props.disabled ? styles.disabled : ''}`} />
      {!!labelText && labelText}
    </label>
  );
});
CheckboxAlt.displayName = 'Checkbox';

import {useCallback} from 'react';
import styles from './LeaderboardCard.module.scss';
import {useNavigate} from 'react-router-dom';
import {DateTime} from 'luxon';
import {isTvMode} from '../../../../utils/utils';
import {ReactComponent as FigurePassingIcon} from '../../../../assets/img/icons/redesign-icons/figure-passing.svg';
import {AthleteRankedLeaderboardEntry} from 'api/Leaderboards/leaderboard.types';

export const LeaderboardCard = ({leaderboard}: {leaderboard: AthleteRankedLeaderboardEntry}) => {
  const navigate = useNavigate();
  const tvModeEnabled = isTvMode();

  const {_id: id, name, startDate, endDate} = leaderboard.leaderboard;
  const {overallRank} = leaderboard.athlete;

  const startDateFormatted = DateTime.fromISO(startDate).toFormat('LLL d');
  const endDateFormatted = DateTime.fromISO(endDate).toFormat('LLL d');

  const onClick = useCallback(() => {
    navigate(`${tvModeEnabled ? '/tv' : ''}/leaderboards/${id.toString()}`);
  }, [navigate, id, tvModeEnabled]);

  return (
    // <button onClick={onClick} className={`${styles.container} ${status === 'completed' ? styles.completed : ''}`}>
    <button onClick={onClick} className={styles.container}>
      <div className={styles.icon}>
        <FigurePassingIcon />
      </div>
      <div className={styles.section}>
        <p className={styles.name}>{name}</p>
        <p className={styles.date}>
          {startDateFormatted} - {endDateFormatted}
        </p>
      </div>
      {/* TODO animate loading */}
      {overallRank && <p className={styles.rank}>{`#${overallRank}`}</p>}
    </button>
  );
};

import {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Assessment as AssessmentProps} from 'api/Coach/useGetCoachInfo';
import {useProfiles} from 'api/User/useProfiles';
import {deletePlayerFromAssessment, getSelectedPlayersAssessmentPerformanceData} from 'api/api';
import {DropdownOption} from 'components/Dropdown/Dropdown';
import {Team} from 'user/user.interface';
import {downloadPerformanceDataAsExcel} from 'pages/Coach/utils';
import {useGetDirectorInfo} from 'api/Director/useGetDirectorInfo';
import {roleType} from 'constants/constants';
import {useGetAssessmentPlayers} from 'api/CoachAndDirector/useGetAssessmentPlayers';
import {AssessmentView} from 'pages/Coach/CoachAssessment/Assessment.view';

export const DirectorAssessment = ({assessment}: {assessment: AssessmentProps | null}) => {
  const navigate = useNavigate();
  const {teamId} = useParams();
  const [showDeletePlayerModal, setShowDeletePlayerModal] = useState<boolean>(false);
  const [playerToBeDeleted, setPlayerToBeDeleted] = useState<string>('');
  const [selectedPlayers, setSelectedPlayers] = useState<string[]>([]);
  const [ageGroup, setAgeGroup] = useState<DropdownOption | null>(null);
  const [sortBy, setSortBy] = useState<DropdownOption | null>(null);
  const {currentProfile} = useProfiles();
  const {data: directorInfo, isLoading: isDirectorLoading} = useGetDirectorInfo(currentProfile?._id);
  const {data: players, isLoading: isPlayersLoading} = useGetAssessmentPlayers({
    assessmentId: assessment?._id ?? '',
    yearOfBirth: ageGroup?.value ?? '',
    sortBy: sortBy?.value ?? '',
    role: roleType.director,
  });
  const teamInfo = useMemo(
    () => directorInfo?.teams.find((team) => team._id === teamId),
    [teamId, directorInfo?.teams]
  );

  const togglePlayerSelection = useCallback((playerId: string) => {
    setSelectedPlayers((prevSelected) =>
      prevSelected.includes(playerId) ? prevSelected.filter((id) => id !== playerId) : [...prevSelected, playerId]
    );
  }, []);

  const onClickDeletePlayerHandler = useCallback((playerId: string) => {
    setPlayerToBeDeleted(playerId);
    setShowDeletePlayerModal(true);
  }, []);

  const onClickDeletePlayerConfirm = useCallback(async () => {
    try {
      await deletePlayerFromAssessment(assessment?._id ?? '', playerToBeDeleted);
      toast.success('Player deleted successfully.');
      setShowDeletePlayerModal(false);
      setPlayerToBeDeleted('');
    } catch {
      toast.error('Failed to delete player.');
    }
  }, [playerToBeDeleted, assessment?._id]);

  const setSelectAllPlayers = useCallback(() => {
    const playerIds = players?.map((player) => player._id) ?? [];
    setSelectedPlayers(playerIds.length === selectedPlayers.length ? [] : playerIds);
  }, [players, selectedPlayers.length]);

  const onClickViewPlayerAssessmentData = useCallback(
    (playerId: string) => {
      const url = `/director/teams/${teamId}/playerAssessmentData/?assessmentId=${assessment?._id}&playerId=${playerId}`;
      navigate(url);
    },
    [assessment?._id, teamId, navigate]
  );

  const onClickExportHandler = async () => {
    try {
      const playersPerformanceData = await getSelectedPlayersAssessmentPerformanceData(
        selectedPlayers.join(','),
        assessment?._id || '',
        roleType.director,
        sortBy?.value || ''
      );
      downloadPerformanceDataAsExcel(playersPerformanceData, assessment as AssessmentProps, teamInfo as Team);
      toast.success('Players performance data exported successfully.');
    } catch {
      toast.error('Failed to export players performance data.');
    }
  };

  useEffect(() => {
    if (players?.length) {
      setSelectedPlayers([]);
    }
  }, [players]);

  return (
    <AssessmentView
      assessmentName={assessment?.name || ''}
      players={players || []}
      selectedPlayers={selectedPlayers}
      ageGroup={ageGroup}
      sortBy={sortBy}
      isLoading={isDirectorLoading || isPlayersLoading}
      showDeletePlayerModal={showDeletePlayerModal}
      setSelectAllPlayers={setSelectAllPlayers}
      togglePlayerSelection={togglePlayerSelection}
      onClickViewPlayerAssessmentData={onClickViewPlayerAssessmentData}
      onClickDeletePlayerHandler={onClickDeletePlayerHandler}
      onClickDeletePlayerConfirm={onClickDeletePlayerConfirm}
      setShowDeletePlayerModal={setShowDeletePlayerModal}
      setSortBy={setSortBy}
      setAgeGroup={setAgeGroup}
      onClickExportHandler={onClickExportHandler}
    />
  );
};

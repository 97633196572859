import {useQuery} from '@tanstack/react-query';
import {getAssessmentPlayers} from 'api/api';
import {Profile} from 'user/player-info.interface';

type GetAssessmentPlayersParams = {
  assessmentId: string;
  yearOfBirth: string;
  sortBy: string;
  role: string;
};

export const useGetAssessmentPlayers = ({assessmentId, yearOfBirth, sortBy, role}: GetAssessmentPlayersParams) => {
  return useQuery<Profile[]>({
    queryKey: ['assessment-players', assessmentId, yearOfBirth, sortBy, role],
    queryFn: () => getAssessmentPlayers(assessmentId, yearOfBirth, sortBy, role),
    enabled: !!assessmentId,
  });
};

import {useCallback, useState} from 'react';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {toast} from 'react-toastify';
import {assignExistingDirectorToTeam, createTeam, updateTeamLogo} from 'api/api';
import {roleType} from 'constants/constants';
import {TeamForm, TeamFormData} from './TeamForm';
import {Modal} from 'components/Modal/Modal';
import {useProfiles} from 'api/User/useProfiles';
import {useGetDirectorInfo} from 'api/Director/useGetDirectorInfo';
import {isDirector} from 'user/user-utils';

interface AddTeamModalProps {
  showModal: boolean;
  onClose: () => void;
}

export interface AssignDirectorData {
  email: string;
  teamId: string;
}

/**
 * Represents a modal component for creating a team.
 * @param {AddTeamModalProps} props - The component props.
 * @returns {JSX.Element} The JSX element representing the create team modal.
 */
export const AddTeamModal = ({showModal, onClose}: AddTeamModalProps): JSX.Element => {
  const [errorMsg, setErrorMsg] = useState('');
  const queryClient = useQueryClient();
  const {currentProfile} = useProfiles();
  const {data: directorInfo} = useGetDirectorInfo(currentProfile?._id);

  const teams = directorInfo?.teams;
  const siteId = teams?.[0]?.siteId;

  const invalidateDirectorInfo = useCallback(() => {
    queryClient.invalidateQueries(['director-info']);
  }, [queryClient]);

  const createTeamMutation = useMutation(createTeam);

  const updateTeamLogoMutation = useMutation((formData: FormData) => updateTeamLogo(formData, roleType.director));

  const assignDirectorMutation = useMutation(assignExistingDirectorToTeam);

  const handleError = (error: any) => {
    const errMessage = error?.response?.data?.message || error?.response?.data?.error || 'Failed to create team.';
    setErrorMsg(errMessage);
  };

  const createTeamHandler = useCallback(
    async (data: TeamFormData) => {
      try {
        const {logoUrl, ...teamData} = data;

        // Create the team
        const team = await createTeamMutation.mutateAsync({...teamData, siteId});
        const teamId = team._id;

        // Prepare logo upload data
        const formData = new FormData();
        formData.append('file', logoUrl as File);
        formData.append('teamId', teamId);

        // Perform logo upload
        await updateTeamLogoMutation.mutateAsync(formData);

        //Assign director to team
        if (isDirector(currentProfile)) {
          await assignDirectorMutation.mutateAsync({
            directorData: {
              email: currentProfile.email as string,
              teamId,
            },
          });
        }
        invalidateDirectorInfo();
        onClose();
        toast.success('Successfully created team.');
      } catch (error: any) {
        handleError(error);
        if (createTeamMutation.isSuccess || updateTeamLogoMutation.isSuccess || assignDirectorMutation.isSuccess) {
          invalidateDirectorInfo();
        }
      }
    },
    [
      createTeamMutation,
      updateTeamLogoMutation,
      assignDirectorMutation,
      siteId,
      currentProfile?.email,
      currentProfile?.role,
      onClose,
    ]
  );

  const isLoading =
    createTeamMutation.isLoading || updateTeamLogoMutation.isLoading || assignDirectorMutation.isLoading;

  return (
    <Modal isOpen={showModal} onClose={onClose} showHeader={true} title="Add Team" modalTestId="add-team-modal">
      <TeamForm onCancelHandler={onClose} onSubmitHandler={createTeamHandler} loading={isLoading} error={errorMsg} />
    </Modal>
  );
};

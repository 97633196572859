import {memo} from 'react';
import styles from './BaselineLevelInfo.module.scss';
import {SessionInfoLevel} from 'pages/Baseline/SessionInfo/BaselineSessionInfo';

export const BaselineLevelInfo = memo(({selectedLevel}: {selectedLevel: SessionInfoLevel}) => {
  return (
    <div className={styles.container}>
      {selectedLevel.info.map((info, i) => (
        <div key={`info_${i}`}>
          <h2>{info.title}</h2>

          {info.data.map((infoEntry, j) => (
            <p key={`info_entry_${j}`}>{infoEntry}</p>
          ))}
        </div>
      ))}
    </div>
  );
});
BaselineLevelInfo.displayName = 'BaselineLevelInfo';

import {PropsWithChildren, useMemo} from 'react';
import {useProfiles} from '../../api/User/useProfiles';
import {isDirector} from 'user/user-utils';
import {useGetDirectorInfo} from 'api/Director/useGetDirectorInfo';
import {useNavigate, useParams} from 'react-router-dom';
import {Team} from 'user/user.interface';
import Spinner from 'components/Loader/Spinner';

export default function DirectorAuthContainer({children}: PropsWithChildren) {
  const {currentProfile} = useProfiles();
  const navigate = useNavigate();
  const {data: directorInfo, isLoading} = useGetDirectorInfo(currentProfile?._id);
  const {teamId} = useParams();

  const isDirectorInTeam = useMemo(
    () => directorInfo?.teams?.some((team: Team) => team?._id === teamId),
    [directorInfo, teamId]
  );

  if (!isDirector(currentProfile)) {
    return <div>Insufficient permissions</div>;
  }

  if (isLoading) {
    return (
      <div className="w-full h-screen flex flex-1 items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (teamId && !isDirectorInTeam) {
    navigate('/director');
    return null;
  }

  return <>{children}</>;
}

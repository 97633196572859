import {memo, useEffect, useState} from 'react';
import styles from './ClinicsList.module.scss';
import moment from 'moment';
import {Enrollment} from 'api/Classes/useGetPrograms';
// TODO too many calendar icons
import {ReactComponent as CalendarIcon} from '../../../assets/img/icons/redesign-icons/calendar-basic.svg';
import {ReactComponent as LocationIcon} from '../../../assets/img/icons/redesign-icons/location-pin.svg';
import {ReactComponent as WhistleIcon} from '../../../assets/img/icons/redesign-icons/whistle.svg';
import {ReactComponent as ClockFaceIcon} from '../../../assets/img/icons/redesign-icons/clock-face.svg';
import {ReactComponent as ChevronIcon} from '../../../assets/img/icons/redesign-icons/chevron-down.svg';

type ScheduleEvent = Enrollment & {
  nextAvailableDate: number;
  formattedDate: string;
  formattedMonth: string;
  formattedDay: string;
  formattedTime: string;
};

type ClinicsListProps = {
  clinics: Enrollment[][];
};

const determineNextEventDate = (event: Enrollment) => {
  const now = moment();
  const eventStartDate = moment(event.schedule.startDate);
  const eventStartTime = moment(event.schedule.startTime);
  const [hours, minutes] = [eventStartTime.get('h'), eventStartTime.get('m')];
  const eventEnd = moment(event.schedule.endDate);
  let nextDate;

  if (now.isBefore(eventStartDate)) {
    nextDate = eventStartDate;
  } else {
    for (let i = 1; i <= 7; i++) {
      // now.add(...) would mutate the original date -_-
      if (event.schedule.daysArray.includes(moment().add(i, 'days').format('dddd'))) {
        nextDate = now.add(i, 'days');
        break;
      }
    }
  }

  if (nextDate && nextDate.isBefore(eventEnd)) {
    return nextDate.set('h', hours).set('m', minutes).set('s', 0).set('ms', 0).valueOf();
  } else {
    return null;
  }
};

//
const processEvents = (eventArrays: Enrollment[][], max = 12) => {
  const _eventArrays = eventArrays.map((arr) =>
    arr
      .map((event) => {
        const nextAvailableDate = determineNextEventDate(event);
        if (nextAvailableDate) {
          return {
            ...event,
            nextAvailableDate,
          };
        }
      })
      .sort((a, b) => (a?.nextAvailableDate ?? 0) - (b?.nextAvailableDate ?? 0))
  );

  const _events: ScheduleEvent[] = [];

  let continueLooping = true;
  while (_events.length < max && continueLooping) {
    const currentLength = _events.length;
    _eventArrays.forEach((arr) => {
      const _event = arr.shift();
      if (_event) {
        const _date = moment(_event.nextAvailableDate);
        _events.push({
          ..._event,
          formattedDate: _date.format(`MMMM D[,] YYYY`),
          formattedMonth: _date.format(`MMM`),
          formattedDay: _date.format(`D`),
          formattedTime: _date.format('h:mm:ss a'),
        });
      }
    });
    if (_events.length === currentLength) {
      continueLooping = false;
    }
  }

  return (_events.length > max ? _events.slice(0, max) : _events).sort(
    (a, b) => +b.schedule.startDate - +a.schedule.startDate
  );
};

const MAX_EVENTS_INITIALLY_SHOWN = 3;
export const ClinicsList = memo(({clinics}: ClinicsListProps) => {
  // TODO settle on terminology 'Event' vs 'Clinic'
  const [events, setEvents] = useState<ScheduleEvent[]>();
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    if (clinics) {
      setEvents(processEvents(clinics));
    }
  }, [clinics]);

  if (!events) {
    return null;
  }

  return (
    <>
      <ul className={styles.eventList}>
        {events.map((event, i) =>
          showAll || i < MAX_EVENTS_INITIALLY_SHOWN ? <ClinicCard event={event} key={i} /> : null
        )}
      </ul>
      {!showAll && events.length > MAX_EVENTS_INITIALLY_SHOWN ? (
        <button className={styles.seeMoreButton} onClick={() => setShowAll((state) => !state)}>
          <span>See More</span>
          <ChevronIcon />
        </button>
      ) : null}
    </>
  );
});
ClinicsList.displayName = 'ClinicsList';

type ClinicCardProps = {event: ScheduleEvent; key: number};
const ClinicCard = memo(({event, key}: ClinicCardProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <li key={`event_card_${key}`} className={styles.eventCard}>
      <div className={styles.adornment}>
        <p>{event.formattedMonth}</p>
        <p className={styles.number}>{event.formattedDay}</p>
      </div>
      <div className={styles.content}>
        <p className={styles.title}>{event.name}</p>

        {isOpen ? (
          <ul className={styles.detailsList}>
            <li>
              <CalendarIcon />
              <p>{event.schedule.daysArray.join(`, `)}</p>
            </li>
            <li>
              <ClockFaceIcon />
              <p>{event.formattedTime}</p>
            </li>
            <li>
              <WhistleIcon />
              <p>{`Coach: ${event.staff?.firstName} ${event.staff?.lastName}`}</p>
            </li>
            <li>
              <LocationIcon />
              <p>{event.location?.address}</p>
            </li>
          </ul>
        ) : null}
      </div>
      <div className={styles.buttonWrapper}>
        <button
          className={`${styles.expandButton} ${isOpen ? styles.expanded : ''}`}
          onClick={() => setIsOpen((state) => !state)}
        >
          <ChevronIcon />
        </button>
      </div>
    </li>
  );
});
ClinicCard.displayName = 'ClinicCard';

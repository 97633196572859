import styles from './Trainer.module.scss';
import {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {getTrainerUser} from '../../api/api';
import {useTrainerContext} from '../../context/UserContextProvider';
import {logAnalyticsEvent} from '../../common/analytics-events';
import {getUrlSearchParam} from '../../utils/utils';
import Spinner from '../../components/Loader/Spinner';
import {Profile} from '../../user/player-info.interface';
import {getTrainerEmulationUrlPath} from '../../components/TrainerEmulationContainer/TrainerEmulationContainer';

export const TrainerDirectEmulation = () => {
  const navigate = useNavigate();
  const trainerContext = useTrainerContext();
  const mboClientId = getUrlSearchParam('mboClientId');
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    if (!trainerContext) {
      console.log('No trainer context available.');
      navigate('/home');
      return;
    }

    if (!mboClientId) {
      setError('No MBO client ID provided via "mboClientId" URL search parameter.');
      return;
    }

    (async () => {
      try {
        const {data: user} = await getTrainerUser({mboClientId});
        const profile = user?.profiles.find((p: Profile) => p.mboDetails?.clientId === mboClientId);
        if (user && profile) {
          trainerContext.emulateUser(user, profile._id, true);
          logAnalyticsEvent('trainer-emulation-started', {
            profileId: profile._id,
            mboClientId: profile.mboDetails?.clientId,
            mboSiteId: profile.mboDetails?.siteId,
            directLink: true,
          });
          // TODO: possible area to navigate to connect instead of emulating
          navigate(getTrainerEmulationUrlPath('/home', profile.mboDetails?.siteId));
        } else {
          setError(`
            No athlete profile found for MBO client ID "${mboClientId}". Please make sure that the provided MBO 
            client ID is correct, that an athlete profile exists and is associated with the given MBO client ID, and 
            that both trainer and athlete profiles are associated with the same MBO site/center.
          `);
        }
      } catch (err: any) {
        setError(err.message);
      }
    })();
  }, [mboClientId, navigate, trainerContext]);

  return (
    <div className={styles.container}>
      <div className={styles.centeredContainer}>
        {error ? (
          <div className="error">
            <h2 className={styles.heading2}>Error</h2>
            <p>Encountered error while trying to emulate profile.</p>
            <p>{error}</p>
            <p>
              <Link to="/trainer" className="submit-btn">
                Go to trainer index page
              </Link>
            </p>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

import {useMemo} from 'react';
import {useGetPrograms} from './useGetPrograms';
import {GetMboProgramsParams} from 'api/api';
import moment from 'moment';
import {enrollmentAgeMatches, isEnrollable} from 'utils/programs-utils';

type UseGetEventsParams = GetMboProgramsParams & {
  age?: number;
};

export const useGetEvents = ({siteId, age}: UseGetEventsParams) => {
  const {data: programs, isFetching, isLoading} = useGetPrograms({siteId});
  const date = useMemo(() => moment().startOf('day'), []);

  const classes = useMemo(
    () =>
      programs
        ?.filter((program) => program.name === 'Soccer Classes')
        .map((program) =>
          program.enrollments.filter(
            (enrollment) => isEnrollable(enrollment, date) && enrollmentAgeMatches({enrollment, age})
          )
        )
        .filter((enrollments) => enrollments.length),
    [age, date, programs]
  );

  const camps = useMemo(
    () =>
      programs
        ?.filter((program) => program.name === 'TOCA Camps & Clinics')
        .map((program) =>
          program.enrollments.filter(
            (enrollment) => isEnrollable(enrollment, date) && enrollmentAgeMatches({enrollment, age})
          )
        )
        .filter((enrollments) => enrollments.length),
    [age, date, programs]
  );

  return {
    camps,
    classes,
    isFetching,
    isLoading,
  };
};

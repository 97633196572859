export const loadingMessages = [
  'Loading...',
  "...at least you're not on hold...",
  'Moving the satellites into position',
  'Are we there yet?',
  'Just count to 10',
  'Why so serious?',
  'Counting backwards from Infinity',
  "Don't panic...",
  'Do not run! We are your friends!',
  "We're making you a cookie.",
  'Creating time-loop inversion field',
  'Spinning the wheel of fortune...',
  'Loading the enchanted bunny...',
  'Computing chance of success',
  'I feel like im supposed to be loading something...',
  'Adjusting flux capacitor...',
  'Please wait until the sloth starts moving.',
  "Don't break your screen yet!",
  "I swear it's almost done.",
  "Let's take a mindfulness minute...",
  'Unicorns are at the end of this road, I promise.',
  'Cleaning off the cobwebs...',
  "Making sure all the i's have dots...",
  'Granting wishes...',
  'Time flies when you’re having fun.',
  'Spinning the hamster...',
  'Computing the secret to life, the universe, and everything.',
  'When nothing is going right, go left!!...',
  "I'm not lazy, I'm just relaxed!",
  'I think I am, therefore, I am. I think.',
  'We are not liable for any broken screens as a result of waiting.',
  'Hello IT, have you tried turning it off and on again?',
  'Twiddling thumbs...',
  'Feel free to spin in your chair',
  "Never let a computer know you're in a hurry.",
  'Pushing pixels...',
  'Still faster than Windows update',
  'Feeding unicorns...',
  'Whoah, look at it go!',
  "No, I'm awake. I was just resting my eyes.",
  'One mississippi, two mississippi...',
  "Don't panic... AHHHHH!",
  'Ensuring Gnomes are still short.',
  'Baking ice cream...',
];

import {SessionInfoLevel} from 'pages/Baseline/SessionInfo/BaselineSessionInfo';
import styles from './BaselineLevelInfoTable.module.scss';
import {BaselineLevelInfo} from '../LevelInfo/BaselineLevelInfo';
import {memo} from 'react';

export const BaselineLevelInfoTable = memo(({levels}: {levels: SessionInfoLevel[]}) => {
  return (
    <table className={styles.container}>
      <thead>
        <tr>
          {levels.map((level, i) => (
            <th key={`level_heading_${i}`} className={styles.levelHeading}>
              {level.level}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        <tr className={styles.blankRow} />

        <tr>
          {levels.map((level, i) => (
            <td key={`level_info_${i}`}>
              <BaselineLevelInfo selectedLevel={level} />
            </td>
          ))}
        </tr>

        <tr className={styles.blankRow} />

        <tr>
          {levels.map((level, i) => (
            <td key={`level_totals_${i}`} className={styles.levelTotals}>
              <div className={styles.sessionTotals}>
                <p>Total Balls: {level.totals.balls}</p>
                <p>Total Time: {level.totals.time} minutes</p>
              </div>
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
});
BaselineLevelInfoTable.displayName = 'BaselineLevelInfoTable';

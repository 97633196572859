import {useEffect, useRef} from 'react';
import {createPortal} from 'react-dom';
import './Splash.style.scss';
import {MyTocaLogo} from '../Logo/MyTocaLogo';

const splashRoot = document.querySelector('#splash') as HTMLElement | null;

const Splash = () => {
  const el = useRef(document.createElement('div'));

  useEffect(() => {
    // Use this in case CRA throws an error about react-hooks/exhaustive-deps
    const current = el.current;

    if (splashRoot) {
      splashRoot.appendChild(current);
      return () => void splashRoot.removeChild(current);
    }
  }, []);

  return createPortal(
    <div className="splash-container">
      <MyTocaLogo className="toca-splash-logo" />
      <div className="toca-spinning-ball-container">
        <img src="/images/soccer-ball-animated-white-bg.gif" alt="TOCA spinning soccer ball" />
      </div>
    </div>,
    el.current
  );
};

export default Splash;

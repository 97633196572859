import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type CoachState = {
  activeAssessmentId: string;
  activeTeamId: string;
};

const initialState = {activeAssessmentId: ''} as CoachState;

const coachSlice = createSlice({
  name: 'coach',
  initialState,
  reducers: {
    setActiveAssessmentId(state, action: PayloadAction<string>) {
      state.activeAssessmentId = action.payload;
    },
    setActiveTeamId(state, action: PayloadAction<string>) {
      state.activeTeamId = action.payload;
    },
  },
});

export const {setActiveAssessmentId, setActiveTeamId} = coachSlice.actions;

export default coachSlice.reducer;

import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import styles from './SignUpForm.module.scss';
import {StandardInput} from 'components/Input/StandardInput/StandardInput';
import {BookingFormButton} from 'components/Buttons/Form/FormButton';
import {ErrorMessage} from 'components/Forms/ErrorMessage/ErrorMessage';
import {useState} from 'react';

type FormTypes = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  dob: string;
};

export const SignUpForm = ({errorMessage, onSubmitHandler}: any) => {
  const [showDateInput, setShowDateInput] = useState(false);
  const [autoFocusDateInput, setAutoFocusDateInput] = useState(false);

  const validationSchema = yup.object().shape({
    firstName: yup.string().required('Required').trim('Name cannot include leading or trailing spaces'),
    lastName: yup.string().required('Required').trim('Name cannot include leading or trailing spaces'),
    dob: yup.date().typeError('Required').required('Required'),
    email: yup.string().required('Email is required').email('Email is invalid'),
    password: yup
      .string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters')
      .max(40, 'Password must not exceed 40 characters'),
  });

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<FormTypes>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const showDateInputAndFocus = () => {
    setAutoFocusDateInput(true);
    setShowDateInput(true);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} className={styles.container}>
      <StandardInput
        spellCheck="false"
        {...register('firstName')}
        type="text"
        id="firstName"
        placeholder="First Name"
      />
      <ErrorMessage text={errors.firstName?.message ? errors.firstName.message : '\u00A0'} />

      <StandardInput spellCheck="false" {...register('lastName')} type="text" id="lastName" placeholder="Last Name" />
      <ErrorMessage text={errors.lastName?.message ? errors.lastName.message : '\u00A0'} />

      <StandardInput spellCheck="false" {...register('email')} type="email" id="email" placeholder="Email" />
      <ErrorMessage text={errors.email?.message ? errors.email.message : '\u00A0'} />

      <StandardInput
        spellCheck="false"
        {...register('password')}
        type="password"
        id="password"
        placeholder="password"
      />
      <ErrorMessage text={errors.password?.message ? errors.password.message : '\u00A0'} />

      <div>
        {/* Number input placeholder and actual input */}
        {!showDateInput && (
          <StandardInput
            variant="new"
            spellCheck="false"
            type="text"
            placeholder="Date of Birth (mm/dd/yyyy)"
            onFocus={showDateInputAndFocus}
          />
        )}
        {showDateInput && (
          <StandardInput
            variant="new"
            spellCheck="false"
            {...register('dob')}
            type="date"
            id="dob"
            placeholder="Date of Birth (mm/dd/yyyy)"
            autoFocus={autoFocusDateInput}
            hasError={!!errors.dob?.message}
            min="1900-01-01"
            max={new Date().toISOString().split('T')[0]}
          />
        )}
        <p className="text-sm text-red-500">{errors.dob?.message ?? '\u00A0'}</p>
      </div>

      <ErrorMessage text={errorMessage ? errorMessage : '\u00A0'} />
      <div className={styles.buttonWrapper}>
        <BookingFormButton type="submit" hasError={Object.keys(errors).length !== 0} isPrimary>
          <p className={styles.buttonText}>Create Account</p>
        </BookingFormButton>
      </div>
    </form>
  );
};

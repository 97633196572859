import {auth as fireBaseAuth} from './config';

export async function simpleLogout(reason?: string) {
  await silentLogout();
  window.location.replace(`/auth${reason ? '?reason=' + reason : ''}`);
}

export async function silentLogout() {
  await fireBaseAuth.signOut();
  localStorage.clear();
}

import {PlayerAssessmentConnectsBaselineSessionData} from 'components/Connects/PlayerAssessmentBaselineSessionData/PlayerAssessmentConnectsBaselineSessionData';
import Spinner from 'components/Loader/Spinner';
import {Button} from 'components/Connects/Button';
import {PlayerSessionSummary} from 'api/Trainer/useGetPlayerLastSessionSummary';
import {ReactComponent as ArrowLeft} from '../../../assets/img/icons/new-chevron-left-black.svg';

interface PlayerAssessmentDataViewProps {
  isLoading: boolean;
  playerAssessmentData: PlayerSessionSummary;
  isSessionSelectionCompleted: boolean;
  date: string;
  playerName: string;
  assessmentName: string;
  onClickGoBack: () => void;
  onClickDownloadHandler: () => void;
}

export const PlayerAssessmentDataView = ({
  isLoading,
  playerAssessmentData,
  isSessionSelectionCompleted,
  date,
  playerName,
  assessmentName,
  onClickDownloadHandler,
  onClickGoBack,
}: PlayerAssessmentDataViewProps) => {
  return (
    <>
      {isLoading ? (
        <div className="w-full h-full flex flex-1 items-center justify-center">
          <Spinner />
        </div>
      ) : playerAssessmentData ? (
        <>
          <button className="flex text-lg mt-4" onClick={onClickGoBack}>
            <ArrowLeft className="mt-1" />
            <div className="text-primary ml-2">{assessmentName} </div>
          </button>
          <div className="grow flex flex-col">
            <div className="flex flex-col items-center">
              <div className="text-5xl text-primary text-center leading-[60px] font-semibold mt-6">
                PLAYER ASSESSMENT DATA
              </div>
              <div className="text-center text-secondary text-lg font-semibold mt-4 mb-2">Player: {playerName}</div>
            </div>

            {playerAssessmentData?.isBaseline ? (
              <>
                <div className="exceed-global-padding lg:max-w-[800px] lg:mx-auto lg:w-full">
                  <PlayerAssessmentConnectsBaselineSessionData
                    sessionSummary={playerAssessmentData}
                    enableSessionSelection={false}
                    date={date}
                    showAllExercises={!isSessionSelectionCompleted}
                  />
                </div>
                <div className="exceed-global-padding p-6 mt-2 border-2 border-grey" onClick={onClickDownloadHandler}>
                  <Button color="secondary" variant="cta" text="Download XLS" />
                </div>
              </>
            ) : (
              <div className="grid place-content-center">
                <p className="text-sm text-grey-xdark mt-4">Player assessment data not found</p>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="grow flex flex-col">
          <p className="text-sm text-grey-xdark text-center p-4">unable to load player assessment data</p>
        </div>
      )}
    </>
  );
};

export const teamColors = [
  '#E81F3E', // team-red
  '#0089D0', // team-medium-blue
  '#FADD44', // team-yellow
  '#B498F5', // team-light-purple
  '#7AD97E', // team-mint-green
  '#5C636B', // team-dark-gray
  '#DE29EE', // team-purple
  '#FF70A3', // team-pink
  '#6E41B9', // team-indigo
  '#000000', // team-black
  '#4ADAC9', // team-cyan
  '#13521A', // team-dark-green
  '#E9662E', // team-orange
  '#121F94', // team-dark-blue
  '#F8B430', // team-yellow-orange
  '#18716B', // team-teal
  '#0085FF', // team-sky-blue
  '#470688', // team-dark-purple
  '#F17EEC', // team-light-pink
  '#CF2074', // team-dark-pink
  '#692929', // team-dark-maroon
];

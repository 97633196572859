import {useCallback} from 'react';
import {Program, useGetPrograms} from './useGetPrograms';
import {UseQueryResult} from '@tanstack/react-query';
import {GetMboProgramsParams} from 'api/api';

type useGetSingleProgramParams = GetMboProgramsParams & {
  programId?: string | null;
};

export const useGetSingleProgram = ({siteId, programId}: useGetSingleProgramParams) => {
  const select = useCallback((data: Program[]) => data.find((program) => program.programId === programId), [programId]);

  return useGetPrograms({siteId, select}) as UseQueryResult<Program>;
};

import {memo, useCallback, useMemo, useState} from 'react';
import moment from 'moment';
import {ClassSchedule, EnrollmentClass} from 'api/Classes/useGetPrograms';
import {Button} from 'components/Connects/Button';
import {EnrollmentCheckoutCard} from './EnrollmentCheckoutCard';
import {RadioWrapper} from 'components/RadioButton/RadioWrapper';
import {format24hrTimeString} from 'utils/programs-utils';

type ProgramCheckoutSingleSelectionProps = {
  enrollment: ClassSchedule; // TODO CLASSES naming
  onSubmit: ({selectedClassId}: {selectedClassId?: string}) => void;
  selectedPlayerIds: string[];
  setCheckoutSelectedSession?: React.Dispatch<React.SetStateAction<EnrollmentClass | undefined>>;
};

export const ClassesCheckoutSingleSelection = memo(
  ({enrollment, selectedPlayerIds, onSubmit, setCheckoutSelectedSession}: ProgramCheckoutSingleSelectionProps) => {
    const [isClassSelectionOpen, setIsClassSelectionOpen] = useState(true);
    const [selectedClass, setSelectedClass] = useState<string>();

    const selectClass: React.FormEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        setSelectedClass(e.currentTarget.value);
        setCheckoutSelectedSession?.(enrollment.classes.find((_class) => _class.classId === e.currentTarget.value)); // TODO CLASSES this still needs to be fixed
        // setIsClassSelectionOpen(false);
      },
      [enrollment.classes, setCheckoutSelectedSession]
    );

    const checkoutConditionsError = useMemo(
      () => !selectedPlayerIds.length || !selectedClass,
      // || enrollment.classes.find((cls) => Number(cls?.webCapacity) - Number(cls?.webBooked) < selectedPlayerIds.length),
      [selectedClass, selectedPlayerIds.length]
    );

    const selectedStartDate = useMemo(
      () => selectedClass && enrollment.classes.find((cls) => cls.classId === selectedClass)?.startDateTime,
      [enrollment.classes, selectedClass]
    );

    if (!enrollment) {
      console.error('enrollment missing during checkout');
      return null;
    }

    return (
      <>
        <div className="flex flex-col gap-8">
          <EnrollmentCheckoutCard
            enrollment={enrollment}
            overrideSessionCount={selectedClass ? 1 : 0}
            overrideStartDate={selectedStartDate}
            isSingleSelection={true}
          />

          {/*<div className="flex flex-col-reverse sm:flex-row xs:mx-8 sm:mx-0 justify-around gap-y-2 gap-x-4">*/}
          {/*  <div className="shrink">*/}
          {/*    <Button*/}
          {/*      text={*/}
          {/*        isClassSelectionOpen*/}
          {/*          ? `Close Class Selection`*/}
          {/*          : selectedClass*/}
          {/*          ? `Choose A Different Session`*/}
          {/*          : `Select A Class`*/}
          {/*      }*/}
          {/*      onClick={() => setIsClassSelectionOpen((prev) => !prev)}*/}
          {/*    />*/}
          {/*  </div>*/}

          {/*  <div className="block sm:hidden">*/}
          {/*    <FormDividerRevised text="" />*/}
          {/*  </div>*/}

          {/*  <div className="shrink">*/}
          {/*    <Button*/}
          {/*      variant="cta"*/}
          {/*      onClick={() => onSubmit({selectedClassId: selectedClass})}*/}
          {/*      text="Confirm Reservation"*/}
          {/*      color={checkoutConditionsError ? 'grey' : 'primary'}*/}
          {/*      disabled={!!checkoutConditionsError}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className="text-[1.5rem] text-center text-secondary font-medium">
            Select the class you would like to attend
          </div>

          <div
            className={`grid gap-2 grid-cols-1 sm:grid-cols-2 sm:gap-x-8 ${
              isClassSelectionOpen ? 'max-h-[10000px]' : 'max-h-0'
            } transition-[max-height] duration-500 overflow-hidden`}
          >
            {enrollment.classes.map((cls) => {
              return (
                <div className="flex justify-center" key={cls.classId}>
                  <_ClassRadioCard
                    enrollmentClass={cls}
                    selectedClass={selectedClass}
                    selectClass={selectClass}
                    selectedPlayerCount={selectedPlayerIds.length ?? 1}
                  />
                </div>
              );
            })}
          </div>

          <div className="flex flex-col-reverse sm:flex-row xs:mx-8 sm:mx-0 justify-around gap-y-2 gap-x-4">
            <div className="shrink">
              <Button
                variant="cta"
                onClick={() => onSubmit({selectedClassId: selectedClass})}
                text="Confirm Reservation"
                color={checkoutConditionsError ? 'grey' : 'primary'}
                disabled={!!checkoutConditionsError}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
);
ClassesCheckoutSingleSelection.displayName = 'ProgramCheckoutSingleSelection';

type _ClassRadioCardProps = {
  enrollmentClass: EnrollmentClass;
  selectedClass?: string;
  selectClass: React.FormEventHandler<HTMLInputElement>;
  selectedPlayerCount: number;
};
const _ClassRadioCard = memo(
  ({enrollmentClass, selectedClass, selectClass, selectedPlayerCount}: _ClassRadioCardProps) => {
    const dateTime = moment(enrollmentClass.startDateString ?? enrollmentClass.startDateTime); // TODO CLASSES is inaccurate worse than missing?
    const isDisabled = enrollmentClass.capacity.available < selectedPlayerCount;
    const isSelected = selectedClass === enrollmentClass.classId;

    return (
      <div
        className={`grow overflow-hidden bg-white border border-grey rounded-lg shadow-flat flex pl-0 pr-6 py-2 max-w-[420px] ${
          isDisabled ? 'opacity-50' : isSelected ? 'shadow-secondary/25 border-secondary' : ''
        } transition-all`}
      >
        <RadioWrapper
          id={enrollmentClass.classId}
          name="classSchedule"
          value={enrollmentClass.classId}
          checked={isSelected}
          onChange={selectClass}
          disabled={isDisabled}
        >
          <div className="flex flex-col grow">
            <p className="font-poppins font-semibold text-lg text-primary">
              <span className="text-secondary">{dateTime.format('dddd[, ]')}</span>
              {dateTime.format('MMM D YYYY')}
            </p>
            <div className="flex justify-between flex-wrap">
              {/* <p className="font-poppins font-medium text-sm text-grey-xdark">{dateTime.format('h:mma')}</p> */}
              <p className="font-poppins font-medium text-sm text-grey-xdark">
                {format24hrTimeString({time: enrollmentClass.startTimeString})}
              </p>
              <p className="font-poppins font-medium text-sm text-grey-xdark">{`${enrollmentClass.capacity.available}/${enrollmentClass.capacity.max} available`}</p>
            </div>
          </div>
        </RadioWrapper>
      </div>
    );
  }
);
_ClassRadioCard.displayName = 'ClassCard';

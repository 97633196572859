import React, {useState, useCallback, useEffect, useRef, KeyboardEvent} from 'react';
import styles from './AutocompleteSearch.module.scss';

export interface Option {
  label: string;
  value: any;
}

interface AutocompleteSearchProps {
  options: Option[];
  onSearchInputChange: (search: string) => void;
  onOptionSelectionHandler: (option: Option) => void;
}

/**
 * AutocompleteSearch component.
 *
 * @component
 * @example
 * ```tsx
 * <AutocompleteSearch
 *   options={options}
 *   onSearchInputChange={handleSearchInputChange}
 *   onOptionSelectionHandler={handleOptionSelection}
 * />
 * ```
 */
export const AutocompleteSearch: React.FC<AutocompleteSearchProps> = ({
  options,
  onSearchInputChange,
  onOptionSelectionHandler,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [inputFocused, setInputFocused] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState<number>(-1);

  const autocomplete = useRef<HTMLDivElement>(null);
  const textInputRef = useRef<HTMLInputElement>(null);

  /**
   * Handles the change event of the search input.
   */
  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {value} = event.target;
      setSearchTerm(value);
      onSearchInputChange(value);
      setInputFocused(true);
      setFocusedIndex(0);
    },
    [onSearchInputChange]
  );

  /**
   * Handles the focus event of the search input.
   */
  const handleInputFocus = useCallback(() => {
    setInputFocused(true);
    setFocusedIndex(0);
  }, []);

  /**
   * Handles the selection of an option.
   */
  function onOptionSelection(option: Option): void {
    const {label} = option;
    setSearchTerm(label);
    onSearchInputChange(label);
    onOptionSelectionHandler(option);
    setInputFocused(false);
  }

  /**
   * Handles the click event outside the autocomplete component to set input focus to false.
   */
  const handleClickOutSide = (e: MouseEvent) => {
    if (autocomplete.current && !autocomplete.current.contains(e.target as Node)) {
      setInputFocused(false);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowDown') {
      // Move focus down
      setFocusedIndex((prevIndex) => (prevIndex < options.length - 1 ? prevIndex + 1 : prevIndex));
    } else if (event.key === 'ArrowUp') {
      // Move focus up
      setFocusedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    } else if (event.key === 'Enter') {
      // Handle selection on Enter key press
      if (focusedIndex !== -1 && focusedIndex < options.length) {
        onOptionSelection(options[focusedIndex]);
        if (textInputRef.current) {
          textInputRef.current.blur();
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  }, []);

  return (
    <div ref={autocomplete} className={styles.autocompleteSearch}>
      <div className={styles.inputContainer}>
        <input
          ref={textInputRef}
          type="text"
          value={searchTerm}
          onKeyDown={handleKeyDown}
          onFocus={handleInputFocus}
          onChange={handleInputChange}
        />
        <button>Search</button>
      </div>
      {inputFocused && options.length > 0 && (
        <ul className={styles.listContainer}>
          {options.map((option, index) => (
            <li
              className={`${styles.listItem} ${index === focusedIndex ? styles.listItemSelected : ''}`}
              key={option.value}
              onClick={() => onOptionSelection(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

import {memo} from 'react';
import styles from './BaselineSessionTable.module.scss';
import {PerformanceMetric, TrainingSession} from 'api/User/useGetPlayerDetails';
import {baselineBenchmarkValues, ExerciseBenchmark} from 'pages/Baseline/Benchmarks/BaselineBenchmarks';
import {useProfiles} from 'api/User/useProfiles';
import moment from 'moment';

export const BaselineSessionTable = memo(({sessionData}: {sessionData: TrainingSession}) => {
  const benchmarks = baselineBenchmarkValues;
  const levelBenchmark = benchmarks.find((benchmark) => benchmark.level === sessionData.baselineLevel);

  if (!levelBenchmark || !sessionData.performance) {
    return null;
  }

  const {exercises} = sessionData.performance;

  return (
    <div className={styles.tableContainer}>
      <table>
        <thead>
          <tr>
            <th className={`${styles.fullWidth} ${styles.levelHeading} ${styles[levelBenchmark.level]}`}>
              <p>{levelBenchmark.level}</p>
            </th>
            <th>Target Streak</th>
            <th>Streak</th>
            <th>Target Accuracy</th>
            <th>Accuracy</th>
            <th>Target SOP</th>
            <th>SOP</th>
            <th>Target MPH</th>
            <th>MPH</th>
          </tr>
        </thead>

        <tbody>
          {/* {exercises.map((exercise, i) => {
              const _benchmark = levelBenchmark.benchmarks[i];
              return <FormattedSessionRow benchmark={_benchmark} exercise={exercise} key={`benchmark_row_${i}`} />;
            })} */}
          {levelBenchmark.benchmarks.map((benchmark, i) => {
            const exercise = exercises[i];
            return exercise ? (
              <FormattedSessionRow benchmark={benchmark} exercise={exercise} key={`benchmark_row_${i}`} />
            ) : null;
          })}

          <tr className={styles.blankRow} />
          <TrainerHeadingRow />
          <TrainerDataRow sessionData={sessionData} />
        </tbody>
      </table>
    </div>
  );
});
BaselineSessionTable.displayName = 'BaselineSessionTable';

const FormattedSessionRow = ({benchmark, exercise}: {benchmark: ExerciseBenchmark; exercise: PerformanceMetric}) => {
  if (!benchmark || !exercise) {
    return null;
  }

  return (
    <tr>
      <td className={styles.fullWidth}>
        {benchmark.number}. {benchmark.title}
      </td>
      <td>{benchmark.streak ?? '-'}</td>
      <td>{exercise.streak ?? '-'}</td>
      <td>{benchmark.accuracy * 100}%</td>
      <td>{exercise.accuracy}%</td>
      <td>{benchmark.sop ?? '-'}</td>
      <td>{exercise.speedOfPlay ?? '-'}</td>
      <td>{benchmark.mph ?? '-'}</td>
      <td>{exercise.ballSpeed ?? '-'}</td>
    </tr>
  );
};

const TrainerHeadingRow = memo(() => {
  return (
    <tr className={styles.trainerHeadingRow}>
      <th className={styles.fullWidth}>Trainer Name</th>
      <th>Date</th>

      <th className={styles.borderlessCell} />
      <th className={styles.borderlessCell}>Player&nbsp;Name</th>
      <th className={styles.borderlessCell} />

      <th>Age</th>
      <th>Balls</th>
      <th>Minutes</th>
      <th>Sets</th>
    </tr>
  );
});
TrainerHeadingRow.displayName = 'TrainerHeadingRow';

const TrainerDataRow = memo(({sessionData}: {sessionData: TrainingSession}) => {
  const {currentProfile} = useProfiles();

  const playerAge = moment().diff(currentProfile.dob, 'years');
  const sessionStart = moment(sessionData.startTime);
  const sessionLength = moment(sessionData.endTime).diff(sessionStart, 'minutes');

  return (
    <tr className={styles.trainerDataRow}>
      <td className={styles.fullWidth}>{sessionData.mboTrainer?.fullName ?? ''}</td>
      <td>{sessionStart.format('MM/DD/YY')}</td>

      <td className={styles.borderlessCell} />
      <td className={`${styles.borderlessCell} ${styles.playerNameCell}`}>
        <p>
          {currentProfile.firstName} {currentProfile.lastName}
        </p>
      </td>
      <td className={styles.borderlessCell} />

      <td>{playerAge}</td>
      <td>{sessionData.totalBallsFired}</td>
      <td>{sessionLength}</td>
      <td>{sessionData.performance?.metrics.repetitions}</td>
    </tr>
  );
});
TrainerDataRow.displayName = 'TrainerDataRow';

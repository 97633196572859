import {getLatLongOffsetBasedOnResolution} from './Utils/common';

const {latOffSet, lonOffSet} = getLatLongOffsetBasedOnResolution();

//These are Costa Mesa coordinates
export const initialMapCenter = {
  latitude: 33.67418565216646 + latOffSet,
  longitude: -117.88954954477214 + lonOffSet,
};

export const initialViewState = {
  latitude: initialMapCenter.latitude,
  longitude: initialMapCenter.longitude,
  zoom: 10,
};

export const MAP_FLY_TO_DURATION = 2000;

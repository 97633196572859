import {Helmet} from 'react-helmet';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useGetAthleteInfo} from 'api/Trainer/useGetAthleteInfo';
import {useGetPrograms} from 'api/Classes/useGetPrograms';
import {useGetMboVisits} from 'api/Booking/useGetMboVisits';
import {LoadingAnimationFullHeight} from 'components/Loader/LoadingAnimation';
import {PageHeading} from 'components/Connects/PageHeading';
import {ButtonTray} from 'components/Connects/ButtonTray';
import {Button} from 'components/Connects/Button';
import {SessionRecap} from 'components/Connects/SessionRecap/SessionRecap';
import {LogoBanner} from 'components/Connects/LogoBanner';
import {ProfileBar} from 'components/ProfileBar/ProfileBar';
import {useEffect} from 'react';

export const Connects = () => {
  const navigate = useNavigate();
  const {trainerID} = useParams(); // TODO are we actually using this for anything?
  const [searchParams] = useSearchParams();
  const [clientId, siteId, baselineVisited] = [
    searchParams.get('mboClientId'),
    searchParams.get('mboSiteId'),
    searchParams.get('baselineVisited'),
  ];

  useGetPrograms({siteId});
  useGetMboVisits({clientId, siteId});
  const {data: athleteInfo, isLoading} = useGetAthleteInfo({clientId, siteId});

  useEffect(() => {
    if (!isLoading && athleteInfo?.lastSession?.isBaseline) {
      if (baselineVisited) {
        // navigate(`/trainer/connects/nextweek?trainer=${trainerID}&mboClientId=${clientId}&mboSiteId=${siteId}`, {
        //   replace: true,
        // });
        navigate(`/trainer/connects/schedule?trainer=${trainerID}&mboClientId=${clientId}&mboSiteId=${siteId}`, {
          replace: true,
        });
      } else {
        navigate(`/trainer/connects/baseline?trainer=${trainerID}&mboClientId=${clientId}&mboSiteId=${siteId}`, {
          replace: true,
        });
      }
    }
  }, [isLoading, athleteInfo, navigate, trainerID, baselineVisited, clientId, siteId]);

  return (
    <>
      <Helmet>
        <title>MyTOCA | Connects</title>
      </Helmet>

      {isLoading ? (
        <LoadingAnimationFullHeight />
      ) : athleteInfo ? (
        <div className="grow flex flex-col">
          <LogoBanner />
          <ProfileBar athleteInfo={athleteInfo} />

          {athleteInfo.lastSessionSummary ? (
            <>
              <div className="my-6">
                <PageHeading text="What We Did" />
              </div>
              <SessionRecap
                sessionSummary={athleteInfo.lastSessionSummary}
                lastSession={athleteInfo.lastSession}
                level={athleteInfo.currentLevel}
                sessionNumber={athleteInfo.lastSessionNumber} // TODO
              />
            </>
          ) : (
            <div className="grid place-content-center">
              <p className="text-sm text-grey-xdark text-center p-4">Error loading last session summary</p>
            </div>
          )}

          <div className="md:mx-auto md:max-w-[600px] md:w-full mt-8">
            <ButtonTray>
              {athleteInfo?.lastSession?.isBaseline ? (
                <Button
                  text="Previous"
                  color="secondary"
                  onClick={() =>
                    navigate(
                      `/trainer/connects/baseline?trainer=${trainerID}&mboClientId=${clientId}&mboSiteId=${siteId}&baselineVisited=true`
                    )
                  }
                />
              ) : null}
              <Button
                text="Next"
                color="secondary"
                variant="cta"
                // onClick={() =>
                //   navigate(
                //     `/trainer/connects/nextWeek?trainer=${trainerID}&mboClientId=${clientId}&mboSiteId=${siteId}`
                //   )
                // }
                onClick={() =>
                  navigate(
                    `/trainer/connects/schedule?trainer=${trainerID}&mboClientId=${clientId}&mboSiteId=${siteId}`
                  )
                }
              />
            </ButtonTray>
          </div>
        </div>
      ) : (
        <div className="grow flex flex-col">
          <p className="text-sm text-grey-xdark text-center p-4">unable to load athlete data</p>
        </div>
      )}
    </>
  );
};

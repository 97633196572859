import {memo} from 'react';
import {FkoPlayer} from '../../redux/slices/fkoFormData';
import {parseDateTime} from '../../utils/utils';
import styles from './ConfirmationCard.module.scss';
import {useGetSport} from '../../common/useGetSport';

type ConfirmationCardProps = {
  player: FkoPlayer;
};

export const ConfirmationCard = memo((props: ConfirmationCardProps) => {
  const {player} = props;
  const {sessionTypeName} = useGetSport();

  // couldn't make typescript happy
  if (!player.selectedSession) {
    return null;
  }
  const session = player.selectedSession;

  const parsedStart = parseDateTime(session.startDate);
  const parsedEnd = parseDateTime(session.endDate);
  const coach = `Coach ${session?.staff?.firstName} ${session?.staff?.lastName}`;

  return (
    <>
      <div className={styles.container}>
        <p className={styles.sessionText}>
          <span className={styles.green}>Confirmed: </span>
          {sessionTypeName}
        </p>
        <p className={styles.playerName}>
          {player.firstName} {player.lastName}
        </p>
        <div className={styles.dateSection}>
          <p>
            {parsedStart.dayStrShort}, {parsedStart.monthStr} {parsedStart.day}
          </p>
          <p>
            {parsedStart.time} - {parsedEnd.time}
          </p>
        </div>
        <p className={styles.coach}>{coach}</p>
      </div>
    </>
  );
});

ConfirmationCard.displayName = 'ConfirmationCard';

interface MarkerPopoverContentProps {
  name: string;
  address: string;
  logo: string;
  state: string;
}

/**
 * Content to be shown on market hover on the map.
 */
export const MarkerPopoverContent = ({name, address, logo, state}: MarkerPopoverContentProps) => {
  return (
    <div className="w-[320px] flex p-3 bg-white">
      <img src={logo} className="mr-3" width="72" height="72" alt="location-logo" />
      <div className="flex flex-col ml-2">
        <p className="text-lg text-primary font-medium leading-6">{`${name}${state && `, ${state}`}`}</p>
        {address && (
          <div className="mt-2 text-sm">
            <span>{address}</span>
          </div>
        )}
      </div>
    </div>
  );
};

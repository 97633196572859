import {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {UseFormRegister, UseFormSetValue} from 'react-hook-form';
import {FormInput} from '../../components/Forms/FormInput/FormInput';
import {useClubSearch} from 'hooks/useClubSearch';
import {RegistrationFormTypes} from './RegistrationLandingForm';
import {delay} from 'lodash';

type PlayerClub = {id: string; clubName: string};

export const CLUB_NAMES = [
  'A.C. River',
  'Achilles FC',
  'AFC Lightning',
  'ALBION SC Los Angeles',
  'ALBION SC Las Vegas',
  'ALBION SC Merced',
  'ALBION SC San Diego',
  'Alexandria SA',
  'Athletum FC Academy',
  'Atlanta United FC',
  'Atletico Santa Rosa',
  'Austin FC',
  'Ballistic United',
  'Baltimore Armour',
  'Barca Residency Academy',
  'Bavarian United SC',
  'Bayside FC',
  'Beachside Soccer Club Connecticut',
  'Beadling SC',
  'Bethesda SC',
  'Blau Weiss Gottschee',
  'Breakers FC',
  'Capital City SC',
  'Carolina Core FC',
  'Cedar Stars Academy - Bergen',
  'Cedar Stars Academy - Monmouth',
  'CF Montreal',
  'Chargers Soccer Club',
  'Charlotte FC',
  'Chicago FC United',
  'Chicago Fire FC',
  'Chula Vista FC',
  'Cincinnati United Premier Soccer Club',
  'City SC San Diego',
  'Colorado Rapids',
  'Colorado Rapids Youth SC',
  'Columbus Crew SC',
  'Connecticut United FC',
  'Dallas Hornets',
  'DC United',
  'De Anza Force',
  'Diablo Valley Wolves',
  'Downtown United Soccer Club',
  'FA Euro New York',
  'FC Bay Area Surf',
  'FC Cincinnati',
  'FC Dallas',
  'FC DELCO',
  'FC Golden State Force',
  'FC Greater Boston Bolts',
  'FC Westchester New York',
  'Florida Rush',
  'Global Football Innovation Academy',
  'Hoosier Premier',
  'Hoover-Vestavia Soccer',
  'Houston Dynamo',
  'Houston Rangers',
  'IDEA Toros Futbol Academy',
  'IdeaSport Soccer Academy',
  'IMG Academy',
  'Indy Eleven',
  'Inter Atlanta FC',
  'Inter Miami CF',
  'Intercontinental Football Academy of New England',
  'Ironbound Soccer Club',
  'Jacksonville FC',
  'Javanon FC',
  'Keystone FC',
  'KSA',
  'LA Galaxy',
  'Lamorinda SC',
  'Lanier Soccer Association',
  'Las Vegas Sports Academy',
  'Long Island Soccer Club',
  'Los Angeles Bulls Soccer Club',
  'Los Angeles Football Club',
  'Los Angeles Soccer Club',
  'Los Angeles Surf',
  'Lou Fusz Athletic',
  'Louisiana TDP Elite',
  'Metropolitan Oval',
  'Miami Futbol Academy Rush',
  'Michigan Jaguars',
  'Michigan Wolves',
  'Midwest United',
  'Minnesota United FC',
  'Modesto Ajax United',
  'Murrieta Soccer Academy',
  'Napa United',
  'Nashville SC',
  'Nashville United Soccer Academy',
  'NEFC',
  'New England Revolution',
  'New York City FC',
  'New York Red Bulls',
  'New York Soccer Club',
  'Nomads',
  'Oakwood Soccer Club',
  'Orlando City SC',
  'Orlando City Soccer School South',
  'Orlando City Youth SC',
  'PA Classics',
  'Philadelphia Union',
  'Phoenix Rising FC',
  'Players Development Academy',
  'Portland Timbers',
  'Queen City Mutiny FC',
  'Real Colorado',
  'Real Futbol Academy',
  'Real Salt Lake',
  'Rochester NY FC Academy',
  'RSL Arizona',
  'RSL Arizona Mesa',
  'Sacramento Republic',
  'Sacramento United',
  'San Francisco Glens',
  'San Francisco Seals',
  'San Jose Earthquakes',
  'Santa Barbara Soccer Club',
  'SC Del Sol',
  'SC Wave',
  'Seacoast United',
  'Seattle Sounders FC',
  'Shattuck-St. Mary`s',
  'Silicon Valley SA',
  'SoCal Reds FC',
  'Sockers FC',
  'South Florida Football Academy',
  'Southern States Soccer Club',
  'Southern Soccer Academy',
  'Sporting Athletic Club',
  'Sporting Kansas City',
  'Springfield SYC',
  'St. Louis City SC',
  'St. Louis Scott Gallagher',
  'Strikers FC',
  'Tampa Bay United',
  'Tormenta FC Academy',
  'Toronto FC Academy',
  'Total Futbol Academy',
  'Triangle United Soccer Association',
  'TSF Academy - NJ',
  'Valeo FC',
  'Vancouver Whitecaps FC',
  'Vardar Soccer Club',
  'Ventura County Fusion',
  'Wake FC',
  'West Florida Flames',
  'West Virginia Soccer',
  'Weston FC',
  'WSC Crush',
  'MLS NEXT',
];

export const PLAYER_CLUBS: PlayerClub[] = [
  {id: '1', clubName: 'A.C. River'},
  {id: '2', clubName: 'Achilles FC'},
  {id: '3', clubName: 'AFC Lightning'},
  {id: '4', clubName: 'ALBION SC Los Angeles'},
  {id: '5', clubName: 'ALBION SC Las Vegas'},
  {id: '6', clubName: 'ALBION SC Merced'},
  {id: '7', clubName: 'ALBION SC San Diego'},
  {id: '8', clubName: 'Alexandria SA'},
  {id: '9', clubName: 'Athletum FC Academy'},
  {id: '10', clubName: 'Atlanta United FC'},
  {id: '11', clubName: 'Atletico Santa Rosa'},
  {id: '12', clubName: 'Austin FC'},
  {id: '13', clubName: 'Ballistic United'},
  {id: '14', clubName: 'Baltimore Armour'},
  {id: '15', clubName: 'Barca Residency Academy'},
  {id: '16', clubName: 'Bavarian United SC'},
  {id: '17', clubName: 'Bayside FC'},
  {id: '18', clubName: 'Beachside Soccer Club Connecticut'},
  {id: '19', clubName: 'Beadling SC'},
  {id: '20', clubName: 'Bethesda SC'},
  {id: '21', clubName: 'Blau Weiss Gottschee'},
  {id: '22', clubName: 'Breakers FC'},
  {id: '23', clubName: 'Capital City SC'},
  {id: '24', clubName: 'Carolina Core FC'},
  {id: '25', clubName: 'Cedar Stars Academy - Bergen'},
  {id: '26', clubName: 'Cedar Stars Academy - Monmouth'},
  {id: '27', clubName: 'CF Montreal'},
  {id: '28', clubName: 'Chargers Soccer Club'},
  {id: '29', clubName: 'Charlotte FC'},
  {id: '30', clubName: 'Chicago FC United'},
  {id: '31', clubName: 'Chicago Fire FC'},
  {id: '32', clubName: 'Chula Vista FC'},
  {id: '33', clubName: 'Cincinnati United Premier Soccer Club'},
  {id: '34', clubName: 'City SC San Diego'},
  {id: '35', clubName: 'Colorado Rapids'},
  {id: '36', clubName: 'Colorado Rapids Youth SC'},
  {id: '37', clubName: 'Columbus Crew SC'},
  {id: '38', clubName: 'Connecticut United FC'},
  {id: '39', clubName: 'Dallas Hornets'},
  {id: '40', clubName: 'DC United'},
  {id: '41', clubName: 'De Anza Force'},
  {id: '42', clubName: 'Diablo Valley Wolves'},
  {id: '43', clubName: 'Downtown United Soccer Club'},
  {id: '44', clubName: 'FA Euro New York'},
  {id: '45', clubName: 'FC Bay Area Surf'},
  {id: '46', clubName: 'FC Cincinnati'},
  {id: '47', clubName: 'FC Dallas'},
  {id: '48', clubName: 'FC DELCO'},
  {id: '49', clubName: 'FC Golden State Force'},
  {id: '50', clubName: 'FC Greater Boston Bolts'},
  {id: '51', clubName: 'FC Westchester New York'},
  {id: '52', clubName: 'Florida Rush'},
  {id: '53', clubName: 'Global Football Innovation Academy'},
  {id: '54', clubName: 'Hoosier Premier'},
  {id: '55', clubName: 'Hoover-Vestavia Soccer'},
  {id: '56', clubName: 'Houston Dynamo'},
  {id: '57', clubName: 'Houston Rangers'},
  {id: '58', clubName: 'IDEA Toros Futbol Academy'},
  {id: '59', clubName: 'IdeaSport Soccer Academy'},
  {id: '60', clubName: 'IMG Academy'},
  {id: '61', clubName: 'Indy Eleven'},
  {id: '62', clubName: 'Inter Atlanta FC'},
  {id: '63', clubName: 'Inter Miami CF'},
  {id: '64', clubName: 'Intercontinental Football Academy of New England'},
  {id: '65', clubName: 'Ironbound Soccer Club'},
  {id: '66', clubName: 'Jacksonville FC'},
  {id: '67', clubName: 'Javanon FC'},
  {id: '68', clubName: 'Keystone FC'},
  {id: '69', clubName: 'KSA'},
  {id: '70', clubName: 'LA Galaxy'},
  {id: '71', clubName: 'Lamorinda SC'},
  {id: '72', clubName: 'Lanier Soccer Association'},
  {id: '73', clubName: 'Las Vegas Sports Academy'},
  {id: '74', clubName: 'Long Island Soccer Club'},
  {id: '75', clubName: 'Los Angeles Bulls Soccer Club'},
  {id: '76', clubName: 'Los Angeles Football Club'},
  {id: '77', clubName: 'Los Angeles Soccer Club'},
  {id: '78', clubName: 'Los Angeles Surf'},
  {id: '79', clubName: 'Lou Fusz Athletic'},
  {id: '80', clubName: 'Louisiana TDP Elite'},
  {id: '81', clubName: 'Metropolitan Oval'},
  {id: '82', clubName: 'Miami Futbol Academy Rush'},
  {id: '83', clubName: 'Michigan Jaguars'},
  {id: '84', clubName: 'Michigan Wolves'},
  {id: '85', clubName: 'Midwest United'},
  {id: '86', clubName: 'Minnesota United FC'},
  {id: '87', clubName: 'Modesto Ajax United'},
  {id: '88', clubName: 'Murrieta Soccer Academy'},
  {id: '89', clubName: 'Napa United'},
  {id: '90', clubName: 'Nashville SC'},
  {id: '91', clubName: 'Nashville United Soccer Academy'},
  {id: '92', clubName: 'NEFC'},
  {id: '93', clubName: 'New England Revolution'},
  {id: '94', clubName: 'New York City FC'},
  {id: '95', clubName: 'New York Red Bulls'},
  {id: '96', clubName: 'New York Soccer Club'},
  {id: '97', clubName: 'Nomads'},
  {id: '98', clubName: 'Oakwood Soccer Club'},
  {id: '99', clubName: 'Orlando City SC'},
  {id: '100', clubName: 'Orlando City Soccer School South'},
  {id: '101', clubName: 'Orlando City Youth SC'},
  {id: '102', clubName: 'PA Classics'},
  {id: '103', clubName: 'Philadelphia Union'},
  {id: '104', clubName: 'Phoenix Rising FC'},
  {id: '105', clubName: 'Players Development Academy'},
  {id: '106', clubName: 'Portland Timbers'},
  {id: '107', clubName: 'Queen City Mutiny FC'},
  {id: '108', clubName: 'Real Colorado'},
  {id: '109', clubName: 'Real Futbol Academy'},
  {id: '110', clubName: 'Real Salt Lake'},
  {id: '111', clubName: 'Rochester NY FC Academy'},
  {id: '112', clubName: 'RSL Arizona'},
  {id: '113', clubName: 'RSL Arizona Mesa'},
  {id: '114', clubName: 'Sacramento Republic'},
  {id: '115', clubName: 'Sacramento United'},
  {id: '116', clubName: 'San Francisco Glens'},
  {id: '117', clubName: 'San Francisco Seals'},
  {id: '118', clubName: 'San Jose Earthquakes'},
  {id: '119', clubName: 'Santa Barbara Soccer Club'},
  {id: '120', clubName: 'SC Del Sol'},
  {id: '121', clubName: 'SC Wave'},
  {id: '122', clubName: 'Seacoast United'},
  {id: '123', clubName: 'Seattle Sounders FC'},
  {id: '124', clubName: 'Shattuck-St. Mary`s'},
  {id: '125', clubName: 'Silicon Valley SA'},
  {id: '126', clubName: 'SoCal Reds FC'},
  {id: '127', clubName: 'Sockers FC'},
  {id: '128', clubName: 'South Florida Football Academy'},
  {id: '129', clubName: 'Southern States Soccer Club'},
  {id: '130', clubName: 'Southern Soccer Academy'},
  {id: '131', clubName: 'Sporting Athletic Club'},
  {id: '132', clubName: 'Sporting Kansas City'},
  {id: '133', clubName: 'Springfield SYC'},
  {id: '134', clubName: 'St. Louis City SC'},
  {id: '135', clubName: 'St. Louis Scott Gallagher'},
  {id: '136', clubName: 'Strikers FC'},
  {id: '137', clubName: 'Tampa Bay United'},
  {id: '138', clubName: 'Tormenta FC Academy'},
  {id: '139', clubName: 'Toronto FC Academy'},
  {id: '140', clubName: 'Total Futbol Academy'},
  {id: '141', clubName: 'Triangle United Soccer Association'},
  {id: '142', clubName: 'TSF Academy - NJ'},
  {id: '143', clubName: 'Valeo FC'},
  {id: '144', clubName: 'Vancouver Whitecaps FC'},
  {id: '145', clubName: 'Vardar Soccer Club'},
  {id: '146', clubName: 'Ventura County Fusion'},
  {id: '147', clubName: 'Wake FC'},
  {id: '148', clubName: 'West Florida Flames'},
  {id: '149', clubName: 'West Virginia Soccer'},
  {id: '150', clubName: 'Weston FC'},
  {id: '151', clubName: 'WSC Crush'},
  {id: '152', clubName: 'MLS NEXT'},
];

export const PlayerClubSearchableList = memo(
  ({
    errorMessage,
    hasError,
    register,
    setValue,
  }: {
    errorMessage?: string;
    hasError: boolean;
    register: UseFormRegister<RegistrationFormTypes>;
    setValue: UseFormSetValue<RegistrationFormTypes>;
  }) => {
    const clubList = PLAYER_CLUBS;
    const {updateQuery, indexResults} = useClubSearch({clubList});
    const [isListOpen, setIsListOpen] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>();
    const errorRef = useRef<number>();

    useEffect(() => {
      // there's an annoying delay before correct validation with this approach, and this is the easiest way to fix it for now
      clearTimeout(errorRef.current);
      if (hasError && !showError) {
        errorRef.current = delay(() => setShowError(hasError), 120);
      } else {
        setShowError(hasError); // false
      }
    }, [setShowError, hasError]);

    const filteredClubs = useMemo(() => {
      const clubArray = [];
      for (const i of indexResults) {
        try {
          clubArray.push(clubList[i]);
        } catch {
          //
        }
      }
      return clubArray;
    }, [clubList, indexResults]);

    const onSearchChange = useCallback(
      (e: React.FormEvent<HTMLInputElement>) => {
        updateQuery(e.currentTarget.value);
        if (e.currentTarget.value) {
          setIsListOpen(true);
        } else {
          setIsListOpen(false);
        }
      },
      [updateQuery]
    );

    const selectClub = useCallback(
      ({clubName}: {clubName: string}) => {
        setValue('club', clubName, {shouldValidate: true});
        setIsListOpen(false);
      },
      [setIsListOpen, setValue]
    );

    // TODO should prob make it so that field blur autoselects the best result. this wasn't working right though

    // const ensureValue = useCallback(
    //   (e: React.FormEvent<HTMLInputElement>) => {
    //     if (e.currentTarget.value && !CLUB_NAMES.includes(e.currentTarget.value)) {
    //       let _clubIndex = CLUB_NAMES.findIndex((name) => name.toLowerCase() === e.currentTarget.value?.toLowerCase());
    //       if (_clubIndex === -1 && indexResults.length) {
    //         _clubIndex = indexResults[0];
    //       }
    //       const clubName = clubList[_clubIndex]?.clubName ?? '';
    //       selectClub({clubName});
    //     }
    //     setIsListOpen(false);
    //   },
    //   [updateQuery]
    // );

    return (
      <div className="flex flex-col">
        <FormInput
          spellCheck="false"
          {...register('club')}
          type="text"
          id="club"
          placeholder="Enter your club name"
          hasError={showError}
          onChange={onSearchChange}
        />
        {showError && errorMessage ? (
          <p className="text-xs text-alert">{errorMessage}</p>
        ) : (
          <div className="h-[16px]" />
        )}

        {isListOpen && (
          <ul className="flex flex-col w-full max-h-[280px] mb-4 overflow-scroll rounded border border-primary/15 shadow-inner shadow-grey-dark/50">
            {filteredClubs?.length && indexResults?.length ? (
              filteredClubs.map((club) => (
                <li
                  key={club.id}
                  onClick={() => selectClub({clubName: club.clubName})}
                  className="flex px-5 py-2 border-b border-grey-light text-grey-xdark text-[15px] cursor-pointer"
                >
                  <p>{club.clubName}</p>
                </li>
              ))
            ) : (
              <li className="flex px-4 py-3 border-b border-grey-light text-grey-xdark text-[16px]">
                <p>No matching clubs found.</p>
              </li>
            )}
          </ul>
        )}
      </div>
    );
  }
);
PlayerClubSearchableList.displayName = 'PlayerClubSearchableList';

import {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import styles from './VerifyEmail.module.scss';
import {auth as fireBaseAuth} from '../../firebase/config';
import {sendEmailVerification} from 'firebase/auth';
import {getPostLoginRedirectUri, refreshAccessToken} from '../../user/user-utils';
import {useGetUser} from '../../api/User/useGetUser';
import {PageHeading} from 'components/PageHeading/PageHeading';
import {PageSubHeading} from 'components/PageSubHeading/PageSubHeading';
import {BookingFormButton} from 'components/Buttons/Form/FormButton';
import LoaderFullPage from '../LoaderFullPage/LoaderFullPage';

export const VerifyEmail = () => {
  const navigate = useNavigate();
  const {data: user} = useGetUser();
  const [showStillNotVerifiedMsg, setShowStillNotVerifiedMsg] = useState(false);
  const [showEmailSentMsg, setShowEmailSentMsg] = useState<string | null>(null);

  if (!user) {
    return <LoaderFullPage />;
  }

  const doSendEmailVerification = async () => {
    try {
      await sendEmailVerification(fireBaseAuth.currentUser);
      setShowEmailSentMsg('We have re-sent the email verification link.');
    } catch (err) {
      setShowEmailSentMsg(
        'We were unable to re-send the email verification link at this time. Please try again later.'
      );
    }
    setTimeout(() => setShowEmailSentMsg(null), 5000);
  };

  const verifiedEmailVerification = async () => {
    await fireBaseAuth.currentUser.reload();
    if (fireBaseAuth.currentUser.emailVerified) {
      await refreshAccessToken(); // refresh so that id token reflects verified email
      navigate(getPostLoginRedirectUri());
    } else {
      setShowStillNotVerifiedMsg(true);
      setTimeout(() => setShowStillNotVerifiedMsg(false), 5000);
    }
  };

  const email = user.account?.email ?? user.profiles?.[0]?.email;

  return (
    <>
      <PageHeading text="Verify your email address" backButton={false} />
      <PageSubHeading text="You’re almost there!" />

      <div className={styles.container}>
        <p className={styles.info}>
          We sent you an email to <span>{email}</span>. Just click on the link in that email to complete your email
          verification.
        </p>
        <p className={styles.info}>If you don’t see it, you may need to check your spam folder.</p>
        <div className={styles.actionResponse}>
          {showStillNotVerifiedMsg && (
            <p className={styles.error}>It looks like your email address is still not verified.</p>
          )}
          {showEmailSentMsg && <p className={styles.success}>{showEmailSentMsg}</p>}
        </div>
        <BookingFormButton
          isPrimary
          onClick={verifiedEmailVerification}
          text="I have verified my email"
          type="submit"
        />
        <BookingFormButton onClick={doSendEmailVerification} text="Resend verification link" type="submit" />

        <div className={styles.bottomContainer}>
          <p>
            Need help? <Link to="/helpdesk">Contact us</Link>
          </p>
          <p>
            <Link to="/logout">Sign out</Link>
          </p>
        </div>
      </div>
    </>
  );
};

import {Outlet} from 'react-router-dom';
import AuthContainer from '../components/AuthContainer/AuthContainer';
import AdminAuthContainer from '../components/AdminAuthContainer/AdminAuthContainer';

export default function RootAdminLayout() {
  return (
    <AuthContainer>
      <AdminAuthContainer>
        <Outlet />
      </AdminAuthContainer>
    </AuthContainer>
  );
}

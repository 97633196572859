import {ReactElement, useRef, useState, useCallback, useMemo, useEffect, memo} from 'react';
import {useHeightObserver} from '../../hooks/useHeightObserver';
import styles from './VirtualizedList.module.scss';

export type VirtualizedListProps = {
  items: ReactElement[];
  itemHeight: number;
  overscanItems?: number;
  precision?: number;
  startingItem?: number;
  scrollToItem?: number;
};

/**
 *  Creates a virtualized list from an array of JSX.Elements,
 *  and a desired height for each element.
 */
export const VirtualizedList = memo(
  ({
    items,
    itemHeight,
    overscanItems = 20,
    precision = 5,
    scrollToItem = -1,
    startingItem = 0,
  }: VirtualizedListProps) => {
    const listRef = useRef<HTMLDivElement>(null);
    const {observedHeight: listHeight} = useHeightObserver(listRef);
    const [scrollTop, setScrollTop] = useState(0);

    // when scrolling extremely quickly, the 'scrollTop' state update is
    // unreliable at triggering the final render. this timeout serves as a backup.
    const quickScrollTimer = useRef<NodeJS.Timeout>();
    if (quickScrollTimer.current) {
      clearTimeout(quickScrollTimer.current);
      quickScrollTimer.current = undefined;
    }

    useEffect(() => {
      return () => {
        if (quickScrollTimer.current) {
          clearTimeout(quickScrollTimer.current);
        }
      };
    }, []);

    const onScroll = useCallback((e: any) => {
      setScrollTop(e.currentTarget.scrollTop);
    }, []);

    useEffect(() => {
      if (listRef.current && startingItem > 0) {
        listRef.current.scrollTo({top: startingItem * itemHeight});
      }
      // the intention is to fire this effect only ONE time on mount
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (listRef.current && scrollToItem >= 0) {
        listRef.current.scrollTo({top: scrollToItem * itemHeight, behavior: 'smooth'});
      }
    }, [itemHeight, scrollToItem]);

    const sliceIndicies = useRef<{start: number; end: number}>({
      start: 0,
      end: 0,
    });

    const itemsLength = useMemo(() => items.length, [items]);
    const innerHeight = useMemo(() => itemsLength * itemHeight, [itemsLength, itemHeight]);

    /**
     * this will recalculate every scroll pixel, the return is read by useEffect below
     * these values can be exceed array length but this is handled later
     */
    const calculateIndicies = useMemo(() => {
      const startIndex = Math.floor(scrollTop / itemHeight) - overscanItems;
      const endIndex = Math.floor((scrollTop + listHeight) / itemHeight + overscanItems);

      return {startIndex, endIndex};
    }, [itemHeight, listHeight, overscanItems, scrollTop]);

    /**
     * this changes the 'sliceIndicies' ref at every increment of 'precision',
     * to reduce the frequency of recalculations in 'renderedItems()'
     */
    useEffect(() => {
      const {startIndex, endIndex} = calculateIndicies;

      if (
        startIndex <= sliceIndicies.current?.start - precision ||
        endIndex - precision >= sliceIndicies.current?.end
      ) {
        sliceIndicies.current.start = Math.max(0, startIndex);
        sliceIndicies.current.end = Math.min(itemsLength, endIndex);

        if (startIndex > 0 && endIndex < itemsLength) {
          quickScrollTimer.current = setTimeout(() => setScrollTop((prev) => prev + 1), 100);
        }
      }
    }, [calculateIndicies, itemsLength, precision]);

    /**
     * this returns an a subset of the initial 'items' with an added positional styling wrapper
     */
    const renderedItems = useMemo(
      () =>
        items.slice(sliceIndicies.current.start, sliceIndicies.current.end).map((item, i) => (
          <li
            key={i}
            style={{
              position: 'absolute',
              top: `${(i + sliceIndicies.current.start) * itemHeight}px`,
              width: '100%',
              height: `${itemHeight}`,
            }}
          >
            {item}
          </li>
        )),
      // while the ref changing won't trigger a re-render, we do want the memoized value to recalculate
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [itemHeight, items, sliceIndicies.current.start, sliceIndicies.current.end]
    );

    const listStyle: React.CSSProperties = useMemo(
      () => ({
        position: 'relative',
        height: `${innerHeight}px`,
        width: '100%',
      }),
      [innerHeight]
    );

    // forced rerender at the start to set the initial values from ref
    useEffect(() => {
      setScrollTop((prev) => prev - 1);
    }, []);

    return (
      <div ref={listRef} onScroll={onScroll} className={styles.container}>
        <MemoizedList listStyle={listStyle} renderedItems={renderedItems} />
      </div>
    );
  }
);
VirtualizedList.displayName = 'VirtualizedList';

const MemoizedList = memo(
  ({listStyle, renderedItems}: {listStyle: React.CSSProperties; renderedItems: JSX.Element[]}) => {
    return <ul style={listStyle}>{renderedItems}</ul>;
  }
);
MemoizedList.displayName = 'MemoizedList';

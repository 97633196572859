import {useMemo} from 'react';
import {Helmet} from 'react-helmet';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import {useGetPlayerDetails} from 'api/User/useGetPlayerDetails';
import {useGetPlayerSession} from 'api/User/useGetPlayerSession';
import {PlayerAssessmentConnectsBaselineSessionData} from 'components/Connects/PlayerAssessmentBaselineSessionData/PlayerAssessmentConnectsBaselineSessionData';
import {LogoBanner} from 'components/Connects/LogoBanner';
import {LoadingAnimationFullHeight} from 'components/Loader/LoadingAnimation';
import {PageHeading} from 'components/PageHeading/PageHeading';
import {ProfileBar} from 'components/ProfileBar/ProfileBar';
import {useProfiles} from 'api/User/useProfiles';

const PlayerAssessmentBaselineData = () => {
  const {sessionDate} = useParams();
  const {currentProfileTeam} = useProfiles();

  const {data: playerDetails, isLoading: isPlayerDetailsLoading} = useGetPlayerDetails();

  const sessionData = useMemo(
    () =>
      playerDetails?.baselineSessions?.find(
        (session) =>
          moment(session.startTime).startOf('day').toDate().toString() ===
          moment(sessionDate).startOf('day').toDate().toString()
      ),
    [playerDetails, sessionDate]
  );

  const {data: playerAssessmentSummary, isFetching: isPlayerAssessmentSummaryFetching} = useGetPlayerSession({
    clientId: sessionData?.mboPlayer?.mboClientId,
    sessionId: sessionData?.clientSessionSummaryId,
    siteId: sessionData?.siteId,
  });

  const isSessionSelectionCompleted = useMemo(
    () =>
      playerAssessmentSummary?.exerciseSummary?.find((exercise) => {
        return 'confirmedBy' in exercise;
      }),
    [playerAssessmentSummary]
  );

  const startDate = sessionData?.startTime ? moment(sessionData.startTime).format('MMM DD, YYYY') : undefined;
  return (
    <>
      <Helmet>
        <title>MyTOCA | Player Assessment Data</title>
      </Helmet>

      {isPlayerDetailsLoading || isPlayerAssessmentSummaryFetching ? (
        <LoadingAnimationFullHeight />
      ) : playerDetails ? (
        <div className="grow flex flex-col">
          <div>
            <LogoBanner />
            <ProfileBar playerDetails={playerDetails} teamColor={currentProfileTeam?.teamColor} />

            {sessionData && playerAssessmentSummary ? (
              <div className="exceed-global-padding">
                <div className="text-center">
                  <PageHeading text="Player Assessment Data" />
                </div>
                {playerAssessmentSummary?.isBaseline &&
                isSessionSelectionCompleted &&
                sessionData.isAssessmentSession ? (
                  <>
                    <div className="exceed-global-padding lg:max-w-[800px] lg:mx-auto lg:w-full mb-8">
                      <PlayerAssessmentConnectsBaselineSessionData
                        sessionSummary={playerAssessmentSummary}
                        enableSessionSelection={false}
                        date={startDate}
                      />
                    </div>
                  </>
                ) : (
                  <div className="grid place-content-center">
                    <p className="text-sm text-grey-xdark">Player assessment data not found</p>
                  </div>
                )}
              </div>
            ) : (
              <div className="grid place-content-center">
                <p className="text-sm text-grey-xdark">Unable to load player assessment data</p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="grow grid place-content-center m-8">
          <p className="text-sm text-grey-xdark text-center p-4">Unable to load player details</p>
        </div>
      )}
    </>
  );
};

export default PlayerAssessmentBaselineData;

import {memo} from 'react';

export interface FormButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isPrimary?: boolean;
  hasError?: boolean;
}

export const FormButton = memo(({children, isPrimary, hasError, ...props}: FormButtonProps) => {
  const primaryClassNames = 'bg-fko-button-primary border border-fko-button-primary text-fko-white';
  const errorClassNames = 'bg-grey-xxdark border border-grey-xxdark text-fko-white cursor-not-allowed';
  const defaultClassNames = 'bg-white border border-grey text-black';
  const classNames = hasError ? errorClassNames : isPrimary ? primaryClassNames : defaultClassNames;

  return (
    <>
      <button
        className={`flex items-center justify-center w-full h-[48px] rounded-[12px] font-helvetica font-medium text-xs text-center uppercase transition-all ${classNames}`}
        {...props}
      >
        {children}
      </button>
    </>
  );
});
FormButton.displayName = 'FormButton';

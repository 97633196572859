import {useEffect, useState} from 'react';
import {auth, setAnalyticsUserId} from './config';
import {setAccessToken, setCurrentAccountId} from '../user/user-utils';

export const useAuthStatus = (): any => {
  // assume user is logged out initially
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [checkingStatus, setCheckingStatus] = useState<boolean>(true);

  useEffect(() => {
    // auth listener to keep track of user signing in and out
    const unsubscribe = auth.onAuthStateChanged((user: any) => {
      if (user) {
        setAccessToken(user.accessToken);
        setCurrentAccountId(user.uid);
        setAnalyticsUserId(user.uid);
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
      setCheckingStatus(false);
    });

    return () => unsubscribe();
  }, []);

  return {loggedIn, checkingStatus};
};

import * as Sentry from '@sentry/react';
import type {BrowserOptions} from '@sentry/browser/build/npm/types/client';

export function initSentry() {
  const enabled = process.env.REACT_APP_SENTRY_ENABLED === 'true';
  const dsn = process.env.REACT_APP_SENTRY_DSN;

  if (!enabled || !dsn) {
    return;
  }

  const environment = process.env.REACT_APP_SENTRY_ENVIRONMENT || undefined;
  const tracingEnabled = process.env.REACT_APP_SENTRY_ENABLE_TRACING === 'true';
  const sessionReplayEnabled = process.env.REACT_APP_SENTRY_ENABLE_SESSION_REPLAY === 'true';
  const integrations: BrowserOptions['integrations'] = [];
  // Note: If providing a release name, we also need to pass --release=<release_name> to the Sentry CLI when
  // uploading sourcemaps.
  // const release = (isDevelopmentEnvironment ? 'dev' : 'prod') + '-latest';

  const config: BrowserOptions = {
    dsn,
    integrations,
    environment,
    // release,
  };

  if (tracingEnabled) {
    const apiUrl = process.env.REACT_APP_API_URL;
    integrations.push(Sentry.browserTracingIntegration());
    Object.assign(config, {
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [apiUrl],
    });
  }

  if (sessionReplayEnabled) {
    integrations.push(Sentry.replayIntegration());
    Object.assign(config, {
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }

  Sentry.init(config);
}

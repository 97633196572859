import {useSportContext} from 'context/SportContext';
import {
  dbatPermittedLocationIds,
  fkoPermittedLocationIds,
  freeTrialClassesPermittedLocationIds,
  playerAssessmentPermittedLocationIds,
} from 'feature-flags/fko-locations';
import {useMemo} from 'react';
import {useParams} from 'react-router-dom';

export type SportName = 'dbat' | 'playerAssessment' | 'classes' | 'freeTrialClasses' | 'toca';

export interface SportInfo {
  sport: SportName;
  sessionTypeName: string;
  isDbat: boolean;
  isPlayerAssessment: boolean;
  isClasses: boolean;
  isFreeTrialClasses: boolean;
  basePath: string;
  basePathWithoutSessionId: string;
  fkoMethod: string; // Needs to match expected HubSpot value
  fkoAnalyticsPrefix: string;
  sportPermittedLocations: string[];
}

export const useGetSport = (): SportInfo => {
  const {sport} = useSportContext();
  const {sessionId} = useParams();

  const sportInfo: SportInfo = useMemo(() => {
    if (sport === 'DBAT') {
      return {
        sport: 'dbat',
        sessionTypeName: 'Free Evaluation',
        isDbat: true,
        isPlayerAssessment: false,
        isClasses: false,
        isFreeTrialClasses: false,
        basePath: sessionId ? `/${sessionId}/dbat-fps` : '/dbat-fps',
        basePathWithoutSessionId: '/dbat-fps',
        fkoMethod: 'player_portal_d-bat_promotional_lesson_registration',
        fkoAnalyticsPrefix: 'dbat_fko',
        sportPermittedLocations: dbatPermittedLocationIds,
      };
    }

    if (sport === 'PLAYER_ASSESSMENT') {
      return {
        sport: 'playerAssessment',
        sessionTypeName: 'Player Assessment',
        isDbat: false,
        isPlayerAssessment: true,
        isClasses: false,
        isFreeTrialClasses: false,
        basePath: sessionId ? `/${sessionId}/player-assessment` : '/player-assessment',
        basePathWithoutSessionId: '/player-assessment',
        fkoMethod: 'player_portal_player_assessment_registration',
        fkoAnalyticsPrefix: 'player_assessment_fko',
        sportPermittedLocations: playerAssessmentPermittedLocationIds,
      };
    }

    if (sport === 'FREE_TRIAL_CLASS') {
      return {
        sport: 'freeTrialClasses',
        sessionTypeName: 'Free Trial Class',
        isDbat: false,
        isPlayerAssessment: false,
        isClasses: false,
        isFreeTrialClasses: true,
        basePath: sessionId ? `/${sessionId}/ftc` : '/ftc',
        basePathWithoutSessionId: '/ftc',
        fkoMethod: 'player_portal_free_toca_class_registration',
        fkoAnalyticsPrefix: 'free_trial_classes',
        sportPermittedLocations: freeTrialClassesPermittedLocationIds,
      };
    }

    // TODO CLASSES we probably need to support both free trial and regular classes at some point
    // if (sport === 'CLASSES') {
    //   return {
    //     sport: 'classes',
    //     sessionTypeName: 'Free Trial Class', // TODO
    //     isDbat: false,
    //     isPlayerAssessment: false,
    //     isClasses: true,
    //     isFreeTrialClasses: false,
    //     basePath: sessionId ? `/${sessionId}/classes` : '/classes',
    //     basePathWithoutSessionId: '/classes',
    //     fkoMethod: 'player_portal_free_toca_class_registration', // TODO
    //     fkoAnalyticsPrefix: 'free_trial_classes', // TODO
    //     sportPermittedLocations: freeTrialClassesPermittedLocationIds, // TODO
    //   };
    // }

    return {
      sport: 'toca',
      sessionTypeName: 'Free Baseline Session',
      isDbat: false,
      isPlayerAssessment: false,
      isClasses: false,
      isFreeTrialClasses: false,
      basePath: sessionId ? `/${sessionId}/fko` : '/fko',
      basePathWithoutSessionId: '/fko',
      fkoMethod: 'player_portal_fko_registration',
      fkoAnalyticsPrefix: 'fko',
      sportPermittedLocations: fkoPermittedLocationIds,
    };
  }, [sessionId, sport]);

  return sportInfo;
};

import {useQuery} from '@tanstack/react-query';
import {getTeamCoaches} from 'api/api';
import {Profile} from 'user/player-info.interface';

export const useGetTeamCoaches = (teamId: string) => {
  return useQuery<Profile[]>({
    queryKey: ['team-coaches', teamId],
    queryFn: () => getTeamCoaches(teamId),
    enabled: !!teamId,
  });
};

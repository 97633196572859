import React from 'react';
import styles from './Toggle.module.scss';
import {ReactComponent as ToggleCheckedIcon} from '../../assets/img/icons/toggle-check.svg';
import {ReactComponent as ToggleUnCheckedIcon} from '../../assets/img/icons/toggle-unchecked.svg';

interface ToggleProps {
  isOn?: boolean;
  onToggle?: (state: boolean) => void;
  id?: string;
  name?: string;
  isDisabled?: boolean;
}
const Toggle = ({isOn = false, onToggle, id, name, isDisabled = false}: ToggleProps) => {
  const handleClick = () => {
    if (isDisabled) {
      return;
    }
    const newState = !isOn;
    if (onToggle) {
      onToggle(newState);
    }
  };

  return (
    <div className={styles.toggleWrapper}>
      <input
        type="checkbox"
        id={id}
        name={name}
        checked={isOn}
        onChange={handleClick}
        className={styles.toggleInput}
        disabled={isDisabled}
      />
      <div
        className={`${styles.toggle} ${isOn ? styles.on : styles.off} ${isDisabled && styles.disabled}`}
        onClick={handleClick}
      >
        <div className={styles.toggleSwitch}>{isOn ? <ToggleCheckedIcon /> : <ToggleUnCheckedIcon />}</div>
      </div>
    </div>
  );
};

export default Toggle;

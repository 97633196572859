import {isEqual} from 'lodash';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {useProfiles} from '../../api/User/useProfiles';
import {useAppDispatch, useAppSelector} from '../../redux/reduxUtils';
import {
  setDesiredDates,
  setPlayerPreferences,
  initializeBookingCreation,
  resetBookingSessions,
} from '../../redux/slices/booking';
import {setFkoAppointmentDates} from '../../redux/slices/fkoFormData';
import {
  createPlaceholderSessionFromPreferences,
  currentDatePlusDays,
  getNextWeekDay,
  getWeeklyDatesInclusive,
} from '../../utils/utils';

const appointmentsStartDate = currentDatePlusDays(1).toISOString();
const appointmentDaysAhead = 7 * 16; // 16 weeks

export const useInitializeBookings = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {currentProfile} = useProfiles();
  const {initializedSiteId} = useAppSelector((state) => state.bookingReducer.bookingCreation);
  const {appointmentCalendar} = useAppSelector((state) => state.fkoFormDataReducer);

  type overrideParams = {
    startDate?: string;
    weeks?: number;
  };

  const initializeBookings = useCallback(
    (override?: overrideParams) => {
      const mboSiteId = currentProfile.location?.siteId;

      if (!mboSiteId) {
        navigate('/home');
        console.error('Missing MBO site ID. Could not be read via "currentProfile.location.siteId".');
      }

      if (isEqual(appointmentCalendar, {}) || initializedSiteId !== mboSiteId) {
        dispatch(setFkoAppointmentDates({daysAhead: appointmentDaysAhead, startDate: appointmentsStartDate}));
        // dispatch(setAppointmentDates({daysAhead: appointmentDaysAhead, startDate: appointmentsStartDate}));
      }

      const hasCompleteBookingPreferences = Boolean(
        currentProfile.preferences?.booking &&
          currentProfile.preferences.booking.day &&
          currentProfile.preferences.booking.weeks &&
          currentProfile.preferences.booking.startTime &&
          currentProfile.preferences.booking.endTime &&
          currentProfile.preferences.booking.sessionType?.name &&
          currentProfile.preferences.booking.sessionType?.id
      );

      dispatch(
        initializeBookingCreation({
          initializedSiteId: mboSiteId,
          profileId: currentProfile._id,
          usingPreferences: hasCompleteBookingPreferences,
        })
      );

      // If we have all required booking preferences, generate suggested sessions and go to bookings review
      if (hasCompleteBookingPreferences && currentProfile.preferences?.booking) {
        const placeholderSession = createPlaceholderSessionFromPreferences({
          preferences: currentProfile.preferences.booking,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          location: currentProfile.location!,
          startDate: override?.startDate,
        });
        const {day, weeks} = currentProfile.preferences.booking;
        const start = override?.startDate
          ? getNextWeekDay({startDate: override.startDate, day})
          : getNextWeekDay({day});

        dispatch(resetBookingSessions());
        dispatch(setDesiredDates(getWeeklyDatesInclusive({startDate: start, numWeeks: override?.weeks ?? weeks})));
        dispatch(setPlayerPreferences({preferredSession: placeholderSession}));
        navigate('/bookings/review');
      }
    },
    [appointmentCalendar, initializedSiteId, currentProfile, dispatch, navigate]
  );

  return {initializeBookings};
};

import {Outlet} from 'react-router-dom';
import {Navbar} from '../components/Navbar/Navbar';
import {PageContainer} from 'components/PageContainer/RevisedPageContainer';
import {LogoBanner} from 'components/Connects/LogoBanner';
import {TeamProfile} from 'components/TeamProfile/TeamProfile';
import Spinner from 'components/Loader/Spinner';
import {Team} from 'user/user.interface';

export default function CoachDirectorNavbarLayoutView({
  teamInfo,
  loading,
  showTeamProfileBar,
  role,
}: {
  teamInfo: Team | undefined;
  loading: boolean;
  showTeamProfileBar: boolean;
  role: string;
}) {
  return (
    <PageContainer>
      {loading ? (
        <div className="w-full h-screen flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          {!showTeamProfileBar ? (
            <>
              <LogoBanner />
              <TeamProfile teamInfo={teamInfo} role={role} />
            </>
          ) : null}

          <Outlet />
          <Navbar />
        </>
      )}
    </PageContainer>
  );
}

import {useState} from 'react';
import styles from './SignUp.module.scss';
import {Link, useNavigate} from 'react-router-dom';
import {auth as fireBaseAuth} from '../../firebase/config';
import {setAccessToken, setCurrentAccountId} from '../../user/user-utils';
import {createUserWithEmailAndPassword, sendEmailVerification} from 'firebase/auth';
import {useSetGlobalLoading} from '../../hooks/useGlobalLoading';
import {Helmet} from 'react-helmet';
import {SignUpForm} from './SignUpForm';
import {ReactComponent as MyTocaLogo} from '../../assets/img/icons/redesign-icons/mytoca-logo.svg';
import {PageHeading} from '../../components/PageHeading/PageHeading';
import {createInitialProfile} from '../../api/api';
import {DateTime} from 'luxon';

export const SignUp = () => {
  const {setGlobalLoading} = useSetGlobalLoading();
  const navigate = useNavigate();
  const [errMes, setErrMes] = useState<string>('');

  const onSubmitHandler = async (data: any) => {
    setGlobalLoading(true);

    try {
      const {user} = await createUserWithEmailAndPassword(fireBaseAuth, data.email, data.password);
      setCurrentAccountId(user.uid);
      setAccessToken((await user.getIdTokenResult()).token);
      if (!fireBaseAuth.currentUser.emailVerified) {
        await sendEmailVerification(fireBaseAuth.currentUser);
      }
      const authUserNameParts = user.displayName?.split(' ') ?? [];
      await createInitialProfile({
        email: data.email ?? user.email,
        firstName: data.firstName ?? (authUserNameParts.length ? authUserNameParts[0] : undefined),
        lastName: data.lastName ?? (authUserNameParts.length > 1 ? authUserNameParts[1] : undefined),
        dob: data.dob ? DateTime.fromJSDate(data.dob).toFormat('MM/dd/yyyy') : undefined,
      });
      setGlobalLoading(false);
      navigate(user.emailVerified ? '/home' : '/verify-email');
    } catch (error: any) {
      let errorMsg = 'Sign up failed';
      if (error.code === 'auth/email-already-in-use') {
        errorMsg = 'This email address is already in use';
      }
      setErrMes(errorMsg);
      setGlobalLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>MyTOCA | Sign Up</title>
      </Helmet>

      <div className={styles.container}>
        <div className={styles.logo}>
          <Link to={'/auth'}>
            <MyTocaLogo />
          </Link>
        </div>
        <PageHeading text="Create an Account" backDestination="/auth" />

        <SignUpForm errorMessage={errMes} onSubmitHandler={onSubmitHandler} />
      </div>
    </>
  );
};

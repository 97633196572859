import {memo, useMemo} from 'react';
import styles from './StandardButton.module.scss';

export type ButtonVariant = 'primary' | 'light' | 'dark' | 'ghost' | 'warning' | 'danger';
export type StandardButtonVariant = 'primary' | 'light' | 'alert' | 'danger';

export interface StandardButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  variant?: StandardButtonVariant;
}

export const StandardButton = memo((props: StandardButtonProps) => {
  const {variant = 'light', text, ...rest} = props;

  const buttonClasses = useMemo(() => `${styles[variant]} ${styles.container}`, [variant]);

  return (
    <button className={buttonClasses} {...rest}>
      <span>{text}</span>
    </button>
  );
});
StandardButton.displayName = 'StandardButton';

import {Outlet} from 'react-router-dom';
import {Navbar} from '../components/Navbar/Navbar';
import {PageContainer} from 'components/PageContainer/RevisedPageContainer';

export default function NavbarLayout() {
  return (
    <PageContainer>
      {/* ensures Navbar (w/ position:sticky) is always at screen bottom*/}
      <div className="flex flex-col min-h-[100dvh]">
        <Outlet />
      </div>
      <Navbar />
    </PageContainer>
  );
}

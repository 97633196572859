import {memo} from 'react';
import styles from './FormDivider.module.scss';

/**
 * @deprecated this remains as an FKO component, use FormDividerRevised instead
 */
export const FormDivider = memo(({text}: {text: string}) => {
  return (
    <div className={styles.container}>
      <span className={styles.dividerLine} />
      <p className={styles.dividerText}>{text}</p>
      <span className={styles.dividerLine} />
    </div>
  );
});
FormDivider.displayName = 'FormDivider';

import {useState, useCallback} from 'react';
import {AutocompleteSearch, Option} from 'components/AutocompleteSearch/AutocompleteSearch';
import {LocationOption, transformGeocodingResponseToOptions} from 'pages/CenterDiscovery/Utils/dataTransformer';
import mapLogo from 'assets/img/icons/redesign-icons/map.svg';

interface LocationInputProps {
  getUserNearestLocations: (lat: number, lon: number) => void;
  showMapInMobileView?: boolean;
  onClickToggleMapInMobileView?: () => void;
}

/**
 * LocationInput component for searching and selecting locations.
 */
export const LocationInput = ({
  getUserNearestLocations,
  showMapInMobileView,
  onClickToggleMapInMobileView,
}: LocationInputProps) => {
  const [options, setOptions] = useState<LocationOption[]>([]);

  /**
   * Fetches location options from the geocoding API based on the search input.
   */
  const searchGeocodingAPIForLocations = useCallback(
    async (search: string) => {
      try {
        const response = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(search)}.json?access_token=${
            process.env.REACT_APP_MAP_BOX_ACCESS_KEY
          }`
        );
        const data = await response.json();
        const locationOptions = transformGeocodingResponseToOptions(data);
        setOptions(locationOptions);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    },
    [setOptions]
  );

  /**
   * Handles the selection of an option and gets the nearest locations based on the selected option.
   */
  const onOptionSelectionHandler = useCallback(
    (option: Option) => {
      const coordinates = JSON.parse(option.value);
      getUserNearestLocations(coordinates.lat, coordinates.lon);
    },
    [getUserNearestLocations]
  );

  /**
   * Gets the user's current location using the Geolocation API and gets the nearest locations based on the user's location.
   */
  const onGetUserLocationFromGeoLocationAPI = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log('User location:', position.coords);
          const {latitude, longitude} = position.coords;
          getUserNearestLocations(latitude, longitude);
        },
        (error) => {
          console.error('Error getting user location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, [getUserNearestLocations]);

  return (
    <div className="pl-14 pr-14 pt-8 pb-8">
      <p className="text-2xl text-primary font-bold mb-6">FIND YOUR TOCA CENTER</p>
      <AutocompleteSearch
        options={options}
        onSearchInputChange={searchGeocodingAPIForLocations}
        onOptionSelectionHandler={onOptionSelectionHandler}
      />
      <div className="flex justify-between mt-4 ml-2 mr-2">
        <p style={{display: 'none'}} className="text-secondary text-xs cursor-pointer underline" onClick={onGetUserLocationFromGeoLocationAPI}>
          Use current location
        </p>
        <div
          className="lg:hidden text-secondary text-xs cursor-pointer underline flex"
          onClick={onClickToggleMapInMobileView}
        >
          <img className="mr-1" src={mapLogo} alt="map logo" />
          <span>{showMapInMobileView ? 'Close map' : 'View map'}</span>
        </div>
      </div>
    </div>
  );
};

import {memo} from 'react';
import {useProfiles} from '../../../api/User/useProfiles';
import styles from './BookingInfo.module.scss';

export const BookingInfo = memo(() => {
  const {currentProfile: profile} = useProfiles();

  return (
    <div className={styles.container}>
      <p>
        Location:
        <span>{profile?.location?.name}</span>
      </p>
      <p>
        Player:
        <span>
          {profile.firstName} {profile.lastName}
        </span>
      </p>
    </div>
  );
});
BookingInfo.displayName = 'BookingInfo';

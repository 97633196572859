import styles from './FkoPlayersList.module.scss';
import {ReactNode, memo, useCallback, useMemo, useState} from 'react';
import {useAppSelector} from '../../redux/reduxUtils';
import {FkoPlayer} from '../../redux/slices/fkoFormData';
import {PlayerCard} from './PlayerCard';
import {AddPlayerModal} from 'components/FKO/PlayersListModals/FkoPlayersListModals';
import {ReactComponent as PlusIcon} from '../../assets/img/icons/plus.svg';
import {FKOModal} from 'components/FKO/FKOModal/FKOModal';
import {FormSectionHeading} from 'components/Forms/FormSectionHeading/FormSectionHeading';

export type FkoPlayersModalState =
  | {
      player?: FkoPlayer | undefined;
      children: ReactNode;
      heading?: string | undefined;
      subHeading?: string | undefined;
    }
  | undefined;

export const FkoPlayersList = memo(() => {
  const {playersArray, siteId} = useAppSelector((state) => state.fkoFormDataReducer);
  const [modalState, setModalState] = useState<FkoPlayersModalState>();

  // eligible players first
  const sortedPlayersArray = useMemo(
    () => [...playersArray].sort((a, b) => (a.eligibility?.isEligible ? (b.eligibility?.isEligible ? 0 : -1) : 1)),
    [playersArray]
  );

  const closeModal = useCallback(() => {
    setModalState(undefined);
  }, [setModalState]);

  const openAddPlayerModal = useCallback(() => {
    setModalState({
      player: undefined,
      children: <AddPlayerModal closeModal={closeModal} siteId={siteId} />,
      heading: `Add New Player`,
      subHeading: `We just need a few details first`,
    });
  }, [closeModal, setModalState]);

  return (
    <>
      <FKOModal
        isOpen={!!modalState?.children}
        closeModal={closeModal}
        heading={modalState?.heading}
        subHeading={modalState?.subHeading}
        showFullScreen
        fixedMaxWidth
      >
        {modalState?.children}
      </FKOModal>

      <div className={`grow flex flex-col w-full`}>
        <FormSectionHeading text="your players" />

        <div className="flex-[0]">
          <ul>
            {sortedPlayersArray.map((player, i) => (
              <li key={`${player.firstName}${i}`}>
                <PlayerCard
                  player={player}
                  isConfirmed={player.appointmentConfirmed}
                  scheduleError={!!player.error}
                  setModalState={setModalState}
                />
              </li>
            ))}
          </ul>

          <button className={styles.addPlayerButton} onClick={openAddPlayerModal}>
            <div className={styles.buttonFlex}>
              <div className={styles.plusIcon}>
                <PlusIcon />
              </div>
              <span className="uppercase">Add Another Player</span>
            </div>
          </button>
        </div>
      </div>
    </>
  );
});
FkoPlayersList.displayName = 'FkoPlayersList';

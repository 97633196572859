import {forwardRef} from 'react';
import styles from './RadioButton.module.scss';

export interface RadioButtonProps extends React.HTMLProps<HTMLInputElement> {
  id: string;
}
export const RadioWrapper = forwardRef<HTMLInputElement, RadioButtonProps>((props, ref) => {
  const {id, children, ...rest} = props;

  return (
    <label
      htmlFor={id}
      className={`grow flex items-center gap-4 ${props.disabled ? 'cursor-default' : 'cursor-pointer'}`}
    >
      <input ref={ref} className={styles.radioInput} id={id} type="radio" {...rest} />
      <div className={styles.radioDisplay} />
      {children}
    </label>
  );
});
RadioWrapper.displayName = 'RadioWrapper';

import mapPin from 'assets/img/icons/redesign-icons/map-pin.svg';
import {MarkerPopoverContent} from '../MarkerPopverContent/MarkerPopoverContent';
import {Popover, ArrowContainer} from 'react-tiny-popover';

interface LocationMarkerProps {
  number: number;
  name: string;
  address: string;
  logo: string;
  state: string;
  onClickLocationMarkerHandler: (index: number) => void;
  open: boolean;
}

/**
 * Renders a location marker with a number inside.
 */
export const LocationMarker = ({
  number,
  name,
  address,
  logo,
  state,
  open,
  onClickLocationMarkerHandler,
}: LocationMarkerProps) => {
  return (
    <Popover
      isOpen={open}
      positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority
      content={({position, childRect, popoverRect}) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'white'}
          arrowSize={10}
        >
          <MarkerPopoverContent name={name} address={address} logo={logo} state={state} />
        </ArrowContainer>
      )}
    >
      <div className="relative flex justify-center" onClick={() => onClickLocationMarkerHandler(number - 1)}>
        <img src={mapPin} alt="TOCA Logo" />
        <div className="absolute text-black bg-white w-5 h-5 rounded-full flex items-center justify-center mt-4">
          {number}
        </div>
      </div>
    </Popover>
  );
};

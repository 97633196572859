type PathwayName = {
  name: string;
  description: string;
};

export type PathwayNames = {
  [key: string]: PathwayName;
};

export const pathwayNames: PathwayNames = {
  //beginner
  'BEG INTENSIVE 1': {
    name: 'Beginner Intensive 1',
    description:
      'This session focused on simple movement patterns, basic receiving techniques, increasing passing accuracy, improving 1st time finishing, and introducing the player to the decision-making process.',
  },
  'BEG INTENSIVE 2': {
    name: 'Beginner Intensive 2',
    description:
      'This session focused on simple movement patterns, basic receiving techniques, increasing passing accuracy, improving 1st time finishing, and introducing the player to the decision-making process.',
  },
  'BEG INTENSIVE 3': {
    name: 'Beginner Intensive 3',
    description:
      'This session focused on simple movement patterns, basic receiving techniques, increasing passing accuracy, improving 1st time finishing, and elevating the decision-making process.',
  },
  'BEG INTENSIVE 4': {
    name: 'Beginner Intensive 4',
    description:
      'This session focused on simple movement patterns, basic receiving techniques, increasing passing accuracy, improving 1st time finishing, and elevating the decision-making process.',
  },
  'BEG INTENSIVE 5': {
    name: 'Beginner Intensive 5',
    description:
      'This session focused on movement patterns to create space, receiving to turn techniques, increasing passing accuracy, improving multi touch finishing, and enhancing the decision-making process.',
  },
  'BEG INTENSIVE 6': {
    name: 'Beginner Intensive 6',
    description:
      'This session focused on movement patterns to create space, receiving to turn techniques, increasing passing accuracy, improving multi touch finishing, and enhancing the decision-making process.',
  },
  'BEG INTENSIVE 7': {
    name: 'Beginner Intensive 7',
    description:
      'This session focused on movement patterns to create space, receiving to turn techniques, increasing passing accuracy, improving multi touch finishing, and enhancing the decision-making process.',
  },
  'BEG INTENSIVE 8': {
    name: 'Beginner Intensive 8',
    description:
      'This session focused on movement patterns to create space, receiving to turn techniques, increasing passing accuracy, improving multi touch finishing, and enhancing the decision-making process.',
  },
  //intermediate
  'INT INTENSIVE 1': {
    name: 'Intermediate Intensive 1',
    description:
      'This session focused on creating space, enhancing receiving techniques, increasing passing accuracy, improving multi touch finishing, and elevating decision-making skills.',
  },
  'INT INTENSIVE 2': {
    name: 'Intermediate Intensive 2',
    description:
      'This session focused on creating space, enhancing receiving techniques, increasing passing accuracy, improving multi touch finishing, and elevating decision-making skills.',
  },
  'INT INTENSIVE 3': {
    name: 'Intermediate Intensive 3',
    description:
      'This session focused on creating space, enhancing receiving out of the air (driven) techniques, increasing passing accuracy, improving 1st time half volley finishing, and elevating your decision-making skills.',
  },
  'INT INTENSIVE 4': {
    name: 'Intermediate Intensive 4',
    description:
      'This session focused on creating space, enhancing receiving out of the air (driven) techniques, increasing passing accuracy, improving 1st time half volley finishing, and elevating your decision-making skills.',
  },
  'INT INTENSIVE 5': {
    name: 'Intermediate Intensive 5',
    description:
      'This session focused on creating space, enhancing receiving out of the air (lob) techniques, increasing passing accuracy, improving multi touch finishing from varying heights, and elevating your decision-making skills.',
  },
  'INT INTENSIVE 6': {
    name: 'Intermediate Intensive 6',
    description:
      'This session focused on creating space, enhancing receiving out of the air (lob) techniques, increasing passing accuracy, improving multi touch finishing from varying heights, and elevating your decision-making skills.',
  },
  'INT INTENSIVE 7': {
    name: 'Intermediate Intensive 7',
    description:
      'This session focused on creating space, enhancing receiving techniques from varying deliveries, increasing passing accuracy, improving 1st time volley finishing, and elevating your decision-making skills.',
  },
  'INT INTENSIVE 8': {
    name: 'Intermediate Intensive 8',
    description:
      'This session focused on creating space, enhancing receiving techniques from varying aerial deliveries, increasing passing accuracy, improving 1st time half volley and volley finishing, and elevating your decision-making skills.',
  },
  //advanced
  'ADV INTENSIVE 1': {
    name: 'Advanced Intensive 1',
    description:
      'This session focused on creating space, enhancing receiving techniques, increasing passing accuracy, improving multi touch finishing, and elevating decision-making skills.',
  },
  'ADV INTENSIVE 2': {
    name: 'Advanced Intensive 2',
    description:
      'This session focused on creating space, enhancing receiving techniques, increasing passing accuracy, improving multi touch finishing, and elevating decision-making skills.',
  },
  'ADV INTENSIVE 3': {
    name: 'Advanced Intensive 3',
    description:
      'This session focused on creating space, enhancing receiving out of the air (driven) techniques, increasing passing accuracy, improving 1st time half volley finishing, and elevating your decision-making skills.',
  },
  'ADV INTENSIVE 4': {
    name: 'Advanced Intensive 4',
    description:
      'This session focused on creating space, enhancing receiving out of the air (driven) techniques, increasing passing accuracy, improving 1st time half volley finishing, and elevating your decision-making skills.',
  },
  'ADV INTENSIVE 5': {
    name: 'Advanced Intensive 5',
    description:
      'This session focused on creating space, enhancing receiving out of the air (lob) techniques, increasing passing accuracy, improving multi touch finishing from varying heights, and elevating your decision-making skills.',
  },
  'ADV INTENSIVE 6': {
    name: 'Advanced Intensive 6',
    description:
      'This session focused on creating space, enhancing receiving out of the air (lob) techniques, increasing passing accuracy, improving multi touch finishing from varying heights, and elevating your decision-making skills.',
  },
  'ADV INTENSIVE 7': {
    name: 'Advanced Intensive 7',
    description:
      'This session focused on creating space, enhancing receiving techniques from varying deliveries, increasing passing accuracy, improving 1st time volley finishing, and elevating your decision-making skills.',
  },
  'ADV INTENSIVE 8': {
    name: 'Advanced Intensive 8',
    description:
      'This session focused on creating space, enhancing receiving techniques from varying aerial deliveries, increasing passing accuracy, improving 1st time half volley and volley finishing, and elevating your decision-making skills.',
  },
};

export const retrievePathwayInfo = (title: string) => {
  // expected title input ex: '***BEG INTENSIVE 7'
  let revisedTitle = title;
  if (title.startsWith('***')) {
    revisedTitle = title.slice(3);
  }

  if (Object.keys(pathwayNames).find((name) => name === revisedTitle)) {
    return {
      pathwayName: pathwayNames[revisedTitle].name,
      pathwayDesc: pathwayNames[revisedTitle].description,
    };
  } else {
    return {
      pathwayName: '',
      pathwayDesc: '',
    };
  }
};

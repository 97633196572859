import {useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useGetCoachInfo} from 'api/Coach/useGetCoachInfo';
import {useProfiles} from 'api/User/useProfiles';
import {roleType} from 'constants/constants';
import {TeamView} from './Team.view';
import {getUserDisplayName} from 'utils/utils';

export const CoachTeam = () => {
  const {teamId} = useParams();
  const navigate = useNavigate();
  const {currentProfile} = useProfiles();
  const {data: coachInfo, isLoading} = useGetCoachInfo(currentProfile?._id);
  const teamInfo = useMemo(() => coachInfo?.teams.find((team) => team._id === teamId), [teamId, coachInfo?.teams]);
  const teamCoachName = getUserDisplayName(currentProfile.firstName, currentProfile.lastName);
  const onClickInviteHandler = () => {
    navigate(`/coach/teams/${teamInfo?._id}/invite`);
  };

  return (
    <TeamView
      onClickInviteHandler={onClickInviteHandler}
      team={teamInfo}
      loginUserName={teamCoachName}
      loading={isLoading}
      role={roleType.teamCoach}
    />
  );
};

import {useCallback} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch} from '../redux/reduxUtils';
import {silentLogout} from '../firebase/auth-utils';

export const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const logout = useCallback(
    async (redirect = '/auth') => {
      const version: string | null = localStorage.getItem('version');
      const existingParams = localStorage.getItem('fko_initial');
      await silentLogout();
      if (version) {
        localStorage.setItem('version', version);
      }
      if (existingParams) {
        localStorage.setItem('fko_initial', existingParams);
      }
      await queryClient.resetQueries();
      dispatch({type: 'USER_LOGOUT'});
      navigate(redirect);
    },
    [dispatch, navigate, queryClient]
  );

  return {logout};
};

// export async function simpleLogout(reason?: string) {
//   await silentLogout();
//   window.location.replace(`/auth${reason ? '?reason=' + reason : ''}`);
// }

// export async function silentLogout() {
//   await fireBaseAuth.signOut();
//   localStorage.clear();
// }

import styles from './SessionSummaryCard.module.scss';
import {ReactComponent as FigureFootballer} from '../../assets/img/icons/figure-passing.svg';
import {memo} from 'react';

type SessionSummaryCardProps = {
  title?: string;
  description?: string;
};

export const SessionSummaryCard = memo(({title, description, ...rest}: SessionSummaryCardProps) => {
  if (!title) {
    return null;
  }

  return (
    <div className={styles.container} {...rest}>
      <div className={styles.icon}>
        <FigureFootballer />
      </div>
      <div className={styles.content}>
        <p className={styles.title}>{title}</p>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  );
});
SessionSummaryCard.displayName = 'SessionSummaryCard';

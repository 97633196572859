import {PlayerAssessmentConnectsMobileBaselineSessionTable} from 'components/Connects/PlayerAssessmentBaselineSessionData/PlayerAssessmentConnectsMobileBaselineSessionTable';
import {PlayerSessionSummary} from 'api/Trainer/useGetPlayerLastSessionSummary';

export const PlayerAssessmentConnectsBaselineSessionData = ({
  sessionSummary,
  toggleSessionSelection,
  enableSessionSelection = false,
  selectedSessionIndexes = [],
  date = '',
  showAllExercises = false,
}: {
  sessionSummary: PlayerSessionSummary;
  toggleSessionSelection?: (sessionIndex: number) => void;
  enableSessionSelection: boolean;
  selectedSessionIndexes?: number[];
  date?: string;
  showAllExercises?: boolean;
}) => {
  return (
    <>
      <p className="p-2 text-lg text-center font-semibold text-secondary">Player Assessment</p>
      {date && <p className="p-2 text-lg text-center font-semibold text-secondary">{date}</p>}
      <div className="flex flex-col lg:rounded lg:shadow-dispersed lg:overflow-hidden">
        <div className="text-lg text-white font-medium uppercase">
          <p className="p-3 text-center bg-secondary">{`${sessionSummary.baselineLevel ?? ''} Baseline`}</p>
          <div className="p-3 bg-primary flex items-center justify-around">
            <div className="flex gap-2 items-center justify-center">
              <p>Balls:</p>
              <div className="h-[40px] w-[40px] rounded-full grid place-content-center bg-secondary">
                <p>{sessionSummary?.sessionSummary?.totalBallsFired}</p>
              </div>
            </div>
            <div className="flex gap-2 items-center justify-center">
              <p>Sets:</p>
              <div className="h-[40px] w-[40px] rounded-full grid place-content-center bg-secondary">
                <p>{sessionSummary.sessionSummary.repetitions}</p>
              </div>
            </div>
          </div>
        </div>
        <PlayerAssessmentConnectsMobileBaselineSessionTable
          sessionData={sessionSummary}
          toggleSessionSelection={toggleSessionSelection}
          enableSessionSelection={enableSessionSelection}
          selectedSessionIndexes={selectedSessionIndexes}
          showAllExercises={showAllExercises}
        />
      </div>
    </>
  );
};

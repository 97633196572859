import {Helmet} from 'react-helmet';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useGetAthleteInfo} from 'api/Trainer/useGetAthleteInfo';
import {LoadingAnimationFullHeight} from 'components/Loader/LoadingAnimation';
import {PageHeading} from 'components/Connects/PageHeading';
import {Button} from 'components/Connects/Button';
import {ButtonTray} from 'components/Connects/ButtonTray';
import {LogoBanner} from 'components/Connects/LogoBanner';
import {UpcomingCalendar} from 'components/Connects/UpcomingCalendar/UpcomingCalendar';
import {ProfileBar} from 'components/ProfileBar/ProfileBar';

export const Schedule = () => {
  const navigate = useNavigate();
  const {trainerID} = useParams();
  const [searchParams] = useSearchParams();
  const [clientId, siteId] = [searchParams.get('mboClientId'), searchParams.get('mboSiteId')];

  const {data: athleteInfo, isLoading} = useGetAthleteInfo({clientId, siteId});

  return (
    <>
      <Helmet>
        <title>MyTOCA | Schedule</title>
      </Helmet>

      {isLoading ? (
        <LoadingAnimationFullHeight />
      ) : athleteInfo ? (
        <div className="grow flex flex-col">
          <LogoBanner />
          <ProfileBar athleteInfo={athleteInfo} />
          <div className="my-6">
            <PageHeading text="Upcoming" />
          </div>

          <UpcomingCalendar
            athleteAge={athleteInfo.age}
            baselineSessions={athleteInfo.baselineSessions}
            currentPathway={athleteInfo.currentPathway}
            lastSession={athleteInfo.lastSession}
            clientId={clientId}
            siteId={siteId}
          />

          <div className="md:mx-auto md:max-w-[600px] md:w-full">
            <ButtonTray>
              <Button
                text="Previous"
                color="secondary"
                // onClick={() =>
                //   navigate(
                //     `/trainer/connects/nextweek?trainer=${trainerID}&mboClientId=${clientId}&mboSiteId=${siteId}`
                //   )
                // }
                onClick={() =>
                  athleteInfo?.lastSession?.isBaseline
                    ? navigate(
                        `/trainer/connects/baseline?trainer=${trainerID}&mboClientId=${clientId}&mboSiteId=${siteId}`
                      )
                    : navigate(
                        `/trainer/connects?trainer=${trainerID}&mboClientId=${clientId}&mboSiteId=${siteId}&baselineVisited=true`
                      )
                }
              />
              <Button
                text="Done"
                color="secondary"
                variant="cta"
                onClick={() => navigate(`/trainer/connects/finish/?trainer=${trainerID}`)}
              />
            </ButtonTray>
          </div>
        </div>
      ) : (
        <div className="grow flex flex-col">
          <p className="text-sm text-grey-xdark text-center p-4">unable to load athlete info</p>
        </div>
      )}
    </>
  );
};

// const FeedbackSection: React.FC<{handleRating: (rate: number) => void}> = ({handleRating}) => (
//   <>
//     <div className={styles.feedbackContainer}>
//       <h3>{`How was today's session? `}</h3>
//       <div className={styles.ratingContainer}>
//         <Rating
//           onClick={handleRating}
//           SVGstrokeColor="#643DF2"
//           fillColor="#643DF2"
//           size={60}
//           style={{alignSelf: 'center'}}
//         />
//       </div>
//     </div>
//     <div className={styles.optionalText}>
//       <label className={styles.label}>Optional session feedback</label>
//       <textarea className={styles.feedbackTextarea}></textarea>
//     </div>
//   </>
// );

// const SessionsList: React.FC<{sessions: ScheduleVisit[]}> = ({sessions}) => (
//   <div className={styles.sessionList}>
//     {sessions.map((session, i) => (
//       <div key={`session_card_${i}`} className={styles.sessionCard}>
//         <div className={styles.weekMarker}>
//           {/* looks kinda angry with this much red maybe? refactored a bit anyway. */}
//           {/* {session.weekInTraining ? (
//             <>
//               <p>Week</p>
//               <p>{session.weekInTraining}</p>
//             </>
//           ) : (
//             <>
//               <p className={styles.error}>Week</p>
//               <p className={styles.error}>{Number(sessions[sessions.length - 2].weekInTraining) + 1}</p>
//             </>
//           )} */}

//           <p>Week</p>
//           <p>{session.sessionNumber}</p>
//           {/* <p>{session.weekInTraining ?? Number(sessions[sessions.length - 2].weekInTraining) + 1}</p> */}
//         </div>

//         <div className={styles.sessionDetails}>
//           {session.formattedDate ? (
//             <p className={styles.date}>{session.formattedDate}</p>
//           ) : (
//             <p className={`${styles.date} ${styles.error}`}>Not Booked 😢</p>
//           )}
//           {/* <p className={styles.coach}>{session.coach}</p> */}
//         </div>
//       </div>
//     ))}
//   </div>
// );

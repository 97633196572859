// TODO We should probably retrieve these from the API/DB
export const BOOKABLE_SESSION_TYPES = ['Guided - 1 Player'];

export const BOOKING_CANCELLATION_THRESHOLD_HOURS = 24;

export const WEEK_REPEAT_OPTIONS = [
  {
    value: '4',
    displayValue: '4 weeks',
  },
  {
    value: '6',
    displayValue: '6 weeks',
  },
  {
    value: '12',
    displayValue: '12 weeks',
  },
  {
    value: '16',
    displayValue: '16 weeks',
  },
];

import {useQuery} from '@tanstack/react-query';
import {getAllVisits, GetAllVisitsParams} from 'api/api';
import {TocaLocation} from 'constants/locations';

export type Visit = {
  Id: number;
  SiteId: number;
  LocationId: number;
  AppointmentId: number; // 0 ?
  ClassId: number;
  ClientId: string;
  ClientPhotoUrl: string | null;
  ClientUniqueId: number; // not reliable
  ServiceId: number | null;
  ServiceName: string | null;
  Service: {
    Id: number;
    SiteId: number;
    ClientID: string;
    ProductId: number;
    Name: string;
    PaymentDate: string;
    Program: {
      Id: number;
      Name: string;
      ScheduleType: 'All' | 'Class' | 'Enrollment' | 'Appointment' | 'Resource' | 'Arrival';
      CancelOffset: number;
      ContentFormats: Array<'InPerson' | 'Livestream:Mindbody' | 'Livestream:Other'>;
      ScheduleOffset: number;
      ScheduleOffsetEnd: number;
      PricingRelationships: {
        PaysFor: number[];
        PaidBy: number[];
      };
    };
    ActiveDate: string;
    Count: number;
    Current: boolean;
    ExpirationDate: string;
    Remaining: number;
    Returned: boolean;
  } | null;
  ProductId: number | null;
  StaffId: number | null;
  Name: string;
  StartDateTime: string; // 2024-09-19T10:35:00
  EndDateTime: string;
  AppointmentGenderPreference: 'Female' | 'Male' | 'None';
  AppointmentStatus:
    | 'None'
    | 'Requested'
    | 'Booked'
    | 'Completed'
    | 'Confirmed'
    | 'Arrived'
    | 'NoShow'
    | 'Cancelled'
    | 'LateCancelled';
  LastModifiedDateTime?: string;
  LateCancelled: boolean;
  MakeUp: boolean;
  SignedIn: boolean;
  WebSignup: boolean;
  Action: 'None' | 'Added' | 'Updated' | 'Failed' | 'Removed';
  Missed: boolean;
  VisitType: number; // 195
  TypeGroup: number; // 78
  TypeTaken: number | string; // 'Unpaid Soccer Classes'
};

export type FutureVisit = Visit & {
  cancellable: boolean;
  location?: TocaLocation;
};

export const generateVisitsQueryKey = ({clientId}: {clientId?: string | null}) => {
  return ['player', 'sessions', clientId];
};

export const useGetMboVisits = ({clientId, siteId}: GetAllVisitsParams) => {
  return useQuery<Visit[]>({
    queryKey: generateVisitsQueryKey({clientId}),
    queryFn: () => getAllVisits({clientId, siteId}),
    enabled: !!clientId,
    retry: (count, error) => {
      if (error === 'Invalid parameters') {
        return true;
      }
      if (count > 1) {
        return false;
      }
      return true;
    },
  });
};

import {useState} from 'react';
import styles from './ForgetPassword.module.scss';
import {useSetGlobalLoading} from '../../hooks/useGlobalLoading';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {ForgetPasswordForm} from './ForgetPasswordForm';
import {auth as fireBaseAuth} from '../../firebase/config';
import {sendPasswordResetEmail} from 'firebase/auth';
import {PageHeading} from 'components/PageHeading/PageHeading';
import {PageSubHeading} from 'components/PageSubHeading/PageSubHeading';
import {logAnalyticsEvent} from '../../common/analytics-events';

export const ForgetPassword = () => {
  const {setGlobalLoading} = useSetGlobalLoading();
  const [errMes, setErrMes] = useState<string>('');
  const [sendToEmail, setSendToEmail] = useState<string>('');
  const [successMes, setSuccessMes] = useState<boolean>(false);

  const onSubmitHandler = (data: any) => {
    setGlobalLoading(true);
    logAnalyticsEvent('passwordReset', {
      email: data.email,
    });
    sendPasswordResetEmail(fireBaseAuth, data.email)
      .then(() => {
        setSuccessMes(true);
        setSendToEmail(data.email);
      })
      .catch((error) => {
        if (error.code === 'auth/user-not-found') {
          setErrMes('The email does not exist');
        } else {
          setErrMes('Operation failed');
        }
      })
      .finally(() => setGlobalLoading(false));
  };

  return (
    <>
      <Helmet>
        <title>MyTOCA | Forgot Password</title>
      </Helmet>

      <PageHeading text="Reset Password" />
      <div className={styles.container}>
        {!successMes ? (
          <>
            <PageSubHeading text="Forgot your password?" />
            <ForgetPasswordForm errorMessage={errMes} onSubmitHandler={onSubmitHandler} />

            <div className={styles.bottomContainer}>
              <p>
                Don’t have an account? <Link to={'/signup'}> Sign up for free</Link>
              </p>
            </div>
          </>
        ) : (
          <>
            <PageSubHeading text="Check your email" />

            <div className={styles.info}>
              <p>
                We sent instructions to reset your password to <span>{sendToEmail}</span>
              </p>
              <p>If you don’t see it, you may have to check your spam folder.</p>
            </div>

            <div className={styles.bottomContainer}>
              <Link to={'/signin'}> Sign in</Link>
            </div>
          </>
        )}
      </div>
    </>
  );
};

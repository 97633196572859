import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type LoadingState = {
  loading: boolean;
};

const initialState = {loading: false} as LoadingState;

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export const {setLoading} = loadingSlice.actions;

export default loadingSlice.reducer;

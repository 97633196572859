import {useState} from 'react';
import {ReactComponent as HamburgerIcon} from '../../assets/img/icons/redesign-icons/hamburger.svg';
import {Link} from 'react-router-dom';

//
export const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        className={`h-[52px] xs:h-[64px] w-[52px] xs:w-[64px] grid place-content-center cursor-pointer transition-all ${
          isOpen ? 'bg-primary bg-opacity-10' : ''
        }`}
      >
        <HamburgerIcon />
      </button>

      {/* TODO Mobile styles */}
      <div
        className={`absolute top-[100%] right-0 ${
          isOpen ? 'max-h-[600px] opacity-100 shadow-dispersed' : 'max-h-[0px] opacity-0'
        } overflow-hidden transition-opacity`}
      >
        <ul className="flex flex-col bg-white border border-grey border-t-0 rounded-b-sm">
          <li className="py-4 px-10 hover:bg-primary hover:bg-opacity-10 border-b border-grey-light">
            <MenuLink text="Private&nbsp;Training" url="https://www.tocafootball.com/free-baseline-session" />
          </li>
          <li className="py-4 px-10 hover:bg-primary hover:bg-opacity-10 border-b border-grey-light">
            <MenuLink text="Camps" url="https://www.tocafootball.com/go/toca-training-clinics-2024" />
          </li>
          <li className="py-4 px-10 hover:bg-primary hover:bg-opacity-10">
            <MenuLink text="Classes" url="https://www.tocafootball.com/go/toca-training-clinics-2024" />
          </li>
        </ul>
      </div>
    </div>
  );
};

const MenuLink = ({text, url}: {text: string; url: string}) => {
  return (
    <Link to={url} className="block text-lg text-center">
      {text}
    </Link>
  );
};

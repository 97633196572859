import {PathwayLevel} from 'api/User/useGetPlayerDetails';
import {Curriculum} from 'pages/Connects/types';

export const getAthleteCurriculum = (pathwayLevel: PathwayLevel, sessionNumber: number) => {
  return curriculum[pathwayLevel].find((session) => session.sessionNumber === sessionNumber);
};

const keyData = [
  {
    metric: 'accuracy',
    title: 'Accuracy',
    description: 'The TOCA Smart Targets measure the number of successful completions per set/session',
  },
  {
    metric: 'streak',
    title: 'Streak',
    description: 'The TOCA Smart Targets measure the number of successful passing/finishing completions in a row',
  },
  {
    metric: 'recoveries',
    title: 'Recovery',
    description: `As a training metric involves assessing an athlete's ability to rebound from a missed repetition.`,
  },
  // {
  //   metric: 'speedOfPlay',
  //   title: 'Speed of Play',
  //   description:
  //     "The TOCA Smart Targets accurately measure the time it takes the ball to pass through the Target sensors following the ball's release from the Touch Trainer",
  // },
  {
    metric: 'ballSpeed',
    title: 'MPH',
    description: 'MPH (Miles Per Hour) is the speed measured as the ball travels through the TOCA Smart Target sensors',
  },
];

// ***** NOTE: if descriptions are rewritten, the Connect page might need to be updated *****
// (currently every description can be changed to present tense with 'focused' -> 'focuses')
export const curriculum: Curriculum = {
  beginner: [
    {
      sessionNumber: 1,
      description:
        'This session focused on simple movement patterns, basic receiving techniques, increasing passing accuracy, improving 1st time finishing, and introducing the player to the decision-making process.',
      narrowedFocus: ['Pre Scan to collect information', 'Shape of contact surface when Receiving'],
      img: 'https://global-uploads.webflow.com/60c7be61132e3a9edf0a3315/613a382fcf47d08fbc0be92c_HAAN0494.jpg',
      education: {
        title: 'Technical Tactical Excellence',
        url: 'https://www.tocafootball.com/journal-post/soccer-training',
      },
      keyData,
    },
    {
      sessionNumber: 2,
      description:
        'This session focused on simple movement patterns, basic receiving techniques, increasing passing accuracy, improving 1st time finishing, and introducing the player to the decision-making process.',
      narrowedFocus: ['Pre Scan to collect information', 'Body Shape in preparation to Pass'],
      img: 'https://global-uploads.webflow.com/60c7be61132e3a9edf0a3315/613a382fcf47d08fbc0be92c_HAAN0494.jpg',
      education: {
        title: 'Technical Tactical Excellence',
        url: 'https://www.tocafootball.com/journal-post/soccer-training',
      },
      keyData,
    },
    {
      sessionNumber: 3,
      description:
        'This session focused on simple movement patterns, basic receiving techniques, increasing passing accuracy, improving 1st time finishing, and elevating the decision-making process.',
      narrowedFocus: ['Pre Scan to collect information', 'Body Shape in preparation to Pass'],
      img: 'https://global-uploads.webflow.com/60c7be61132e3a9edf0a3315/613a382fcf47d08fbc0be92c_HAAN0494.jpg',
      education: {
        title: 'Technical Tactical Excellence',
        url: 'https://www.tocafootball.com/journal-post/soccer-training',
      },
      keyData,
    },
    {
      sessionNumber: 4,
      description:
        'This session focused on simple movement patterns, basic receiving techniques, increasing passing accuracy, improving 1st time finishing, and elevating the decision-making process.',
      narrowedFocus: ['Timing and Angle of Approach to the Ball', 'Shape of contact surface when Finishing'],
      img: 'https://global-uploads.webflow.com/60c7be61132e3a9edf0a3315/613a382fcf47d08fbc0be92c_HAAN0494.jpg',
      education: {
        title: 'Technical Tactical Excellence',
        url: 'https://www.tocafootball.com/journal-post/soccer-training',
      },
      keyData,
    },
    {
      sessionNumber: 5,
      description:
        'This session focused on movement patterns to create space, receiving to turn techniques, increasing passing accuracy, improving multi touch finishing, and enhancing the decision-making process.',
      narrowedFocus: ['Timing and Angle of Approach to the Ball', 'Body Shape in preparation to Receive'],
      img: 'https://global-uploads.webflow.com/60c7be61132e3a9edf0a3315/613a382fcf47d08fbc0be92c_HAAN0494.jpg',
      education: {
        title: 'Technical Tactical Excellence',
        url: 'https://www.tocafootball.com/journal-post/soccer-training',
      },
      keyData,
    },
    {
      sessionNumber: 6,
      description:
        'This session focused on movement patterns to create space, receiving to turn techniques, increasing passing accuracy, improving multi touch finishing, and enhancing the decision-making process.',
      narrowedFocus: ['Pre Scan to collect information', 'Shape of contact surface when Receiving'],
      img: 'https://global-uploads.webflow.com/60c7be61132e3a9edf0a3315/613a382fcf47d08fbc0be92c_HAAN0494.jpg',
      education: {
        title: 'Technical Tactical Excellence',
        url: 'https://www.tocafootball.com/journal-post/soccer-training',
      },
      keyData,
    },
    {
      sessionNumber: 7,
      description:
        'This session focused on movement patterns to create space, receiving to turn techniques, increasing passing accuracy, improving multi touch finishing, and enhancing the decision-making process.',
      narrowedFocus: ['Pre Scan to collect information', 'Body Shape in preparation to Pass'],
      img: 'https://global-uploads.webflow.com/60c7be61132e3a9edf0a3315/613a382fcf47d08fbc0be92c_HAAN0494.jpg',
      education: {
        title: 'Technical Tactical Excellence',
        url: 'https://www.tocafootball.com/journal-post/soccer-training',
      },
      keyData,
    },
    {
      sessionNumber: 8,
      description:
        'This session focused on movement patterns to create space, receiving to turn techniques, increasing passing accuracy, improving multi touch finishing, and enhancing the decision-making process.',
      narrowedFocus: ['Timing and Angle of Approach to the Ball', 'Shape of contact surface when Finishing'],
      img: 'https://global-uploads.webflow.com/60c7be61132e3a9edf0a3315/613a382fcf47d08fbc0be92c_HAAN0494.jpg',
      education: {
        title: 'Technical Tactical Excellence',
        url: 'https://www.tocafootball.com/journal-post/soccer-training',
      },
      keyData,
    },
  ],
  intermediate: [
    {
      sessionNumber: 1,
      description:
        'This session focused on creating space, enhancing receiving techniques, increasing passing accuracy, improving multi touch finishing, and elevating decision-making skills.',
      narrowedFocus: ['Pre Scan to collect information', 'Timing and Angle of Approach to the Ball'],
      img: 'https://global-uploads.webflow.com/60c7be61132e3a9edf0a3315/613a382fcf47d08fbc0be92c_HAAN0494.jpg',
      education: {
        title: 'Technical Tactical Excellence',
        url: 'https://www.tocafootball.com/journal-post/soccer-training',
      },
      keyData,
    },
    {
      sessionNumber: 2,
      description:
        'This session focused on creating space, enhancing receiving techniques, increasing passing accuracy, improving multi touch finishing, and elevating decision-making skills.',
      narrowedFocus: ['Body shape in preparation to Receive', 'Shape of contact surface when Finishing'],
      img: 'https://global-uploads.webflow.com/60c7be61132e3a9edf0a3315/613a382fcf47d08fbc0be92c_HAAN0494.jpg',
      education: {
        title: 'Technical Tactical Excellence',
        url: 'https://www.tocafootball.com/journal-post/soccer-training',
      },
      keyData,
    },
    {
      sessionNumber: 3,
      description:
        'This session focused on creating space, enhancing receiving out of the air (driven) techniques, increasing passing accuracy, improving 1st time half volley finishing, and elevating your decision-making skills.',
      narrowedFocus: ['Timing and Angle of Approach to the flight of the Ball', 'Decision Making'],
      img: 'https://global-uploads.webflow.com/60c7be61132e3a9edf0a3315/613a382fcf47d08fbc0be92c_HAAN0494.jpg',
      education: {
        title: 'Technical Tactical Excellence',
        url: 'https://www.tocafootball.com/journal-post/soccer-training',
      },
      keyData,
    },
    {
      sessionNumber: 4,
      description:
        'This session focused on creating space, enhancing receiving out of the air (driven) techniques, increasing passing accuracy, improving 1st time half volley finishing, and elevating your decision-making skills.',
      narrowedFocus: ['Pre Scan to collect information', 'Body shape in preparation to Receive'],
      img: 'https://global-uploads.webflow.com/60c7be61132e3a9edf0a3315/613a382fcf47d08fbc0be92c_HAAN0494.jpg',
      education: {
        title: 'Technical Tactical Excellence',
        url: 'https://www.tocafootball.com/journal-post/soccer-training',
      },
      keyData,
    },
    {
      sessionNumber: 5,
      description:
        'This session focused on creating space, enhancing receiving out of the air (lob) techniques, increasing passing accuracy, improving multi touch finishing from varying heights, and elevating your decision-making skills.',
      narrowedFocus: ['Timing and Angle of Approach to the flight of the Ball', 'Shape of the contact surface'],
      img: 'https://global-uploads.webflow.com/60c7be61132e3a9edf0a3315/613a382fcf47d08fbc0be92c_HAAN0494.jpg',
      education: {
        title: 'Technical Tactical Excellence',
        url: 'https://www.tocafootball.com/journal-post/soccer-training',
      },
      keyData,
    },
    {
      sessionNumber: 6,
      description:
        'This session focused on creating space, enhancing receiving out of the air (lob) techniques, increasing passing accuracy, improving multi touch finishing from varying heights, and elevating your decision-making skills.',
      narrowedFocus: ['Pre scan to collect information', 'Body Shape when passing'],
      img: 'https://global-uploads.webflow.com/60c7be61132e3a9edf0a3315/613a382fcf47d08fbc0be92c_HAAN0494.jpg',
      education: {
        title: 'Technical Tactical Excellence',
        url: 'https://www.tocafootball.com/journal-post/soccer-training',
      },
      keyData,
    },
    {
      sessionNumber: 7,
      description:
        'This session focused on creating space, enhancing receiving techniques from varying deliveries, increasing passing accuracy, improving 1st time volley finishing, and elevating your decision-making skills.',
      narrowedFocus: ['Movement to create space influenced by available options', 'Timing of contact in Finishing Set'],
      img: 'https://global-uploads.webflow.com/60c7be61132e3a9edf0a3315/613a382fcf47d08fbc0be92c_HAAN0494.jpg',
      education: {
        title: 'Technical Tactical Excellence',
        url: 'https://www.tocafootball.com/journal-post/soccer-training',
      },
      keyData,
    },
    {
      sessionNumber: 8,
      description:
        'This session focused on creating space, enhancing receiving techniques from varying aerial deliveries, increasing passing accuracy, improving 1st time half volley and volley finishing, and elevating your decision-making skills.',
      narrowedFocus: ['Choice of surface to receive', 'Shape of Contact surface in Finishing Set'],
      img: 'https://global-uploads.webflow.com/60c7be61132e3a9edf0a3315/613a382fcf47d08fbc0be92c_HAAN0494.jpg',
      education: {
        title: 'Technical Tactical Excellence',
        url: 'https://www.tocafootball.com/journal-post/soccer-training',
      },
      keyData,
    },
  ],
  advanced: [
    {
      sessionNumber: 1,
      description:
        'This session focused on creating space, enhancing receiving techniques, increasing passing accuracy, improving multi touch finishing, and elevating decision-making skills.',
      narrowedFocus: ['Pre Scan to collect information', 'Timing and Angle of Approach to the Ball'],
      img: 'https://global-uploads.webflow.com/60c7be61132e3a9edf0a3315/613a382fcf47d08fbc0be92c_HAAN0494.jpg',
      education: {
        title: 'Technical Tactical Excellence',
        url: 'https://www.tocafootball.com/journal-post/soccer-training',
      },
      keyData,
    },
    {
      sessionNumber: 2,
      description:
        'This session focused on creating space, enhancing receiving techniques, increasing passing accuracy, improving multi touch finishing, and elevating decision-making skills.',
      narrowedFocus: ['Body shape in preparation to Receive', 'Shape of contact surface when Finishing'],
      img: 'https://global-uploads.webflow.com/60c7be61132e3a9edf0a3315/613a382fcf47d08fbc0be92c_HAAN0494.jpg',
      education: {
        title: 'Technical Tactical Excellence',
        url: 'https://www.tocafootball.com/journal-post/soccer-training',
      },
      keyData,
    },
    {
      sessionNumber: 3,
      description:
        'This session focused on creating space, enhancing receiving out of the air (driven) techniques, increasing passing accuracy, improving 1st time half volley finishing, and elevating your decision-making skills.',
      narrowedFocus: ['Timing and Angle of Approach to the flight of the Ball', 'Decision Making'],
      img: 'https://global-uploads.webflow.com/60c7be61132e3a9edf0a3315/613a382fcf47d08fbc0be92c_HAAN0494.jpg',
      education: {
        title: 'Technical Tactical Excellence',
        url: 'https://www.tocafootball.com/journal-post/soccer-training',
      },
      keyData,
    },
    {
      sessionNumber: 4,
      description:
        'This session focused on creating space, enhancing receiving out of the air (driven) techniques, increasing passing accuracy, improving 1st time half volley finishing, and elevating your decision-making skills.',
      narrowedFocus: ['Pre Scan to collect information', 'Body shape in preparation to Receive'],
      img: 'https://global-uploads.webflow.com/60c7be61132e3a9edf0a3315/613a382fcf47d08fbc0be92c_HAAN0494.jpg',
      education: {
        title: 'Technical Tactical Excellence',
        url: 'https://www.tocafootball.com/journal-post/soccer-training',
      },
      keyData,
    },
    {
      sessionNumber: 5,
      description:
        'This session focused on creating space, enhancing receiving out of the air (lob) techniques, increasing passing accuracy, improving multi touch finishing from varying heights, and elevating your decision-making skills.',
      narrowedFocus: ['Timing and Angle of Approach to the flight of the Ball', 'Shape of the contact surface'],
      img: 'https://global-uploads.webflow.com/60c7be61132e3a9edf0a3315/613a382fcf47d08fbc0be92c_HAAN0494.jpg',
      education: {
        title: 'Technical Tactical Excellence',
        url: 'https://www.tocafootball.com/journal-post/soccer-training',
      },
      keyData,
    },
    {
      sessionNumber: 6,
      description:
        'This session focused on creating space, enhancing receiving out of the air (lob) techniques, increasing passing accuracy, improving multi touch finishing from varying heights, and elevating your decision-making skills.',
      narrowedFocus: ['Pre scan to collect information', 'Body Shape when passing'],
      img: 'https://global-uploads.webflow.com/60c7be61132e3a9edf0a3315/613a382fcf47d08fbc0be92c_HAAN0494.jpg',
      education: {
        title: 'Technical Tactical Excellence',
        url: 'https://www.tocafootball.com/journal-post/soccer-training',
      },
      keyData,
    },
    {
      sessionNumber: 7,
      description:
        'This session focused on creating space, enhancing receiving techniques from varying deliveries, increasing passing accuracy, improving 1st time volley finishing, and elevating your decision-making skills.',
      narrowedFocus: ['Movement to create space influenced by available options', 'Timing of contact in Finishing Set'],
      img: 'https://global-uploads.webflow.com/60c7be61132e3a9edf0a3315/613a382fcf47d08fbc0be92c_HAAN0494.jpg',
      education: {
        title: 'Technical Tactical Excellence',
        url: 'https://www.tocafootball.com/journal-post/soccer-training',
      },
      keyData,
    },
    {
      sessionNumber: 8,
      description:
        'This session focused on creating space, enhancing receiving techniques from varying aerial deliveries, increasing passing accuracy, improving 1st time half volley and volley finishing, and elevating your decision-making skills.',
      narrowedFocus: ['Choice of surface to receive', 'Shape of Contact surface in Finishing Set'],
      img: 'https://global-uploads.webflow.com/60c7be61132e3a9edf0a3315/613a382fcf47d08fbc0be92c_HAAN0494.jpg',
      education: {
        title: 'Technical Tactical Excellence',
        url: 'https://www.tocafootball.com/journal-post/soccer-training',
      },
      keyData,
    },
  ],
};

// const curriculums = [
//   {
//     session: {
//       id: 1,
//       phase: 'beginner',
//     },
//     narrowedFocus: ['Timing and Angle of Approach to the Ball', 'Body Shape in preparation to Receive'],
//     focus: 'Receiving with the Inside of the Foot. Body Shape',
//     education: {
//       title: 'Technical Tactical Excellence',
//       url: 'https://www.tocafootball.com/journal-post/soccer-training',
//     },
//     keyData,
//     nextWeek: [
//       {
//         title: 'Objectives',
//         objectives: ['Developing', 'Excellence', 'Technical Tactical'],
//         img: 'https://global-uploads.webflow.com/60c7be61132e3a9edf0a3315/6343fbe5b1b25a4a9fe6746e_210608_ha_toca_training_004_LowRes.jpg',
//       },
//       // {
//       //   title: 'Technical Focus Area',
//       //   objectives: ['Developing', 'Excellence', 'Technical Tactical'],
//       //   focus: ['Receiving'],
//       //   img: 'https://global-uploads.webflow.com/60c7be61132e3a9edf0a3315/613a382fcf47d08fbc0be92c_HAAN0494.jpg',
//       // },
//     ],
//   },
// ];

// export const getCurrentCurriculum = (sessionNumber: number) =>
//   curriculums.filter((c) => c.session.id === sessionNumber)[0];

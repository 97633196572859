import {useState} from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {useAppSelector} from 'redux/reduxUtils';
import {createOrUpdateHubSpotContact} from 'api/api';
import {FormInput} from 'components/Forms/FormInput/FormInput';
import {Button} from 'components/Connects/Button';
import {PHONE_REGEX} from 'pages/RegistrationLanding/RegistrationLandingForm';
import {logAnalyticsEvent} from 'common/analytics-events';
import {useGetSport} from 'common/useGetSport';

type EmptyDayFormProps = {
  selectedDate: string;
};

type FormType = {phoneNumber: string};

export const SchedulingEmptyDayForm = ({selectedDate}: EmptyDayFormProps) => {
  const {fkoAnalyticsPrefix} = useGetSport();
  const {userData, siteId} = useAppSelector((state) => state.fkoFormDataReducer);
  const [contactRequestResult, setContactRequestResult] = useState<null | 'sent' | 'error'>(null);

  const sendContactRequest = ({phoneNumber}: FormType) => {
    logAnalyticsEvent(`${fkoAnalyticsPrefix}_appointment_call_requested`, {
      siteId: siteId,
      first_name: userData?.firstName,
      last_name: userData?.lastName,
      email: userData?.email,
      phone: phoneNumber,
      requested_date: selectedDate,
    });

    createOrUpdateHubSpotContact({
      email: userData?.email,
      phone: phoneNumber,
      fko_request_date: selectedDate,
      fko_method: `player_portal_${fkoAnalyticsPrefix}_registration`,
    })
      .then(() => {
        setContactRequestResult('sent');
      })
      .catch((err) => {
        setContactRequestResult('error');
        console.error('Failed to create/update HubSpot contact', err);
      });
  };

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<FormType>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        phoneNumber: yup
          .string()
          .required('Phone number is required')
          .matches(PHONE_REGEX, 'Please enter a valid phone number'),
      })
    ),
    defaultValues: {
      phoneNumber: userData?.phoneNumber,
    },
  });

  return (
    <div className="flex flex-col gap-2">
      {contactRequestResult === 'error' ? (
        <p className="text-sm">
          We&apos;re sorry but it looks like some of our systems are having issues at the moment. Please{' '}
          <a
            href="https://www.tocafootball.com/contact-us" // TODBAT links tbd
            className="underline text-secondary"
            target="_blank"
            rel="noreferrer"
          >
            click here
          </a>{' '}
          to contact us directly.
        </p>
      ) : (
        <form onSubmit={handleSubmit(sendContactRequest)} className="flex flex-col items-center">
          <p className="text-sm mb-4">
            This day is popular! Click the contact button below to have our teammates work their magic. We&apos;ll call
            you back at the following number:
          </p>

          <FormInput
            {...register('phoneNumber')}
            id="phoneNumber"
            hasError={!!errors.phoneNumber?.message}
            placeholder="Phone Number"
            spellCheck="false"
            type="tel"
          />
          <p className="text-xs text-alert mb-1">{errors.phoneNumber?.message ?? '\u00A0'}</p>

          <div className="shrink">
            {contactRequestResult === 'sent' ? (
              <p className="p-3 mb-[-4px] text-lg font-semibold uppercase text-fko-primary">Sent!</p>
            ) : (
              <Button text="Contact me" type="submit" />
            )}
          </div>
        </form>
      )}
    </div>
  );
};

import {memo} from 'react';
import {Link} from 'react-router-dom';
import {Program} from 'api/Classes/useGetPrograms';
import {Button} from 'components/Connects/Button';
import classPlaceholder from '../../assets/img/groupClass.jpg';
// import trainingPlaceholder from '../../assets/img/training.jpg';

export const ProgramCard = memo(({program}: {program: Program}) => {
  return (
    <div className="overflow-hidden flex flex-col gap-4 justify-between h-full">
      <Link to={`${program.programId}`}>
        {/* <div className="flex h-60 sm:w-44 sm:h-full"> */}
        <div className="flex h-60 rounded-lg overflow-hidden">
          <img
            src={program.mainImage?.url ?? classPlaceholder}
            alt=""
            className="h-full w-full object-cover object-[25%,60%]"
          />
        </div>
      </Link>

      <div className="h-12 grid content-center">
        <h4 className="font-poppins font-semibold text-2xl text-primary uppercase text-center tracking-[1px]">
          {program.name}
        </h4>
      </div>

      <div className="grow">
        <p className="font-poppins text-base text-dark-blue">{program.slug ?? program.description}</p>
      </div>

      <div className="grid content-center mx-auto">
        <Link to={`${program.programId}`}>
          <Button text="See&nbsp;Details" />
        </Link>
      </div>
    </div>
  );
});
ProgramCard.displayName = 'ProgramCard';

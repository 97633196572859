import {useCallback} from 'react';
import {useLoadScript} from 'common/use-load-script.hook';
import {executeRecaptchaV3} from 'utils/utils';
import {verifyRecaptcha} from 'api/api';
import {IS_TEST_ENVIRONMENT} from 'common/isTestEnvironment';
import {recaptchaSiteKey} from 'common/fko-utils';

interface RecaptchaResponse {
  verifyRecaptchaChallenge: () => Promise<boolean>;
}

export const useRecaptcha = (): RecaptchaResponse => {
  useLoadScript(
    `https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`
  );

  const verifyRecaptchaChallenge = useCallback(async (): Promise<boolean> => {
    if (IS_TEST_ENVIRONMENT || process.env.REACT_APP_IS_CAPTCHA_DISABLED === 'true') {
      return true;
    }

    try {
      const token = await executeRecaptchaV3(recaptchaSiteKey);
      const result = await verifyRecaptcha(token);
      return result.success && result.score >= 0.3;
    } catch (err) {
      console.error('reCAPTCHA verification failed:', err);
      return false;
    }
  }, []);

  return {
    verifyRecaptchaChallenge
  };
};
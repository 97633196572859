import {FormSelectOption} from 'components/Forms/FormSelect/FormSelect';
import {Assessment} from 'api/Coach/useGetCoachInfo';
import {getLocationBySiteId} from 'constants/locations';
import {isDaylightSavings} from 'utils/utils';

export const generateTimeOptions = (): FormSelectOption[] => {
  const options: FormSelectOption[] = [];
  const time = new Date(0); // Initialize time to epoch (January 1, 1970 00:00:00 UTC)

  for (let i = 0; i < 288; i++) {
    // Loop for 288 intervals (288 * 5 minutes = 24 hours)
    const hours = String(time.getUTCHours()).padStart(2, '0'); // Get hours (UTC) and format to 2 digits
    const minutes = String(time.getUTCMinutes()).padStart(2, '0'); // Get minutes (UTC) and format to 2 digits
    const timeString = `${hours}:${minutes}`; // Create time string in HH:mm format
    options.push({id: timeString, value: timeString, displayValue: timeString});
    time.setUTCMinutes(time.getUTCMinutes() + 5); // Increment time by 5 minutes for the next interval
  }

  return options; // Return the array of time options
};

export const isFutureAssessment = (assessment: Assessment): boolean => {
  const location = getLocationBySiteId(assessment.siteId);

  // Parse the asssessment time string to extract the components
  const [year, month, day] = assessment.startDate.split('-').map(Number);
  const [hours, minutes] = assessment.startTime.split(':').map(Number);

  // Determine the UTC hours based on whether it's DST or not
  const utcOffset = isDaylightSavings(assessment.startDate) ? location?.dstOffset ?? 0 : location?.utcOffset ?? 0;
  const utcHours = hours - utcOffset;

  // Create the asssessment time in UTC
  const asssessmentDateUtc = Date.UTC(year, month - 1, day, utcHours, minutes);

  // Get the current time in UTC in milliseconds
  const currentDateUtc = Date.now();

  // Compare the asssessment time with the current time
  return asssessmentDateUtc > currentDateUtc;
};

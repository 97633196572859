import {FormSelectOption} from '../../components/Forms/FormSelect/FormSelect';
import {TocaLocation} from '../../constants/locations';
import {SanitizedProfile} from '../../user/player-info.interface';
import {createDateMap, parseDateTime} from '../../utils/utils';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Session} from '../../pages/RegistrationScheduling/SchedulingForm';
import {RegistrationFormTypes} from 'pages/RegistrationLanding/RegistrationLandingForm';
import {createMboClients, ProfileUpdate, updateProfileFkoApi} from 'api/api';
import store from 'redux/store';
import uuid from 'react-uuid';

export type ExperienceLevel = '1 year or fewer' | '1-2 years' | '3 years +';
export type ExperienceOptions = {id: number; value: ExperienceLevel}[];

export type TrackingStrings =
  | 'fbclid'
  | 'gclid'
  | 'utm_source'
  | 'utm_medium'
  | 'utm_campaign'
  | 'utm_content'
  | 'utm_term'
  | 'hs_analytics_source';

export type TrackingParams = {
  [key in TrackingStrings]?: string | null;
};

export type FkoInitialParams = TrackingParams & {
  locationId?: string | null;
  trainingProgram?: string | null;
  embed?: string | null;
  sessionTypeNames?: string[];
  date?: string;
  dateEnd?: string;
  dailyStartHour?: string;
  dailyStartMinute?: string;
  dailyEndHour?: string;
  dailyEndMinute?: string;
  sport?: 'toca' | 'dbat' | 'playerAssessment';
  teamId?: string;
};

export type FkoPlayer = SanitizedProfile & {
  location?: TocaLocation;
  appointmentConfirmed?: boolean;
  error?: string;
  playerId?: string; // MBO clientId
  selectedSession?: Session;
  isDbat?: boolean;
  siteId?: string;
};

export type FkoPlayerWithSession = FkoPlayer & Required<Pick<FkoPlayer, 'mboDetails' | 'playerId' | 'selectedSession'>>;

export type FkoUserData = {
  dob?: string | undefined;
  email: string;
  firstName: string;
  lastName: string;
  location: string;
  phoneNumber?: string;
  club?: string; // MLS Next Fest param

  // referrer?: string;
  // receiveTocaNotification?: boolean;
  // agreement?: boolean;
};

export type AppointmentCalendar = {
  [dateStr: string]: {
    dateStr: string; // 2022-10-19
    day: string; // 5
    dayStrShort: string; // Mon
    monthStr: string; // Oct
    appointments: Session[];
    fetching: boolean;
    hasFetched: boolean;
  };
};

type FkoFormDataState = {
  experienceOptions: ExperienceOptions;
  referrerOptions: FormSelectOption[];
  initialParams: FkoInitialParams;
  userData: FkoUserData | null;
  playersArray: FkoPlayer[];
  appointmentDates: {
    startDate: string;
    endDate: string;
  } | null;
  appointmentCalendar: AppointmentCalendar;
  removedSessions: Session[];
  siteId: string;
};

// export const createUserInBackground = createAsyncThunk(
//   'fkoFormData/createNewFkoUser',
//   async (
//     {
//       siteId,
//       email,
//       formData,
//       queryClient,
//       isClasses = false,
//     }: {
//       siteId: string;
//       email: string;
//       formData: RegistrationFormTypes;
//       queryClient: QueryClient;
//       isClasses?: boolean;
//     },
//     thunkApi
//   ) => {
//     const createUserResult = await createUser(email).catch((error) => {
//       void saveErrorDetails({
//         key: 'firebaseCreateUserError',
//         message: 'Failed to create user account',
//         context: isClasses ? 'ftc' : 'fko',
//         severity: 'medium',
//         siteId,
//         email,
//         data: {error},
//       });
//     });
//     const accountId = createUserResult?.user?.uid;

//     if (!accountId) {
//       console.error('Missing accountId after user account create');
//       return;
//     }

//     setCurrentAccountId(accountId);

//     const updatedUserInfo: ProfileUpdate = {
//       profileId: '', // omit for initial account profile
//       siteId: siteId,
//       update: {
//         email,
//         firstName: formData.firstName?.trim(),
//         lastName: formData.lastName?.trim(),
//         phoneNumber: formData.phoneNumber,
//         dob: formData.dob,
//         preferences: {
//           // receiveTocaNotification: Boolean(formData.receiveTocaNotification),
//           // receiveTocaNotificationOptInOn: formData.receiveTocaNotification ? new Date().toISOString() : undefined,
//           receiveTocaNotification: false, // TODO confirm intention here. this has not been true recently as the form was not setting this value
//           receiveTocaNotificationOptInOn: undefined,
//         },
//         tracking: isClasses
//           ? {
//               // TODO CLASSES
//             }
//           : {
//               fko: store.getState().fkoFormDataReducer.initialParams || {},
//               // referrer: formData.referrer,  // TODO confirm intention here. this has not been true recently as the form was not setting this value
//             },
//       },
//     };

//     const updatedProfile = await updateProfileFkoApi(updatedUserInfo);

//     if (isClasses) {
//       void createMboClients({
//         siteId: formData.location,
//         email,
//         clients: [
//           {
//             accountHolder: true, // TODO this is fine for now but this whole process is dumb
//             firstName: updatedProfile.firstName,
//             lastName: updatedProfile.lastName,
//             birthdate: updatedProfile.dob || '2000-01-01',
//             clientSideOnlyId: uuid(),
//           },
//         ],
//         isFko: true, // 'true' is correct, for now.
//         returnProfiles: true,
//       }).then((res) => {
//         if (res.profiles?.length) {
//           thunkApi.dispatch(setFkoPlayersArray(res.profiles));
//         } else {
//           thunkApi.dispatch(setFkoPlayersArray([updatedProfile]));
//         }
//       });
//     }

//     if (updatedProfile?._id) {
//       // this is only used to determine who clicked the accept terms checkbox later
//       setCurrentAccountHolderProfileId(updatedProfile._id);
//     }

//     await fetchCurrentUserWithRetry(); // TODO Do we need this at all?
//     await queryClient.invalidateQueries({queryKey: ['user']});
//   }
// );

export const createInitialFkoProfile = createAsyncThunk(
  'fkoFormData/createNewFkoUser',
  async (
    {
      siteId,
      email,
      formData,
      isClasses = false,
    }: {
      siteId: string;
      email: string;
      formData: RegistrationFormTypes;
      isClasses?: boolean;
    },
    thunkApi
  ) => {
    const updatedUserInfo: ProfileUpdate = {
      email,
      siteId,
      update: {
        email,
        firstName: formData.firstName?.trim(),
        lastName: formData.lastName?.trim(),
        phoneNumber: formData.phoneNumber,
        dob: formData.dob,
        club: formData.club ?? undefined,
        preferences: {
          receiveTocaNotification: false,
          receiveTocaNotificationOptInOn: undefined,
        },
        tracking: isClasses
          ? {
              // TODO CLASSES
            }
          : {
              fko: store.getState().fkoFormDataReducer.initialParams || {},
            },
      },
    };

    const updatedProfile = await updateProfileFkoApi(updatedUserInfo);

    if (isClasses) {
      void createMboClients({
        siteId: formData.location,
        email,
        clients: [
          {
            accountHolder: true, // TODO this is fine for now but this whole process is dumb
            firstName: updatedProfile.firstName,
            lastName: updatedProfile.lastName,
            birthdate: updatedProfile.dob || '2000-01-01',
            clientSideOnlyId: uuid(),
          },
        ],
        isFko: true, // 'true' is correct, for now.
        returnProfiles: true,
      }).then((res) => {
        if (res.profiles?.length) {
          thunkApi.dispatch(setFkoPlayersArray(res.profiles));
        } else {
          thunkApi.dispatch(setFkoPlayersArray([updatedProfile]));
        }
      });
    }
  }
);

//

const initialState: FkoFormDataState = {
  experienceOptions: [
    {id: 1, value: '1 year or fewer'},
    {id: 2, value: '1-2 years'},
    {id: 3, value: '3 years +'},
  ],
  referrerOptions: [
    {id: 'Google AdWords', value: 'Google AdWords', displayValue: 'Google'},
    {id: 'Facebook', value: 'Facebook', displayValue: 'Facebook'},
    {
      id: 'Word of mouth',
      value: 'Word of mouth',
      displayValue: 'Friends/Family/Coach',
    },
    {id: 'Customer Event', value: 'Customer Event', displayValue: 'Event'},
    {id: 'Other', value: 'Other', displayValue: 'Other'},
  ],
  initialParams: {
    locationId: null,
    trainingProgram: null,
    fbclid: null,
  },
  playersArray: [],
  userData: null,
  appointmentDates: null,
  appointmentCalendar: {},
  removedSessions: [],
  siteId: '',
};

type SetFkoAvailableAppointments = {
  appointments: Session[];
  datesInRange?: string[];
};

const fkoFormDataSlice = createSlice({
  name: 'fkoFormData',
  initialState,
  reducers: {
    resetFkoToInitial: (state) => {
      Object.assign(state, initialState);
    },
    setFkoUserData: (state, action: PayloadAction<FkoUserData | null>) => {
      state.userData = action.payload;
    },
    setFkoInitialParams: (state, action: PayloadAction<FkoInitialParams>) => {
      state.initialParams = action.payload;
      if (action.payload.locationId) {
        state.siteId = action.payload.locationId;
      }
    },
    setFkoSiteId: (state, action: PayloadAction<string>) => {
      state.siteId = action.payload;
    },
    setFkoPlayersArray: (state, action: PayloadAction<FkoPlayer[]>) => {
      state.playersArray = action.payload;
    },
    addFkoPlayer: (state, action: PayloadAction<FkoPlayer>) => {
      const existingPlayerIndex = state.playersArray.findIndex((player) => player.playerId === action.payload.playerId);
      if (existingPlayerIndex === -1) {
        state.playersArray.push(action.payload);
      } else if (existingPlayerIndex > -1) {
        state.playersArray.splice(existingPlayerIndex, 1, action.payload);
      }
    },
    updateFkoPlayer: (state, action: PayloadAction<{playerId: string; update: Partial<FkoPlayer>}>) => {
      const {playerId, update} = action.payload;
      const player = state.playersArray.find((player) => player.playerId === playerId);
      if (player) {
        Object.assign(player, update);
      }
    },
    removeFkoPlayer: (state, action: PayloadAction<FkoPlayer>) => {
      state.playersArray = state.playersArray.filter((player) => player.playerId !== action.payload.playerId); //TODO .data?
    },
    /**
     * Generates future dates for given days ahead, without any appointment data.
     * This is needed to show a list of X days out into the future, regardless of
     * whether appointment data has been loaded for those days yet or not.
     */
    setFkoAppointmentDates: (
      state,
      action: PayloadAction<{daysAhead?: number; startDate?: string; endDate?: string}>
    ) => {
      const {startDate, endDate, daysAhead} = action.payload;
      state.appointmentCalendar = createDateMap({startDate, daysAhead});

      if (startDate && endDate) {
        state.appointmentDates = {startDate, endDate};
      } else {
        state.appointmentDates = null;
      }
    },
    setFkoAvailableAppointmentsFetching: (state, action: PayloadAction<{fetching: boolean; dateKeys: string[]}>) => {
      for (const dateKey of action.payload.dateKeys) {
        if (state.appointmentCalendar[dateKey]) {
          state.appointmentCalendar[dateKey].fetching = action.payload.fetching;
        }
      }
    },
    removeFkoAppointment: (state, action: PayloadAction<{session: Session}>) => {
      const {session} = action.payload;
      const dateStr = session.dateStr ?? '';

      state.appointmentCalendar[dateStr].appointments = state.appointmentCalendar[dateStr].appointments.filter(
        (appt) => appt.id !== session.id
      );
      state.removedSessions.push(session);
    },
    addFkoAppointment: (state, action: PayloadAction<{session: Session}>) => {
      const {session} = action.payload;
      const dateStr = session.dateStr ?? '';

      state.appointmentCalendar[dateStr].appointments.push(session);
      state.appointmentCalendar[dateStr].appointments.sort(
        (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      );

      if (state.removedSessions?.length) {
        state.removedSessions = state.removedSessions.filter((removedSession) => removedSession.id !== session.id);
      }
    },
    setFkoAvailableAppointments: (state, action: PayloadAction<SetFkoAvailableAppointments>) => {
      const {appointments, datesInRange} = action.payload;

      if (appointments) {
        state.appointmentCalendar = {
          ...state.appointmentCalendar,
          ...appointments.reduce((map: AppointmentCalendar, appointment: Session) => {
            const dateInfo = parseDateTime(appointment.startDate);
            map[dateInfo.dateStr] = map[dateInfo.dateStr] || {
              ...dateInfo,
              appointments: [],
              fetching: false,
              hasFetched: true,
            };
            appointment.dateStr = dateInfo.dateStr; // add dateStr on appointment for easy map lookup later on
            if (
              !state.removedSessions?.length ||
              !state.removedSessions.find((session) => session.id === appointment.id)
            ) {
              map[dateInfo.dateStr].appointments.push(appointment);
            }
            return map;
          }, {}),
        };
      }

      // Make sure all dates in range get the updated fetch status, even if no data was returned for a day
      if (datesInRange?.length) {
        for (const dateStr of datesInRange) {
          if (state.appointmentCalendar[dateStr]) {
            Object.assign(state.appointmentCalendar[dateStr], {
              fetching: false,
              hasFetched: true,
            });
          } else {
            console.warn(`FKO appointment calendar entry missing for ${dateStr}`);
          }
        }
      }
    },
  },
});

export const {
  resetFkoToInitial,
  setFkoUserData,
  setFkoInitialParams,
  setFkoSiteId,
  addFkoPlayer,
  updateFkoPlayer,
  removeFkoPlayer,
  setFkoPlayersArray,
  setFkoAppointmentDates,
  setFkoAvailableAppointmentsFetching,
  setFkoAvailableAppointments,
  addFkoAppointment,
  removeFkoAppointment,
} = fkoFormDataSlice.actions;

export default fkoFormDataSlice.reducer;

import {Helmet} from 'react-helmet';
import {LoadingAnimationFullHeight, LoadingAnimationOverlay} from 'components/Loader/LoadingAnimation';
import {PageHeading} from 'components/Connects/PageHeading';
import {LogoBanner} from 'components/Connects/LogoBanner';
import {UpcomingCalendar} from 'components/Connects/UpcomingCalendar/UpcomingCalendar';
import {useGetPlayerDetails} from 'api/User/useGetPlayerDetails';
import {useProfiles} from 'api/User/useProfiles';
import {ProfileBar} from 'components/ProfileBar/ProfileBar';

export const AthleteCalendar = () => {
  const {currentProfile, currentProfileTeam} = useProfiles();
  const {data: playerDetails, isLoading, isFetching} = useGetPlayerDetails();

  return (
    <>
      <Helmet>
        <title>MyTOCA | Upcoming</title>
      </Helmet>

      {isLoading ? (
        <LoadingAnimationFullHeight />
      ) : playerDetails ? (
        <>
          <LogoBanner />
          <ProfileBar playerDetails={playerDetails} teamColor={currentProfileTeam?.teamColor || ''} />
          <div className="my-6">
            <PageHeading text="Upcoming" />
          </div>

          {currentProfile.mboDetails ? (
            <UpcomingCalendar
              athleteAge={playerDetails?.age}
              baselineSessions={playerDetails?.baselineSessions}
              currentPathway={playerDetails.pathways?.currentPathway}
              lastSession={playerDetails.pathways?.lastSession}
              clientId={currentProfile.mboDetails.clientId}
              siteId={currentProfile.mboDetails.siteId}
            />
          ) : null}
        </>
      ) : (
        <div>
          <p>unable to load athlete info</p>
        </div>
      )}
      <LoadingAnimationOverlay isLoading={isFetching} />
    </>
  );
};

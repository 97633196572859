import {Helmet} from 'react-helmet';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import moment from 'moment';
import {useGetAthleteInfo} from 'api/Trainer/useGetAthleteInfo';
import {LoadingAnimationFullHeight} from 'components/Loader/LoadingAnimation';
import {PageHeading} from 'components/Connects/PageHeading';
import {ButtonTray} from 'components/Connects/ButtonTray';
import {Button} from 'components/Connects/Button';
import {LogoBanner} from 'components/Connects/LogoBanner';
import {ProfileBar} from 'components/ProfileBar/ProfileBar';
import {PlayerAssessmentConnectsBaselineSessionData} from 'components/Connects/PlayerAssessmentBaselineSessionData/PlayerAssessmentConnectsBaselineSessionData';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useProfiles} from 'api/User/useProfiles';
import {useUpdateSessionSelection} from 'api/Trainer/useUpdateSessionSelection';
import {SetSessionSelectionRequestPayload} from 'api/api';
import {useGetPlayerLastSessionSummary} from 'api/Trainer/useGetPlayerLastSessionSummary';
import {ConnectsBaselineSessionData} from 'components/Connects/BaselineSessionData/ConnectsBaselineSessionData';

export const ConnectsBaselineData = () => {
  const navigate = useNavigate();
  const {currentProfile} = useProfiles();
  const {trainerID} = useParams();
  const [searchParams] = useSearchParams();
  const [clientId, siteId] = [searchParams.get('mboClientId'), searchParams.get('mboSiteId')];
  const [selectedSessionIndexes, setSelectedSessionsIndexes] = useState<number[]>([]);

  const {data: athleteInfo, isLoading} = useGetAthleteInfo({clientId, siteId});

  //Currently using this only for player assessment session, TODO: Need to refactor to use the same hook for both
  const {data: playerLastSessionSummary, isLoading: isLoadingPlayerLastSessionSummary} = useGetPlayerLastSessionSummary(
    {clientId, siteId}
  );

  const isSessionSelectionCompleted = useMemo(
    () =>
      playerLastSessionSummary?.exerciseSummary?.find((exercise) => {
        return 'confirmedBy' in exercise;
      }),
    [playerLastSessionSummary]
  );

  useEffect(() => {
    if (!isSessionSelectionCompleted && playerLastSessionSummary?.exerciseSummary) {
      const defaultSelectedIndexes: number[] = [];
      for (let i = 0; i < playerLastSessionSummary.exerciseSummary.length; i++) {
        const exercise = playerLastSessionSummary.exerciseSummary[i];
        if (exercise.defaultSelected) {
          defaultSelectedIndexes.push(i);
        }
      }
      setSelectedSessionsIndexes(defaultSelectedIndexes);
    }
  }, [isSessionSelectionCompleted, playerLastSessionSummary]);

  const startDate = moment(playerLastSessionSummary?.startTime).format('MMM DD, YYYY');

  const {setSessionSelection} = useUpdateSessionSelection({clientId});

  const toggleSessionSelection = useCallback(
    (sessionIndex: number) => {
      if (!selectedSessionIndexes.includes(sessionIndex)) {
        setSelectedSessionsIndexes((prevSelection) => [...prevSelection, sessionIndex]);
      } else {
        setSelectedSessionsIndexes((prevSelection) =>
          prevSelection.filter((selectedIndex) => selectedIndex !== sessionIndex)
        );
      }
    },
    [setSelectedSessionsIndexes, selectedSessionIndexes]
  );

  const confirmSessionSelectionHandler = () => {
    // TODO It might be better to always use the profile `_id` instead of the optional `mboDetails.clientId`
    const trainerId = currentProfile?.mboDetails?.clientId || currentProfile._id;

    const requestPayload: SetSessionSelectionRequestPayload = {
      clientSessionSummaryId: playerLastSessionSummary?.clientSessionSummaryId || '',
      trainerId: trainerId || '',
      selectedExercisesIndex: selectedSessionIndexes,
    };
    setSessionSelection(requestPayload);
  };

  const onClickContinueHandler = () => {
    navigate(`/trainer/connects?trainer=${trainerID}&mboClientId=${clientId}&mboSiteId=${siteId}&baselineVisited=true`);
  };

  useEffect(() => {
    if (!isLoading && !athleteInfo?.lastSession?.isBaseline) {
      navigate(
        `/trainer/connects?trainer=${trainerID}&mboClientId=${clientId}&mboSiteId=${siteId}&baselineVisited=true`,
        {replace: true}
      );
    }
  }, [isLoading, athleteInfo, navigate, trainerID, clientId, siteId]);

  const isPlayerAssessmentSession = playerLastSessionSummary?.isPlayerAssessment;
  const heading = isPlayerAssessmentSession ? 'Review and Approve Player Data' : 'Baseline Session Data';

  return (
    <>
      <Helmet>
        <title>MyTOCA | Connects</title>
      </Helmet>

      {isLoading || isLoadingPlayerLastSessionSummary ? (
        <LoadingAnimationFullHeight />
      ) : athleteInfo ? (
        <div className="grow flex flex-col">
          <LogoBanner />
          <ProfileBar athleteInfo={athleteInfo} />

          <div className="my-6 text-center">
            <PageHeading text={heading} />
          </div>

          {playerLastSessionSummary?.isBaseline && isPlayerAssessmentSession ? (
            <>
              <div className="exceed-global-padding lg:max-w-[800px] lg:mx-auto lg:w-full">
                <PlayerAssessmentConnectsBaselineSessionData
                  sessionSummary={playerLastSessionSummary}
                  toggleSessionSelection={toggleSessionSelection}
                  enableSessionSelection={!isSessionSelectionCompleted}
                  selectedSessionIndexes={selectedSessionIndexes}
                  date={startDate}
                />
              </div>
            </>
          ) : athleteInfo.lastSession?.isBaseline ? (
            <div className="exceed-global-padding lg:max-w-[800px] lg:mx-auto lg:w-full">
              <ConnectsBaselineSessionData session={athleteInfo.lastSession} />
            </div>
          ) : (
            <div className="grid place-content-center">
              <p className="text-sm text-grey-xdark">Error loading baseline session data</p>
            </div>
          )}

          <div className="md:mx-auto md:max-w-[600px] md:w-full mt-8">
            <ButtonTray>
              <Button
                text={!isPlayerAssessmentSession || isSessionSelectionCompleted ? 'Continue' : 'Confirm Selection'}
                color="secondary"
                variant="cta"
                onClick={
                  !isPlayerAssessmentSession || isSessionSelectionCompleted
                    ? onClickContinueHandler
                    : confirmSessionSelectionHandler
                }
              />
            </ButtonTray>
          </div>
        </div>
      ) : (
        <div className="grow flex flex-col">
          <p className="text-sm text-grey-xdark text-center p-4">unable to load athlete data</p>
        </div>
      )}
    </>
  );
};

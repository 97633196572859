import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {useAppDispatch} from '../../redux/reduxUtils';
import {setFkoAvailableAppointments, setFkoAvailableAppointmentsFetching} from '../../redux/slices/fkoFormData';
import {getDatesInRange} from '../../utils/utils';
import {mbo} from '../api';

export type AvailableSessionsQuery = {
  siteId: string;
  startDate?: string;
  endDate?: string;
  staffIds?: string[];
  programIds?: string[];
  sessionTypeIds?: string[];
  sessionTypeNames?: string[];
  onlyAvailable?: boolean;
  onlyBookable?: boolean;
};

export type GetAvailableSessionsParams = Omit<AvailableSessionsQuery, 'siteId'> & {
  siteId?: string; // siteId actally is required, as it sets the query to 'enabled'
};

/**
 * Gets available sessions (bookable appointments).
 *
 * (do NOT use this for FKO sessions, see: 'useGetFkoAvailableSessions')
 */
export const useGetAvailableSessions = (params: GetAvailableSessionsParams) => {
  const dispatch = useAppDispatch();

  const paramStrings: string[] = [];
  if (params) {
    for (const [key, value] of Object.entries(params)) {
      if (Array.isArray(value)) {
        value.forEach((val, i) => {
          paramStrings.push(`${key}[${i}]=${encodeURIComponent(val)}`);
        });
      } else if (value) {
        paramStrings.push(`${key}=${encodeURIComponent(value)}`);
      }
    }
  }
  const query = paramStrings.join('&');

  const datesInRange = getDatesInRange({
    startDate: params.startDate,
    endDate: params.endDate,
  });

  return useQuery<AvailableSessionResponse>({
    queryKey: [`booking`, 'appointments', query],
    queryFn: () => {
      dispatch(setFkoAvailableAppointmentsFetching({fetching: true, dateKeys: datesInRange}));
      return axios.get(`${mbo}/available-sessions?${query}`).then((res) => res.data);
    },
    enabled: !!datesInRange && !!params.siteId,
    staleTime: 60000,
    onSuccess: (data) => {
      const _sessions = data.availableSessions.map((session) => ({
        id: session._id,
        startDate: session.startDateTime,
        endDate: session.endDateTime,
        sessionType: {id: Number(session.sessionTypeId), name: session.sessionTypeName},
        staff: {id: Number(session.staffId), firstName: session.staffFirstName, lastName: session.staffLastName},
        location: {
          ...session.location,
          id: session.location.id ?? 0, // this really shouldn't ever be null, but just in case
        },
      }));
      dispatch(setFkoAvailableAppointments({appointments: [..._sessions], datesInRange: [...datesInRange]}));
    },
    onSettled: () => {
      dispatch(setFkoAvailableAppointmentsFetching({fetching: false, dateKeys: datesInRange}));
    },
  });
};

export type AvailableSessionResponse = {
  count: number;
  availableSessions: AvailableSession[];
};

export interface AvailableSession {
  _id: string; // Types.ObjectId;
  isAvailable: boolean;
  blockedByAppointmentId: string | null;
  availabilityId: string;
  siteId: string;
  staffId: string;
  staffFirstName: string;
  staffLastName: string;
  staffDisplayName: string;
  startDateTime: string; // Date;
  endDateTime: string; // Date;
  bookableEndDateTime: string; // Date;
  durationMinutes?: number;
  programId: string;
  programName: string;
  sessionTypeId: string;
  sessionTypeName: string;
  scheduleType?: string;
  cancelOffset: number;
  contentFormats?: string[];
  location: AvailabilityLocationInfo & {id: number | null};
  meta: {
    utcOffset: number;
    originalTimeBlockStartDate: string; // Date;
    originalTimeBlockEndDate: string; // Date;
    isMasked: boolean;
    showPublic: boolean;
  };
  // createdAt: Date;
  // updatedAt: Date;
}

export interface AvailabilityLocationInfo {
  id: number;
  name?: string;
  phone?: string;
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
}

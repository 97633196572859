import React from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {FormInput} from '../FormInput/FormInput';
import {FormButton} from '../FormButton/FormButton';
import {FormSelect, FormSelectOption} from '../FormSelect/FormSelect';

export type FormFieldConfig = {
  name: string;
  type: string;
  placeholder: string;
  validation: yup.AnySchema;
  spellCheck?: boolean;
  options?: FormSelectOption[];
};

export type FormData = Record<string, string>;

type FormFieldsProps = {
  ctaButtonText?: string;
  onSubmit: (data: FormData) => void;
  errorMessage?: string;
  fields: FormFieldConfig[];
  isLoading?: boolean;
};

export const CommonRegistrationForm = ({
  ctaButtonText = 'Continue',
  onSubmit,
  errorMessage,
  fields,
  isLoading = false,
}: FormFieldsProps) => {
  const formSchema = yup.object().shape(
    fields.reduce((acc, field) => {
      acc[field.name] = field.validation;
      return acc;
    }, {} as Record<string, yup.AnySchema>)
  );

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<FormData>({
    mode: 'onBlur',
    resolver: yupResolver(formSchema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="grow w-full">
      {fields.map((field) => (
        <React.Fragment key={field.name}>
          {field.type === 'select' ? (
            <FormSelect
              {...register(field.name)}
              options={field.options}
              placeholderValue={field.placeholder}
              hasError={!!errors[field.name]?.message}
            />
          ) : (
            <FormInput
              spellCheck={field.spellCheck ?? false}
              {...register(
                field.name,
                field.name === 'email' ? {setValueAs: (val: string) => val.toLowerCase()} : undefined
              )}
              type={field.type}
              id={field.name}
              placeholder={field.placeholder}
              hasError={!!errors[field.name]?.message}
            />
          )}
          <p className="text-xs text-alert mb-1 ml-1">{(errors[field.name]?.message as string) ?? '\u00A0'}</p>
        </React.Fragment>
      ))}

      <div className="h-[13px] block" />

      <p className="text-xs text-alert mb-1">{errorMessage ?? '\u00A0'}</p>

      <FormButton type="submit" isPrimary hasError={Object.keys(errors).length !== 0} disabled={isLoading}>
        {isLoading ? 'Loading...' : ctaButtonText}
      </FormButton>
    </form>
  );
};

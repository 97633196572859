import {Outlet} from 'react-router-dom';
import {Navbar} from '../components/Navbar/Navbar';
import {PageContainer} from 'components/PageContainer/RevisedPageContainer';

export default function TrainerNavbarLayout() {
  return (
    <PageContainer>
      <Outlet />
      <Navbar />
    </PageContainer>
  );
}

import * as yup from 'yup';
import { FormFieldConfig } from "components/Forms/CommonRegistrationForm/CommonRegistrationForm";
import { PHONE_REGEX } from 'utils/utils';
import { GetHubspotFormDataForSubmissionProps, GetFormFieldsProps } from 'components/Forms/CommonRegistrationForm/CommonRegistrationPage';
import { TrackingStrings } from 'redux/slices/fkoFormData';

export type FreePickupAndLeaguesUrlParams = {
    [key in TrackingStrings]?: string;
} & {
    locations__drop_down_?: string;
    source?: string;
}

export const freePickupAndLeaguesInterestedInOptions = [
    {id: 'Leagues', value: 'Leagues', displayValue: 'Leagues'}, 
    {id: 'Pickup', value: 'Open Play', displayValue: 'Pickup'},
    {id: 'Both', value: 'Both Leagues & Open Play', displayValue: 'Both'}
]

export const getFreePickupAndLeaguesFormDataForSubmission = (
  {validLocation, urlParams, formData}: GetHubspotFormDataForSubmissionProps<FreePickupAndLeaguesUrlParams>
): Record<string, string> => {
    const {locations__drop_down_, source, ...otherUrlParams} = urlParams;
  
    const hubSpotData: Record<string, string> = {
      firstname: formData.firstname,
      lastname: formData.lastname,
      email: formData.email,
      phone: formData.phone,
      locations__drop_down_: validLocation || formData.location,
      i_am_interested_in___: formData.interestedIn,
      ...otherUrlParams,
    };
  
    if (source) {
      hubSpotData.source = source;
    }
  
    return hubSpotData;
  };
  
export const getFreePickupAndLeaguesFormFields = ({validLocation, locationsAllowed}: GetFormFieldsProps): FormFieldConfig[] => {
    const fields: FormFieldConfig[] = [
      {
        name: 'firstname',
        type: 'text',
        placeholder: 'First Name',
        validation: yup.string().trim().required('Please enter your first name'),
      },
      {
        name: 'lastname',
        type: 'text',
        placeholder: 'Last Name',
        validation: yup.string().trim().required('Please enter your last name'),
      },
      {
        name: 'email',
        type: 'email',
        placeholder: 'Email',
        validation: yup
          .string()
          .email('Email is invalid')
          .required('Email is required')
          .matches(/^[^+]+$/, 'Email must not contain "+" signs'),
      },
      {
        name: 'phone',
        type: 'tel',
        placeholder: 'Phone number',
        validation: yup
          .string()
          .required('Phone number is required')
          .matches(PHONE_REGEX, 'Must be a valid phone number'),
      },
      {
          name: 'interestedIn',
          type: 'select',
          placeholder: 'Interested In',
          validation: yup.string().required('Please select an option'),
          options: freePickupAndLeaguesInterestedInOptions,
      },
    ];
  
    if (!validLocation) {
      fields.push({
        name: 'location',
        type: 'select',
        placeholder: 'Select Location',
        validation: yup.string().required('Please select a location'),
        options: locationsAllowed.map((location) => ({
          id: location,
          value: location,
          displayValue: location,
        })),
      });
    }
  
    return fields;
  };
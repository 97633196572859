import {memo} from 'react';
import CommonRegistrationPage from 'components/Forms/CommonRegistrationForm/CommonRegistrationPage';
import { getFTCDSRFormDataForSubmission, getFTCDSRFormFields } from 'common/ftcDsrUtils';
import { FreeTrialProgram, relevantFTCDSRUrlParams } from 'common/fko-utils';


const FtcDsrRegistrationPage = memo(() => {
  const locationsAllowed = ['Denver', 'Loganville', 'Farmington', 'Cincinnati', 'Toledo'];

  return (
    <CommonRegistrationPage 
      programName={FreeTrialProgram.FTC_DSR} 
      locationsAllowed={locationsAllowed} 
      formId={process.env.REACT_APP_HUBSPOT_FTC_DSR_CLASS_FORM_ID ?? ''}
      relevantUrlParams={relevantFTCDSRUrlParams} 
      getFormFields={getFTCDSRFormFields}
      getHubspotFormDataForSubmission={getFTCDSRFormDataForSubmission}
    />
  );
});

FtcDsrRegistrationPage.displayName = 'FtcDsrClassForm';

export default FtcDsrRegistrationPage;

export const bookingLocLockEnabled = process.env.REACT_APP_BOOKINGS_LOCK_ENABLED === 'true';
export const bookingPermittedLocIds = process.env.REACT_APP_BOOKINGS_PERMITTED_LOCATIONS?.split(',');

/**
 * Determines whether the given location is permitted to see and create bookings in the app.
 * @param locationId The MBO site ID.
 */
export const locationAllowsBookings = (locationId: string | undefined | null) => {
  if (!locationId) {
    return false;
  }
  return !bookingLocLockEnabled || !!bookingPermittedLocIds?.includes(locationId);
};

import {TransformedLocationInfo} from 'pages/CenterDiscovery/Utils/dataTransformer';
import React, {forwardRef, useEffect} from 'react';
import {Map as MapboxMap, Marker, MapRef} from 'react-map-gl';
import {LocationMarker} from '../LocationMarker/LocationMarker';
import {initialViewState} from 'pages/CenterDiscovery/constants';

interface MapProps {
  tocaLocations: TransformedLocationInfo[];
  activeLocationIndexInList: number;
  setActiveLocationListIndex: (index: number) => void;
}

/**
 * Renders a Mapbox map component with markers for TOCA locations.
 *
 * @component
 * @param {React.Ref<MapRef>} ref - The ref object for accessing the map component.
 * @param {MapProps} props - The props for configuring the map component.
 * @returns {JSX.Element} The rendered map component.
 */
const Map: React.ForwardRefRenderFunction<MapRef, MapProps> = (
  {tocaLocations, activeLocationIndexInList, setActiveLocationListIndex},
  ref
) => {
  const [activeTooltipLocationIndex, setActiveTooltipLocationIndex] = React.useState<number>(-1);

  useEffect(() => {
    setActiveTooltipLocationIndex(-1);
  }, [activeLocationIndexInList]);

  const onClickLocationMarkerHandler = React.useCallback(
    (index: number) => {
      // If the location marker is already active, close the tooltip
      if (index === activeTooltipLocationIndex) {
        setActiveTooltipLocationIndex(-1);
      } else {
        // Otherwise, open the tooltip and select the location on map
        setActiveLocationListIndex(index);
        setTimeout(() => {
          setActiveTooltipLocationIndex(index);
        }, 0);
      }
    },
    [activeTooltipLocationIndex, setActiveLocationListIndex]
  );

  return (
    <MapboxMap
      ref={ref}
      mapboxAccessToken={process.env.REACT_APP_MAP_BOX_ACCESS_KEY}
      initialViewState={initialViewState}
      mapStyle={process.env.REACT_APP_MAP_BOX_STYLE}
    >
      {tocaLocations.map((location, index) => (
        <Marker key={location.siteId} longitude={location.coordinates.lon} latitude={location.coordinates.lat}>
          <LocationMarker
            number={index + 1}
            address={location.address}
            state={location.state}
            name={location.name}
            logo={location.logo}
            open={activeTooltipLocationIndex === index}
            onClickLocationMarkerHandler={onClickLocationMarkerHandler}
          />
        </Marker>
      ))}
    </MapboxMap>
  );
};

export default forwardRef(Map);

import {memo} from 'react';
import styles from './ListHeading.module.scss';

export const ListHeading = memo(() => {
  return (
    <div className={styles.container}>
      <p>Rank</p>
      <p className={styles.name}>Name</p>
      <p className={styles.score}>Score</p>
      {/* <p className={styles.score}>Speed</p> */}
    </div>
  );
});
ListHeading.displayName = 'ListHeading';

import {useParams} from 'react-router-dom';
import {useMemo} from 'react';
import {useGetCoachInfo} from 'api/Coach/useGetCoachInfo';
import {useProfiles} from 'api/User/useProfiles';
import {toast} from 'react-toastify';
import {roleType} from 'constants/constants';
import {InviteView} from './Invite.view';

export const CoachInvite = () => {
  const {teamId} = useParams();
  const {currentProfile} = useProfiles();
  const {data: coachInfo, isLoading} = useGetCoachInfo(currentProfile?._id);

  const teamInfo = useMemo(() => coachInfo?.teams.find((team) => team._id === teamId), [teamId, coachInfo?.teams]);
  const inviteLink = `${window.location.origin}/team-onboarding?teamId=${teamId}&role=${roleType.player}&locationId=${teamInfo?.siteId}`;

  const copyInviteLink = () => {
    navigator.clipboard.writeText(inviteLink);
    toast.success('Invite link copied to clipboard');
  };

  const handleInviteLinkShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Player invite link!',
          url: inviteLink,
        });
      } catch (error) {
        copyInviteLink();
      }
    } else {
      copyInviteLink();
    }
  };

  return <InviteView isLoading={isLoading} inviteLink={inviteLink} handleInviteLinkShare={handleInviteLinkShare} />;
};

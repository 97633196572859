import {memo, useEffect, useMemo, useState} from 'react';
import styles from './SessionsList.module.scss';
import moment from 'moment';
import {Visit} from 'api/Booking/useGetMboVisits';
import {parseVisits} from 'utils/booking-utils';
import {ReactComponent as ConeBallIcon} from '../../../assets/img/icons/redesign-icons/cone-ball.svg';
import {Pathway, PlayerDetails, TrainingSession} from 'api/User/useGetPlayerDetails';

type ScheduleVisit = Partial<Visit> & {
  sessionNumber: number;
  formattedDate?: string;
  isBaseline?: boolean;
};

type SessionsListProps = {
  visits?: Visit[];
  lastSession?: TrainingSession;
  currentPathway?: Pathway | null;
  baselineSessions?: PlayerDetails['baselineSessions'];
};

export const SessionsList = memo(({visits, lastSession, currentPathway}: SessionsListProps) => {
  const [sessions, setSessions] = useState<ScheduleVisit[]>();

  // total sessions (sessions per pathway) defaults to 4
  const totalSessions = useMemo(() => currentPathway?.totalSessions ?? 4, [currentPathway?.totalSessions]);
  const lastSessionNumber = useMemo(() => lastSession?.pathwaySessionNumber ?? 0, [lastSession?.pathwaySessionNumber]);
  const remainingSessions = useMemo(
    () => Math.max(0, totalSessions - lastSessionNumber),
    [lastSessionNumber, totalSessions]
  );
  // const lastBaselineDate = useMemo(
  //   () =>
  //     baselineSessions?.length
  //       ? moment(baselineSessions?.sort((a, b) => a.startTime - b.startTime)?.[0].endTime).format('MMMM D[,] YYYY')
  //       : '',
  //   [baselineSessions]
  // );

  const processSessions = (
    visits: Visit[] = [],
    lastSessionNumber: number,
    remainingSessions: number
  ): ScheduleVisit[] => {
    const sessions: ScheduleVisit[] = visits
      .sort((a, b) => Number(a.StartDateTime) - Number(b.StartDateTime))
      .filter((visit) => moment(visit.StartDateTime).isBefore(moment().add(40, 'days')))
      .map((visit, i) => {
        // if (i > remainingSessions) we're past the next Baseline, and the count should start over
        const sessionNumber = i >= remainingSessions ? i - remainingSessions : lastSessionNumber + i + 1;

        return {
          ...visit,
          sessionNumber,
          isBaseline: i === remainingSessions,
          formattedDate: moment(visit.StartDateTime).format(`MMMM D[,] YYYY`),
        };
      });

    if (sessions.length < remainingSessions) {
      while (sessions.length < remainingSessions) {
        const _sessionNumber = sessions?.length ? sessions[sessions.length - 1].sessionNumber : lastSessionNumber;
        sessions.push({
          sessionNumber: _sessionNumber + 1,
        });
      }
      sessions.push({
        sessionNumber: sessions?.length + 1,
        isBaseline: true,
      });
    }

    return sessions;
  };

  useEffect(() => {
    if (visits) {
      const visitsToDisplay = Math.max(remainingSessions, totalSessions);
      const {nearFutureVisits} = parseVisits({visits, limit: visitsToDisplay});
      setSessions(processSessions(nearFutureVisits, lastSessionNumber, remainingSessions));
    } else {
      setSessions(undefined);
    }
  }, [visits, lastSessionNumber, remainingSessions, totalSessions]);

  if (!sessions) {
    return null;
  }

  return (
    <>
      <ul className={styles.sessionList}>
        {remainingSessions > 0 ? (
          <li>
            {/* previous Baseline card */}
            {/* <div className={styles.baselineCard}>
            <div className={styles.adornment}>
              <ConeBallIcon />
            </div>
            <div className={styles.sessionDetails}>
              <p className={styles.baselineText}>Last Baseline</p>
              <p className={styles.baselineDate}>{lastBaselineDate}</p>
            </div>
            </div> */}
            <div className={styles.baselineCountdown}>
              <p>{`${remainingSessions} Session${remainingSessions === 1 ? '' : 's'} to Next Baseline`}</p>
            </div>
          </li>
        ) : null}

        {sessions.map((session, i) =>
          session.isBaseline ? (
            <li key={`baseline_card_${i}`} className={styles.baselineCard}>
              <div className={`${styles.adornment} ${styles.upcoming}`}>
                <ConeBallIcon />
              </div>

              <div className={styles.sessionDetails}>
                <p className={styles.baselineText}>Next Baseline</p>
                {session.formattedDate ? (
                  <p className={styles.baselineDate}>{session.formattedDate}</p>
                ) : (
                  <p className={`${styles.baselineDate} ${styles.error}`}>Not Yet Booked 😢</p>
                )}
              </div>
            </li>
          ) : (
            <li key={`session_card_${i}`} className={styles.sessionCard}>
              <div className={styles.adornment}>
                <p>Session</p>
                <p className={styles.number}>{session.sessionNumber}</p>
              </div>

              <div className={styles.sessionDetails}>
                {session.formattedDate ? (
                  <p className={styles.date}>{session.formattedDate}</p>
                ) : (
                  <p className={`${styles.date} ${styles.error}`}>Not Yet Booked 😢</p>
                )}
              </div>
            </li>
          )
        )}
      </ul>
    </>
  );
});
SessionsList.displayName = 'SessionsList';

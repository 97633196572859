import {memo, ReactNode, useState} from 'react';
import {PerformanceMetric, TrainingSession} from 'api/User/useGetPlayerDetails';
import {baselineBenchmarkValues, ExerciseBenchmark} from 'pages/Baseline/Benchmarks/BaselineBenchmarks';
import {ReactComponent as Chevron} from '../../../assets/img/icons/redesign-icons/chevron-down.svg';

export const MobileBaselineSessionTable = memo(({sessionData}: {sessionData?: TrainingSession}) => {
  const benchmarks = baselineBenchmarkValues;
  const levelBenchmark = benchmarks.find((benchmark) => benchmark.level === (sessionData?.baselineLevel || 'beginner'));

  if (!levelBenchmark || !sessionData?.performance) {
    return null;
  }
  const {exercises} = sessionData.performance;

  return (
    <>
      {levelBenchmark?.benchmarks.map((benchmark, i) => {
        const exercise = exercises?.[i];
        return exercise ? (
          <_ExerciseSection key={`benchmark_${i}`} benchmark={benchmark} exercise={exercise} defaultOpen={true} />
        ) : null;
      })}
    </>
  );
});
MobileBaselineSessionTable.displayName = 'MobileBaselineSessionTable';

const _ExerciseSection = ({
  benchmark,
  exercise,
  defaultOpen = false,
}: {
  benchmark: ExerciseBenchmark;
  exercise: PerformanceMetric;
  defaultOpen?: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultOpen);

  return (
    <div
      className={`${
        isExpanded ? 'max-h-[600px]' : 'max-h-[48px]'
      } w-full overflow-hidden transition-all border border-grey-light border-b-0 last-of-type:border-b lg:last-of-type:rounded-b`}
    >
      <button
        onClick={() => setIsExpanded((prev) => !prev)}
        className="h-[48px] w-full px-6 py-4 bg-white flex justify-end items-center"
      >
        <span className="grow text-base text-left text-tertiary font-semibold overflow-hidden whitespace-nowrap text-ellipsis">
          {benchmark.title}
        </span>
        <Chevron className={`${isExpanded ? 'rotate-180' : ''} w-4 h-4 fill-grey-xdark opacity-75 transition-all`} />
      </button>

      <div className="border-t border-grey-light">
        <_SessionTable benchmark={benchmark} exercise={exercise} />
      </div>
    </div>
  );
};

const _SessionTable = memo(({benchmark, exercise}: {benchmark: ExerciseBenchmark; exercise: PerformanceMetric}) => {
  return (
    <>
      <table className="grid grid-cols-[minmax(min-content,2fr),minmax(min-content,1fr),minmax(min-content,2fr),minmax(min-content,1fr)]">
        <thead className="contents">
          <tr className="contents text-xs sm:text-sm tracking-[-0.25px] text-primary">
            <th className="font-semibold bg-tertiary bg-opacity-20">
              <_Cell>Target Streak</_Cell>
            </th>
            <th className="font-normal bg-white">
              <_Cell>Streak</_Cell>
            </th>
            <th className="font-semibold bg-tertiary bg-opacity-20">
              <_Cell>Target Accuracy</_Cell>
            </th>
            <th className="font-normal bg-white">
              <_Cell>Accuracy</_Cell>
            </th>
            <th className="row-start-3 font-semibold bg-tertiary bg-opacity-20">
              <_Cell>Target SOP</_Cell>
            </th>
            <th className="row-start-3 font-normal bg-white">
              <_Cell>SOP</_Cell>
            </th>
            <th className="row-start-3 font-semibold bg-tertiary bg-opacity-20">
              <_Cell>Target MPH</_Cell>
            </th>
            <th className="row-start-3 font-normal bg-white">
              <_Cell>MPH</_Cell>
            </th>
          </tr>
        </thead>

        <tbody className="contents">
          <tr className="contents text-sm sm:text-base text-primary">
            <td className="bg-tertiary bg-opacity-25">
              <_Cell>{benchmark?.streak ?? '-'}</_Cell>
            </td>
            <td className="bg-grey-xlight">
              <_Cell>{exercise?.streak ?? '-'}</_Cell>
            </td>
            <td className="bg-tertiary bg-opacity-25">
              <_Cell>{benchmark?.accuracy ? `${benchmark.accuracy * 100}%` : '-'}</_Cell>
            </td>
            <td className="bg-grey-xlight">
              <_Cell>{exercise?.accuracy ? `${exercise.accuracy}%` : '-'}</_Cell>
            </td>
            <td className="row-start-4 bg-tertiary bg-opacity-25">
              <_Cell>{benchmark?.sop ?? '-'}</_Cell>
            </td>
            <td className="row-start-4 bg-grey-xlight">
              <_Cell>{exercise?.speedOfPlay ?? '-'}</_Cell>
            </td>
            <td className="row-start-4 bg-tertiary bg-opacity-25">
              <_Cell>{benchmark?.mph ?? '-'}</_Cell>
            </td>
            <td className="row-start-4 bg-grey-xlight">
              <_Cell>{exercise?.ballSpeed ?? '-'}</_Cell>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
});
_SessionTable.displayName = '_SessionTable';

const _Cell = ({children}: {children: ReactNode}) => {
  return <p className="h-[48px] min-h-[48px] grid place-content-center uppercase p-1">{children}</p>;
};

import {useState} from 'react';
import {LogoBanner} from 'components/Connects/LogoBanner';
import Spinner from 'components/Loader/Spinner';
import {PageContainer} from 'components/PageContainer/RevisedPageContainer';
import {Team} from 'user/user.interface';
import {ReactComponent as InviteIcon} from '../../../assets/img/icons/invite-icon.svg';
import {useProfiles} from 'api/User/useProfiles';
import {isDirector} from 'user/user-utils';
import {AddTeamModal} from 'pages/Director/AddTeamModal/AddTeamModal';

export const TeamsView = ({
  loading,
  teams,
  onTeamSelect,
  logout,
  role,
}: {
  loading: boolean;
  teams: Team[] | undefined;
  onTeamSelect: (teamId: string) => void;
  logout: () => void;
  role: string;
}) => {
  const [showAddTeamModal, setShowAddTeamModal] = useState<boolean>(false);
  const {currentProfile} = useProfiles();
  const isDirectorRole = isDirector(currentProfile);

  return (
    <PageContainer>
      {loading ? (
        <div className="w-full h-screen flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          <LogoBanner />
          <div className="flex flex-col items-center">
            <div className="w-full flex flex-col justify-center items-center lg:items-start mt-4 ">
              <div className="text-xl text-primary font-bold">SELECT TEAM</div>
              <div className="flex flex-wrap lg:justify-start justify-center mt-4">
                {!teams?.length ? (
                  <div className="flex flex-col">
                    <div className="text-base">This {role} is not part of any team</div>
                    <div className="text-primary text-base mt-2 cursor-pointer" onClick={() => logout()}>
                      Logout
                    </div>
                  </div>
                ) : (
                  teams?.map((team) => {
                    const teamName = team?.teamName || '';
                    return (
                      <div
                        onClick={() => onTeamSelect(team._id)}
                        key={team._id}
                        data-test-id={teamName}
                        className="h-36 w-[102px] mr-2 mt-1 mb-1 rounded-md border-grey border flex flex-col items-center cursor-pointer shadow-lg"
                      >
                        <img src={team?.logoUrl} className="object-cover rounded-md mt-4 min-h-16 min-w-16 h-16 w-16" />
                        <div className="text-md mt-5 w-full text-center pl-2 pr-2 line-clamp-2 " title={teamName}>
                          {teamName}
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
          {isDirectorRole && (
            <>
              <AddTeamModal showModal={showAddTeamModal} onClose={() => setShowAddTeamModal(false)} />
              <div className="fixed md:absolute right-2 md:right-6 bottom-8 cursor-pointer">
                <InviteIcon data-test-id="add-team" onClick={() => setShowAddTeamModal(true)} />
              </div>
            </>
          )}
        </>
      )}
    </PageContainer>
  );
};

import {memo, useCallback, useState} from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {createMboClients} from 'api/api';
import {Button} from 'components/Connects/Button';
import {StandardInput} from 'components/Input/StandardInput/StandardInput';
import Spinner from 'components/Loader/Spinner';
import {useQueryClient} from '@tanstack/react-query';
import {setFkoPlayersArray} from 'redux/slices/fkoFormData';
import {useAppDispatch, useAppSelector} from 'redux/reduxUtils';

type PlayerCreationFormProps = {
  email: string;
  siteId: string;
  closeForm: () => void;
  isLoggedInUser?: boolean;
};

type AddPlayerSubmission = {firstName: string; lastName: string; birthdate: string};

export const PlayerCreationForm = memo(
  ({email, siteId, closeForm, isLoggedInUser = false}: PlayerCreationFormProps) => {
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();
    const {playersArray} = useAppSelector((state) => state.fkoFormDataReducer);

    const [showDateInput, setShowDateInput] = useState(false);
    const [autoFocusDateInput, setAutoFocusDateInput] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();

    const restrictedNamePairs = playersArray.map((player) =>
      !player.mboDetails || player.mboDetails.siteId === siteId
        ? [player.firstName.toLowerCase(), player.lastName.toLowerCase()]
        : []
    );

    const showDateInputAndFocus = () => {
      setAutoFocusDateInput(true);
      setShowDateInput(true);
    };

    const addNewPlayer = useCallback(
      async ({firstName, lastName, birthdate}: AddPlayerSubmission) => {
        setIsLoading(true);
        createMboClients({
          siteId,
          email,
          clients: [{firstName, lastName, birthdate}],
          isFko: true,
          returnProfiles: !isLoggedInUser,
        }).then((res) => {
          const error = res?.results?.find((entry) => !!entry?.error);
          if (error) {
            setErrorMessage(error.error);
            setIsLoading(false);
            // TODO pretty sure we can't have a logged in user here
          } else if (isLoggedInUser) {
            queryClient.invalidateQueries({queryKey: ['user'], refetchType: 'all'}).finally(() => {
              setIsLoading(false);
              closeForm();
            });
          } else {
            if (res.profiles?.length) {
              dispatch(setFkoPlayersArray(res.profiles));
            } else {
              // TODO CLASSES probably should do something
            }
            setIsLoading(false);
            closeForm();
          }
        });
      },
      [closeForm, queryClient, siteId]
    );

    // const addNewPlayer = useCallback(
    //   async ({firstName, lastName, birthdate}: AddPlayerSubmission) => {
    //     setIsLoading(true);
    //     createMboClients({siteId, clients: [{firstName, lastName, birthdate}]}).then((res) => {
    //       const error = res?.results?.find((entry) => !!entry?.error);
    //       const result = res?.results?.find((entry) => entry?.clientSideOnlyId);
    //       if (error) {
    //         console.log('error, result :>> ', error, result);
    //         setErrorMessage(error.error);
    //         setIsLoading(false);
    //       } else {
    //         queryClient.invalidateQueries({queryKey: ['user'], refetchType: 'all'}).finally(() => {
    //           setIsLoading(false);
    //           closeForm();
    //         });
    //       }
    //     });
    //   },
    //   [closeForm, queryClient, siteId]
    // );

    const validationSchema = yup.object().shape({
      firstName: yup
        .string()
        .trim('Name cannot include leading or trailing spaces')
        .test(
          'repeatname',
          'Sorry, player name must be different than other names on account',
          (firstName, testContext) => {
            const {lastName} = testContext.parent;
            if (!firstName || !lastName) {
              return true;
            }
            return !restrictedNamePairs.find(
              (namePairs) => namePairs[0] === firstName.toLowerCase() && namePairs[1] === lastName.toLowerCase()
            );
          }
        )
        .required('Required'),
      lastName: yup.string().trim('Name cannot include leading or trailing spaces').required('Required'),
      birthdate: yup.date().typeError('Required').required('Required'),
    });

    const {
      register,
      handleSubmit,
      formState: {errors},
    } = useForm<AddPlayerSubmission>({
      mode: 'onChange',
      resolver: yupResolver(validationSchema),
      reValidateMode: 'onChange',
    });

    return (
      <div>
        <form onSubmit={handleSubmit(addNewPlayer)}>
          <div>
            <StandardInput
              variant="new"
              spellCheck="false"
              {...register('firstName')}
              type="text"
              id="firstName"
              placeholder="Player First Name"
              hasError={!!errors.firstName?.message}
            />
            <p className="text-sm text-red-500">{errors.firstName?.message ?? '\u00A0'}</p>

            <StandardInput
              variant="new"
              spellCheck="false"
              {...register('lastName')}
              type="text"
              id="lastName"
              placeholder="Player Last Name"
              hasError={!!errors.lastName?.message}
            />
            <p className="text-sm text-red-500">{errors.lastName?.message ?? '\u00A0'}</p>

            {/* Number input placeholder and actual input */}
            {!showDateInput && (
              <StandardInput
                variant="new"
                spellCheck="false"
                type="text"
                placeholder="Player Date of Birth (mm/dd/yyyy)"
                onFocus={showDateInputAndFocus}
              />
            )}
            {showDateInput && (
              <StandardInput
                variant="new"
                spellCheck="false"
                {...register('birthdate')}
                type="date"
                id="birthdate"
                placeholder="Player Date of Birth (mm/dd/yyyy)"
                autoFocus={autoFocusDateInput}
                hasError={!!errors.birthdate?.message}
                min="1900-01-01"
                max={new Date().toISOString().split('T')[0]}
              />
            )}
            <p className="text-sm text-red-500">{errors.birthdate?.message ?? '\u00A0'}</p>
          </div>

          <div>
            <Button text="Add New Player" variant="cta" />
          </div>
        </form>

        {isLoading || errorMessage ? (
          <div className="absolute top-0 left-0 h-full w-full grid place-content-center p-8 bg-primary bg-opacity-80 rounded-lg">
            {isLoading ? (
              <Spinner darkMode />
            ) : (
              <div className="flex flex-col items-center gap-4 text-sm text-white text-center">
                <p>{`We're sorry, an unexpected error occurred creating this player.`}</p>
                <p>{`Please try again at another time.`}</p>
                <div className="shrink mt-2">
                  <Button
                    text="Okay"
                    onClick={() => {
                      setErrorMessage(undefined);
                      closeForm();
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  }
);
PlayerCreationForm.displayName = 'PlayerCreationForm';

import {memo} from 'react';
import CommonRegistrationPage from 'components/Forms/CommonRegistrationForm/CommonRegistrationPage';
import { getFreePickupAndLeaguesFormDataForSubmission, getFreePickupAndLeaguesFormFields } from 'common/freePickupandLeaguesUtils';
import { FreeTrialProgram, relevantFreePickupSoccerAndLeaguesUrlParams } from 'common/fko-utils';

const FreeLeaguesRegistrationPage = memo(() => {
    const locationsAllowed = [
      'Allen', 
      'Burnsville', 
      'Carrollton', 
      'Denver', 
      'Evanston', 
      'Keller', 
      'Loganville', 
      'Madison', 
      'Naperville', 
      'Nashville',
      "Novi East",
      "Richmond",
      "Rocky River",
      "St. Louis",
      "The Colony",
      "Toledo"
    ];

    return (
      <CommonRegistrationPage 
        programName={FreeTrialProgram.LEAGUES_TRIAL} 
        locationsAllowed={locationsAllowed} 
        formId={process.env.REACT_APP_HUBSPOT_FREE_LEAGUES_FORM_ID ?? ''}
        relevantUrlParams={relevantFreePickupSoccerAndLeaguesUrlParams}
        getFormFields={getFreePickupAndLeaguesFormFields}
        getHubspotFormDataForSubmission={getFreePickupAndLeaguesFormDataForSubmission}
      />
    );
  });
  

FreeLeaguesRegistrationPage.displayName = 'FreeLeaguesRegistrationPage';

export default FreeLeaguesRegistrationPage;

import {Helmet} from 'react-helmet';
import styles from './BaselineBenchmarks.module.scss';
import {PageHeading} from 'components/PageHeading/PageHeading';
import {FormEventHandler, memo, useCallback, useMemo, useState} from 'react';
import {SelectOptions, StandardSelect} from 'components/Select/StandardSelect/StandardSelect';
import {BaselineContainer} from 'components/Baseline/Container/BaselineContainer';
import {ResponsiveContainer} from 'components/ResponsiveContainer/ResponsiveContainer';
import {PathwayLevel} from 'api/User/useGetPlayerDetails';

export type ExerciseBenchmark = {
  title: string;
  number: number;
  streak: number;
  accuracy: number;
  sop: number;
  mph: number;
};

export type BenchmarkLevel = {
  level: PathwayLevel;
  benchmarks: ExerciseBenchmark[];
};

export const baselineBenchmarkValues: BenchmarkLevel[] = [
  {
    level: 'beginner',
    benchmarks: [
      {
        title: 'Passing (GC1-5 (1) x 10)',
        number: 1,
        streak: 5,
        accuracy: 0.7,
        sop: 4.0,
        mph: 15,
      },
      {
        title: 'Passing (GC1-5 (1) x 10)',
        number: 2,
        streak: 4,
        accuracy: 0.6,
        sop: 4.0,
        mph: 15,
      },
      {
        title: 'Passing/Awareness 360 (GC1-5 (3) x 10)',
        number: 3,
        streak: 4,
        accuracy: 0.6,
        sop: 4.0,
        mph: 15,
      },
      {
        title: 'Passing (GLR1-5 (1) x 10)',
        number: 4,
        streak: 4,
        accuracy: 0.7,
        sop: 4.0,
        mph: 15,
      },
      {
        title: 'Passing (GLR1-5 (1) x 10)',
        number: 5,
        streak: 4,
        accuracy: 0.6,
        sop: 4.0,
        mph: 15,
      },
      {
        title: 'Passing/Awareness 360 (GLR1-5 (3) x 10)',
        number: 6,
        streak: 4,
        accuracy: 0.6,
        sop: 4.0,
        mph: 15,
      },
      {
        title: 'Multi Touch Finishing (GC1-6 (3) x 10)',
        number: 7,
        streak: 5,
        accuracy: 0.6,
        sop: 5.0,
        mph: 20,
      },
      {
        title: '1st Time Finishing (GC1-5 (3) x 10) (DF)',
        number: 8,
        streak: 4,
        accuracy: 0.6,
        sop: 3.5,
        mph: 20,
      },
      {
        title: '1st Time Finishing (GC1-5 (3) x 10) (NDF)',
        number: 9,
        streak: 3,
        accuracy: 0.4,
        sop: 3.5,
        mph: 20,
      },
    ],
  },
  {
    level: 'intermediate',
    benchmarks: [
      {
        title: 'Passing/Awareness 360 (GC2-5 (2) x 12)',
        number: 1,
        streak: 6,
        accuracy: 0.75,
        sop: 3.5,
        mph: 20,
      },
      {
        title: 'Passing/Awareness 360 (GLR2-5 (2) x 12)',
        number: 2,
        streak: 6,
        accuracy: 0.75,
        sop: 3.5,
        mph: 20,
      },
      {
        title: 'Passing/Awareness 360 (G3PAN2-5 (2) x 12)',
        number: 3,
        streak: 6,
        accuracy: 0.75,
        sop: 3.5,
        mph: 20,
      },
      {
        title: 'Passing/Awareness 360 (AC2-5 (2) x 12)',
        number: 4,
        streak: 5,
        accuracy: 0.7,
        sop: 4.0,
        mph: 20,
      },
      {
        title: 'Passing/Awareness 360 (LC2-5 (2) x 12)',
        number: 5,
        streak: 5,
        accuracy: 0.7,
        sop: 4.0,
        mph: 20,
      },
      {
        title: 'Passing/Awareness 360 (GALC2-5 (2) x 12)',
        number: 6,
        streak: 5,
        accuracy: 0.7,
        sop: 4.0,
        mph: 20,
      },
      {
        title: 'Multi Touch Finishing (GLR2-5 (3) x 12)',
        number: 7,
        streak: 4,
        accuracy: 0.6,
        sop: 3.5,
        mph: 25,
      },
      {
        title: '1st Time Finishing (AC1-5 (3) x 12)',
        number: 8,
        streak: 3,
        accuracy: 0.4,
        sop: 3.5,
        mph: 25,
      },
      {
        title: 'Multi Touch Finishing (LC3-5 (3) x 12)',
        number: 9,
        streak: 3,
        accuracy: 0.4,
        sop: 4.5,
        mph: 25,
      },
    ],
  },

  {
    level: 'advanced',
    benchmarks: [
      {
        title: 'Passing/Awareness 360 (GC2-4 (1) x 12)',
        number: 1,
        streak: 8,
        accuracy: 0.83,
        sop: 3.0,
        mph: 25,
      },
      {
        title: 'Passing/Awareness 360 (LR2-4 (1) x 12)',
        number: 2,
        streak: 8,
        accuracy: 0.83,
        sop: 3.0,
        mph: 25,
      },
      {
        title: 'Passing/Awareness 360 (G3PAN2-4 (1) x 12)',
        number: 3,
        streak: 8,
        accuracy: 0.83,
        sop: 3.0,
        mph: 25,
      },
      {
        title: 'Passing/Awareness 360 (AC2-5 (1) x 12)',
        number: 4,
        streak: 8,
        accuracy: 0.75,
        sop: 3.25,
        mph: 25,
      },
      {
        title: 'Passing/Awareness 360 (LC2-5 (1) x 12)',
        number: 5,
        streak: 8,
        accuracy: 0.83,
        sop: 3.5,
        mph: 25,
      },
      {
        title: 'Passing/Awareness 360 (GALC2-5 (1) x 12)',
        number: 6,
        streak: 8,
        accuracy: 0.83,
        sop: 3.5,
        mph: 35,
      },
      {
        title: 'Multi Touch Finishing (GLR3-5 (3) x 12)',
        number: 7,
        streak: 5,
        accuracy: 0.6,
        sop: 3.5,
        mph: 35,
      },
      {
        title: '1st Time Finishing (AC1-4 (3) x 12)',
        number: 8,
        streak: 4,
        accuracy: 0.4,
        sop: 3.0,
        mph: 35,
      },
      {
        title: 'Multi Touch Finishing (LC4-5 (3) x 12)',
        number: 9,
        streak: 6,
        accuracy: 0.4,
        sop: 4.5,
        mph: 20,
      },
    ],
  },

  // {
  //   level: 'pro',
  //   benchmarks: [
  //     {
  //       title: 'Passing/Awareness 360 (GC3-4 (1) x 12)',
  //       number: 1,
  //       streak: 9,
  //       accuracy: 0.9,
  //       sop: 2.5,
  //       mph: 25,
  //     },
  //     {
  //       title: 'Passing/Awareness 360 (GLR3-4 (1) x 12)',
  //       number: 2,
  //       streak: 9,
  //       accuracy: 0.9,
  //       sop: 2.5,
  //       mph: 25,
  //     },
  //     {
  //       title: 'Passing/Awareness 360 (G3PAN3-4 (1) x 12)',
  //       number: 3,
  //       streak: 9,
  //       accuracy: 0.9,
  //       sop: 2.5,
  //       mph: 25,
  //     },
  //     {
  //       title: 'Passing/Awareness 360 (AC2-5 (1) x 12)',
  //       number: 4,
  //       streak: 9,
  //       accuracy: 0.9,
  //       sop: 3.0,
  //       mph: 25,
  //     },
  //     {
  //       title: 'Passing/Awareness 360 (LC2-5 (1) x 12)',
  //       number: 5,
  //       streak: 9,
  //       accuracy: 0.9,
  //       sop: 3.25,
  //       mph: 25,
  //     },
  //     {
  //       title: 'Passing/Awareness 360 (ALC2-5 (1) x 12)',
  //       number: 6,
  //       streak: 9,
  //       accuracy: 0.9,
  //       sop: 3.5,
  //       mph: 25,
  //     },
  //     {
  //       title: 'Multi Touch Finishing (GLR3-5 (1) x 12)',
  //       number: 7,
  //       streak: 6,
  //       accuracy: 0.75,
  //       sop: 3.5,
  //       mph: 40,
  //     },
  //     {
  //       title: '1st Time Finishing (AC2-4 (1) x 12)',
  //       number: 8,
  //       streak: 4,
  //       accuracy: 0.5,
  //       sop: 2.5,
  //       mph: 40,
  //     },
  //     {
  //       title: 'Multi Touch Finishing (LC4-5 (1) x 12)',
  //       number: 9,
  //       streak: 6,
  //       accuracy: 0.5,
  //       sop: 4,
  //       mph: 40,
  //     },
  //   ],
  // },
];

export const BaselineBenchmarks = () => {
  const benchmarks = baselineBenchmarkValues;
  const [selectedLevel, setSelectedLevel] = useState<BenchmarkLevel | undefined>(benchmarks?.[0]);

  const selectOptions = useMemo(
    () =>
      benchmarks.map((_level: BenchmarkLevel) => ({
        value: _level.level,
      })),
    [benchmarks]
  );

  const onSelectLevel: FormEventHandler<SelectOptions> = useCallback(
    (e) => setSelectedLevel(benchmarks.find((_level) => _level.level === e.currentTarget.value)),
    [benchmarks]
  );

  return (
    <>
      <Helmet>
        <title>MyTOCA | Baseline Benchmarks</title>
      </Helmet>

      <BaselineContainer>
        <PageHeading text="Baseline Benchmarks" />

        <div className={styles.container}>
          <ResponsiveContainer sm md>
            <div className={styles.maxWidthWrapper}>
              <StandardSelect
                id="session-select"
                options={selectOptions}
                onChange={onSelectLevel}
                defaultValue={selectOptions[0].value}
              />

              {selectedLevel && <BaselineLevelBenchmark level={selectedLevel} />}
            </div>
          </ResponsiveContainer>

          <ResponsiveContainer lg xl>
            <div className={styles.benchmarkTablesWrapper}>
              {benchmarks.map((level, i) => (
                <BaselineLevelBenchmark level={level} key={`benchmark_level_${i}`} />
              ))}
            </div>
          </ResponsiveContainer>
        </div>
      </BaselineContainer>
    </>
  );
};

const BaselineLevelBenchmark = memo(({level}: {level: BenchmarkLevel}) => {
  return (
    <div className={styles.levelBenchmarkContainer}>
      <p className={`${styles.levelHeading} ${styles[level.level]}`}>{level.level}</p>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Streak</th>
            <th>Accuracy</th>
            <th>SOP</th>
            <th>MPH</th>
          </tr>
        </thead>

        <tbody>
          {level.benchmarks.map((benchmark, i) => (
            <tr key={`baseline_row_${i}`}>
              <td className={styles.benchmarkNum}>{benchmark.number}</td>
              <td>{benchmark.streak.toFixed(0)}</td>
              <td>{(benchmark.accuracy * 100).toFixed(0)}%</td>
              <td>{benchmark.sop.toFixed(2)}</td>
              <td>{benchmark.mph.toFixed(0)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});
BaselineLevelBenchmark.displayName = 'BaselineLevelBenchmark';

export const themeColors = {
  primary: '#0d1367',
  secondary: '#5517e0',
  tertiary: '#904efc',
  lightgrey: '#d8d8d8',
  blue: '#2731ba',
  lightBlue: '#5b62c1',
  darkGreyAlt: '#5f5f69',
  white: '#ffffff',
};

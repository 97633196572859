import {useQuery} from '@tanstack/react-query';
import {useAppSelector} from '../../redux/reduxUtils';
import {getPlayerDetails} from '../api';
import {Team} from 'user/user.interface';

export type PathwayLevel = 'beginner' | 'intermediate' | 'advanced';
export type Genders = 'male' | 'female' | 'non-binary' | 'none' | 'other';

export type PerformanceMetric = {
  accuracy: number;
  ballSpeed: number;
  speedOfPlay: number;
  recoveries: number;
  streak: number;
  name?: string;
  selected?: boolean;
};

export type TrainingSession = {
  mboPlayer: {
    mboClientId: string;
    firstName: string | null;
    lastName: string | null;
    gender: string | null;
    email: string | null;
    phoneNumber: string | null;
    dob: string | null; // "2020-06-16T00:00:00",
    imageUrl: string | null; // "images/default-profile.png"
    // playerStats?: RawPlayerStats | null;
    alias: string | null; // "GM"
  };
  mboTrainer: {
    id: string | null;
    fullName: string | null;
    imageUrl: string | null;
  } | null;
  siteId: number;
  pathwayName: string;
  playerPathwayId?: string;
  pathwaySessionNumber?: number;
  totalSessionsInPathway: number;
  totalBallsFired: number;
  totalGoals: number;
  clientSessionSummaryId: string;
  isAssessmentSession: boolean;
  lifetimeTouches?: number;
  bestStreak: number;
  startTime: number;
  endTime: number;
  focuses:
    | {
        id: number;
        created: number | null; // 1708448328210
        name: string | null; // "Beginner Baseline - 20 MINS", "Advanced Intensive 4", "Intermediate INTENSIVE 7"
        imageId: number | null;
        challenge: boolean | null;
        leaderboardId: unknown | null; // currently no data
        structures: string[] | null; // [ "BASELINE" ]
        exercises: unknown[]; // currently no data
        deliveryCodes: unknown[]; // currently no data
        focusType: string | null; // "BASELINE", "FOCUS 1"
      }[]
    | null;
  performance?: {
    metrics: PerformanceMetric & {
      repetitions: number;
    };
    exercises: PerformanceMetric[];
  };
  isBaseline?: boolean;
  baselineLevel?: PathwayLevel;
};

export type Pathway = {
  pathwayId: number | null;
  pathwayName: string | null;
  playerPathwayId: string | null;
  totalSessions?: number;
  completedSessions?: number;
  sessions?: TrainingSession[];
  level?: PathwayLevel;
};

export type PathwayLevelData = {
  pathways: Pathway[];
  sessions: TrainingSession[];
};

export type PlayerDetails = {
  firstName: string;
  lastName: string;
  fullName: string;
  siteId: string;
  location?: string;
  profileExists: boolean;
  email: string;
  // age: number;
  age?: number | string; // temp
  gender?: Genders;
  levelOfPlay?: string; // TODO confirm intention with this property
  memberSince: string | null;
  // club: unknown;
  // sporfieLiveUrl?: {
  //   thumbnailURL: string;
  //   liveURL: string;
  // };
  currentLevel: PathwayLevel;
  currentPathwayId: number | null;
  currentPathwayName: string | null;
  lastSessionNumber?: number;
  lastSessionDate?: string;
  lastSessionSummary?: PerformanceMetric & {
    repetitions: number;
  };
  lastSession?: TrainingSession;
  currentPathway: Pathway | null;
  pathways?: {
    beginner: PathwayLevelData | null;
    intermediate: PathwayLevelData | null;
    advanced: PathwayLevelData | null;
    currentPathway: Pathway | null;
    currentLevel: PathwayLevel;
    lastSession: TrainingSession | undefined;
  };
  baselineSessions?: TrainingSession[];
  playerTeam?: Team | null;
};

export const useGetPlayerDetails = (overrideId?: string | null) => {
  const {currentProfileId} = useAppSelector((state) => state.profileReducer);
  const profileId = overrideId ?? currentProfileId;

  return useQuery<PlayerDetails>({
    queryKey: ['user', 'details', profileId],
    queryFn: () => getPlayerDetails().then((res) => res.data),
    enabled: !!profileId,
  });
};

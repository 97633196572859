import {useQuery, useQueryClient} from '@tanstack/react-query';
import {useCallback, useRef} from 'react';
import {useAppDispatch} from '../../redux/reduxUtils';
import {setCurrentProfileId} from '../../redux/slices/profile';
import {User} from '../../user/user.interface';
import {getCurrentUser} from '../api';
import {useStore} from 'react-redux';
import {RootState} from '../../redux/store';

type getUserParams = {
  enabled?: boolean;
};

const MAX_USER_REQUEST_RETRY = 30;

const prevProfileIdSelector = (state: RootState) => state.profileReducer.currentProfileId;

export const useGetUser = (params: getUserParams = {enabled: true}) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const store = useStore<RootState>();
  const internalUserRequestCount = useRef(0);
  const {enabled = true} = params;

  const setProfilesFromUser = useCallback(
    (user: User) => {
      const prevProfileId = prevProfileIdSelector(store.getState());
      const profiles = user.profiles;
      const profileTeams = user.profileTeams;

      queryClient.setQueryData(['user', 'profiles'], profiles);
      queryClient.setQueryData(['user', 'profileTeams'], profileTeams);

      // if the localstorage ProfileId is NOT part of this Account, set a new default ProfileId
      if (!prevProfileId || (prevProfileId && !profiles.find((p) => p._id === prevProfileId))) {
        if (profiles.length) {
          const currentProfileId = (profiles.find((p) => !!p.accountHolder) ?? profiles[0])._id;

          dispatch(setCurrentProfileId(currentProfileId));
          void queryClient.invalidateQueries({queryKey: ['user', 'details'], refetchType: 'all'});
        } else {
          console.debug(
            'Could not determine current player id. That might be OK, if no MBO data is associated with the user yet.'
          );
        }
      }
    },
    [dispatch, queryClient, store]
  );

  /**
   * Returns the database user/profiles when available.
   *
   * For existing users, that will be right away.
   *
   * For new users, that will be after the new user has been fully created and initialized.
   * New users/profiles get created via the onCreateUser action when a new Firebase user record is added.
   * Since there is a delay between the Firebase record creation and the database record creation, we
   * might need to retry a few times, until we get a result back or the max attempts have been exceeded.
   * For new users, there might also be additional initialization logic, such as setting related
   * MBO data, in which case a "_pending" property will indicate whether the user is ready or not.
   */
  const fetchUserWithLogic = useCallback(() => {
    internalUserRequestCount.current = internalUserRequestCount.current + 1;

    return getCurrentUser().then((res) => {
      const user = res.data;
      const pending = user._pending;
      if (pending && internalUserRequestCount.current < MAX_USER_REQUEST_RETRY) {
        return Promise.reject('pending');
      }
      setProfilesFromUser(user);
      return user;
    });
  }, [setProfilesFromUser]);

  return useQuery<User>({
    queryKey: [`user`],
    queryFn: fetchUserWithLogic,
    retry: MAX_USER_REQUEST_RETRY,
    retryDelay: 1000,
    enabled,
    //onSuccess: setProfilesFromUser, // NOTE: Using this results in repeated calls for each rendered instance of the query
  });
};

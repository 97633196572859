import {FormEventHandler, memo, useCallback, useEffect, useMemo, useState} from 'react';
import styles from './Home.module.scss';
import {Helmet} from 'react-helmet';
import {PathwayLevel, TrainingSession, useGetPlayerDetails} from '../../api/User/useGetPlayerDetails';
import {LoadingAnimationOverlay} from 'components/Loader/LoadingAnimation';
import {PathwaySelection} from 'components/PathwaySelection/PathwaySelection';
import {SelectOptions, StandardSelect} from 'components/Select/StandardSelect/StandardSelect';
import Splash from 'components/Splash/Splash'; // TODO eval component
import {SessionRecap} from 'components/Connects/SessionRecap/SessionRecap';
import {ButtonTray} from 'components/Connects/ButtonTray';
import {Button} from 'components/Connects/Button';
import {useNavigate} from 'react-router-dom';
import {LogoBanner} from 'components/Connects/LogoBanner';
import {ProfileBar} from 'components/ProfileBar/ProfileBar';
import {useGetPrograms} from 'api/Classes/useGetPrograms';
import {useGetMboVisits} from 'api/Booking/useGetMboVisits';
import {useProfiles} from 'api/User/useProfiles';
// import {BaselineSessionTable} from 'components/Baseline/SessionTable/BaselineSessionTable';

export const NewHome = memo(() => {
  const navigate = useNavigate();
  const [curSession, setCurSession] = useState<TrainingSession | null>(null);
  const [activeLevel, setActiveLevel] = useState<PathwayLevel>();

  const {currentProfile, currentProfileTeam} = useProfiles();
  const {
    data: playerDetails,
    isLoading: isPlayerDetailsLoading,
    isFetching: isPlayerDetailsFetching,
  } = useGetPlayerDetails();

  useGetPrograms({siteId: currentProfile.mboDetails?.siteId});
  useGetMboVisits({clientId: currentProfile.mboDetails?.clientId, siteId: currentProfile.mboDetails?.siteId});

  const setCurrentSession = useCallback(
    (index: number) => {
      if (activeLevel && playerDetails?.pathways?.[activeLevel]?.sessions?.length) {
        setCurSession(playerDetails?.pathways[activeLevel]?.sessions[index] ?? null);
      } else {
        setCurSession(null);
      }
    },
    [activeLevel, playerDetails?.pathways]
  );

  const sessionCounts = {
    baseline: 0,
    normal: 0,
  };
  const selectOptions = useMemo(
    () =>
      activeLevel && playerDetails?.pathways
        ? playerDetails.pathways?.[activeLevel]?.sessions
            ?.map((session, i) => {
              if (session.isBaseline) {
                sessionCounts.baseline++;
              } else {
                sessionCounts.normal++;
              }

              return {
                value: i,
                displayValue: session.isBaseline
                  ? `Baseline Session ${sessionCounts.baseline}`
                  : `Session ${sessionCounts.normal}`,
              };
            })
            .reverse() // important that { value:i } is set before reversal
        : null,
    // sessionCounts should not be a dependancy
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeLevel, playerDetails?.pathways]
  );

  const selectSession: FormEventHandler<SelectOptions> = useCallback(
    (e) => {
      setCurrentSession(Number(e.currentTarget.value));
    },
    [setCurrentSession]
  );

  useEffect(() => {
    // on load, set initial pathway
    if (playerDetails?.pathways?.currentLevel && !activeLevel) {
      setActiveLevel(playerDetails.pathways.currentLevel);
    }

    // if pathway contains no sessions // set initial session
    if (!selectOptions) {
      setCurSession(null);
    } else if (!curSession && playerDetails?.pathways && activeLevel) {
      if (playerDetails.pathways[activeLevel] && playerDetails.pathways[activeLevel]?.sessions.length) {
        setCurSession(
          // this is confirmed above, ts not inferring properly
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          playerDetails.pathways[activeLevel]!.sessions[playerDetails.pathways[activeLevel]!.sessions.length - 1] ??
            null
        );
      }
    }
  }, [selectOptions, playerDetails?.pathways, activeLevel, curSession]);

  if (isPlayerDetailsLoading) {
    return <Splash />;
  }

  const hasPathways =
    playerDetails?.pathways?.['beginner']?.sessions?.length ||
    playerDetails?.pathways?.['intermediate']?.sessions?.length ||
    playerDetails?.pathways?.['advanced']?.sessions?.length;

  const curIsLastSession = curSession?.endTime === playerDetails?.pathways?.lastSession?.endTime;

  return (
    <>
      <Helmet>
        <title>MyTOCA | Home</title>
      </Helmet>

      <LogoBanner />
      <ProfileBar playerDetails={playerDetails} teamColor={currentProfileTeam?.teamColor || ''} />

      <div className={styles.flexContainer}>
        {!activeLevel || !hasPathways ? (
          <div className={styles.errorContainer}>
            <p>No pathways to show</p>
          </div>
        ) : playerDetails.pathways ? (
          <>
            <div className={`${styles.selectionWrapper}`}>
              <PathwaySelection
                pathways={playerDetails.pathways}
                activeLevel={activeLevel}
                setActiveLevel={setActiveLevel}
              />

              {!selectOptions?.length ? (
                <div className={styles.errorContainer}>
                  <p>No session found to display performance</p>
                </div>
              ) : (
                <div className="md:mx-auto md:max-w-[600px] w-full">
                  <StandardSelect
                    id="home-session-select"
                    options={selectOptions}
                    defaultValue={selectOptions[0].value}
                    onChange={selectSession}
                  />
                </div>
              )}
            </div>

            {curSession?.performance?.metrics ? (
              <>
                {/* <>
                    // curSession.isBaseline ? (
                    //   <BaselineSessionTable sessionData={curSession} />
                    // ) : (
                      </> */}
                <SessionRecap
                  sessionSummary={curSession.performance.metrics}
                  level={activeLevel}
                  sessionNumber={curSession.pathwaySessionNumber}
                />

                {curIsLastSession ? (
                  <div className="md:mx-auto md:max-w-[600px] md:w-full">
                    <ButtonTray>
                      {/* <div style={{width: '100%'}} /> */}
                      <Button
                        text="Next Week"
                        color="secondary"
                        variant="cta"
                        onClick={() =>
                          navigate(`/nextsession?sessionNum=${(curSession.pathwaySessionNumber ?? 0) + 1}`)
                        }
                      />
                    </ButtonTray>
                  </div>
                ) : null}
              </>
            ) : null}
          </>
        ) : null}
      </div>
      <LoadingAnimationOverlay isLoading={isPlayerDetailsFetching} />
    </>
  );
});
NewHome.displayName = 'NewHome';

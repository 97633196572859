import {useMemo} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {convertDateRange, downloadPerformanceDataAsExcel} from '../utils';
import {
  AssessmentPlayerPerformanceData,
  useGetSelectedPlayersAssessmentData,
} from 'api/CoachAndDirector/useGetSelectedPlayersAssessmentData';
import {Assessment, useGetCoachInfo} from 'api/Coach/useGetCoachInfo';
import {useProfiles} from 'api/User/useProfiles';
import {PlayerSessionSummary} from 'api/Trainer/useGetPlayerLastSessionSummary';
import {Team} from 'user/user.interface';
import {roleType} from 'constants/constants';
import {useGetAssessment} from 'api/CoachAndDirector/useGetAssessment';
import {PlayerAssessmentDataView} from './PlayerAssessmentData.view';
import {getUserDisplayName} from 'utils/utils';

export const CoachPlayerAssessmentData = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {currentProfile} = useProfiles();
  const {data: coachInfo} = useGetCoachInfo(currentProfile?._id);
  const [playerId, assessmentId] = [searchParams.get('playerId') ?? '', searchParams.get('assessmentId') ?? ''];
  const {data: assessmentInfo} = useGetAssessment(assessmentId, roleType.teamCoach);
  const {data: playersAssessmentData, isLoading} = useGetSelectedPlayersAssessmentData({
    playerIds: playerId,
    assessmentId,
    role: roleType.teamCoach,
    sortBy: '',
  });

  const date = convertDateRange(assessmentInfo?.startDate ?? '', assessmentInfo?.endDate ?? '');
  const playerInfo = playersAssessmentData?.[0]?.playerInfo;
  const playerName = getUserDisplayName(playerInfo?.firstName, playerInfo?.lastName);
  const playerAssessmentData = playersAssessmentData?.[0]?.performanceDetails;

  const isSessionSelectionCompleted = useMemo(
    () =>
      playerAssessmentData?.exerciseSummary?.find((exercise) => {
        return 'confirmedBy' in exercise;
      }),
    [playerAssessmentData]
  );

  const onClickDownloadHandler = async () => {
    downloadPerformanceDataAsExcel(
      playersAssessmentData as AssessmentPlayerPerformanceData[],
      assessmentInfo as Assessment,
      coachInfo?.teams?.[0] as Team
    );
  };

  return (
    <PlayerAssessmentDataView
      isLoading={isLoading}
      playerAssessmentData={playerAssessmentData as PlayerSessionSummary}
      isSessionSelectionCompleted={!!isSessionSelectionCompleted}
      playerName={playerName}
      date={date}
      onClickDownloadHandler={onClickDownloadHandler}
      assessmentName={assessmentInfo?.name || ''}
      onClickGoBack={() => navigate(-1)}
    />
  );
};

import styles from './BookingConfirmation.module.scss';
import {memo, useCallback, useEffect, useMemo, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../redux/reduxUtils';
import {BookingSession, setBookingCompleted, setBookingSessions} from '../../../redux/slices/booking';
import {TocaLocation} from '../../../constants/locations';
import {createOrUpdateHubSpotDeal} from '../../../api/api';
import {BookingInfo} from '../../../components/Booking/BookingInfo/BookingInfo';
import {AlertContainer} from '../../../components/Booking/MessageContainer/MessageContainer';
import {SessionCard} from '../../../components/Booking/SessionCard/SessionCard';
import {BookingFormButton} from '../../../components/Buttons/Form/FormButton';
import {setLoading} from '../../../redux/slices/loading';
import {useCreateNewBookings} from '../../../api/Booking/useCreateNewBookings';
import {useProfiles} from '../../../api/User/useProfiles';
import {useQueryClient} from '@tanstack/react-query';
import {Profile} from '../../../user/player-info.interface';
import {Helmet} from 'react-helmet';
import {PageHeading} from 'components/PageHeading/PageHeading';
import {AboveNavbarContainer} from 'components/Navbar/AboveNavbarContainer/AboveNavbarContainer';
import {logAnalyticsEvent} from '../../../common/analytics-events';
import {generateVisitsQueryKey} from 'api/Booking/useGetMboVisits';

export const BookingConfirmation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const {createBookings} = useCreateNewBookings();
  const {currentProfile} = useProfiles();

  const {sessions, profileId} = useAppSelector((state) => state.bookingReducer.bookingCreation);
  const hubspotDealId = useRef<number | undefined>();

  const sessionErrors = useMemo(() => sessions.filter((s) => !!s.bookingStatus?.error), [sessions]);
  const confirmedSessions = useMemo(() => sessions.filter((s) => !!s.bookingStatus?.confirmed), [sessions]);

  useEffect(() => {
    if (!currentProfile || !sessions.length) {
      navigate('/bookings');
    } else if (currentProfile._id !== profileId) {
      navigate('/home');
    }

    logAnalyticsEvent('booking_confirmation', {
      profileId: currentProfile._id,
      mboSiteId: currentProfile.mboDetails?.siteId,
      mboClientId: currentProfile.mboDetails?.clientId,
      sessionCount: sessions.length,
    });
  }, [navigate, currentProfile, sessions.length, profileId]);

  useEffect(() => {
    if (!confirmedSessions?.length || !currentProfile) {
      return;
    }
    createOrUpdateDealInHubSpot(
      confirmedSessions,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      currentProfile.location!,
      currentProfile,
      hubspotDealId.current
    ).then((result) => {
      hubspotDealId.current = result.dealId;
    });
  }, [confirmedSessions, currentProfile]);

  useEffect(() => {
    if (confirmedSessions.length === sessions.length) {
      // Indicate that the booking was completed, so that we can clear booking info on subsequent screens to
      // avoid going back to the confirmed bookings when starting another booking flow.
      dispatch(setBookingCompleted(true));
    }
  }, [confirmedSessions, dispatch, sessions]);

  const reattemptBookings = useCallback(async () => {
    if (currentProfile && sessions.length && location) {
      dispatch(setLoading(true));

      const unconfirmedSessions = sessions.filter((s) => !s.bookingStatus?.confirmed);
      const confirmedSessions = sessions.filter((s) => s.bookingStatus?.confirmed);

      const appointmentResults = await createBookings({
        sessions: unconfirmedSessions,
        player: currentProfile,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        location: currentProfile.location!, //todo improve assertions
      });

      queryClient.invalidateQueries({
        queryKey: generateVisitsQueryKey({clientId: currentProfile.mboDetails?.clientId}),
        refetchType: 'all',
      });

      dispatch(setLoading(false));

      if (appointmentResults) {
        dispatch(
          setBookingSessions([
            ...confirmedSessions,
            ...appointmentResults.map((s) => ({
              ...s.session,
              bookingStatus: {
                confirmed: s.confirmed,
                error: s.error,
              },
            })),
          ])
        );
      }
    }
  }, [currentProfile, sessions, dispatch, createBookings, queryClient]);

  if (!currentProfile) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>MyTOCA | Booking Confirmation</title>
      </Helmet>

      <div className="lg:max-w-[900px] lg:w-full lg:mx-auto lg:px-8 lg:border-x border-solid border-grey-light">
        <PageHeading text="Book Sessions" />
        <BookingInfo />

        <div className={styles.container}>
          {!!sessionErrors.length && (
            <div style={{marginBottom: '20px'}}>
              <AlertContainer>
                <p>
                  {sessionErrors.length} session{sessionErrors.length !== 1 ? 's' : ''} could not be booked. Please
                  review below.
                </p>
              </AlertContainer>
            </div>
          )}

          {!!confirmedSessions.length && (
            <ConfirmedSessionText numConfirm={confirmedSessions.length} numTotal={sessions.length} />
          )}

          <ul className={styles.bookedSessions}>
            {sessions.map((session, i) => (
              <li key={`conf-session-card${i}`} style={{marginBottom: '20px'}}>
                <SessionCard session={session} />
              </li>
            ))}
          </ul>
        </div>

        <AboveNavbarContainer>
          {sessionErrors.length ? (
            <BookingFormButton hasError isPrimary text="update unavailable sessions" />
          ) : confirmedSessions.length < sessions.length ? (
            <BookingFormButton
              hasError
              isPrimary
              onClick={reattemptBookings}
              text={`book ${sessions.length} session${sessions.length > 1 ? 's' : ''} `}
            />
          ) : (
            <BookingFormButton isPrimary text="view my bookings" onClick={() => navigate('/mybookings')} />
          )}
        </AboveNavbarContainer>
      </div>
    </>
  );
};

// changes wording slightly when some unconfirmed sessions remain
const ConfirmedSessionText = memo(({numConfirm, numTotal}: {numConfirm: number; numTotal: number}) => {
  return numConfirm < numTotal ? (
    <h3 className={styles.confirmationSubheading}>
      <span>{numConfirm}</span> of your {numTotal} session{numConfirm !== 1 ? 's' : ''} were booked:
    </h3>
  ) : (
    <h3 className={styles.confirmationSubheading}>
      Successfully booked <span>{numConfirm}</span> session{numConfirm !== 1 ? 's' : ''}:
    </h3>
  );
});
ConfirmedSessionText.displayName = 'ConfirmedSessionText';

//

async function createOrUpdateDealInHubSpot(
  bookings: BookingSession[],
  location: TocaLocation,
  currentProfile: Profile,
  dealId?: number
) {
  if (!bookings.length) {
    return;
  }

  const bookingInfo = bookings.map((booking) => ({
    id: booking.id,
    startDate: booking.startDate,
    endDate: booking.endDate,
    sessionTypeId: booking.sessionType.id,
    sessionTypeName: booking.sessionType.name,
    staffId: booking.staff?.id,
  }));

  return createOrUpdateHubSpotDeal({
    id: dealId,
    // Note: {email} is replaced with current user's email server-side
    dealname: `MyTOCA TOCA Training Multi Booking | ${location.name} | {email} | ${currentProfile.mboDetails?.clientId}`,
    multi_appointment_booking_info: JSON.stringify(bookingInfo),
    mbo_site_id: location.siteId,
    program_name: 'Multi Appointment Booking',
  });
}

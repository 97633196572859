import {DayPicker, DayPickerDefaultProps, DayPickerSingleProps} from 'react-day-picker';
import {useAppSelector} from 'redux/reduxUtils';
import {constructDateFromHyphenated} from 'utils/utils';

// import 'react-day-picker/dist/style.css';
// export const DatePicker = (props: React.ComponentPropsWithoutRef<typeof DayPicker>) => {
//   const [selected, setSelected] = useState<Date>();
//   return <DayPicker mode="single" selected={selected} onSelect={setSelected} />;
// };

export const FkoDatePicker = (
  props: Partial<DayPickerSingleProps> & {
    startDate?: string;
    endDate?: string;
  }
) => {
  const {appointmentCalendar} = useAppSelector((state) => state.fkoFormDataReducer);

  const emptyDates = [];
  for (const [dateStr, calendarDay] of Object.entries(appointmentCalendar)) {
    if (!calendarDay.appointments.length && calendarDay.hasFetched) {
      emptyDates.push(new Date(`${dateStr}T12:00:00Z`)); // TODO this is hacky and only works because we're dealing exclusively with continental US timezones.
    }
  }

  const startingDate = props.startDate ? constructDateFromHyphenated(props.startDate) : new Date();
  const currentYear = startingDate.getFullYear();
  const endYear = props.endDate ? constructDateFromHyphenated(props.endDate).getFullYear() : currentYear + 1;
  const disabledDays = [...emptyDates, {before: startingDate, after: constructDateFromHyphenated(props.endDate)}];

  const {mode = 'single', fromYear = currentYear, toYear = endYear, disabled = disabledDays, ...rest} = props;

  return (
    <DayPicker mode={mode} fromYear={fromYear} toYear={toYear} disabled={disabled} classNames={classNames} {...rest} />
  );
};

const classNames: DayPickerDefaultProps['classNames'] = {
  vhidden: 'sr-only',
  caption: 'flex justify-between items-center',
  root: 'text-black',
  // months: 'flex gap-4 relative px-4',
  caption_label: 'text-xl px-2',
  nav: 'flex',
  nav_button: 'grid place-content-center w-10 h-10 hover:stroke-primary hover:drop-shadow active:text-secondary',
  // nav_button_next: 'right-0',
  // nav_button_previous: 'left-0',
  // table: 'border-collapse border-spacing-0',
  head_cell: 'w-10 h-10 uppercase align-middle text-center text-xs',
  cell: 'w-10 h-10 align-middle text-center border-0 px-0 cursor-pointer',
  day: 'grid place-content-center text-sm rounded-full w-10 h-10 transition-colors hover:bg-fko-primary hover:bg-opacity-10 focus:outline-none focus-visible:ring focus-visible:ring-primary focus-visible:ring-opacity-50 active:bg-fko-primary active:text-white',
  day_selected: 'text-white bg-fko-primary/90 hover:bg-fko-primary/100',
  day_today: 'font-bold',
  day_disabled: 'opacity-25 hover:bg-white active:bg-white active:text-gray-800',
  day_outside: 'enabled:opacity-50',
  day_range_middle: 'rounded-none',
  day_range_end: 'rounded-l-none rounded-r-full',
  day_range_start: 'rounded-r-none rounded-l-full',
  day_hidden: 'hidden',
};

import {useEffect, useMemo, useRef, useState} from 'react';
import {debounce} from 'lodash';
import Fuse from 'fuse.js';

type PlayerClub = {id: string; clubName: string};

export const useClubSearch = ({clubList}: {clubList: PlayerClub[]}) => {
  const [query, setQuery] = useState('');

  const updateQuery = useRef(debounce(setQuery, 250)).current;

  useEffect(() => {
    return () => {
      updateQuery.cancel();
    };
  }, [updateQuery]);

  const fuse = useMemo(
    () =>
      new Fuse(clubList, {
        findAllMatches: true,
        ignoreLocation: false,
        keys: ['clubName'],
        minMatchCharLength: 1,
        shouldSort: true,
        threshold: 0.3,
      }),
    [clubList]
  );

  const indexResults = useMemo(() => fuse.search(query).map((res) => res.refIndex), [fuse, query]);

  return {query, updateQuery, indexResults};
};

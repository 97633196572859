import {useQuery} from '@tanstack/react-query';
import {getPlayerLeaderboards} from 'api/api';

export const useGetLeaderboardList = ({mboClientId, locationId}: {mboClientId?: string; locationId?: string}) => {
  return useQuery({
    queryKey: ['leaderboard-list', mboClientId],
    queryFn: () => getPlayerLeaderboards().then((res) => res.data),
    enabled: !!mboClientId && !!locationId,
    refetchOnWindowFocus: false,
  });
};

import {useQuery} from '@tanstack/react-query';
import {getAssessmentById} from 'api/api';
import {Assessment} from '../Coach/useGetCoachInfo';

export const useGetAssessment = (assessmentId: string, role: string) => {
  return useQuery<Assessment>({
    queryKey: ['assessment', assessmentId, role],
    queryFn: () => getAssessmentById(assessmentId, role),
    enabled: !!assessmentId,
  });
};

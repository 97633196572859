import {memo, useMemo, useState} from 'react';
import styles from './UpcomingCalendar.module.scss';
import {useGetEvents} from 'api/Classes/useGetEvents';
import {useGetMboVisits} from 'api/Booking/useGetMboVisits';
import {Pathway, PlayerDetails, TrainingSession} from 'api/User/useGetPlayerDetails';
import {LoadingAnimation} from 'components/Loader/LoadingAnimation';
import {ErrorDisplay} from 'components/Connects/ErrorDisplay';
import {SessionsList} from 'components/Connects/SessionsList/SessionsList';
import {ClinicsList} from '../ClinicsList/ClinicsList';
// import {EventsList} from 'components/Connects/EventsList/EventsList';

type ScheduleView = 'calendar' | 'clinics' | 'events';

type UpcomingCalendarProps = {
  athleteAge?: string | number;
  baselineSessions: PlayerDetails['baselineSessions'];
  currentPathway?: Pathway | null;
  lastSession?: TrainingSession;
  clientId?: string | null;
  siteId?: string | null;
};

export const UpcomingCalendar = ({
  athleteAge,
  baselineSessions,
  currentPathway,
  lastSession,
  clientId,
  siteId,
}: UpcomingCalendarProps) => {
  const age = useMemo(() => Number(athleteAge), [athleteAge]);

  const [view, setView] = useState<ScheduleView>('calendar');

  const {data: visits, isLoading: isLoadingVisits} = useGetMboVisits({clientId, siteId});
  const {camps, classes, isLoading: isLoadingEvents} = useGetEvents({siteId, age});

  const clinics = useMemo(() => [...(camps ?? []), ...(classes ?? [])], [camps, classes]);

  return (
    <div className={`${styles.flexContainer} md:max-w-[800px] md:mx-auto`}>
      <ScheduleViewToggle view={view} setView={setView} />

      <>
        {view === 'calendar' && (
          <section>
            {isLoadingVisits ? (
              <LoadingAnimation />
            ) : (
              <SessionsList
                visits={visits}
                lastSession={lastSession}
                currentPathway={currentPathway}
                baselineSessions={baselineSessions}
              />
              // ) : (
              //   <ErrorDisplay text="No upcoming sessions found" />
            )}
          </section>
        )}

        {view === 'clinics' && (
          <section>
            {isLoadingEvents ? (
              <LoadingAnimation />
            ) : camps?.length || classes?.length ? (
              <ClinicsList clinics={clinics} />
            ) : (
              <ErrorDisplay text="No upcoming clinics found" />
            )}
          </section>
        )}

        {view === 'events' && (
          <section>
            <ErrorDisplay text="Event listings coming soon!" />
            {/* {isLoadingEvents ? (
              <LoadingAnimation />
            ) : camps?.length || classes?.length ? (
              <EventsList enrollments={clinics} />
            ) : (
              <ErrorDisplay text="No upcoming events found" />
            )} */}
          </section>
        )}
      </>
    </div>
  );
};

const ScheduleViewToggle = memo(
  ({view, setView}: {view: ScheduleView; setView: React.Dispatch<React.SetStateAction<ScheduleView>>}) => {
    return (
      <div className={styles.viewToggleContainer}>
        <button
          className={`${styles.toggleButton} ${view === 'calendar' ? styles.active : ''}`}
          onClick={() => setView('calendar')}
        >
          Calendar
        </button>
        <button
          className={`${styles.toggleButton} ${view === 'clinics' ? styles.active : ''}`}
          onClick={() => setView('clinics')}
        >
          Clinics
        </button>
        <button
          disabled
          className={`${styles.toggleButton} ${view === 'events' ? styles.active : ''}`}
          onClick={() => setView('events')}
        >
          Events
        </button>
      </div>
    );
  }
);
ScheduleViewToggle.displayName = 'ScheduleViewToggle';

// const FeedbackSection: React.FC<{handleRating: (rate: number) => void}> = ({handleRating}) => (
//   <>
//     <div className={styles.feedbackContainer}>
//       <h3>{`How was today's session? `}</h3>
//       <div className={styles.ratingContainer}>
//         <Rating
//           onClick={handleRating}
//           SVGstrokeColor="#643DF2"
//           fillColor="#643DF2"
//           size={60}
//           style={{alignSelf: 'center'}}
//         />
//       </div>
//     </div>
//     <div className={styles.optionalText}>
//       <label className={styles.label}>Optional session feedback</label>
//       <textarea className={styles.feedbackTextarea}></textarea>
//     </div>
//   </>
// );

// const SessionsList: React.FC<{sessions: ScheduleVisit[]}> = ({sessions}) => (
//   <div className={styles.sessionList}>
//     {sessions.map((session, i) => (
//       <div key={`session_card_${i}`} className={styles.sessionCard}>
//         <div className={styles.weekMarker}>
//           {/* looks kinda angry with this much red maybe? refactored a bit anyway. */}
//           {/* {session.weekInTraining ? (
//             <>
//               <p>Week</p>
//               <p>{session.weekInTraining}</p>
//             </>
//           ) : (
//             <>
//               <p className={styles.error}>Week</p>
//               <p className={styles.error}>{Number(sessions[sessions.length - 2].weekInTraining) + 1}</p>
//             </>
//           )} */}

//           <p>Week</p>
//           <p>{session.sessionNumber}</p>
//           {/* <p>{session.weekInTraining ?? Number(sessions[sessions.length - 2].weekInTraining) + 1}</p> */}
//         </div>

//         <div className={styles.sessionDetails}>
//           {session.formattedDate ? (
//             <p className={styles.date}>{session.formattedDate}</p>
//           ) : (
//             <p className={`${styles.date} ${styles.error}`}>Not Booked 😢</p>
//           )}
//           {/* <p className={styles.coach}>{session.coach}</p> */}
//         </div>
//       </div>
//     ))}
//   </div>
// );

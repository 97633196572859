import {useGetSport} from 'common/useGetSport';
import {memo} from 'react';
import {Link} from 'react-router-dom';

export const Footer = memo(({withNavbar}: {withNavbar?: boolean}) => {
  const {basePath, isFreeTrialClasses} = useGetSport();
  const currentYear = new Date().getFullYear().toString();

  return (
    <div
      className={`flex flex-col gap-6 p-8 lg:flex-row lg:p-12 ${
        withNavbar ? '' : 'mt-16'
      } items-center justify-between bg-primary text-[rgba(255,255,255,0.66)] text-xs md:text-sm exceed-global-padding `}
    >
      <div className="flex flex-col md:flex-row items-center gap-4">
        {isFreeTrialClasses ? (
          <Link to={`${basePath}/help`} className="underline" target="_blank" rel="noreferrer">
            Need Help?
          </Link>
        ) : null}
        <a
          href="https://www.tocafootball.com/policies/terms-of-service"
          className="underline"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>
        <a
          href="https://www.tocafootball.com/policies/privacy-policy"
          className="underline"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        {/* <a href="" className="underline" target="_blank" rel="noreferrer">
          Cookies Settings
        </a> */}
      </div>

      <div className="flex flex-col gap-4">
        <div className="lg:hidden flex w-full h-1 border-t-2 border-[rgba(255,255,255,0.25)]" />
        <p>{`© ${currentYear} TOCA Soccer. All rights reserved.`}</p>
      </div>
    </div>
  );
});
Footer.displayName = 'Footer';

import {useMemo} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {useGetCoachInfo} from 'api/Coach/useGetCoachInfo';
import {useProfiles} from 'api/User/useProfiles';
import CoachDirectorNavbarLayoutView from './CoachDirectorNavbarLayoutView';
import {roleType} from 'constants/constants';

export default function CoachNavbarLayout() {
  const {teamId} = useParams();
  const location = useLocation();
  const {currentProfile} = useProfiles();
  const {data: coachInfo, isLoading} = useGetCoachInfo(currentProfile?._id);

  const teamInfo = useMemo(() => coachInfo?.teams.find((team) => team._id === teamId), [teamId, coachInfo?.teams]);

  // Check if the current route is team edit page
  const isTeamEdit = location.pathname.includes('team/edit');

  return (
    <CoachDirectorNavbarLayoutView
      showTeamProfileBar={isTeamEdit}
      loading={isLoading}
      teamInfo={teamInfo}
      role={roleType.teamCoach}
    />
  );
}

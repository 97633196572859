import { memo } from "react";
import { FreeTrialProgram } from "common/fko-utils";

type WelcomeBlockProps = {
    programName: FreeTrialProgram;
}

const WelcomeBlock = memo(({programName}: WelcomeBlockProps) => {
    return (
        <p className="max-w-[400px] text-base text-primary font-medium text-center">
            {programName === FreeTrialProgram.PICKUP_SOCCER ? (
                <>
                    <span className="block text-lg">Ready to try?</span>
                    <span className="block text-lg">Claim a Free Week of Pickup!</span>
                    <span className="block text-sm">* For Adult Pickup Customers Only</span>
                </>
            ) : programName === FreeTrialProgram.LEAGUES_TRIAL ? (
                <>
                    <span className="block text-lg">Interested in playing at TOCA?</span>
                    <span className="block text-lg">Fill out this form to get started.</span>
                </>
            ) :  (
                <>
                    <span className="block text-lg">Schedule a FREE, no-commitment class for your child.</span>
                </>
            )}
        </p>
    );
});

WelcomeBlock.displayName = 'WelcomeBlock';

export default WelcomeBlock;
import {FormEvent, memo, useCallback, useEffect, useState} from 'react';
import styles from './Help.module.scss';
import {Accordion, AccordionCard} from '../../components/Accordion/Accordion';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import {PageHeading} from 'components/PageHeading/PageHeading';
import {PageSubHeading} from 'components/PageSubHeading/PageSubHeading';
import {StandardButton} from 'components/Buttons/Standard/StandardButton';
import {StandardInput} from 'components/Input/StandardInput/StandardInput';

const faqs: AccordionCard[] = [
  {
    heading: 'What is TOCA?',
    content:
      'We are the next generation of technology-enabled training. With our patented technology including the Touch Trainer and Smart Targets, our training program develops well-rounded players through quality repetitions and game-like simulations.',
  },
  {
    heading: 'What is the expected skill level to participate?',
    content:
      "At TOCA everybody plays! From our technology-led programs for young kids 18 months - 7 years and our individualized soccer training programs for 8 - 17 year olds, to our group classes and adult recreational soccer leagues, there's a TOCA experience for everyone!",
  },
  {
    heading: 'What kind of experience do the TOCA Trainers have?',
    content:
      'All of our trainers have played soccer at a high level and each trainer has gone through a certification with us to make sure you get top quality trainers every time.',
  },
  {
    heading: 'How do Player Pathways work?',
    content:
      'Pathways are a focused and efficient approach to player development. They encourage a growth mindset for our players, which means mistakes are embraced so growth can happen.  Every Pathway begins – and progresses – where your Player currently is in their skill development. TOCA is for every player. Each session in the pathway is divided into well-defined phases or “focuses”, with each one dependent on the completion of the previous one.  As the Player progresses through a Pathway they are building confidence with a variety of skills. It’s a logical approach to training that is based on technical building blocks. We begin with simplified game situations before adding complex ones.',
  },
  {
    heading: 'How can I invite my teammates?',
    content:
      'We’re working on new features to bring your whole team into the Player Portal. The first step is to enroll as a TOCA member. For now, feel free to share this link with your teammates so they can get their first session for free: https://www.tocafootball.com/free-kickoff',
  },
];

export const Help = memo(() => {
  const [filteredFAQs, setFilteredFAQs] = useState([...faqs]);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    setFilteredFAQs(faqs.filter((faq) => faq?.heading.toLowerCase().includes(searchInput.toLowerCase())));
  }, [searchInput]);

  const handleSearchInput = useCallback((e: FormEvent<HTMLInputElement>) => {
    setSearchInput(e.currentTarget.value);
  }, []);

  return (
    <>
      <Helmet>
        <title>MyTOCA | Help</title>
      </Helmet>
      <PageHeading text="Need Help?" />

      <div className={styles.container}>
        <div className={styles.inputWrapper}>
          <StandardInput
            type="search"
            autoComplete="off"
            placeholder="Search topics"
            name="search"
            id="search"
            value={searchInput}
            onChange={handleSearchInput}
          />
        </div>

        <PageSubHeading text="Browse all help articles" />
        <Accordion cards={filteredFAQs} />
        {!filteredFAQs.length && <h4 className={styles.noResults}>No results found</h4>}

        <Link to={'/helpdesk'} className={styles.helpButton}>
          <StandardButton text="Submit Request for Help" />
        </Link>
      </div>
    </>
  );
});
Help.displayName = 'Help';

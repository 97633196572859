import AuthContainer from '../components/AuthContainer/AuthContainer';
import {Outlet} from 'react-router-dom';
import WithCurrentProfileContainer from '../components/WithCurrentProfileContainer/WithCurrentProfileContainer';
import {UserProvider} from '../context/UserContextProvider';
import {TrainerEmulationContainer} from 'components/TrainerEmulationContainer/TrainerEmulationContainer';

export default function RootAuthenticatedLayout() {
  return (
    <AuthContainer>
      <UserProvider>
        <TrainerEmulationContainer>
          <WithCurrentProfileContainer>
            <Outlet />
            {/* <TourDisplay>
            </TourDisplay> */}
          </WithCurrentProfileContainer>
        </TrainerEmulationContainer>
      </UserProvider>
    </AuthContainer>
  );
}

import {memo} from 'react';
import styles from './BookingHeader.module.scss';
import {ReactComponent as ChevronLeft} from '../../../assets/img/icons/new-chevron-left-white.svg';

export const BookingHeader = memo(({text, backOnClick}: {text: string; backOnClick?: () => void}) => {
  return (
    <div className={styles.container}>
      {backOnClick && (
        <button className={styles.backButton} onClick={backOnClick}>
          <ChevronLeft />
        </button>
      )}
      <h1 className={styles.headingText}>{text}</h1>
    </div>
  );
});
BookingHeader.displayName = 'BookingHeader';

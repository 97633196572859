import {memo} from 'react';

type PageHeadingProps = {
  text: string;
};

// TODO migrate these Connect components to a general folder, as they will be the base components now
export const PageHeading = memo(({text}: PageHeadingProps) => {
  return (
    <h1 className="font-roboto-con font-bold tracking-[-0.5px] text-[44px] sm:text-[50px] md:text-[58px] leading-[52px] md:leading-[64px] lg:text-center text-primary uppercase">
      {text}
    </h1>
  );
});
PageHeading.displayName = 'PageHeading';

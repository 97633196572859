import styles from './BookingSessionForm.module.scss';
import {useState, useEffect, memo, useMemo, useCallback} from 'react';
import {useGetAvailableSessions} from 'api/FKO/useGetAvailableSessions';
import {SessionCard} from '../../../components/Booking/SessionCard/SessionCard';
import {BookingFormButton} from '../../../components/Buttons/Form/FormButton';
import {DateCarousel} from '../../../components/DateCarousel/DateCarousel';
import {useAppDispatch, useAppSelector} from '../../../redux/reduxUtils';
import LoaderInline from '../../LoaderInline/LoaderInline';
import {Session} from '../../RegistrationScheduling/SchedulingForm';
import {StandardSelect} from '../../../components/Select/StandardSelect/StandardSelect';
import {BOOKABLE_SESSION_TYPES, WEEK_REPEAT_OPTIONS} from '../../../constants/bookingConsts';
import {Modal} from 'components/Modal/Modal';
import {ModalBookingSession} from 'components/Booking/ModalBookingSession/ModalBookingSession';
import {AboveNavbarContainer} from 'components/Navbar/AboveNavbarContainer/AboveNavbarContainer';
import moment from 'moment';
import {ReactComponent as CalendarIcon} from '../../../assets/img/icons/redesign-icons/calendar-minimal.svg';
import {FkoDatePicker} from 'components/DatePicker/DatePicker';
import {constructDateFromHyphenated} from 'utils/utils';

export const BookingSessionForm = ({
  siteId,
  onSubmit,
  reselecting,
  initialDate,
}: {
  siteId: string;
  onSubmit: ({session, repeat}: {session: Session; repeat: number}) => void;
  reselecting?: boolean;
  initialDate?: string;
}) => {
  const dispatch = useAppDispatch();
  // const appointmentCalendar = useAppSelector((state) => state.bookingReducer.appointmentCalendar);
  const appointmentCalendar = useAppSelector((state) => state.fkoFormDataReducer.appointmentCalendar);

  const firstSessionKey = Object.keys(appointmentCalendar)[0];
  const lastSessionKey = Object.keys(appointmentCalendar).reverse()[0];

  const [selectedSession, setSelectedSession] = useState<Session | undefined>();
  const [selectedDate, setSelectedDate] = useState(initialDate ?? firstSessionKey);
  const [month, setMonth] = useState<Date>(constructDateFromHyphenated(selectedDate));
  const [scrollToDate, setScrollToDate] = useState<string>(initialDate ?? firstSessionKey);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [fetched, setFetched] = useState(firstSessionKey ? appointmentCalendar[firstSessionKey]?.hasFetched : false);
  const disabled = !selectedSession;

  useGetAvailableSessions({
    siteId: siteId,
    sessionTypeNames: BOOKABLE_SESSION_TYPES,
    startDate: `${firstSessionKey}T00:00:00Z`,
    endDate: `${lastSessionKey}T23:59:59Z`,
  });

  const visibleAppointments = useMemo(
    () => (selectedDate ? appointmentCalendar[selectedDate].appointments : []),
    [appointmentCalendar, selectedDate]
  );

  const closeSelectedSessionModal = useCallback(() => {
    setSelectedSession(undefined);
  }, []);

  const onDateSelect = useCallback(
    (dateKey: string) => {
      setFetching(appointmentCalendar[dateKey].fetching);
      setFetched(appointmentCalendar[dateKey].hasFetched);
      setSelectedDate(dateKey);
      setMonth(constructDateFromHyphenated(dateKey));
    },
    [appointmentCalendar]
  );

  const calendarSelect = useCallback(
    (date?: Date) => {
      setIsCalendarOpen(false);
      if (date) {
        const dateStr = moment(date).format('YYYY[-]MM[-]DD');
        onDateSelect(dateStr);
        setScrollToDate(dateStr);
      }
    },
    [onDateSelect]
  );

  useEffect(() => {
    // TODO errors here without ?, no ts error, fix typescript and fix logic
    if (selectedDate) {
      setFetching(appointmentCalendar[selectedDate]?.fetching);
      setFetched(appointmentCalendar[selectedDate]?.hasFetched);
    }
  }, [selectedDate, appointmentCalendar, dispatch, siteId]);

  if (!selectedDate) {
    return null;
  }

  return (
    <>
      <div className={`relative flex flex-col transition-all`}>
        <div className="absolute top-[-60px] right-0 h-[36px] w-[36px] grid place-content-center pb-[2px] rounded-full bg-primary bg-opacity-5 hover:bg-opacity-10 transition-all">
          <button onClick={() => setIsCalendarOpen((prev) => !prev)}>
            <CalendarIcon className="h-[24px] w-[24px] fill-primary active:fill-secondary opacity-90 hover:opacity-100 transition-all" />
          </button>
        </div>
        <div
          className={`${
            isCalendarOpen ? 'h-[330px] opacity-100' : 'h-[0px] opacity-0'
          } flex justify-center lg:justify-end items-start overflow-hidden  transition-all`}
        >
          <div className="p-2 border border-primary border-opacity-20 rounded-lg">
            {/* because Bookings currently uses FKO apptCal in redux, this can be used here */}
            <FkoDatePicker
              selected={constructDateFromHyphenated(selectedDate)}
              onSelect={(date) => calendarSelect(date)}
              startDate={firstSessionKey}
              endDate={lastSessionKey}
              month={month}
              onMonthChange={setMonth}
            />
          </div>
        </div>
      </div>

      <DateCarousel
        appointmentCalendar={appointmentCalendar}
        setSelectedDate={setSelectedDate}
        selectedDate={selectedDate}
        onDateSelect={onDateSelect}
        scrollToDate={scrollToDate}
      />

      {fetching && !fetched && !visibleAppointments.length ? (
        <div className={styles.loadingText}>
          <LoaderInline text="Loading session times..." />
        </div>
      ) : (
        <div>
          {visibleAppointments?.length ? (
            <>
              <ul className={styles.sessionsList}>
                {visibleAppointments.map((appointment: Session, index: number) => (
                  <li key={index} onClick={() => setSelectedSession(appointment)} className={styles.sessionWrapper}>
                    <SessionCard displayOnly session={appointment} highlight={selectedSession?.id === appointment.id} />
                  </li>
                ))}
              </ul>

              {!disabled && (
                <AboveNavbarContainer>
                  <div className={styles.bookSessionButton}>
                    <BookingFormButton isPrimary>Book Session</BookingFormButton>
                  </div>
                </AboveNavbarContainer>
              )}
            </>
          ) : (
            <div className={styles.noAppointmentsText}>
              <p>Sorry, there aren&apos;t any sessions available on this day.</p>
              <p>Please select a different day.</p>
            </div>
          )}
        </div>
      )}
      <Modal isOpen={!!selectedSession} onClose={closeSelectedSessionModal} title="Book Session">
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        <BookingModal reselecting={Boolean(reselecting)} session={selectedSession!} onSubmit={onSubmit} />
      </Modal>
    </>
  );
};

export const BookingModal = memo(
  ({
    session,
    onSubmit,
    reselecting,
  }: {
    session: Session;
    onSubmit: ({session, repeat}: {session: Session; repeat: number}) => void;
    reselecting: boolean;
  }) => {
    const [repeatWeeks, setRepeatWeeks] = useState<string>(reselecting ? '0' : '12');
    const onSubmitMemo = useCallback(
      () => onSubmit({session, repeat: parseInt(repeatWeeks)}),
      [onSubmit, repeatWeeks, session]
    );

    return (
      <div className={styles.bookingModal}>
        <ModalBookingSession session={session} />

        {!reselecting && (
          <>
            <div className={styles.repeatSection}>
              {/* <Checkbox id="repeatcheck" labelText="" defaultChecked onChange={() => setRepeatWeeks('0')} /> */}
              <p className={styles.repeatText}>Repeat Session For:</p>
              <StandardSelect
                id="week-select"
                options={WEEK_REPEAT_OPTIONS}
                onChange={(e) => setRepeatWeeks(e.currentTarget.value.toString())}
                defaultValue="12"
              />
            </div>
            <p className={styles.addendumText}>We recommend at least 12 training sessions for best results</p>
          </>
        )}

        <BookingFormButton type="submit" isPrimary onClick={onSubmitMemo}>
          {reselecting ? 'select this session' : 'continue to review'}
        </BookingFormButton>
      </div>
    );
  }
);
BookingModal.displayName = 'BookingModal';

import styles from './ScrollableList.module.scss';
import {VirtualizedList} from '../VirtualizedList/VirtualizedList';

export type ScrollableListProps = {
  items: JSX.Element[];
  itemHeight: number;
  heading?: JSX.Element;
  startingItem?: number;
  scrollToItem?: number;
};

export const ScrollableList = ({items, itemHeight, heading, scrollToItem, startingItem}: ScrollableListProps) => {
  return (
    <div className={styles.container}>
      {heading && heading}
      <VirtualizedList items={items} itemHeight={itemHeight} scrollToItem={scrollToItem} startingItem={startingItem} />
    </div>
  );
};

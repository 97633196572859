import {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {CurriculumSession} from './types';
import {LoadingAnimationFullHeight} from 'components/Loader/LoadingAnimation';
import {useGetAthleteInfo} from 'api/Trainer/useGetAthleteInfo';
import {getAthleteCurriculum} from 'constants/curriculumData';
import {PageHeading} from 'components/Connects/PageHeading';
import {ButtonTray} from 'components/Connects/ButtonTray';
import {Button} from 'components/Connects/Button';
import {LogoBanner} from 'components/Connects/LogoBanner';
import {UpcomingSessionSummary} from 'components/Connects/UpcomingSessionSummary/UpcomingSessionSummary';
import {ProfileBar} from 'components/ProfileBar/ProfileBar';

export const NextWeek = () => {
  const navigate = useNavigate();
  const {trainerID} = useParams();
  const [searchParams] = useSearchParams();
  const [clientId, siteId] = [searchParams.get('mboClientId'), searchParams.get('mboSiteId')];
  const [nextSession, setNextSession] = useState<CurriculumSession>();

  const {data: athleteInfo, isLoading} = useGetAthleteInfo({clientId, siteId});

  useEffect(() => {
    if (athleteInfo?.currentLevel) {
      setNextSession(
        getAthleteCurriculum(athleteInfo.currentLevel, Math.max(0, athleteInfo?.lastSessionNumber ?? 0) + 1)
      );
    }
  }, [athleteInfo?.currentLevel, athleteInfo?.lastSessionNumber]);

  return (
    <>
      <Helmet>
        <title>MyTOCA | Next Week</title>
      </Helmet>

      {isLoading ? (
        <LoadingAnimationFullHeight />
      ) : athleteInfo && nextSession ? (
        <div className="grow flex flex-col">
          <LogoBanner />
          <ProfileBar athleteInfo={athleteInfo} />
          <div className="my-6">
            <PageHeading text="Next Week" />
          </div>

          <UpcomingSessionSummary nextSession={nextSession} />

          <div style={{height: '32px'}} />

          <div className="md:mx-auto md:max-w-[600px] md:w-full">
            <ButtonTray>
              <Button
                text="Previous"
                color="secondary"
                onClick={() =>
                  athleteInfo?.lastSession?.isBaseline
                    ? navigate(
                        `/trainer/connects/baseline?trainer=${trainerID}&mboClientId=${clientId}&mboSiteId=${siteId}`
                      )
                    : navigate(
                        `/trainer/connects?trainer=${trainerID}&mboClientId=${clientId}&mboSiteId=${siteId}&baselineVisited=true`
                      )
                }
              />
              <Button
                text="Next"
                color="secondary"
                variant="cta"
                onClick={() =>
                  navigate(
                    `/trainer/connects/schedule?trainer=${trainerID}&mboClientId=${clientId}&mboSiteId=${siteId}`
                  )
                }
              />
            </ButtonTray>
          </div>
        </div>
      ) : !athleteInfo ? (
        <div className="grow flex flex-col">
          <p className="text-sm text-grey-xdark text-center p-4">there was an error loading athlete information</p>
        </div>
      ) : (
        <div className="grow flex flex-col">
          <p className="text-sm text-grey-xdark text-center p-4">there was an error loading upcoming curriculum</p>
        </div>
      )}
    </>
  );
};

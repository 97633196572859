import React, {useEffect} from 'react';
import './Helpdesk.style.scss';
import {Link} from 'react-router-dom';
import {useHubspotForm} from '@aaronhayes/react-use-hubspot-form';
import {auth as fireBaseAuth} from '../../firebase/config';
import {useProfiles} from '../../api/User/useProfiles';
import {Helmet} from 'react-helmet';
import {PageHeading} from '../../components/PageHeading/PageHeading';

const formOptions = {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  portalId: process.env.REACT_APP_HUBSPOT_PORTAL_ID!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  formId: process.env.REACT_APP_HUBSPOT_HELPDESK_FORM_ID!,
  target: '#helpdesk-hubspot-form',
};

export const Helpdesk = () => {
  const firebaseUser = fireBaseAuth.currentUser;
  const {currentProfile} = useProfiles();

  // Copy form options since HubSpot modifies the passed object
  const {loaded, formCreated} = useHubspotForm({...formOptions});

  useEffect(() => {
    setTimeout(() => {
      if (formCreated && loaded) {
        const formContainer = document.getElementById('helpdesk-hubspot-form');

        if (!formContainer) {
          return;
        }

        if (firebaseUser || currentProfile) {
          const email = firebaseUser?.email;
          // TODO currentProfile or account holder?
          const phoneNumber = firebaseUser?.phoneNumber || currentProfile?.phoneNumber;
          const firstName = currentProfile?.firstName;
          const lastName = currentProfile?.lastName;
          // TODO Read current player location and set value on form dropdown

          if (email) {
            const emailInput = formContainer.querySelector('input[name="email"]');
            if (emailInput) {
              (emailInput as any).value = email;
            }
          }
          if (phoneNumber) {
            const phoneNumberInput = formContainer.querySelector('input[name="phone"]');
            if (phoneNumberInput) {
              (phoneNumberInput as any).value = phoneNumber;
            }
          }
          if (firstName) {
            const firstNameInput = formContainer.querySelector('input[name="firstname"]');
            if (firstNameInput) {
              (firstNameInput as any).value = firstName;
            }
          }
          if (lastName) {
            const lastNameInput = formContainer.querySelector('input[name="lastname"]');
            if (lastNameInput) {
              (lastNameInput as any).value = lastName;
            }
          }
        } else {
          // User is not signed in

          const subjectInput = formContainer.querySelector('input[name="TICKET.subject"]');
          if (subjectInput) {
            (subjectInput as any).value = 'Issue signing in to Player Portal';
          }

          const detailsInput = formContainer.querySelector('textarea[name="TICKET.content"]');
          if (detailsInput) {
            (detailsInput as any).innerText =
              'I am having trouble signing in to the Player Portal online. Can you please contact me ' +
              'via the provided email or phone number to discuss and resolve my issue?';
          }
        }
      }
    }, 500);
  }, [firebaseUser, currentProfile, formCreated, loaded]);

  return (
    <div className="bottom-container helpdesk">
      <Helmet>
        <title>MyTOCA | Submit Request for Help</title>
      </Helmet>
      <PageHeading text="Submit Request for Help" />

      {!firebaseUser && (
        <div className="help-instructions">
          <p>
            <strong>Please read and try these options, before you submit a request: </strong>
          </p>
          <ol>
            <li>
              If you have signed in to this MyTOCA Portal previously, use the
              <Link to="/forget-password">
                <strong>Forgot Password</strong>
              </Link>{' '}
              option to reset your password.
            </li>
            <li>
              If this is your first time,{' '}
              <Link to="/signup">
                <strong>Sign Up</strong>
              </Link>
              for a new account using your MindBody email and a new password. Alternatively, you can use the convenient
              social auth methods we support - Google, Facebook, or Apple, as long as those accounts are associated with
              the same email that&apos;s on your MindBody profile.
            </li>
            <li>
              If none of the above works, please submit the form below to contact us for help. Please make sure to
              provide your MindBody email address.
            </li>
          </ol>
          <hr style={{marginTop: '20px'}} />
        </div>
      )}

      <div id="helpdesk-hubspot-form"></div>
    </div>
  );
};

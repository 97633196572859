import {TocaLocation} from '../../constants/locations';
import {BookingSession} from '../../redux/slices/booking';
import {Profile} from '../../user/player-info.interface';
import {createAppointment, saveErrorDetails} from '../api';
import {useMutation} from '@tanstack/react-query';

export type CreatedBookingResponse = {
  session: BookingSession;
  confirmed: boolean;
  response?: unknown;
  error?: string;
};

interface BookingInfo {
  player: Profile;
  location: TocaLocation;
  session: BookingSession;
  sessions: BookingSession[];
}

async function createBooking({
  session,
  player,
  location,
}: Omit<BookingInfo, 'sessions'>): Promise<CreatedBookingResponse> {
  let response;

  if (!player.mboDetails) {
    return {
      confirmed: false,
      error: 'no mbo clientId',
      session,
    };
  }

  const appointmentData = {
    ClientId: player.mboDetails.clientId,
    LocationId: session.location.id,
    SessionTypeId: session.sessionType.id,
    StaffId: session.staff.id,
    StaffRequested: true,
    SendEmail: true, // Intended to be true so that MBO sends appointment emails
    StartDateTime: session.startDate,
  };

  try {
    response = await createAppointment(location.siteId, appointmentData);
  } catch (err: any) {
    const error =
      'ValidationFailed' === err.response?.data?.code
        ? 'The selected time is not available anymore. Please select a different time.'
        : 'Failed to make reservation';

    void saveErrorDetails({
      key: 'BookingFailed',
      message: error,
      description: '[createBooking][createAppointment] Failed to create appointment',
      siteId: location.siteId,
      severity: 'high',
      context: 'bookings',
      url: window.location.href,
      email: player.email,
      accountId: player.accountId,
      profileId: player._id,
      data: {
        player,
        appointmentData,
      },
    });

    return {
      confirmed: false,
      error,
      session,
    };
  }

  return {
    confirmed: true,
    response,
    session,
  };
}

async function createBookings({
  sessions,
  player,
  location,
}: Omit<BookingInfo, 'session'>): Promise<CreatedBookingResponse[]> {
  const bookings = [];

  // IMPORTANT: Don't make parallel requests to create bookings as it will cause the MBO API to error.
  for (const session of sessions) {
    const booking = await createBooking({session, player, location});
    bookings.push(booking);
  }

  return bookings;
}

export const useCreateNewBookings = () => {
  const createMultipleNewBookingsMutation = useMutation({
    mutationFn: createBookings,
  });

  // We expose the regular function, as well as, the react-query mutation, as both can be useful in different situations
  return {createBookings, createMultipleNewBookingsMutation};
};

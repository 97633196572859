import {useQuery} from '@tanstack/react-query';
import {getPublicLeaderboard} from 'api/api';

export const useGetLeaderboardRankings = ({leaderboardId}: {leaderboardId?: string}) => {
  return useQuery({
    queryKey: [`leaderboard`, leaderboardId],
    // eslint-disable-next-line
    queryFn: () => getPublicLeaderboard(leaderboardId!).then((res) => res.data),
    enabled: !!leaderboardId,
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60,
  });
};

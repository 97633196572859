import NestedRadialBar from '../NestedRadialBar/NestedRadialBar';
import './PerformanceCard.style.scss';
import CustomWrapper from '../CustomWrapper/CustomWrapper';
import {memo} from 'react';

interface PerformanceCardTypes {
  firstProg?: number;
  secondProg?: number;
  thirdProg?: number;
  clickable?: boolean;
  // TODO temp
  legacy?: boolean;
}

export const PerformanceCard = memo(
  ({firstProg = 0, secondProg = 0, thirdProg = 0, clickable = false, legacy = false}: PerformanceCardTypes) => {
    return (
      <CustomWrapper
        link={clickable ? (legacy ? '/performance-legacy' : '/performance') : null}
        className="accuracy-card"
      >
        <div className="accuracy-card_result">
          <ul className="result-list">
            <li className="result-list_item">
              <span className="percentage-title">Repetitions</span>
              <span className="percentage first">{firstProg}</span>
            </li>
            <li className="result-list_item">
              <span className="percentage-title">Accuracy</span>
              <span className="percentage second">{secondProg}%</span>
            </li>
            <li className="result-list_item">
              <span className="percentage-title">Speed of Play</span>
              <span className="percentage third">{thirdProg}</span>
            </li>
          </ul>
        </div>
        <div className="accuracy-card_graphic">
          <NestedRadialBar firstProg={firstProg} secondProg={secondProg} thirdProg={thirdProg} />
        </div>
      </CustomWrapper>
    );
  }
);
PerformanceCard.displayName = 'PerformanceCard';

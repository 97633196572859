import styles from './Trainer.module.scss';
import {memo, useEffect, useState} from 'react';
import {getTrainerPlayers} from '../../api/api';
import {TrainerSearch} from './Search/TrainerSearch';
import {Profile} from '../../user/player-info.interface';
import {useProfiles} from '../../api/User/useProfiles';
import {getLocationBySiteId} from '../../constants/locations';
import {LogoBanner} from 'components/Connects/LogoBanner';
import {LoadingAnimation} from 'components/Loader/LoadingAnimation';

export type PlayerShort = Pick<Profile, '_id' | 'dob' | 'email' | 'firstName' | 'lastName'> &
  Required<Pick<Profile, 'mboDetails'>>;

export const Trainer = memo(() => {
  const {currentProfile} = useProfiles();
  const [players, setPlayers] = useState<PlayerShort[]>();
  const [error, setError] = useState(false);

  const trainerLocation = currentProfile?.mboDetails?.siteId
    ? getLocationBySiteId(currentProfile.mboDetails.siteId)
    : undefined;

  useEffect(() => {
    getTrainerPlayers()
      .then((res) => {
        setPlayers(res.data);
        setError(false);
      })
      .catch((err) => setError(err));
  }, []);

  return (
    <>
      <div className={styles.flexContainer}>
        <LogoBanner />

        <div className={styles.trainerHeading}>
          <p className={styles.heading}>Trainer View</p>
          <p>
            <span>Center:</span> {trainerLocation?.name ?? 'n/a'}
          </p>
        </div>

        <div className={styles.searchContainer}>
          {error ? (
            <div className={styles.centeredContainer}>
              <p className={styles.errorText}>There was an error retrieving the list of players.</p>
            </div>
          ) : players ? (
            <TrainerSearch players={players} />
          ) : (
            <LoadingAnimation />
          )}
        </div>
      </div>
    </>
  );
});
Trainer.displayName = 'Trainer';

import {FormEventHandler, memo, useCallback, useEffect, useMemo, useState} from 'react';
import styles from './Home.module.scss';
import {Helmet} from 'react-helmet';
import {PathwayLevel, TrainingSession, useGetPlayerDetails} from '../../api/User/useGetPlayerDetails';
import {retrievePathwayInfo} from 'constants/pathwayNames';
import {ReactComponent as MyTocaLogo} from '../../assets/img/icons/redesign-icons/mytoca-logo.svg';
import {LoadingAnimationOverlay} from 'components/Loader/LoadingAnimation';
import {PageSubHeading} from 'components/PageSubHeading/PageSubHeading';
import {PathwaySelection} from 'components/PathwaySelection/PathwaySelection';
import {SelectOptions, StandardSelect} from 'components/Select/StandardSelect/StandardSelect';
import {PerformanceCard} from '../../components/PerformanceCard/PerformanceCard';
import {SessionSummaryCard} from 'components/SessionSummaryCard/SessionSummaryCard';
// import {StatsCardContainer} from '../../components/StatsCardContainer/StatsCardContainer';
import Splash from 'components/Splash/Splash'; // TODO eval component

// This is getting out of hand, now there are two of them!!
export const NewOldHome = memo(() => {
  const {
    data: playerDetails,
    isLoading: isPlayerDetailsLoading,
    isFetching: isPlayerDetailsFetching,
  } = useGetPlayerDetails();

  const [curSession, setCurSession] = useState<TrainingSession | null>(null);
  const [activeLevel, setActiveLevel] = useState<PathwayLevel>();

  const setCurrentSession = useCallback(
    (index: number) => {
      if (activeLevel && playerDetails?.pathways?.[activeLevel]?.sessions?.length) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setCurSession(playerDetails?.pathways[activeLevel]!.sessions[index]);
      } else {
        setCurSession(null);
      }
    },
    [activeLevel, playerDetails?.pathways]
  );

  const sessionCounts = {
    baseline: 0,
    normal: 0,
  };
  const selectOptions = useMemo(
    () =>
      activeLevel && playerDetails?.pathways
        ? playerDetails.pathways[activeLevel]?.sessions
            ?.map((session, i) => {
              if (session.isBaseline) {
                sessionCounts.baseline++;
              } else {
                sessionCounts.normal++;
              }

              return {
                value: i,
                displayValue: session.isBaseline
                  ? `Baseline Session ${sessionCounts.baseline}`
                  : `Session ${sessionCounts.normal}`,
              };
            })
            .reverse() // important that { value:i } is set before reversal
        : null,
    // sessionCounts should not be a dependancy
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeLevel, playerDetails?.pathways]
  );

  const selectSession: FormEventHandler<SelectOptions> = useCallback(
    (e) => {
      setCurrentSession(Number(e.currentTarget.value));
    },
    [setCurrentSession]
  );

  useEffect(() => {
    // on load, set initial pathway
    if (playerDetails?.pathways?.currentLevel && !activeLevel) {
      setActiveLevel(playerDetails.pathways.currentLevel);
    }

    // if pathway contains no sessions // set initial session
    if (!selectOptions) {
      setCurSession(null);
    } else if (!curSession && playerDetails?.pathways && activeLevel) {
      if (playerDetails?.pathways[activeLevel]?.sessions?.length) {
        setCurSession(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          playerDetails?.pathways[activeLevel]!.sessions[playerDetails?.pathways[activeLevel]!.sessions.length - 1]
        );
      }
    }
  }, [selectOptions, playerDetails?.pathways, activeLevel, curSession]);

  if (isPlayerDetailsLoading) {
    return <Splash />;
  }

  const hasPathways =
    playerDetails?.pathways?.['beginner']?.sessions?.length ||
    playerDetails?.pathways?.['intermediate']?.sessions?.length ||
    playerDetails?.pathways?.['advanced']?.sessions?.length;

  return (
    <>
      <Helmet>
        <title>MyTOCA | Home</title>
      </Helmet>
      <div className={styles.container}>
        <div className={styles.logo}>
          <MyTocaLogo />
        </div>

        {!activeLevel || !hasPathways ? (
          <div className={styles.errorContainer}>
            <p>No pathways to show</p>
          </div>
        ) : playerDetails.pathways ? (
          <>
            <PathwaySelection
              pathways={playerDetails.pathways}
              activeLevel={activeLevel}
              setActiveLevel={setActiveLevel}
            />

            {!selectOptions?.length ? (
              <div className={styles.errorContainer}>
                <p>No session found to display performance</p>
              </div>
            ) : (
              <>
                <div>
                  <div className={styles.sessionHeader}>
                    <StandardSelect
                      id="home-session-select"
                      options={selectOptions}
                      defaultValue={selectOptions[0].value}
                      onChange={selectSession}
                    />
                  </div>
                </div>

                {curSession ? (
                  // curSession.isBaseline ? (
                  //   <BaselineSessionTable sessionData={curSession} />
                  // ) : (
                  <>
                    <div>
                      <div className={styles.cardHeader}>
                        <PageSubHeading text="Performance" />
                      </div>
                      <PerformanceCard
                        firstProg={curSession.performance?.metrics.repetitions}
                        secondProg={curSession.performance?.metrics.accuracy}
                        thirdProg={curSession.performance?.metrics.speedOfPlay}
                      />
                    </div>

                    <div>
                      <PageSubHeading text="Session Summary" />
                      <div className={styles.summaryContainer}>
                        {curSession.focuses
                          ?.filter((f) => f.name && f.name.toUpperCase() !== 'CHALLENGES')
                          .map((focus, i) => {
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            const {pathwayName, pathwayDesc} = retrievePathwayInfo(focus.name!); // confirmed in filter
                            return (
                              <SessionSummaryCard
                                key={`summary_card_${i}`}
                                title={pathwayName}
                                description={pathwayDesc}
                              />
                            );
                          })}
                        {/* <StatsCardContainer data={curSession.stats} /> */}
                      </div>
                    </div>
                  </>
                ) : // )
                null}
              </>
            )}

            {/* {playerDetails.pathways.legacy ? (
              <div className={styles.legacyPathwaysLink}>
                <StandardButton text="View Legacy Pathway Data" onClick={() => setIsLegacyPathways(true)} />
              </div>
            ) : null} */}
          </>
        ) : null}
      </div>

      <LoadingAnimationOverlay isLoading={isPlayerDetailsFetching} />
    </>
  );
});
NewOldHome.displayName = 'NewOldHome';

import {useGetSport} from 'common/useGetSport';
import {ReactNode, memo} from 'react';
import {Link} from 'react-router-dom';

type ErrorCardProps = {
  text?: string;
  children?: ReactNode;
  genericWrapper?: boolean;
};

export const ErrorCard = memo(({text, children, genericWrapper}: ErrorCardProps) => {
  const {basePath} = useGetSport();

  return (
    <div className="flex flex-col gap-2 py-4 px-6 sm:px-16 bg-white overflow-hidden border border-[red] rounded-lg shadow-flat-lg shadow-[rgba(255,0,0,0.3)]">
      {genericWrapper || (!text && !children) ? (
        <>
          <p className="text-base font-medium text-primary text-center">Something went wrong...</p>
          {text && <p className="text-base text-blue-dark text-center">{text}</p>}
          {children}
          <p className="text-base text-blue-dark text-center">
            If you need further assistance, please{' '}
            <Link to={`${basePath}/help`} className="underline text-blue cursor-pointer">
              visit our Help page.
            </Link>
          </p>
        </>
      ) : (
        <>
          {text && <p className="text-base text-blue-dark text-center">{text}</p>}
          {children}
        </>
      )}
    </div>
  );
});
ErrorCard.displayName = 'ErrorCard';

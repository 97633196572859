import {useCallback} from 'react';
import {useAppDispatch, useAppSelector} from '../redux/reduxUtils';
import {setLoading} from '../redux/slices/loading';

export const useGetGlobalLoading = () => {
  const globalLoading = useAppSelector((state) => state.loadingReducer.loading);
  return {globalLoading};
};

export const useSetGlobalLoading = () => {
  const dispatch = useAppDispatch();

  const setGlobalLoading = useCallback(
    (isLoading: boolean) => {
      dispatch(setLoading(isLoading));
    },
    [dispatch]
  );

  return {setGlobalLoading};
};

import {memo, useEffect, useMemo, useState} from 'react';
import {useProfiles} from '../../../api/User/useProfiles';
import {useGetAvailableSessions} from 'api/FKO/useGetAvailableSessions';
import {useAppDispatch, useAppSelector} from '../../../redux/reduxUtils';
import {BookingSession, selectAppointment} from '../../../redux/slices/booking';
import {getAdjustedISODate, parseDateTime} from '../../../utils/utils';
import {SessionCard} from './SessionCard';

export const SessionFetcher = memo(({date, referenceSession}: {date: string; referenceSession: BookingSession}) => {
  const dispatch = useAppDispatch();
  const [parsedSession, setParsedSession] = useState<BookingSession>();

  const {currentProfile} = useProfiles();
  const {appointments, hasFetched} = useAppSelector((state) => state.fkoFormDataReducer.appointmentCalendar[date]);
  const {iso: referenceStartTime} = useMemo(
    () => parseDateTime(referenceSession.startDate),
    [referenceSession.startDate]
  );
  const {iso: referenceEndTime} = useMemo(() => parseDateTime(referenceSession.endDate), [referenceSession.endDate]);
  const [desiredStartDate, desiredEndDate] = [`${date}T${referenceStartTime}`, `${date}T${referenceEndTime}`];

  useGetAvailableSessions({
    siteId: currentProfile.location?.siteId,
    sessionTypeNames: [referenceSession.sessionType.name],
    startDate: desiredStartDate,
    endDate: desiredEndDate,
  });

  useEffect(() => {
    if (hasFetched) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const location = currentProfile.location!;
      const adjustedRefTime = parseDateTime(getAdjustedISODate(desiredStartDate, location)).iso;

      // try first to match both coach & time, otherwise just try to match time
      const matchingSession =
        appointments.find((appt) => {
          const adjustedApptTime = parseDateTime(getAdjustedISODate(appt.startDate, location)).iso;
          return Boolean(appt.staff.id === referenceSession.staff.id && adjustedApptTime === adjustedRefTime);
        }) ??
        appointments.find((appt) => {
          const adjustedApptTime = parseDateTime(getAdjustedISODate(appt.startDate, location)).iso;
          return Boolean(adjustedApptTime === adjustedRefTime);
        });

      if (matchingSession) {
        // if a suitable session is found
        const coachDoesMatch = matchingSession.staff.id === referenceSession.staff.id;
        const session = {
          ...matchingSession,
          bookingStatus: {
            unavailableCoach: !coachDoesMatch,
          },
        };

        dispatch(selectAppointment({session}));
        setParsedSession(session);
      } else {
        // if a suitable session is NOT found, create a placeholder for display
        const placeholderSession = {
          ...referenceSession,
          startDate: desiredStartDate,
          endDate: desiredEndDate,
          dateStr: date,
          bookingStatus: {
            unavailableTime: true,
          },
        };

        dispatch(selectAppointment({session: placeholderSession}));
        setParsedSession(placeholderSession);
      }
    }
  }, [
    date,
    appointments,
    dispatch,
    referenceSession,
    hasFetched,
    referenceStartTime,
    desiredStartDate,
    desiredEndDate,
    currentProfile.location,
  ]);

  return (
    <SessionCard
      session={parsedSession}
      loadingDate={date}
      trayOpen={!!parsedSession?.bookingStatus?.error}
      displayOnly
    />
  );
});
SessionFetcher.displayName = 'SessionFetcher';

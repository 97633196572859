import {memo, useEffect, useState} from 'react';
import styles from './SessionRecap.module.scss';
import {PathwayLevel, PerformanceMetric, TrainingSession} from 'api/User/useGetPlayerDetails';
import {getAthleteCurriculum} from 'constants/curriculumData';
import {SectionHeading} from 'components/Connects/SectionHeading';
import {CurriculumSession} from 'pages/Connects/types';

type SessionRecapProps = {
  sessionSummary: PerformanceMetric;
  lastSession?: TrainingSession;
  level: PathwayLevel;
  sessionNumber?: number;
};

export const SessionRecap = memo(({sessionSummary, lastSession, level, sessionNumber = 1}: SessionRecapProps) => {
  // TODO Why do we use useState + useEffect here?
  const [curriculumSession, setCurriculumSession] = useState<CurriculumSession>();

  useEffect(() => {
    setCurriculumSession(getAthleteCurriculum(level, sessionNumber));
  }, [level, sessionNumber]);

  const TOUCHES_PER_PRO_GAME = 42;
  const lifetimeTouches = lastSession?.lifetimeTouches;
  const lifetimeTouchesDescription = lifetimeTouches
    ? lifetimeTouches < TOUCHES_PER_PRO_GAME
      ? `You've almost played a full game with TOCA!`
      : lifetimeTouches < 2 * TOUCHES_PER_PRO_GAME
      ? `You've played 1 game with TOCA!`
      : `You've played ${Math.floor(lifetimeTouches / TOUCHES_PER_PRO_GAME)} games with TOCA!`
    : undefined;

  return (
    <div className={`${styles.flexContainer} md:max-w-[800px] md:mx-auto`}>
      <section>
        <SectionHeading text="Key Objectives" />
        <ul className={styles.keyObjectives}>
          {curriculumSession?.narrowedFocus?.map((focus, i) => (
            <li key={`objective_li_${i}`}>{focus}</li>
          ))}
        </ul>
      </section>

      <section>
        <SectionHeading text="Session Details" />
        <p className={styles.descriptionText}>{curriculumSession?.description}</p>
      </section>

      <section>
        <ul className={`${styles.metricsList}`}>
          {lifetimeTouches !== undefined ? (
            <li className={styles.statCard}>
              <div className={styles.statValueWrapper}>
                {/* TODO fix tailwind override */}
                <div className={`${styles.statValueBackground} md:max-h-[128px]`}>
                  <p className={styles.statValue}>{lifetimeTouches}</p>
                </div>
              </div>
              <div className={styles.text}>
                <h4>Lifetime Touches</h4>
                <p>
                  The average professional game has{' '}
                  <span className="text-blue-light font-semibold">{TOUCHES_PER_PRO_GAME}</span> touches.{' '}
                  <span className="text-blue-light font-semibold">{lifetimeTouchesDescription}</span>
                </p>
              </div>
            </li>
          ) : null}

          {lastSession?.totalBallsFired !== undefined ? (
            <li className={styles.statCard}>
              <div className={styles.statValueWrapper}>
                {/* TODO fix tailwind override */}
                <div className={`${styles.statValueBackground} md:max-h-[128px]`}>
                  <p className={styles.statValue}>{lastSession.totalBallsFired}</p>
                </div>
              </div>
              <div className={styles.text}>
                <h4>Session Reps</h4>
                <p>
                  The TOCA Training Systems monitors and records the total number of balls a player receives during a
                  session
                </p>
              </div>
            </li>
          ) : null}

          {curriculumSession?.keyData.map((stat, i) => {
            let athleteStat: string | number = Number(sessionSummary?.[stat.metric as keyof PerformanceMetric]);
            if (stat.metric === 'accuracy') {
              athleteStat = athleteStat.toFixed(0).toString() + '%';
            }
            if (stat.metric === 'ballSpeed') {
              return;
            }

            return (
              <li key={`session_stat_${i}`} className={styles.statCard}>
                <div className={styles.statValueWrapper}>
                  {/* TODO fix tailwind override */}
                  <div className={`${styles.statValueBackground} md:max-h-[128px]`}>
                    <p className={styles.statValue}>{athleteStat}</p>
                  </div>
                </div>
                <div className={styles.text}>
                  <h4>{stat.title}</h4>
                  <p>{stat.description}</p>
                </div>
              </li>
            );
          })}
        </ul>
      </section>
    </div>
  );
});
SessionRecap.displayName = 'SessionRecap';

/**
 * Returns the latitude and longitude offset based on the screen resolution and zoom.
 * The offset values below will only work for zoom level 10 to keep the 'Toca' location aligned to the top center in mobile resolutuons.
 */
export const getLatLongOffsetBasedOnResolution = (): {latOffSet: number; lonOffSet: number} => {
  const MOBILE_VIEW_LON_OFFSET = -0.0125;
  const MOBILE_VIEW_LAT_OFFSET = -0.05;
  const WEB_VIEW_LON_OFFSET = 0;
  const WEB_VIEW_LAT_OFFSET = 0;
  if (window && window.innerWidth < 1024) {
    return {latOffSet: MOBILE_VIEW_LAT_OFFSET, lonOffSet: MOBILE_VIEW_LON_OFFSET};
  }
  return {latOffSet: WEB_VIEW_LAT_OFFSET, lonOffSet: WEB_VIEW_LON_OFFSET};
};

import {useQuery} from '@tanstack/react-query';
import {getDirectorInfo} from 'api/api';
import {Assessment} from 'api/Coach/useGetCoachInfo';
import {Profile} from 'user/player-info.interface';
import {Team} from 'user/user.interface';

export type DirectorInfo = {
  directorDetails: Profile;
  teams: Team[];
  teamAssessments: {
    [key: string]: Assessment[];
  };
};

export const useGetDirectorInfo = (directorId: string) => {
  return useQuery<DirectorInfo>({
    queryKey: ['director-info', directorId],
    queryFn: () => getDirectorInfo(directorId),
    enabled: !!directorId,
  });
};

import {tocaLocations, TocaLocation, tocaLocationsWithoutWorkingMboSiteId} from 'constants/locations';

/**
 * Represents the transformed location information.
 */
export interface TransformedLocationInfo {
  siteId: string;
  name: string;
  state: string;
  country: string;
  region: string;
  logo: string;
  email: string;
  phoneNumber: string;
  address: string;
  distanceInMiles: number;
  coordinates: {
    lat: number;
    lon: number;
  };
  centerUrl: string;
  fkoSupported: boolean;
}

/**
 * Represents the API response for nearest centers.
 */
export interface NearestCentersAPIResponse {
  siteId: string;
  distanceInMiles: number;
  coordinates: {
    lat: number;
    lon: number;
  };
}

/**
 * Transforms the API response data into the desired format.
 * @param apiResponseData - The API response data for nearest centers.
 * @returns The transformed location information.
 */
export const transformLocationsData = (apiResponseData: NearestCentersAPIResponse[]): TransformedLocationInfo[] => {
  const transformedData: TransformedLocationInfo[] = [];
  const detailedLocationsInfoObj: {[key: string]: TocaLocation} = {};

  const allTocaLocations = [...tocaLocations, ...tocaLocationsWithoutWorkingMboSiteId];

  // Create a lookup object for detailed location information using siteId as the key
  for (const location of allTocaLocations) {
    detailedLocationsInfoObj[location.siteId] = location;
  }

  // Transform each API response data into the desired format
  for (const location of apiResponseData) {
    const {address, phoneNumber, email, country, state, name, region, logo, centerUrl, fkoSupported} =
      detailedLocationsInfoObj[location.siteId];
    const transformedSiteInfo: TransformedLocationInfo = {
      ...location,
      logo,
      address,
      phoneNumber,
      email,
      country,
      state,
      name,
      region,
      centerUrl,
      fkoSupported,
    };
    transformedData.push(transformedSiteInfo);
  }

  return transformedData;
};

export interface LocationOption {
  value: {lon: number; lat: number};
  label: string;
}

export const transformGeocodingResponseToOptions = (response: any): LocationOption[] => {
  return response.features.map((feature: any) => ({
    value: JSON.stringify({lon: feature.geometry.coordinates[0], lat: feature.geometry.coordinates[1]}),
    label: feature.place_name,
  }));
};

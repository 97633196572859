import {PropsWithChildren} from 'react';
import {ReactComponent as MyTocaLogo} from '../../assets/img/icons/redesign-icons/mytoca-logo.svg';
import styles from './LoaderInline.module.scss';

interface Props {
  text?: string;
  includeLogo?: boolean;
}

const LoaderInline = ({text, includeLogo, children}: PropsWithChildren<Props>) => {
  return (
    <div className={styles.loaderContainer}>
      {includeLogo && <MyTocaLogo />}
      <div className={styles.loaderText}>
        {text}
        {children}
      </div>
    </div>
  );
};

export default LoaderInline;

import {useQuery} from '@tanstack/react-query';
import {getCoachInfo} from 'api/api';
import {Profile} from 'user/player-info.interface';
import {Team} from 'user/user.interface';

export type Assessment = {
  _id: string;
  name: string;
  teamId: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  studio?: string;
  siteId: string;
  players?: Array<{
    id: string;
    clientId: string;
  }>;
};

export interface AssessmentsData {
  completed: Assessment[];
  notStarted: Assessment[];
  inProgress: Assessment[];
}

export type CoachInfo = {
  coachDetails: Profile;
  teams: Team[];
  teamAssessments: {
    [key: string]: Assessment[];
  };
};

export const useGetCoachInfo = (coachId: string) => {
  return useQuery<CoachInfo>({
    queryKey: ['coach-info', coachId],
    queryFn: () => getCoachInfo(coachId),
    enabled: !!coachId,
  });
};

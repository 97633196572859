import React, {useEffect, useRef, useState} from 'react';
import {TocaLogo} from '../../components/Forms/FkoLogos/TocaLogo';
import {DbatLogo} from 'components/Forms/FkoLogos/DbatLogo';
import styles from './LoaderFullPage.module.scss';
import {loadingMessages} from '../../constants/loading-messages';

interface Props {
  text?: string;
  changeMessages?: boolean;
  changeMessagesInterval?: number;
  randomMessages?: boolean;
  isDbat?: boolean;
}

const LoaderFullPage = ({
  text,
  changeMessages = false,
  changeMessagesInterval = 5000,
  randomMessages = false,
  isDbat = false,
}: Props) => {
  const [msg, setMsg] = useState(text ?? (changeMessages ? loadingMessages[0] : 'Loading...'));
  const intervalId = useRef(0);
  const currentMsgIndex = useRef(0);

  useEffect(() => {
    if (changeMessages) {
      intervalId.current = window.setInterval(() => {
        if (randomMessages) {
          currentMsgIndex.current = randomIntFromRange(0, loadingMessages.length - 1);
        } else {
          if (currentMsgIndex.current < loadingMessages.length - 1) {
            currentMsgIndex.current++;
          } else {
            currentMsgIndex.current = 0;
          }
        }
        setMsg(loadingMessages[currentMsgIndex.current]);
      }, changeMessagesInterval);
    }
    return () => {
      clearInterval(intervalId.current);
    };
  }, [changeMessages, changeMessagesInterval, randomMessages]);

  return (
    <div className={`loader-container ${styles.loaderContainer}`}>
      {isDbat ? <DbatLogo withBackground={false} /> : <TocaLogo />}
      <div className={styles.loaderText}>{msg}</div>
    </div>
  );
};

function randomIntFromRange(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export default LoaderFullPage;

import {memo, useEffect} from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useAppSelector} from '../../redux/reduxUtils';
import {logAnalyticsEvent} from '../../common/analytics-events';
import {isLocationPermitted, locationLockEnabled} from '../../feature-flags/fko-locations';
import {FormButton} from '../../components/Forms/FormButton/FormButton';
import {FormOptionGroup, FormSelect} from '../../components/Forms/FormSelect/FormSelect';
import {useGetSport} from 'common/useGetSport';
import {useGetLocationFormOptionGroups} from 'api/FKO/useGetLocationFormOptionGroups';
import {RegistrationFormTypes} from 'pages/RegistrationLanding/RegistrationLandingForm';

type LocationFormType = Pick<RegistrationFormTypes, 'location'>;

export const FkoBackupLocationForm = memo(({onSubmitHandler}: {onSubmitHandler: SubmitHandler<LocationFormType>}) => {
  const {initialParams} = useAppSelector((state) => state.fkoFormDataReducer);
  const {isDbat, isPlayerAssessment, isClasses, isFreeTrialClasses, fkoAnalyticsPrefix, sportPermittedLocations} =
    useGetSport();
  const {locationFormOptionGroups} = useGetLocationFormOptionGroups();

  const restrictedLocationFormOptionGroups = locationFormOptionGroups
    .map((optionGroup) => {
      const allowedOptions = optionGroup.options.filter((opt) =>
        isLocationPermitted({locationId: opt.id, isDbat, isPlayerAssessment, isClasses, isFreeTrialClasses})
      );
      if (!allowedOptions.length) {
        return null;
      }
      return {...optionGroup, options: allowedOptions};
    })
    .filter((optionGroup) => !!optionGroup);

  const formFieldConfig: any = {};
  if (!locationLockEnabled) {
    formFieldConfig.location = yup
      .string()
      .required('Required')
      .notOneOf(['disabled-placeholder'], 'Please choose a location');
  }
  if (locationLockEnabled && sportPermittedLocations) {
    formFieldConfig.location = yup
      .string()
      .required('Required')
      .notOneOf(['disabled-placeholder'], 'Please choose a location')
      .oneOf(sportPermittedLocations, 'Sorry this location is not yet supported');
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<LocationFormType>({
    mode: 'onBlur',
    resolver: yupResolver(yup.object().shape(formFieldConfig)),
  });

  useEffect(() => {
    logAnalyticsEvent(`${fkoAnalyticsPrefix}_registration_landing_form_view`);
  }, [fkoAnalyticsPrefix]);

  return (
    <div className="grow flex flex-col w-full p-4">
      <div className="flex flex-col items-center gap-4 w-full p-4 rounded-lg border border-fko-primary border-opacity-20">
        <p className="text-base text-fko-primary">{`Please confirm your preferred ${
          isDbat ? 'D-BAT' : 'TOCA'
        } location:`}</p>
        <form onSubmit={handleSubmit(onSubmitHandler)} className="grow w-full">
          <FormSelect
            {...register('location')}
            id="location"
            hasError={!!errors.location}
            // this will (confusingly) only be true when an invalid locationId param has been passed in
            optionGroups={
              initialParams.locationId
                ? locationFormOptionGroups
                : (restrictedLocationFormOptionGroups as FormOptionGroup[])
            }
            placeholderValue={isDbat ? 'D-BAT Training Center' : 'TOCA Training Center'}
            defaultValue={'disabled-placeholder'}
            onChange={(e) => setValue('location', e.currentTarget.value, {shouldValidate: true})}
          />
          <small className="err-message">{errors.location?.message ?? '\u00A0'}</small>

          <FormButton type="submit" isPrimary hasError={Object.keys(errors).length !== 0}>
            Confirm
          </FormButton>
        </form>
      </div>
    </div>
  );
});
FkoBackupLocationForm.displayName = 'FkoBackupLocationForm';

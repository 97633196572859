import {memo, useCallback, useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {getLocationBySiteId} from 'constants/locations';
import {useGetSingleProgram} from '../../api/Classes/useGetSingleProgram';
import {useGetSingleClassSchedule} from 'api/Classes/useGetSingleClassSchedule';
import {ClassSchedule, Enrollment} from 'api/Classes/useGetPrograms';
import {Helmet} from 'react-helmet';
import {LoadingAnimationFullHeight} from 'components/Loader/LoadingAnimation';
import {PageHeading} from 'components/Connects/PageHeading';
import {MenuBar} from 'components/MenuBar/MenuBar';
import {Button} from 'components/Connects/Button';
import {Breadcrumbs} from 'components/Breadcrumbs/Breadcrumbs';
import {ReactComponent as LocationPinIcon} from '../../assets/img/icons/redesign-icons/location-pin.svg';
import {ReactComponent as CalendarIcon} from '../../assets/img/icons/redesign-icons/calendar-minimal.svg';
import {ReactComponent as SoccerballIcon} from '../../assets/img/icons/redesign-icons/soccerball-basic.svg';
import {ReactComponent as WhistleIcon} from '../../assets/img/icons/redesign-icons/whistle.svg';
import {ReactComponent as ClockFaceIcon} from '../../assets/img/icons/redesign-icons/clock-face.svg';
import classPlaceholder from '../../assets/img/groupClass.jpg';
import {format24hrTimeString, formatWeekdaysArrayForDisplay, getClassDisplayName} from 'utils/programs-utils';
import {Modal} from 'components/Modal/Modal';
import {ProgramEmptyDayForm} from 'components/Programs/ProgramEmptyDayForm';
import {selectOverrideImage} from 'components/Programs/EnrollmentCard';
import {useAppSelector} from 'redux/reduxUtils';
import {useGetSport} from 'common/useGetSport';

export const ProgramClassScheduleDetails = () => {
  const navigate = useNavigate();
  const {siteId, programId, enrollmentId} = useParams();
  const [contactFormData, setContactFormData] = useState<Enrollment | ClassSchedule | null>();

  const {data: program} = useGetSingleProgram({siteId, programId});
  const {data: classSchedule, isLoading: isLoadingClassSchedule} = useGetSingleClassSchedule({
    siteId,
    programId,
    classScheduleId: enrollmentId, // TODO CLASSES naming
  });
  const {userData} = useAppSelector((state) => state.fkoFormDataReducer);
  const {basePath} = useGetSport();

  const openContactForm = useCallback(
    ({enrollment}: {enrollment: Enrollment | ClassSchedule | null}) => setContactFormData(enrollment),
    []
  );

  const enrollmentImage = selectOverrideImage({enrollmentName: classSchedule?.name});
  const locationName = getLocationBySiteId(siteId ?? '')?.name;
  const pageHeading = getClassDisplayName(classSchedule?.name);
  const bookingUrl = 'register'; //  `/ftc/${siteId}/${programId}/${enrollmentId}/register`;

  useEffect(() => {
    if (!userData) {
      navigate(basePath, {replace: true});
    }
  }, [basePath, navigate, userData]);

  return (
    <>
      <Helmet>
        <title>TOCA Soccer | Enrollment Details</title>
      </Helmet>

      <Modal isOpen={!!contactFormData} onClose={() => setContactFormData(null)} showHeader>
        <ProgramEmptyDayForm enrollment={contactFormData} />
      </Modal>

      <div className="grow flex flex-col gap-3 sm:gap-6">
        <MenuBar />
        {/* TODO CLASSES enrollment/classSchedule naming*/}
        <Breadcrumbs data={{program, enrollment: classSchedule}} />

        {isLoadingClassSchedule ? (
          <LoadingAnimationFullHeight />
        ) : classSchedule ? (
          <>
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-[3fr_2fr] md:grid-cols-2 lg:mx-auto max-w-[1024px]">
              <div className="text-center md:text-left sm:col-span-2">
                <PageHeading text={pageHeading} />
              </div>

              <div className="flex flex-col gap-4">
                {/* TODO CLASSES non-free trial classes prob need pricing */}
                {/* <p className="font-poppins font-semibold text-xl leading-[24px] text-secondary text-center sm:text-left">
                  {classSchedule.costPerSession && `$${classSchedule.costPerSession?.toFixed(2)}`}
                </p> */}

                <p className="font-poppins text-base text-blue-dark">{classSchedule.slug}</p>
                <p className="font-poppins text-base text-blue-dark">{classSchedule.description}</p>

                {classSchedule.highlights?.length || classSchedule.highlightTitle ? (
                  <div className="border border-grey-light rounded px-5 py-3 sm:self-start">
                    <p className="font-poppins text-base text-blue-dark mb-1">
                      {classSchedule.highlightTitle ?? (classSchedule.highlights?.length ? 'Highlights:' : undefined)}
                    </p>
                    <ul className="flex flex-col gap-1 list-disc list-inside">
                      {classSchedule.highlights?.map((highlight, i) => (
                        <li className="font-poppins text-sm text-blue-dark" key={`highlight${i}`}>
                          {highlight}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}

                <TagCards enrollment={classSchedule} locationName={locationName} />

                <div className="flex flex-col gap-x-6 gap-y-2 md:my-4 md:flex-row md:items-center flex-wrap">
                  {classSchedule.capacity.available ? (
                    <Link to={bookingUrl}>
                      <Button text="Book&nbsp;Now" variant="cta" color="primary" />
                    </Link>
                  ) : (
                    <div>
                      <Button text="Book&nbsp;Now" variant="cta" color="grey" disabled />
                    </div>
                  )}

                  {/* ---disabling this as the display is confusing for Classes--- */}
                  {/* TODO CLASSES: re-evaluate when Enrollments are a thing again */}
                  {/* <p className="font-poppins text-sm text-grey-xdark text-center">
                    {classSchedule.capacity.available} {classSchedule.capacity.available === 1 ? 'spot' : 'spots'}{' '}
                    available
                  </p> */}

                  {!classSchedule.capacity.available && (
                    <div className="w-full grid place-content-center">
                      <button
                        onClick={() => openContactForm({enrollment: classSchedule})}
                        className="text-sm text-primary font-medium underline"
                      >
                        Click here to get notified of any availabilities.
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div className="rounded-md">
                <img
                  src={enrollmentImage ?? classPlaceholder}
                  alt=""
                  className="rounded-lg aspect-[4/3] sm:aspect-square lg:aspect-[4/3] object-cover object-[25%,60%]"
                />
              </div>
            </div>
          </>
        ) : (
          <div>
            {/* TODO CLASSES make better */}
            <p>Unable to load enrollment data</p>
          </div>
        )}
      </div>
    </>
  );
};

type TagCardsProps = {enrollment: Enrollment | ClassSchedule; locationName?: string};
export const TagCards = memo(({enrollment, locationName}: TagCardsProps) => {
  const formattedStartTime = format24hrTimeString({time: enrollment.schedule.startTime, includeTimePeriod: false});
  const formattedEndTime = format24hrTimeString({time: enrollment.schedule.endTime});

  return (
    <div className="grid grid-cols-2 gap-3">
      <div className="flex items-center gap-2 p-4 bg-grey-xlight rounded-md">
        <ClockFaceIcon className="h-4 w-4 min-h-4 fill-grey-xdark" />
        <p className="font-poppins font-medium text-sm text-grey-xdark capitalize">
          {formattedStartTime}&nbsp;-&nbsp;{formattedEndTime}
        </p>
      </div>
      <div className="flex items-center gap-2 p-4 bg-grey-xlight rounded-md">
        <CalendarIcon className="h-4 w-4 min-h-4 fill-grey-xdark" />
        <p className="font-poppins font-medium text-sm text-grey-xdark capitalize">
          {formatWeekdaysArrayForDisplay(enrollment.schedule.daysArray)}
        </p>
      </div>
      {locationName && (
        <div className="flex items-center gap-2 p-4 bg-grey-xlight rounded-md">
          <LocationPinIcon className="h-4 w-4 min-h-4 fill-grey-xdark" />
          <p className="font-poppins font-medium text-sm text-grey-xdark capitalize">{locationName}</p>
        </div>
      )}
      {enrollment.skillLevel && (
        <div className="flex items-center gap-2 p-4 bg-grey-xlight rounded-md">
          <WhistleIcon className="h-4 w-4 min-h-4 fill-grey-xdark" />
          <p className="font-poppins font-medium text-sm text-grey-xdark capitalize">
            Skill&nbsp;Level:&nbsp;{enrollment.skillLevel}
          </p>
        </div>
      )}
      {enrollment.tags?.classType && (
        <div className="flex items-center gap-2 p-4 bg-grey-xlight rounded-md">
          <SoccerballIcon className="h-4 w-4 min-h-4 fill-grey-xdark" />
          <p className="font-poppins font-medium text-sm text-grey-xdark capitalize">{enrollment.tags.classType}</p>
        </div>
      )}
      {/* <div className="flex items-center gap-2 p-4 bg-grey-xlight rounded-md">
        <SunshineIcon className="h-4 w-4 min-h-4 fill-grey-xdark" />
        <p className="font-poppins font-medium text-sm text-grey-xdark capitalize">Summer</p>
      </div> */}
      {/* <div className="flex items-center gap-2 p-4 bg-grey-xlight rounded-md">
        <ClockFaceIcon className="h-4 w-4 min-h-4 fill-grey-xdark" />
        <p className="font-poppins font-medium text-sm text-grey-xdark capitalize">Evenings</p>
      </div> */}
    </div>
  );
});
TagCards.displayName = 'TagCards';

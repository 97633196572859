export const connectsFeatureFlagsActive = process.env.REACT_APP_CONNECTS_FEATURE_FLAG_ACTIVE === 'true';
export const connectsEnabledTrainerLocations = process.env.REACT_APP_CONNECTS_ENABLED_LOCATIONS_TRAINER?.split(',');
export const connectsEnabledUserLocations = process.env.REACT_APP_CONNECTS_ENABLED_LOCATIONS_USER?.split(',');
export const connectsEnabledUserAccounts = process.env.REACT_APP_CONNECTS_ENABLED_ACCOUNTS_USER?.split(',');

export const connectsIsEnabledForTrainer = ({siteId}: {siteId: string}) => {
  return connectsFeatureFlagsActive ? !!connectsEnabledTrainerLocations?.includes(siteId) : true;
};

export const connectsIsEnabledForUser = ({siteId, email}: {siteId: string; email: string}) => {
  if (connectsFeatureFlagsActive) {
    return (
      !!connectsEnabledUserLocations?.includes(siteId) &&
      (connectsEnabledUserAccounts?.length ? !!connectsEnabledUserAccounts?.includes(email) : true)
    );
  } else {
    return true;
  }
};

import {useState, useRef, useCallback, useEffect} from 'react';
import {MapRef} from 'react-map-gl';
import {getNearestLocations as getNearestLocationsAPI} from 'api/api';
import Map from './Components/Map/Map';
import {TransformedLocationInfo, transformLocationsData} from './Utils/dataTransformer';
import {LocationsList} from './Components/LocationsList/LocationList';
import {LocationInput} from './Components/LocationInput/LocationInput';
import {getLatLongOffsetBasedOnResolution} from './Utils/common';
import {Helmet} from 'react-helmet';
import {MAP_FLY_TO_DURATION} from './constants';

interface CenterDiscoveryProps {
  onCenterSelectionHandler?: (siteId: string, name: string) => void;
}
/**
 * Center Discovery page that displays the nearest toca centers based on the user's location.
 */
export const CenterDiscovery = ({onCenterSelectionHandler}: CenterDiscoveryProps) => {
  const [locations, setLocations] = useState<TransformedLocationInfo[]>([]);
  const [showMapInMobileView, setShowMapInMobileView] = useState<boolean>(false);
  const [activeLocationIndex, setActiveLocationIndex] = useState<number>(0);
  const mapRef = useRef<MapRef | null>(null);

  /**
   * Fetches the nearest test centers based on the user's location.
   */
  const getUserNearestLocations = useCallback((lat: number, lon: number) => {
    getNearestLocationsAPI(lat, lon).then((data) => {
      // TODO type response data, and this can probably just be tranformed right here
      const transformedLocationsData = transformLocationsData(data);
      setLocations(transformedLocationsData);
    });
  }, []);

  /**
    On initial mount, this effect will set a default location before the user's location is available.
   */
  useEffect(() => {
    // Need to update default location values
    const latitude = 33.67418565216646;
    const longitude = -117.88954954477214;
    getUserNearestLocations(latitude, longitude);
  }, [getUserNearestLocations]);

  /**
   * Callback function to select a location on the map by passing coordinates.
   */
  const selectLocationOnMap = useCallback(
    (lat: number, lon: number, locationIndex: number) => {
      const {latOffSet, lonOffSet} = getLatLongOffsetBasedOnResolution();
      mapRef.current?.flyTo({center: [lon + lonOffSet, lat + latOffSet], duration: MAP_FLY_TO_DURATION, zoom: 10});
      setActiveLocationIndex(locationIndex);
    },
    [setActiveLocationIndex]
  );

  /**
   * Callback function to select a location on the map by user click on center.
   */
  const onClickLocationSelectHandler = useCallback(
    (lat: number, lon: number, locationIndex: number) => {
      if (locationIndex !== activeLocationIndex && typeof onCenterSelectionHandler === 'function') {
        const {name, siteId} = locations[locationIndex];
        onCenterSelectionHandler(siteId, name);
      }
      selectLocationOnMap(lat, lon, locationIndex);
    },
    [locations, onCenterSelectionHandler, selectLocationOnMap, activeLocationIndex]
  );

  /**
   * This effect will select the nearest location on the map every time when user input location gets changed.
   */
  useEffect(() => {
    if (!locations.length) {
      return;
    }
    const selectedLocation = locations[0];
    const {lat, lon} = selectedLocation.coordinates;
    selectLocationOnMap(lat, lon, 0);
  }, [locations, selectLocationOnMap]);

  /**
   * Toggles the map visibility in mobile view.
   */
  const onClickToggleMapInMobileView = useCallback(() => {
    setShowMapInMobileView((prevShowMapState) => !prevShowMapState);
  }, [setShowMapInMobileView]);

  return (
    <>
      <Helmet>
        <title>TOCA Center Finder</title>
      </Helmet>
      <main>
        {/* Mobile View */}
        <div className="flex lg:hidden flex-row">
          <div className="flex flex-col w-full h-screen">
            <div className="bg-white z-20">
              <div className="bg-map-background-mobile bg-cover">
                <LocationInput
                  getUserNearestLocations={getUserNearestLocations}
                  showMapInMobileView={showMapInMobileView}
                  onClickToggleMapInMobileView={onClickToggleMapInMobileView}
                />
              </div>
            </div>
            <div className="relative flex-grow overflow-auto">
              <div className={`absolute z-10 w-full ${showMapInMobileView ? 'bottom-0' : 'h-full'}`}>
                <LocationsList
                  locations={locations}
                  onSelectLocation={onClickLocationSelectHandler}
                  showHorizontalView={showMapInMobileView}
                  activeLocationIndex={activeLocationIndex}
                />
              </div>
              {showMapInMobileView && (
                <div className="absolute w-full h-full">
                  <Map
                    ref={mapRef}
                    tocaLocations={locations}
                    activeLocationIndexInList={activeLocationIndex}
                    setActiveLocationListIndex={setActiveLocationIndex}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Desktop View */}
        <div className="hidden lg:flex flex-row">
          <div className="bg-white w-[30%] z-10">
            <div className="flex flex-col h-screen w-full bg-no-repeat bg-center bg-map-background">
              <div>
                <LocationInput getUserNearestLocations={getUserNearestLocations} />
              </div>
              <div className="overflow-auto">
                <LocationsList
                  locations={locations}
                  onSelectLocation={onClickLocationSelectHandler}
                  activeLocationIndex={activeLocationIndex}
                />
              </div>
            </div>
          </div>
          <div className="flex-grow">
            <Map
              ref={mapRef}
              tocaLocations={locations}
              activeLocationIndexInList={activeLocationIndex}
              setActiveLocationListIndex={setActiveLocationIndex}
            />
          </div>
        </div>
      </main>
    </>
  );
};

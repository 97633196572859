import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Profile} from '../../user/player-info.interface';
import {getCurrentProfileId, setCurrentProfileId as setCurrentProfileIdInLocalStorage} from '../../user/user-utils';

type ProfileState = {
  profiles: Profile[];
  currentProfileId: string;
};

const initialState = {
  profiles: [],
  currentProfileId: getCurrentProfileId(),
} as ProfileState;

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfiles: (state, action: PayloadAction<Profile[]>) => {
      state.profiles = action.payload;
    },
    setCurrentProfileId: (state, action: PayloadAction<string>) => {
      setCurrentProfileIdInLocalStorage(action.payload);
      state.currentProfileId = action.payload;
    },
  },
});

export const {setProfiles, setCurrentProfileId} = profileSlice.actions;

export default profileSlice.reducer;

import {memo} from 'react';
import styles from './UpcomingSessionSummary.module.scss';
import {SectionHeading} from 'components/Connects/SectionHeading';
import {CurriculumSession} from 'pages/Connects/types';

type UpcomingSessionSummaryProps = {
  nextSession: CurriculumSession;
};

export const UpcomingSessionSummary = memo(({nextSession}: UpcomingSessionSummaryProps) => {
  return (
    <div className={`${styles.flexContainer} md:max-w-[800px] md:mx-auto`}>
      <section>
        <SectionHeading text="Education" />
        <a className={styles.educationLink} href={nextSession.education?.url} target="_blank" rel="noopener noreferrer">
          {nextSession.education?.title}
        </a>
      </section>

      {/*<section>*/}
      {/*  <SectionHeading text="Session Details" />*/}
      {/*  <p className={styles.descriptionText}>{nextSession.description.replace('focused', 'focuses')}</p>*/}
      {/*</section>*/}

      <section>
        <div className={styles.objectivesCard}>
          <div className={styles.imageWrapper}>
            <img src={nextSession.img} />
          </div>
          <div className={styles.cardContent}>
            <h4>Key Objectives</h4>
            <ul>
              {nextSession.narrowedFocus?.map((focus, i) => (
                <li key={`objective_li_${i}`}>{focus}</li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <section>
        <SectionHeading text="Top Focus Goals" />

        <ul className={`${styles.metricsList} flex flex-col`}>
          {nextSession.keyData.map((stat, i) =>
            i >= 2 ? null : (
              <li key={`focus_goal_${i}`} className={styles.statCard}>
                {/* <div className={styles.statValueWrapper}>
                        <div className={styles.statValueBackground}>
                          <p className={styles.statValue}>{athleteStat}</p>
                        </div>
                      </div> */}
                <div className={styles.text}>
                  <h4>{stat.title}</h4>
                  <p>{stat.description}</p>
                </div>
              </li>
            )
          )}
        </ul>
      </section>
    </div>
  );
});
UpcomingSessionSummary.displayName = 'UpcomingSessionSummary';

export const PLACEHOLDER_USER_PROFILE_IMG_URL = '/images/avatar-profile.png';

// TODO eval for removal with pathways 2.5
export type Pathway = {
  id: number;
  src: string;
  primary: string;
  tertiary: string;
  pathwayName?: string;
  // this is implied in PathwayCatalog but doesn't seem to actually exist.
  completedPercentage?: number;
};

export type Focus = {
  id: number;
  name: string;
};

export const pathwaysArr: Pathway[] = [
  {
    id: 1,
    pathwayName: 'Foundation',
    src: '/images/pathway1.png',
    primary: '#0ab87b',
    tertiary: '#00764c',
  },
  {
    id: 2,
    pathwayName: 'Technical Mastery',
    src: '/images/pathway2.png',
    primary: '#e83a90',
    tertiary: '#a5195e',
  },
  {
    id: 3,
    pathwayName: 'Attack Minded',
    src: '/images/pathway3.png',
    // TODO these colors have been swapped, confirm
    primary: '#fbdb77',
    tertiary: '#fe3c72',
  },
  {
    id: 4,
    pathwayName: 'Core Skills',
    src: '/images/pathway4.png',
    primary: '#f7931d',
    tertiary: '#f2281c',
  },
  {
    id: 5,
    pathwayName: 'Game Awareness',
    src: '/images/pathway5.png',
    primary: '#6701ee',
    tertiary: '#9748fe',
  },
  {
    id: 6,
    pathwayName: 'Goal Scoring',
    src: '/images/pathway6.png',
    primary: '#00ffff',
    tertiary: '#1aa4a4',
  },
  {
    id: 7,
    pathwayName: 'Goal Keeping',
    src: '/images/pathway7.png',
    primary: '#f2024f',
    tertiary: '#aa093c',
  },
];

export const focuses: Focus[] = [
  {
    id: 30,
    name: 'FINISHING - AERIAL',
  },
  {
    id: 31,
    name: 'PASSING - AERIAL',
  },
  {
    id: 32,
    name: 'DIRECTIONAL 1ST TOUCH',
  },
  {
    id: 33,
    name: 'FINISHING - POWER',
  },
  {
    id: 34,
    name: 'GOALKEEPING',
  },
  {
    id: 35,
    name: 'PASSING',
  },
  {
    id: 36,
    name: 'RECEIVING - AERIAL',
  },
  {
    id: 37,
    name: 'RECEIVING - GROUND',
  },
  {
    id: 38,
    name: 'TURNING',
  },
  {
    id: 53,
    name: 'RECEIVING - BODY',
  },
  {
    id: 54,
    name: 'TURNING - CREATIVE',
  },
  {
    id: 55,
    name: 'PASSING - 1ST TIME',
  },
  {
    id: 56,
    name: 'DRIBBLING AWARENESS',
  },
  {
    id: 57,
    name: 'FINISHING - PLACEMENT',
  },
  {
    id: 58,
    name: 'FINISHING - 1ST TIME',
  },
];

////////////
////////////
// imo this implementation should be removed
export type Pathways = {
  [pathway: string]: Pathway;
};

export const pathways: Pathways = {
  pathway1: {
    id: 1,
    src: '/images/pathway1.png',
    primary: '#0ab87b',
    tertiary: '#00764c',
  },
  pathway2: {
    id: 2,
    src: '/images/pathway2.png',
    primary: '#e83a90',
    tertiary: '#a5195e',
  },
  pathway3: {
    id: 3,
    src: '/images/pathway3.png',
    primary: '#fe3c72',
    tertiary: '#fbdb77',
  },
  pathway4: {
    id: 4,
    src: '/images/pathway4.png',
    primary: '#f7931d',
    tertiary: '#f2281c',
  },
  pathway5: {
    id: 5,
    src: '/images/pathway5.png',
    primary: '#6701ee',
    tertiary: '#9748fe',
  },
  pathway6: {
    id: 6,
    src: '/images/pathway6.png',
    primary: '#0ff',
    tertiary: '#1aa4a4',
  },
  pathway7: {
    id: 7,
    src: '/images/pathway7.png',
    primary: '#f2024f',
    tertiary: '#aa093c',
  },
};

export const roleType = {
  admin: 'admin',
  trainer: 'trainer',
  player: 'player',
  teamCoach: 'coach',
  director: 'director',
};

import {FkoPlayer} from '../../redux/slices/fkoFormData';
import styles from './PlayerCard.module.scss';
import {memo, useCallback} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {ReactComponent as EditIcon} from '../../assets/img/icons/edit-icon-minimal.svg';
import {ReactComponent as QuestionIcon} from '../../assets/img/icons/question-icon.svg';
import {ReactComponent as DoubleChevron} from '../../assets/img/icons/chevron-double-right.svg';
import {ReactComponent as ArrowDouble} from '../../assets/img/icons/arrow-double.svg';
import {ReactComponent as SuccessCheck} from '../../assets/img/icons/success-check.svg';
import {parseDateTime} from '../../utils/utils';
import {StandardButtonFKO} from '../../components/Buttons/Standard/StandardButtonFKO';
import {locationAllowsBookings} from '../../feature-flags/booking-locations';
import {getLocationBySiteId, TocaLocation} from '../../constants/locations';
import {FkoPlayersModalState} from './FkoPlayersList';
import {
  EditOptionsModal,
  EditPlayerBirthdateModal,
  EditPlayerModal,
  IneligibilityModal,
} from 'components/FKO/PlayersListModals/FkoPlayersListModals';
import moment from 'moment';

type PlayerCardProps = {
  player: FkoPlayer;
  isConfirmed?: boolean;
  scheduleError?: boolean;
  setModalState: React.Dispatch<React.SetStateAction<FkoPlayersModalState>>;
};

export const PlayerCard = memo(({player, isConfirmed, scheduleError, setModalState}: PlayerCardProps) => {
  const navigate = useNavigate();

  const {selectedSession} = player;
  const isEligible = player.eligibility?.isEligible;
  const isEditable = (!player.accountHolder && !player?._id && !player.mboDetails?.clientId) || !!selectedSession;
  let startDate, endDate;
  if (selectedSession) {
    startDate = parseDateTime(selectedSession?.startDate);
    endDate = parseDateTime(selectedSession?.endDate);
  }

  const [bookedFkoDates, completedFkoDates] = [
    player.eligibility?.meta?.bookedVisitsDates,
    player.eligibility?.meta?.showVisitsDates,
  ];
  const bookedFkoDate = bookedFkoDates?.length ? moment(bookedFkoDates[0]).format('MMM D, YYYY') : undefined;
  const completedFkoDate = completedFkoDates?.length
    ? moment.max(completedFkoDates.map((date) => moment(date))).format('MMM D, YYYY')
    : undefined;

  let playerLocationName;
  // only show the profile's associated location if it's being marked ineligible and the user needs an explanation
  if (player.eligibility?.meta?.isIncorrectLocation) {
    const playerLocation = player.mboDetails?.siteId
      ? getLocationBySiteId(player.mboDetails.siteId)
      : player.siteId
      ? getLocationBySiteId(player.siteId)
      : null;
    playerLocationName = playerLocation?.fkoCenterName ?? playerLocation?.name;
  }

  const closeModal = useCallback(() => {
    setModalState(undefined);
  }, [setModalState]);

  const closeModalAndSchedule = useCallback(() => {
    setModalState(undefined);
    navigate(`../schedule?playerId=${player.playerId}`);
  }, [setModalState]);

  const openEditPlayerModal = useCallback(
    (player: FkoPlayer) => {
      setModalState({
        player,
        children: <EditPlayerModal closeModal={closeModal} player={player} />,
        heading: `${player.firstName} ${player.lastName}`,
        subHeading: undefined,
      });
    },
    [closeModal, setModalState]
  );

  const openEditPlayerBirthdateModal = useCallback(
    (player: FkoPlayer) => {
      setModalState({
        player,
        children: <EditPlayerBirthdateModal closeModal={closeModalAndSchedule} player={player} />,
        heading: `${player.firstName} ${player.lastName}`,
        subHeading: 'Player birthdate required to proceed! Please enter one below.',
      });
    },
    [closeModal, setModalState]
  );

  const openEditOptionsModal = useCallback(
    (player: FkoPlayer) => {
      // without a session selected, skip directly to player editing (if editable)
      if (isEditable && !player.selectedSession) {
        openEditPlayerModal(player);
      } else {
        setModalState({
          player,
          children: (
            <EditOptionsModal closeModal={closeModal} player={player} openEditPlayerModal={openEditPlayerModal} />
          ),
          heading: `${player.firstName} ${player.lastName}`,
          subHeading: `What would you like to do?`,
        });
      }
    },
    [openEditPlayerModal, setModalState, closeModal]
  );

  const openIneligiblityModal = useCallback(
    (player: FkoPlayer) => {
      setModalState({
        player: undefined,
        children: <IneligibilityModal closeModal={closeModal} ineligiblePlayer={player} />,
        heading: `Ineligible?`,
        subHeading: undefined,
      });
    },
    [closeModal, setModalState]
  );

  return (
    <div className={`${styles.container} ${scheduleError ? styles.hasError : null}`}>
      <div className={styles.flexSection}>
        <h3 className={`${styles.playerName} ${!isEligible ? styles.grey : null}`}>
          {player.firstName} {player.lastName}
        </h3>
        {playerLocationName ? <p className="text-xs text-black/90">Location: {playerLocationName}</p> : null}
        {isEligible ? (
          selectedSession ? (
            <>
              <div className={styles.scheduleWrapper}>
                <p className={styles.scheduledText}>{isConfirmed ? `confirmed` : `selected time`}</p>
                <p>
                  {startDate?.dayStrShort}, {startDate?.monthStr} {startDate?.day}
                  <br /> {startDate?.time} - {endDate?.time}
                </p>
              </div>
            </>
          ) : (
            <>
              {scheduleError && (
                <p className={styles.scheduledText}>
                  {player.error?.includes('available') ? 'selected time no longer available' : player.error}
                </p>
              )}

              {/* require dob to proceed. it is impossible to create an mboClient without one. */}
              {player.dob ? (
                <Link className={styles.scheduleButton} to={`../schedule?playerId=${player.playerId}`}>
                  {scheduleError ? <p>Select a different time</p> : <p>Select a Time</p>}
                  <div className={styles.icon}>
                    <DoubleChevron />
                  </div>
                </Link>
              ) : (
                <button className={styles.scheduleButton} onClick={() => openEditPlayerBirthdateModal(player)}>
                  {scheduleError ? <p>Select a different time</p> : <p>Select a Time</p>}
                  <div className={styles.icon}>
                    <DoubleChevron />
                  </div>
                </button>
              )}
            </>
          )
        ) : null}
      </div>
      <div className={styles.fixedSection}>
        {isConfirmed ? (
          <div className={styles.checkIcon}>
            <SuccessCheck />
          </div>
        ) : !isEligible ? (
          <>
            {bookedFkoDate ? (
              <IneligibleButton
                text={`Booked for ${bookedFkoDate}`}
                onClick={() => openIneligiblityModal(player)}
                location={player.location}
              />
            ) : completedFkoDate ? (
              <IneligibleButton
                text={`Completed on ${completedFkoDate}`}
                onClick={() => openIneligiblityModal(player)}
                location={player.location}
              />
            ) : (
              <IneligibleButton
                text="Not Eligible?"
                onClick={() => openIneligiblityModal(player)}
                location={player.location}
              />
            )}
          </>
        ) : isEditable ? (
          <EditButton onClick={() => openEditOptionsModal(player)} />
        ) : null}
      </div>
    </div>
  );
});
PlayerCard.displayName = 'PlayerCard';

const EditButton = memo(({onClick}: {onClick: React.MouseEventHandler<HTMLButtonElement>}) => {
  return (
    <button className={styles.editButton} onClick={onClick}>
      <div className={styles.icon}>
        <EditIcon />
      </div>
      <p>Edit</p>
    </button>
  );
});
EditButton.displayName = 'EditButton';

const IneligibleButton = memo(
  ({
    onClick,
    location,
    text,
  }: {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    location?: TocaLocation;
    text: string;
  }) => {
    return location && locationAllowsBookings(location.siteId) ? (
      <StandardButtonFKO
        onClick={onClick}
        text={text}
        className={`${styles.ineligibleButton} ${styles.withBookings}`}
        icon={<ArrowDouble />}
      />
    ) : (
      <button onClick={onClick} className={styles.ineligibleButton}>
        <p>{text}</p>
        <div className="ml-2">
          <QuestionIcon className="h-3 w-3 fill-fko-primary" />
        </div>
      </button>
    );
  }
);
IneligibleButton.displayName = 'IneligibleButton';

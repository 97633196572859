import uuid from 'react-uuid';
import {FkoInitialParams} from 'redux/slices/fkoFormData';

type FkoParamsStorage = FkoInitialParams & {
  paramsId: string;
};

export const getFkoInitialParamsLocalstorage = ({paramsId, sport}: {paramsId?: string; sport?: string | null}) => {
  const progress: FkoParamsStorage = JSON.parse(localStorage.getItem('fko_initial') ?? 'null');

  return paramsId === progress?.paramsId && sport === progress?.sport ? progress : undefined;
};

export const setFkoInitialParamsLocalstorage = (progress: FkoInitialParams) => {
  const paramsId = uuid();
  const paramsToSave: FkoParamsStorage = {...progress, paramsId};

  localStorage.setItem('fko_initial', JSON.stringify(paramsToSave));
  return {paramsId};
};

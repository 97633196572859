import React, {useState, useEffect, useRef} from 'react';
import './ModalWrapper.style.scss';
import Portal from './portal';

const ModalWrapper = ({children, open, onClose, locked, addClass, addBackdropClass, showFullScreen}: any) => {
  const [active, setActive] = useState(false);
  const backdrop = useRef(null);

  useEffect(() => {
    const {current}: any = backdrop;

    const transitionEnd = () => setActive(open);

    //***Lock Click outside close***
    // const keyHandler = (e:any) =>
    //   !locked && [27].indexOf(e.which) >= 0 && onClose();

    const clickHandler = (e: any) => !locked && e.target === current && onClose();

    if (current) {
      current.addEventListener('transitionend', transitionEnd);
      current.addEventListener('click', clickHandler);
      //window.addEventListener("keyup", keyHandler);
    }

    if (open) {
      window.setTimeout(() => {
        (document.activeElement as HTMLElement).blur();
        setActive(open);
        (document.querySelector('#root') as HTMLInputElement).setAttribute('inert', 'true');
      }, 10);
    }

    return () => {
      if (current) {
        current.removeEventListener('transitionend', transitionEnd);
        current.removeEventListener('click', clickHandler);
      }

      (document.querySelector('#root') as HTMLInputElement).removeAttribute('inert');
      //window.removeEventListener("keyup", keyHandler);
    };
  }, [open, locked, onClose]);

  return (
    <>
      {(open || active) && (
        <Portal className={`modal-portal ${addClass ? addClass : ''}`}>
          <div
            ref={backdrop}
            className={`modal-backdrop ${showFullScreen ? 'fullscreen' : ''} ${active && open ? 'active' : ''} ${
              addBackdropClass ? addBackdropClass : ''
            }`}
          >
            <div className={showFullScreen ? 'modal-content-fullscreen' : 'modal-content'}>{children}</div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default ModalWrapper;

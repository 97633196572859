import {PropsWithChildren, useEffect} from 'react';
import {useTrainerContext} from 'context/UserContextProvider';
import {useLocation, useNavigate} from 'react-router-dom';

export const TrainerEmulationContainer = ({children}: PropsWithChildren) => {
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const trainerContext = useTrainerContext();

  useEffect(() => {
    if (trainerContext?.emulationStatus.isEmulating && !pathname.startsWith('/emulation')) {
      const siteId = trainerContext.trainer.profiles?.find((p) => !!p.mboDetails?.siteId)?.mboDetails?.siteId;
      navigate(getTrainerEmulationUrlPath(pathname, siteId || 'nosite'), {replace: true});
    } else if (!trainerContext?.emulationStatus.isEmulating && pathname.startsWith('/emulation')) {
      navigate(getNonTrainerEmulationUrlPath(pathname), {replace: true});
    }
  }, [
    navigate,
    pathname,
    trainerContext,
    trainerContext?.emulationStatus.isEmulating,
    trainerContext?.trainer.profiles,
  ]);

  return <>{children}</>;
};

/**
 * Returns an emulation pathname of format `/emulation/:siteId/originalPathname`.
 *
 * @param pathname The original pathname.
 * @param siteId The site ID.
 */
export function getTrainerEmulationUrlPath(pathname: string, siteId: string | undefined) {
  if (!pathname.startsWith('/')) {
    pathname = `/${pathname}`;
  }
  return pathname.startsWith('/emulation') ? pathname : `/emulation/${siteId}${pathname}`;
}

/**
 * Removes the `/emulation` prefix and a site ID from the given path.
 * For example: /emulation/123456/home ==> /home
 *
 * @param pathname The pathname from which to get the non-emulation pathname.
 */
export function getNonTrainerEmulationUrlPath(pathname: string) {
  return pathname.replace(/^\/emulation\/[^\\/]+/, '');
}

import 'react-day-picker/dist/style.css';
import './AddEditAssessmentModal.scss';
import * as yup from 'yup';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {format} from 'date-fns';
import {toast} from 'react-toastify';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {DateRange, DayPicker} from 'react-day-picker';
import {generateTimeOptions} from '../utils';
import {createAssessment, updateAssessment} from 'api/api';
import {Modal} from 'components/Modal/Modal';
import {FormSelect} from 'components/Forms/FormSelect/FormSelect';
import {FormInput} from 'components/Forms/FormInput/FormInput';
import {getLocationBySiteId} from 'constants/locations';
import {Button} from 'components/Connects/Button';
import {yupResolver} from '@hookform/resolvers/yup';
import {Assessment} from 'api/Coach/useGetCoachInfo';
import {getDiffInObject} from 'utils/utils';

interface AddEditAssessmentModalProps {
  showModal: boolean;
  teamId: string;
  siteId: string;
  cancelModal: () => void;
  isEdit?: boolean;
  assessment?: Assessment;
}

interface NativeInputFormData {
  name: string;
  startTime: string;
  endTime: string;
  studio?: string;
  siteId: string;
}

const today = new Date();
const timeOptions = generateTimeOptions();

const getInitialDateRange = (assessment: Assessment) => {
  // Append 'T00:00:00' to ensure date parses as local midnight.
  return {from: new Date(assessment.startDate + 'T00:00:00'), to: new Date(assessment.endDate + 'T00:00:00')};
};

const getDefaultValues = (isEdit: boolean, siteId: string, assessment?: Assessment) =>
  isEdit && assessment
    ? {
        name: assessment.name,
        startTime: assessment.startTime,
        endTime: assessment.endTime,
        studio: assessment.studio,
        siteId: assessment.siteId,
      }
    : {
        siteId,
        startTime: 'disabled-placeholder',
        endTime: 'disabled-placeholder',
      };

export const AddEditAssessmentModal = ({
  showModal,
  cancelModal,
  teamId,
  siteId,
  isEdit = false,
  assessment,
}: AddEditAssessmentModalProps) => {
  const queryClient = useQueryClient();
  const isEditAndAssessment = isEdit && assessment;
  const initialDateRange = isEditAndAssessment ? getInitialDateRange(assessment) : undefined;
  const [dateRange, setDateRange] = useState<DateRange | undefined>(initialDateRange);
  const dayPickerDefaultMonth = isEditAndAssessment ? initialDateRange?.from : today;
  const title = `${isEdit ? 'Edit' : 'Add'} Assessment`;

  const assessmentMutation = useMutation({
    mutationFn: isEditAndAssessment ? updateAssessment : createAssessment,
    onSuccess: () => {
      queryClient.invalidateQueries(['director-info']);
      cancelModal();
      toast.success(`Successfully ${isEditAndAssessment ? 'updated' : 'created'} assessment.`);
    },
    onError: () => {
      toast.error(`Failed to ${isEditAndAssessment ? 'updated' : 'created'} assessment.`);
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: {errors, isSubmitted},
  } = useForm<NativeInputFormData>({
    defaultValues: getDefaultValues(!!isEditAndAssessment, siteId, assessment),
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required('Assessment Name is required'),
        startTime: yup.string().required('Required').notOneOf(['disabled-placeholder'], 'Start time is required'),
        endTime: yup.string().required('Required').notOneOf(['disabled-placeholder'], 'End time is required'),
      })
    ),
  });

  const handleFormSubmit = async (data: NativeInputFormData) => {
    // Validate date range
    if (!dateRange?.from || !dateRange?.to) {
      return;
    }

    const payload = {
      name: data.name,
      startDate: format(dateRange.from, 'yyyy-MM-dd'),
      endDate: format(dateRange.to, 'yyyy-MM-dd'),
      startTime: data.startTime,
      endTime: data.endTime,
      siteId: data.siteId,
      teamId: teamId,
      ...(data.studio && {studio: data.studio}),
    };

    // If updating, only send changed fields
    const requestPayload = isEditAndAssessment
      ? {_id: assessment._id, ...getDiffInObject<Assessment>(assessment, payload)}
      : payload;
    await assessmentMutation.mutateAsync(requestPayload);
  };

  const onClickCancelHandler = () => {
    reset();
    setDateRange(undefined);
    cancelModal();
  };

  return (
    <Modal
      isOpen={showModal}
      onClose={cancelModal}
      showHeader={true}
      title={title}
      modalTestId="add-edit-assessment-modal"
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} className="flex flex-col max-h-[75vh] overflow-y-auto">
        <div className="flex flex-col items-center">
          <DayPicker
            mode="range"
            selected={dateRange}
            onSelect={setDateRange}
            fromDate={today}
            defaultMonth={dayPickerDefaultMonth}
          />
          {isSubmitted && (!dateRange?.from || !dateRange?.to) && (
            <p className="text-xs text-alert mb-1">Start date and End Date is Required</p>
          )}
        </div>
        <div className="mt-3 w-full">
          <FormInput
            {...register('name')}
            id="name"
            hasError={!!errors.name?.message}
            placeholder="Assessment Name"
            type="text"
          />
          {errors.name && <p className="text-xs text-alert mb-1">{errors.name?.message ?? '\u00A0'}</p>}
        </div>

        <div className="flex-col mt-3 w-full">
          <FormSelect
            {...register('startTime')}
            hasError={!!errors.startTime}
            placeholderValue="Select Start Time"
            options={timeOptions}
            onChange={(e) => setValue('startTime', e.currentTarget.value, {shouldValidate: true})}
          />
          {errors.startTime && <p className="text-xs text-alert mt-1">{errors.startTime?.message ?? '\u00A0'}</p>}
        </div>

        <div className="flex-col mt-3 w-full">
          <FormSelect
            {...register('endTime')}
            hasError={!!errors.endTime}
            options={timeOptions}
            placeholderValue="Select End Time"
            onChange={(e) => setValue('endTime', e.currentTarget.value, {shouldValidate: true})}
          />
          {errors.endTime && <p className="text-xs text-alert mt-1">{errors.endTime?.message ?? '\u00A0'}</p>}
        </div>

        <div className="flex-col mt-3 w-full">
          <FormInput
            {...register('studio')}
            id="studio"
            hasError={!!errors.studio?.message}
            placeholder="Studio Name"
            type="text"
          />
          {errors.studio && <p className="text-xs text-alert mt-1">{errors.studio?.message ?? '\u00A0'}</p>}
        </div>

        <div className="flex-col w-full mt-3">
          <FormSelect
            {...register('siteId')}
            hasError={!!errors.siteId}
            disabled
            options={[{id: siteId, displayValue: getLocationBySiteId(siteId)?.name, value: siteId}]}
            onChange={(e) => setValue('siteId', e.currentTarget.value, {shouldValidate: true})}
          />
        </div>
        <div className="flex items-center justify-center mt-4">
          <Button text="Save" color="secondary" variant="cta" disabled={assessmentMutation.isLoading} />
          <Button className="ml-4" text="Cancel" color="primary" variant="standard" onClick={onClickCancelHandler} />
        </div>
      </form>
    </Modal>
  );
};

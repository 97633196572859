import {useQuery} from '@tanstack/react-query';
import {getPlayerLastSessionSummary} from 'api/api';

type SessionSummary = {
  speedOfPlay: number;
  totalGoalsScored: number;
  accuracy: number;
  streak: number;
  mph: number;
  quality_pass: {
    goodPass: number;
    badPass: number;
  };
  totalBallsFired?: number;
  repetitions?: number;
};

export type ExerciseSummary = {
  name: string;
  speedOfPlay: number;
  totalGoalsScored: number;
  accuracy: number;
  streak: number;
  mph: number;
  quality_pass: {
    goodPass: number;
    badPass: number;
  };
  confirmedBy?: string;
  defaultSelected?: boolean;
};

export type PlayerSessionSummary = {
  sessionSummary: SessionSummary;
  exerciseSummary: ExerciseSummary[];
  startTime: number;
  isBaseline: boolean;
  baselineLevel: string;
  isPlayerAssessment: boolean;
  clientSessionSummaryId: string;
};

export type GetPlayerLastSessionSummaryParams = {clientId?: string | null; siteId?: string | null};

export const useGetPlayerLastSessionSummary = ({clientId, siteId}: GetPlayerLastSessionSummaryParams) => {
  return useQuery<PlayerSessionSummary>({
    queryKey: ['player-last-session', clientId],
    queryFn: () => getPlayerLastSessionSummary({clientId, siteId}),
    enabled: !!clientId && !!siteId,
  });
};

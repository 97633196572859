import {Helmet} from 'react-helmet';
import {LogoBanner} from 'components/Connects/LogoBanner';

export const ConnectsFinish = () => {
  return (
    <>
      <Helmet>
        <title>MyTOCA | Finished</title>
      </Helmet>

      <div className="grow flex flex-col">
        <LogoBanner />

        <div className="mx-auto mt-16 grid place-content-center bg-white rounded border border-grey-light shadow-dispersed px-6 py-4">
          <h2 className="text-[28px] text-primary text-center p-2">Finished!</h2>
          <p className="text-lg text-primary font-medium text-center p-4">You can now close this window.</p>
        </div>
      </div>
    </>
  );
};

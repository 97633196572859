import {Outlet} from 'react-router-dom';
import {ClassicPageContainer} from 'components/PageContainer/ClassicPageContainer';

export default function ClassicLayout() {
  return (
    <ClassicPageContainer>
      <Outlet />
    </ClassicPageContainer>
  );
}

import {useQuery, useQueryClient} from '@tanstack/react-query';
import {useCallback, useMemo} from 'react';
import {useAppDispatch, useAppSelector} from '../../redux/reduxUtils';
import {resetBookingSessions} from '../../redux/slices/booking';
import {setCurrentProfileId} from '../../redux/slices/profile';
import {Profile} from '../../user/player-info.interface';
import {removeAllPlayerDataFromLocalStorage} from '../../user/user-utils';
import {getLocationBySiteId} from '../../constants/locations';
import {ProfileTeams} from 'user/user.interface';

export const useProfiles = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const {currentProfileId} = useAppSelector((state) => state.profileReducer);

  let {data: profiles} = useQuery<Profile[]>(
    ['user', 'profiles'],
    () => queryClient.getQueryData(['user', 'profiles']) ?? [],
    {initialData: []}
  );

  const {data: profileTeams} = useQuery<ProfileTeams>(
    ['user', 'profileTeams'],
    () => queryClient.getQueryData(['user', 'profileTeams']) ?? {},
    {initialData: {}}
  );

  profiles = profiles ?? [];
  profiles = useMemo(
    () =>
      profiles.map((profile) => {
        if (profile.mboDetails?.siteId) {
          return {
            ...profile,
            location: getLocationBySiteId(profile.mboDetails.siteId),
          };
        }
        return profile;
      }),
    [profiles]
  );

  const currentProfile = useMemo(
    () => profiles.find((profile) => profile._id === currentProfileId) ?? profiles[0],
    [currentProfileId, profiles]
  );

  const currentProfileTeam = useMemo(() => {
    return profileTeams?.[currentProfile?._id] || {};
  }, [profileTeams, currentProfile]);

  const otherProfiles = useMemo(
    () => (profiles.length <= 1 ? [] : profiles.filter((profile) => profile._id !== currentProfileId)),
    [currentProfileId, profiles]
  );

  const selectProfile = useCallback(
    (id: string) => {
      removeAllPlayerDataFromLocalStorage();
      dispatch(setCurrentProfileId(id));
      dispatch(resetBookingSessions());
    },
    [dispatch]
  );

  return {currentProfile, otherProfiles, selectProfile, currentProfileTeam};
};

import {useQuery} from '@tanstack/react-query';
import {getAthleteInfo, GetAthleteInfoParams} from '../api';
import {PlayerDetails} from 'api/User/useGetPlayerDetails';

export type AthleteInfo = PlayerDetails;

export const useGetAthleteInfo = ({clientId, siteId}: GetAthleteInfoParams) => {
  return useQuery<AthleteInfo>({
    queryKey: ['athlete-info', clientId],
    queryFn: () => getAthleteInfo({clientId, siteId}),
    enabled: !!clientId && !!siteId,
  });
};

import {forwardRef, useCallback, useState} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {ErrorMessage} from 'components/Forms/ErrorMessage/ErrorMessage';
import {StandardInput} from 'components/Input/StandardInput/StandardInput';

type TeamOnboardingSignUpFormSubmission = {
  firstName: string;
  lastName: string;
  birthdate: string;
  email: string;
  password: string;
};

const TeamOnboardingSignUpForm = forwardRef<HTMLFormElement>((_, ref) => {
  const [showDateInput, setShowDateInput] = useState(false);
  const [autoFocusDateInput, setAutoFocusDateInput] = useState(false);

  const validationSchema = yup.object().shape({
    firstName: yup.string().required('Required').trim('Name cannot include leading or trailing spaces'),
    lastName: yup.string().required('Required').trim('Name cannot include leading or trailing spaces'),
    birthdate: yup.date().typeError('Required').required('Required'),
    email: yup.string().required('Email is required').email('Email is invalid'),
    password: yup
      .string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters')
      .max(40, 'Password must not exceed 40 characters'),
  });

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<TeamOnboardingSignUpFormSubmission>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onFormSubmitHandler = useCallback(
    async ({firstName, lastName, birthdate}: TeamOnboardingSignUpFormSubmission) => {
      console.log('firstName, lastName, birthdate :>> ', firstName, lastName, birthdate);
    },
    []
  );

  const showDateInputAndFocus = () => {
    setAutoFocusDateInput(true);
    setShowDateInput(true);
  };

  return (
    <form ref={ref} onSubmit={handleSubmit(onFormSubmitHandler)}>
      <div>
        <StandardInput
          spellCheck="false"
          {...register('firstName')}
          type="text"
          id="firstName"
          placeholder="Player First Name"
          hasError={!!errors.firstName?.message}
        />
        <ErrorMessage text={errors.firstName?.message ?? '\u00A0'} />

        <StandardInput
          spellCheck="false"
          {...register('lastName')}
          type="text"
          id="lastName"
          placeholder="Player Last Name"
          hasError={!!errors.lastName?.message}
        />
        <ErrorMessage text={errors.lastName?.message ?? '\u00A0'} />

        <StandardInput spellCheck="false" {...register('email')} type="email" id="email" placeholder="Email" />
        <ErrorMessage text={errors.email?.message ? errors.email.message : '\u00A0'} />

        <StandardInput
          spellCheck="false"
          {...register('password')}
          type="password"
          id="password"
          placeholder="password"
        />
        <ErrorMessage text={errors.password?.message ? errors.password.message : '\u00A0'} />

        {!showDateInput && (
          <StandardInput
            spellCheck="false"
            type="text"
            placeholder="Player Date of Birth (mm/dd/yyyy)"
            onFocus={showDateInputAndFocus}
          />
        )}
        {showDateInput && (
          <StandardInput
            spellCheck="false"
            {...register('birthdate')}
            type="date"
            id="birthdate"
            placeholder="Player Date of Birth (mm/dd/yyyy)"
            autoFocus={autoFocusDateInput}
            hasError={!!errors.birthdate?.message}
            min="1900-01-01"
            max={new Date().toISOString().split('T')[0]}
          />
        )}
        <ErrorMessage text={errors.birthdate?.message ?? '\u00A0'} />
      </div>
    </form>
  );
});

TeamOnboardingSignUpForm.displayName = 'TeamOnboardingSignUpForm';

export default TeamOnboardingSignUpForm;

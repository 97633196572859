import {memo, PropsWithChildren, useMemo} from 'react';
import styles from './ConnectsComponents.module.scss';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  PropsWithChildren<{
    text?: string; // text or children can be provided
    color?: 'primary' | 'secondary' | 'grey';
    variant?: 'cta' | 'standard';
  }>;

export const Button = memo(
  ({text, color = 'primary', variant = 'standard', onClick, children, className, ...otherButtonProps}: ButtonProps) => {
    const buttonClasses = useMemo(
      () => `${styles[color]} ${styles[variant]} ${styles.button} ${className || ''}`,
      [color, variant, className]
    );

    return (
      <button className={buttonClasses} onClick={onClick} {...otherButtonProps}>
        {children || text}
      </button>
    );
  }
);
Button.displayName = 'Button';

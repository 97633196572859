import React, {useRef, useEffect, useState} from 'react';
import styles from './HorizontalScroll.module.scss';
import {debounce} from 'lodash';

/**
 * Props for the HorizontalScroll component.
 */
interface HorizontalScrollProps {
  children: React.ReactNode;
  onScrollSelectionHandler: (index: number) => void;
  activeLocationListIndex: number;
}

/**
 * A horizontal scroll component that automatically scrolls to the closest item in the center.
 * @param children - The child components to be rendered inside the scroll container.
 */
export const HorizontalScroll = ({
  children,
  onScrollSelectionHandler,
  activeLocationListIndex,
}: HorizontalScrollProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState(activeLocationListIndex);

  useEffect(() => {
    /**
     * Handles the scroll event and scrolls to the closest item in the center.
     */
    const onScrollHandler = () => {
      if (!scrollRef.current) {
        return;
      }
      const container = scrollRef.current;
      const scrollCenter = container.scrollLeft + container.offsetWidth / 2;

      const items = container.getElementsByClassName('item');
      let closestItem: HTMLElement | null = null;
      let minDiff = Infinity;
      let activeElementIndex = 0;

      for (let i = 0; i < items.length; i++) {
        const item = items[i] as HTMLElement;
        const itemCenter = item.offsetLeft + item.offsetWidth / 2;
        const diff = Math.abs(scrollCenter - itemCenter);
        if (diff < minDiff) {
          minDiff = diff;
          closestItem = item;
          activeElementIndex = i;
        }
      }
      if (closestItem) {
        container.scroll({
          left: closestItem.offsetLeft - container.offsetWidth / 2 + closestItem.offsetWidth / 2,
          behavior: 'smooth', // Smooth scrolling behavior
        });
        onScrollSelectionHandler(activeElementIndex);
      }
    };

    const debouncedScrollHandler = debounce(onScrollHandler, 300);

    if (scrollRef.current) {
      const currentScrollRef = scrollRef.current; // Capture the current value of ref
      currentScrollRef.addEventListener('scroll', debouncedScrollHandler);

      return () => {
        currentScrollRef.removeEventListener('scroll', debouncedScrollHandler);
      };
    }
  }, [onScrollSelectionHandler, children]);

  // When the index prop changes, scroll to the corresponding item if it's out of view
  useEffect(() => {
    if (scrollRef.current && activeLocationListIndex !== activeIndex) {
      const container = scrollRef.current;
      const items = container.getElementsByClassName('item');

      if (activeLocationListIndex >= 0 && activeLocationListIndex < items.length) {
        const item = items[activeLocationListIndex] as HTMLElement;
        const itemLeft = item.offsetLeft;
        const itemRight = itemLeft + item.offsetWidth;
        const containerLeft = container.scrollLeft;
        const containerRight = containerLeft + container.offsetWidth;

        if (itemLeft < containerLeft || itemRight > containerRight) {
          container.scroll({
            left: itemLeft - container.offsetWidth / 2 + item.offsetWidth / 2,
            behavior: 'smooth',
          });
          setActiveIndex(activeLocationListIndex);
        }
      }
    }
  }, [activeLocationListIndex, activeIndex]);

  return (
    <div className={styles.horizontalScroll} ref={scrollRef}>
      {React.Children.map(children, (child, index) => (
        <div key={index} className="item">
          {child}
        </div>
      ))}
    </div>
  );
};

import {useCallback} from 'react';
import {useAppDispatch, useAppSelector} from 'redux/reduxUtils';
import {setActiveTeamId as setActiveTeamIdAction} from 'redux/slices/coach';

export const useGlobalActiveTeamState = () => {
  const dispatch = useAppDispatch();
  const globalActiveTeamId = useAppSelector((state) => state.coachReducer.activeTeamId);

  const setGlobalActiveTeamId = useCallback(
    (activeTeamId: string) => {
      dispatch(setActiveTeamIdAction(activeTeamId));
    },
    [dispatch]
  );

  return {setGlobalActiveTeamId, globalActiveTeamId};
};

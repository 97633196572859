import {SubmitHandler, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {StandardInput} from 'components/Input/StandardInput/StandardInput';
import {ErrorMessage} from 'components/Forms/ErrorMessage/ErrorMessage';
import {BookingFormButton} from 'components/Buttons/Form/FormButton';

type FormTypes = {
  email: string;
};

type Props = {
  errorMessage: string;
  onSubmitHandler: SubmitHandler<FormTypes>;
};

export const ForgetPasswordForm = ({errorMessage, onSubmitHandler}: Props) => {
  const validationSchema = yup.object().shape({
    email: yup.string().required('Email is required').email('Email is invalid'),
  });

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<FormTypes>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div>
        <StandardInput spellCheck="false" {...register('email')} type="email" id="email" placeholder="Email" />
        <ErrorMessage text={errors.email?.message ? errors.email.message : '\u00A0'} />
      </div>
      <div>
        <ErrorMessage text={errorMessage ? errorMessage : '\u00A0'} />
        <BookingFormButton
          hasError={Object.keys(errors).length !== 0}
          isPrimary
          type="submit"
          text="Send Password Reset Email"
        />
      </div>
    </form>
  );
};

import {memo} from 'react';
import styles from './Home.module.scss';
import {Helmet} from 'react-helmet';
import {ReactComponent as MyTocaLogo} from '../../assets/img/icons/redesign-icons/mytoca-logo.svg';
import {NewHome} from './NewHome';
import {useProfiles} from 'api/User/useProfiles';
import {connectsIsEnabledForUser} from 'feature-flags/connects';
import {NewOldHome} from './NewOldHome';

export const Home = memo(() => {
  const displayComingSoon = false;

  const {currentProfile} = useProfiles();
  const showConnectsScreens = connectsIsEnabledForUser({
    siteId: currentProfile.mboDetails?.siteId ?? 'void',
    email: currentProfile.email ?? 'void',
  });

  // -- Use this in case we need to roll out new pathways on a per-site basis --
  //const {currentProfile} = useProfiles();
  // return <>{showNewPathways(currentProfile.mboDetails?.siteId ?? 'no') ? <NewHome /> : <OldHome />}</>;

  // -- in case we need legacy pathway support --
  // const {legacy} = queryString.parse(location.search);
  // const [isLegacyPathways, setIsLegacyPathways] = useState(false);
  // return isLegacyPathways ? (
  //   <OldHome setIsLegacyPathways={setIsLegacyPathways} />
  // ) : (
  //   <NewHome setIsLegacyPathways={setIsLegacyPathways} />
  // );

  // TODO Captain Planet levels of garbage cleanup
  return displayComingSoon ? <ComingSoon /> : showConnectsScreens ? <NewHome /> : <NewOldHome />;
});
Home.displayName = 'Home';

function ComingSoon() {
  return (
    <>
      <Helmet>
        <title>MyTOCA | Home</title>
      </Helmet>
      <div className={styles.container}>
        <div className={styles.logo}>
          <MyTocaLogo />
        </div>
        <div>
          <div className={styles.cardHeader} style={{fontSize: '2rem', marginTop: '2rem'}}>
            <h3>Updates coming soon!</h3>
          </div>
          <div style={{fontSize: '2rem', marginTop: '1rem'}}>
            <p>We are currently updating our training plans and associated training data.</p>
            <p>Please check back soon.</p>
          </div>
        </div>
      </div>
    </>
  );
}

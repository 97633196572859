import {useParams} from 'react-router-dom';
import {LeaderboardPageContainer} from '../LeaderboardPageContainer/LeaderboardPageContainer';
import {LeaderboardDetails} from './LeaderboardDetails';
import styles from './LeaderboardDetails.module.scss';
import {LoadingAnimationFullHeight} from 'components/Loader/LoadingAnimation';
import {PageHeading} from 'components/PageHeading/PageHeading';
import {useGetLeaderboardRankings} from 'api/Leaderboards/useGetLeaderboardRankings';

export function LeaderboardDetailsData() {
  const {id: leaderboardId} = useParams<{id: string}>();

  const {data, isLoading} = useGetLeaderboardRankings({leaderboardId});

  if (!data && isLoading) {
    return (
      <LeaderboardPageContainer>
        <LoadingAnimationFullHeight />
      </LeaderboardPageContainer>
    );
  }

  if (!data || !data.leaderboard) {
    return (
      <LeaderboardPageContainer>
        <PageHeading text="Leaderboards" backDestination="/leaderboards" />
        <div className={styles.notFound}>
          <h2>Leaderboard not found</h2>
        </div>
      </LeaderboardPageContainer>
    );
  }

  return <LeaderboardDetails leaderboard={data} />;
}

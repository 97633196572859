import {Button} from 'components/Connects/Button';
import {Modal} from 'components/Modal/Modal';

interface ConfirmationModalProps {
  message: string;
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationModal = ({isOpen = false, message, onConfirm, onCancel}: ConfirmationModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onCancel} showHeader={false}>
      <div className="flex flex-col items-center p-3">
        <div className="flex flex-col items-center">
          <div className="text-lg text-primary leading-7 font-semibold">{message}</div>
          <div className="flex items-center justify-center mt-4">
            <Button text="Confirm" color="secondary" variant="cta" onClick={onConfirm} />
            <Button className="ml-4" text="Cancel" color="primary" variant="standard" onClick={onCancel} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;

import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useCallback} from 'react';
import {setSessionSelection as setSessionSelectionApi, SetSessionSelectionRequestPayload} from '../api';
import {toast} from 'react-toastify';

export const useUpdateSessionSelection = ({clientId}: {clientId?: string | null}) => {
  const queryClient = useQueryClient();

  const mutateSessionSelection = useMutation<void, unknown, SetSessionSelectionRequestPayload>({
    mutationFn: (sessionSelectionRequestPayload) =>
      setSessionSelectionApi(sessionSelectionRequestPayload).then((res: any) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['player-last-session', clientId]});
      toast.success('Sessions selected successfully');
    },
    onError: () => {
      toast.error('Failed to select sessions');
    },
  });

  const setSessionSelection = useCallback(
    async (sessionSelectionRequestPayload: SetSessionSelectionRequestPayload) => {
      return mutateSessionSelection.mutate(sessionSelectionRequestPayload);
    },
    [mutateSessionSelection]
  );

  return {setSessionSelection, loading: mutateSessionSelection.isLoading};
};

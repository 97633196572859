import {useQuery} from '@tanstack/react-query';
import {getTeamPublicInfo} from 'api/api';
import {Team} from 'user/user.interface';

export const useGetTeamPublicInfo = ({teamId}: {teamId?: string}) => {
  return useQuery<Partial<Team>>({
    queryKey: ['team-public', teamId],
    queryFn: () => getTeamPublicInfo({teamId}),
    enabled: !!teamId,
  });
};

import {useEffect} from 'react';
import {logAnalyticsEvent} from '../../../common/analytics-events';
import {useLogout} from '../../../hooks/useLogout';
import {useGetSport} from 'common/useGetSport';

export const FkoRestart = () => {
  const {logout} = useLogout();
  const {basePath, fkoAnalyticsPrefix} = useGetSport();

  useEffect(() => {
    logAnalyticsEvent(`${fkoAnalyticsPrefix}_restart`);
    logout(basePath);
  }, [basePath, fkoAnalyticsPrefix, logout]);

  return null;
};

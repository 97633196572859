import {memo} from 'react';
import styles from './FormSectionHeading.module.scss';

export const FormSectionHeading = memo(({text, lightText = false}: {text: string; lightText?: boolean}) => {
  return (
    <div className={styles.container}>
      <span className={`${styles.sectionText} ${lightText && styles.lightText}`}>{text.toUpperCase()}</span>
      <span className={`${styles.sectionLine} ${lightText && styles.lightText}`} />
    </div>
  );
});
FormSectionHeading.displayName = 'FormSectionHeading';

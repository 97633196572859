import {useQuery} from '@tanstack/react-query';
import {PlayerSessionSummary} from 'api/Trainer/useGetPlayerLastSessionSummary';
import {getPlayerSession} from 'api/api';

export type GetPlayerSessionParams = {clientId?: string | null; siteId?: number | null; sessionId?: string | null};

export const useGetPlayerSession = ({sessionId, clientId, siteId}: GetPlayerSessionParams) => {
  return useQuery<PlayerSessionSummary>({
    queryKey: ['player-last-session', clientId, siteId, sessionId],
    queryFn: () => getPlayerSession({clientId, siteId, sessionId}),
    enabled: !!clientId && !!siteId && !!sessionId,
  });
};

import {useCallback} from 'react';
import {ClassSchedule, Program, useGetPrograms} from './useGetPrograms';
import {UseQueryResult} from '@tanstack/react-query';
import {GetMboProgramsParams} from 'api/api';

type useGetSingleClassScheduleParams = GetMboProgramsParams & {
  programId?: string | null;
  classScheduleId?: string | null;
};

export const useGetSingleClassSchedule = ({siteId, programId, classScheduleId}: useGetSingleClassScheduleParams) => {
  const select = useCallback(
    (data: Program[]) => {
      const program = data.find((program) => program.programId === programId);
      return program?.classSchedules?.find((classSchedule) => classSchedule.classScheduleId === classScheduleId);
    },
    [classScheduleId, programId]
  );

  return useGetPrograms({siteId, select}) as UseQueryResult<ClassSchedule, unknown>;
};

import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {SubmitHandler, useForm} from 'react-hook-form';
import {FormSelect} from 'components/Forms/FormSelect/FormSelect';
import {Button} from 'components/Connects/Button';
import {classesLocationFormOptionGroups} from 'api/FKO/useGetLocationFormOptionGroups';

export const LocationSelectForm = ({
  defaultLocationId,
  onSubmitHandler,
}: {
  defaultLocationId?: string;
  onSubmitHandler: SubmitHandler<{location: string}>;
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<{location: string}>({
    mode: 'onBlur',
    resolver: yupResolver(
      yup.object().shape({
        location: yup.string().required('Required').notOneOf(['disabled-placeholder'], 'Please choose a location'),
      })
    ),
    defaultValues: defaultLocationId ? {location: defaultLocationId} : {location: 'disabled-placeholder'},
  });

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} className="grow w-full flex flex-col gap-3">
      <FormSelect
        {...register('location')}
        id="location"
        hasError={!!errors.location}
        optionGroups={classesLocationFormOptionGroups}
        placeholderValue="TOCA Training Center"
        onChange={(e) => setValue('location', e.currentTarget.value, {shouldValidate: true})}
      />
      <Button type="submit" variant="cta" text="confirm location" />
    </form>
  );
};

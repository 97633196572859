import {useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useProfiles} from 'api/User/useProfiles';
import {roleType} from 'constants/constants';
import {useGetDirectorInfo} from 'api/Director/useGetDirectorInfo';
import {TeamView} from 'pages/Coach/CoachTeam/Team.view';
import {getUserDisplayName} from 'utils/utils';

export const DirectorTeam = () => {
  const {teamId} = useParams();
  const navigate = useNavigate();
  const {currentProfile} = useProfiles();
  const {data: directorInfo, isLoading} = useGetDirectorInfo(currentProfile?._id);
  const teamInfo = useMemo(
    () => directorInfo?.teams.find((team) => team._id === teamId),
    [teamId, directorInfo?.teams]
  );
  const teamDirectorName = getUserDisplayName(currentProfile.firstName, currentProfile.lastName);
  const onClickInviteHandler = () => {
    navigate(`/director/teams/${teamInfo?._id}/invite`);
  };

  return (
    <TeamView
      onClickInviteHandler={onClickInviteHandler}
      team={teamInfo}
      loginUserName={teamDirectorName}
      loading={isLoading}
      role={roleType.director}
    />
  );
};

import {useQueryClient} from '@tanstack/react-query';
import {createContext, PropsWithChildren, useCallback, useContext, useMemo, useState} from 'react';
import {useGetUser} from '../api/User/useGetUser';
import {User} from '../user/user.interface';
import {setCurrentProfileId} from '../redux/slices/profile';
import {useAppDispatch} from '../redux/reduxUtils';
import {isTrainerUser, setCurrentProfileId as setCurrentProfileIdInLocalStorage} from '../user/user-utils';
import {UserContext} from './UserContext';
import {Profile} from '../user/player-info.interface';

export type TrainerContextProps = {
  trainer: User;
  emulationStatus: {
    isDirect: boolean;
    isEmulating: boolean;
  };
  emulateUser: (user: User, profileId: string, direct?: boolean) => void;
  endEmulateUser: () => void;
};

type EmulationInfo = {
  user: User;
  profileId: string;
  emulationStatus: {
    isDirect: boolean;
    isEmulating: boolean;
  };
};

const TrainerContext = createContext<TrainerContextProps | undefined>(undefined);

export const useTrainerContext = () => useContext(TrainerContext);

export const UserProvider = ({children}: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const {data: user} = useGetUser();
  const [emulationInfo, setEmulationInfo] = useState<EmulationInfo | undefined>();
  const _isTrainer = isTrainerUser(user);

  const updateEmulatedUser = useCallback(
    (emulationInfo: EmulationInfo | undefined) => {
      if (!user) {
        return;
      }

      // let accountId: string;
      let profileId: string;
      let profiles: Profile[];
      if (emulationInfo) {
        // accountId = emulationInfo.user.accountId;
        profileId = emulationInfo.profileId;
        profiles = emulationInfo.user.profiles;
      } else {
        // accountId = user?.accountId;
        profileId = user?.profiles.find((p) => p.role?.includes('trainer'))?._id ?? user.profiles[0]._id;
        profiles = user?.profiles;
      }
      // NOTE: The `useAuthStatus` hook will override the currentAccountId and set its value to the accountId of
      // the current user (e.g. the trainer, not the emulated user). Server-side, we don't currently need the accountId.
      // setCurrentAccountId(accountId);
      setCurrentProfileIdInLocalStorage(profileId);
      void queryClient.invalidateQueries(['user', 'details']);
      queryClient.setQueryData(['user', 'profiles'], profiles);
      dispatch(setCurrentProfileId(profileId));
      setEmulationInfo(emulationInfo);
    },
    [dispatch, queryClient, user]
  );

  const emulateUser = useCallback(
    (user: User, profileId: string, direct?: boolean) => {
      updateEmulatedUser({user: user, profileId: profileId, emulationStatus: {isDirect: !!direct, isEmulating: true}});
    },
    [updateEmulatedUser]
  );

  const endEmulateUser = useCallback(() => {
    updateEmulatedUser(undefined);
  }, [updateEmulatedUser]);

  const trainerContext = useMemo(
    () =>
      _isTrainer && user
        ? {
            trainer: user,
            emulationStatus: {
              isDirect: !!emulationInfo?.emulationStatus.isDirect,
              isEmulating: !!emulationInfo?.emulationStatus.isEmulating,
            },
            emulateUser,
            endEmulateUser,
          }
        : undefined,
    [
      _isTrainer,
      user,
      emulationInfo?.emulationStatus.isDirect,
      emulationInfo?.emulationStatus.isEmulating,
      emulateUser,
      endEmulateUser,
    ]
  );

  const userToBeUsed = _isTrainer && emulationInfo?.user ? emulationInfo.user : user;

  return (
    <TrainerContext.Provider value={trainerContext}>
      <UserContext.Provider value={userToBeUsed}>{children}</UserContext.Provider>
    </TrainerContext.Provider>
  );
};

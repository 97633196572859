import './firebase/config'; // keep this to initialize analytics and other things right away
import {Provider} from 'react-redux';
import store from './redux/store';
import {HubspotProvider} from '@aaronhayes/react-use-hubspot-form';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {routes} from './routes';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import {STALE_TIME} from './api/utils';
import {trackUserViaCustomClientId} from './common/analytics-events';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: STALE_TIME,
      refetchOnWindowFocus: false,
    },
  },
});

trackUserViaCustomClientId();

const router = createBrowserRouter(routes);

// <!-- Begin Monetate ExpressTag Sync v8.1. Place at start of document head. DO NOT ALTER. -->
//     <script type="text/javascript">var monetateT = new Date().getTime();</script>
//     <script type="text/javascript" src="[redacted]"></script>
// <!-- End Monetate tag. -->
const monetateUrl = process.env.REACT_APP_MONETATE_SCRIPT_URL;
if (monetateUrl) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const monetateT = new Date().getTime(); // this is declared above...i think we need it?
  const monetateScriptElement = document.createElement('script');
  monetateScriptElement.src = monetateUrl;
  monetateScriptElement.type = 'text/javascript';
  monetateScriptElement.async = true;
  document.head.appendChild(monetateScriptElement);
}

export default function App() {
  return (
    <Provider store={store}>
      <HubspotProvider>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </HubspotProvider>
    </Provider>
  );
}

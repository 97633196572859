import styles from './DateCarousel.module.scss';
import {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper';
import type {Swiper as SwiperType} from 'swiper';
import {AppointmentCalendar} from '../../redux/slices/fkoFormData';
import {ReactComponent as ArrowLeftIcon} from '../../assets/img/icons/redesign-icons/arrow-filled-circle-left.svg';
import {ReactComponent as ArrowRightIcon} from '../../assets/img/icons/redesign-icons/arrow-filled-circle-right.svg';

/*
 * This is NOT the Carousel used in FKO
 * TODO merge carousels
 */
export const DateCarousel = ({
  appointmentCalendar,
  onDateSelect,
  selectedDate,
  setSelectedDate,
  scrollToDate,
}: {
  appointmentCalendar: AppointmentCalendar;
  onDateSelect: (dateStr: string) => void;
  selectedDate: string;
  setSelectedDate: (key: string) => void;
  scrollToDate?: string;
}) => {
  const [swiper, setSwiper] = useState<SwiperType>();

  useEffect(() => {
    if (swiper && scrollToDate) {
      const index = Object.keys(appointmentCalendar).findIndex((date) => date === scrollToDate);
      swiper?.slideTo(index - 1);
    }
  }, [appointmentCalendar, scrollToDate, swiper]);

  const onSlideSelection = (key: string) => {
    setSelectedDate(key);
    onDateSelect(key);
  };

  return (
    <div className={styles.container}>
      <div className="relative bg-white mx-[-20px] md:mx-[-32px] py-[20px]">
        <ArrowLeftIcon
          id="button-prev"
          className="h-[32px] w-[32px] bg-white rounded-full fill-primary active:fill-secondary opacity-90 hover:opacity-100 absolute left-px top-[50%] translate-y-[-50%] z-10 transition-all"
        />
        <ArrowRightIcon
          id="button-next"
          className="h-[32px] w-[32px] bg-white rounded-full fill-primary active:fill-secondary opacity-90 hover:opacity-100 absolute right-px top-[50%] translate-y-[-50%] z-10 transition-all"
        />
        <Swiper
          modules={[Navigation]}
          id="date-carousel"
          onSwiper={setSwiper}
          slidesPerView="auto"
          navigation={{
            nextEl: '#button-next',
            prevEl: '#button-prev',
            disabledClass: '!opacity-0',
          }}
          freeMode={true}
          spaceBetween={16}
          slidesOffsetAfter={64}
          slidesOffsetBefore={24}
        >
          {Object.keys(appointmentCalendar).map((dateKey: any, index: number) => (
            <SwiperSlide key={index} onClick={() => onSlideSelection(dateKey)} className={styles.carouselSlide}>
              <div
                className={`${styles.carouselItem} ${selectedDate === dateKey ? styles.selected : ''} ${
                  !appointmentCalendar[dateKey].appointments.length && appointmentCalendar[dateKey].hasFetched
                    ? styles.empty
                    : ''
                } `}
                data-date={dateKey}
              >
                <p className={styles.day}>{appointmentCalendar[dateKey].dayStrShort}</p>
                <p className={styles.date}>
                  {appointmentCalendar[dateKey].monthStr}
                  <span>&nbsp;{appointmentCalendar[dateKey].day}</span>
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useGetPrograms} from 'api/Classes/useGetPrograms';
import {Helmet} from 'react-helmet';
import {LoadingAnimationFullHeight} from 'components/Loader/LoadingAnimation';
import {PageHeading} from 'components/Connects/PageHeading';
import {MenuBar} from 'components/MenuBar/MenuBar';
import {Breadcrumbs} from 'components/Breadcrumbs/Breadcrumbs';
import {ProgramCard} from 'components/Programs/ProgramCard';
import {LocationSelectForm} from 'components/Programs/LocationSelect';
import {ALLOWED_PROGRAM_NAMES} from 'utils/programs-utils';

export const ProgramsRoot = memo(() => {
  const navigate = useNavigate();
  const {siteId} = useParams();
  const [view, setView] = useState<View>('all');

  const allowedNames = ALLOWED_PROGRAM_NAMES;

  // TODO CLASSES confirm filtering needs and fix this
  const {data: programs, isLoading: isLoadingPrograms} = useGetPrograms({
    siteId,
    select: (programs) =>
      programs.filter(
        (program) =>
          view === 'all'
            ? program.classSchedules?.length && allowedNames.includes(program.name)
            : program.tags?.includes(view)
        // (program) => (view === 'all' ? program : program.tags?.includes(view))
        // (program) => program.enrollments?.length && (view === 'all' ? program : program.tags?.includes(view))
      ),
  });

  // TODO confirm display logic
  const includesMultipleViews = useMemo(
    () => programs?.find((program) => program.tags?.includes('indoor') || program.tags?.includes('outdoor')),
    [programs]
  );

  const onLocationSubmit = useCallback(
    ({location}: {location: string}) => {
      navigate(`${location}`);
    },
    [navigate]
  );

  useEffect(() => {
    if (programs?.length === 1) {
      navigate(`${programs[0].programId}`, {replace: true});
    }
  }, [programs]);

  return (
    <>
      <Helmet>
        <title>TOCA Soccer | Programs</title>
      </Helmet>

      <div className="grow flex flex-col gap-3 sm:gap-6">
        <MenuBar />
        <Breadcrumbs />

        {/* ensure heading doesn't momentarily flash into view before redirect */}
        {siteId && !isLoadingPrograms && programs?.length !== 1 ? (
          <div className="flex flex-col gap-4 md:gap-6 items-center lg:flex-row lg:justify-between mb-2 lg:mb-4">
            <PageHeading text="Our Programs" />
            {includesMultipleViews && <ViewToggle view={view} setView={setView} />}
          </div>
        ) : null}

        {siteId &&
          (isLoadingPrograms ? (
            <LoadingAnimationFullHeight />
          ) : programs?.length ? (
            <ul className="grid grid-cols-1 gap-y-12 gap-x-4 sm:grid-cols-2 md:grid-cols-3">
              {programs.map((program, i) => (
                <li key={`prog_list_card_${i}`}>
                  <ProgramCard program={program} />
                </li>
              ))}
            </ul>
          ) : (
            <div>
              <h4>No Programs were found for this location.</h4>
            </div>
          ))}

        {!siteId && (
          <div className="w-full grid place-content-center">
            <div className="flex flex-col gap-6 p-4 sm:px-16 sm:py-8 bg-white rounded border border-grey shadow-flat-lg">
              <p className="text-lg text-primary text-center">Please select your TOCA location to view programs:</p>
              <LocationSelectForm onSubmitHandler={onLocationSubmit} />
            </div>
          </div>
        )}
      </div>
    </>
  );
});
ProgramsRoot.displayName = 'ProgramsRoot';

type View = 'all' | 'indoor' | 'outdoor';
const ViewToggle = memo(({view, setView}: {view: View; setView: React.Dispatch<React.SetStateAction<View>>}) => {
  return (
    <div className="flex justify-center items-center gap-0 sm:gap-1">
      <button
        className={`font-poppins text-sm text-grey-xdark rounded-full px-4 py-2 transition-colors ${
          view === 'all' ? 'bg-secondary text-white' : ''
        }`}
        onClick={() => setView('all')}
      >
        View&nbsp;All
      </button>
      <button
        className={`font-poppins text-sm text-grey-xdark rounded-full px-4 py-2 transition-colors ${
          view === 'indoor' ? 'bg-secondary text-white' : ''
        }`}
        onClick={() => setView('indoor')}
      >
        Indoor&nbsp;Soccer
      </button>
      <button
        className={`font-poppins text-sm text-grey-xdark rounded-full px-4 py-2 transition-colors ${
          view === 'outdoor' ? 'bg-secondary text-white' : ''
        }`}
        onClick={() => setView('outdoor')}
      >
        Outdoor&nbsp;Soccer
      </button>
    </div>
  );
});
ViewToggle.displayName = 'ViewToggle';

import {memo} from 'react';
import CommonRegistrationPage from 'components/Forms/CommonRegistrationForm/CommonRegistrationPage';
import { getFreePickupAndLeaguesFormDataForSubmission, getFreePickupAndLeaguesFormFields } from 'common/freePickupandLeaguesUtils';
import { FreeTrialProgram, relevantFreePickupSoccerAndLeaguesUrlParams } from 'common/fko-utils';

const FreePickupSoccerRegistrationPage = memo(() => {
    const locationsAllowed = [
      'Denver', 
      'Loganville', 
      'Evanston', 
      'Naperville', 
      'Burnsville', 
      'St. Louis', 
      'Richmond', 
      'Nashville', 
      'Allen', 
      'Carrollton', 
      'Keller', 
      'The Colony', 
      'Madison'
    ];

    return (
      <CommonRegistrationPage 
        programName={FreeTrialProgram.PICKUP_SOCCER} 
        locationsAllowed={locationsAllowed} 
        formId={process.env.REACT_APP_HUBSPOT_FREE_PICKUP_SOCCER_FORM_ID ?? ''}
        relevantUrlParams={relevantFreePickupSoccerAndLeaguesUrlParams} 
        getFormFields={getFreePickupAndLeaguesFormFields}
        getHubspotFormDataForSubmission={getFreePickupAndLeaguesFormDataForSubmission}
      />
    );
  });
  
  FreePickupSoccerRegistrationPage.displayName = 'FreePickupSoccerRegistrationPage';
  
  export default FreePickupSoccerRegistrationPage;
  